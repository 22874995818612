import TitleCard from '../../BotsPage/BotSettings/Blocks/TitleCard';
import LineOfCard from './LineOfCard';
import styles from './Cards.module.css';
import { useTranslation } from 'react-i18next';

export default function Timing() {
  const formatOption: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  };
  const { t } = useTranslation('botsStats');

  const launched: string = new Intl.DateTimeFormat('en', formatOption).format(new Date());
  return (
    <div className={styles.container}>
      <TitleCard title={t('timing')} />
      <LineOfCard title={t('botId')} value='Value' shortTitle={true} />
      <LineOfCard title={t('launched')} value={launched} shortTitle={true} />
      <LineOfCard title={t('startedPrice')} value='-' shortTitle={true} />
      <LineOfCard title={t('finished')} value='-' shortTitle={true} />
    </div>
  );
}
