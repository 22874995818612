// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Pagination_container__mIFgO {
  display: flex;
  position: relative;
  width: 100%;
  margin-top: 20px;
  align-items: center;
}
.Pagination_pagination__1tSqm {
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.Pagination_pages__4x4Xf {
  color: #fff;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.Pagination_page__yGmFk {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  padding: 0 12px;
  color: #fff;
  font-size: 17px;
  font-style: normal;
  line-height: 17px;
  font-weight: 400;
}

.Pagination_activePage__5HTpn {
  align-items: center;
  justify-content: center;
  height: 32px;
  padding: 0 12px;
  border-radius: 12px;
  background: var(--purple-background);
  color: #fff;
  font-size: 17px;
  line-height: 17px;
  font-style: normal;
  font-weight: 400;
}
`, "",{"version":3,"sources":["webpack://./src/components/AdminPage/UsersListPage/Pagination/Pagination.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;EAClB,WAAW;EACX,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;AAClC;;AAEA;EACE,WAAW;EACX,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;EACZ,eAAe;EACf,WAAW;EACX,eAAe;EACf,kBAAkB;EAClB,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;EACZ,eAAe;EACf,mBAAmB;EACnB,oCAAoC;EACpC,WAAW;EACX,eAAe;EACf,iBAAiB;EACjB,kBAAkB;EAClB,gBAAgB;AAClB","sourcesContent":[".container {\n  display: flex;\n  position: relative;\n  width: 100%;\n  margin-top: 20px;\n  align-items: center;\n}\n.pagination {\n  display: flex;\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n}\n\n.pages {\n  color: #fff;\n  font-size: 17px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 24px;\n}\n\n.page {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 32px;\n  padding: 0 12px;\n  color: #fff;\n  font-size: 17px;\n  font-style: normal;\n  line-height: 17px;\n  font-weight: 400;\n}\n\n.activePage {\n  align-items: center;\n  justify-content: center;\n  height: 32px;\n  padding: 0 12px;\n  border-radius: 12px;\n  background: var(--purple-background);\n  color: #fff;\n  font-size: 17px;\n  line-height: 17px;\n  font-style: normal;\n  font-weight: 400;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Pagination_container__mIFgO`,
	"pagination": `Pagination_pagination__1tSqm`,
	"pages": `Pagination_pages__4x4Xf`,
	"page": `Pagination_page__yGmFk`,
	"activePage": `Pagination_activePage__5HTpn`
};
export default ___CSS_LOADER_EXPORT___;
