import { useAppSelector } from '../../redux/hooks/redux';
import { selectBot } from '../../redux/selectors/rootSelector';
import { useGetBotOrdersMutation } from '../../redux/services/botService';
import { IOrderStatistics } from '../../redux/types';
import { formatDeepSnakeObjKeysToCamelCase } from '../../utils/formatSnakeToCamelCase';

enum EResponseCode {
  notFound = 'bot.not_found',
}

export const useRequestBotOrders = () => {
  const selectedBot = useAppSelector(selectBot);

  const [getBotOrders] = useGetBotOrdersMutation();

  const requestBotOrders = async () => {
    try {
      const response = await getBotOrders(selectedBot?.flowId).unwrap();

      if (response.status.code === EResponseCode.notFound) {
        return { totalOrderCount: 0, orders: [] };
      }
      return formatDeepSnakeObjKeysToCamelCase(
        response.bot_statistics as { totalOrderCount: number; orders: IOrderStatistics[] },
      );
    } catch (error) {
      console.error(error);
      return { totalOrderCount: 0, orders: [] };
    }
  };

  return requestBotOrders;
};
