import cn from 'classnames';
import styles from './EquityTable.module.css';

import { prepareEquityData } from '../utils';
import { useAppSelector } from '../../../../../redux/hooks/redux';
import { selectAdminStats } from '../../../../../redux/selectors/rootSelector';

export default function EquityTable() {
  const formatOption: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  };

  const adminPanel = useAppSelector(selectAdminStats).adminPanelInfo;
  const { equityData } = adminPanel;
  const preparedEquityData = prepareEquityData(equityData);

  return (
    <div className={styles.table}>
      <div className={cn(styles.group, styles.firstGroup)} />
      <div className={styles.header}>
        <div className={styles.cell}>Date</div>
        <div className={styles.usersCell}>Running equity</div>
        <div className={styles.usersCell}>Average by user</div>
        <div className={styles.usersCell}>Average by bot</div>
        <div className={styles.usersCell}>Equity allocation</div>
      </div>
      <div className={styles.tableData}>
        {preparedEquityData.map(({ id, date, equity, averageUsers, averageBots, equityAllocation }) => (
          <div className={styles.row} key={id}>
            <div className={styles.cell}>
              {new Intl.DateTimeFormat('ru', formatOption).format(date)}
            </div>
            <div className={styles.cell}>{equity}</div>
            <div className={styles.cell}>{averageUsers}</div>
            <div className={styles.cell}>{averageBots}</div>
            <div className={styles.cell}>{equityAllocation}</div>
          </div>
        ))}
      </div>
    </div>
  );
}
