import { useTranslation } from 'react-i18next';
import styles from '../BotSettings.module.css';
import { memo } from 'react';

interface IButtonsSettings {
  onLaunch: () => void;
  onDiscard: () => void;
  onSave: () => void;
  isLaunchBlocked?: boolean;
  isCreateBlocked?: boolean;
}

function ButtonsSettings({
  onLaunch,
  onDiscard,
  onSave,
  isLaunchBlocked,
  isCreateBlocked,
}: IButtonsSettings) {
  const { t } = useTranslation('botspage');
  return (
    <div className={styles.buttonsBlock}>
      <button className={styles.discardBtn} onClick={onDiscard}>
        {t('settings.discardBtn')}
      </button>
      <div className={styles.innerButtons}>
        <button className={styles.saveBtn} onClick={onSave} disabled={isCreateBlocked}>
          {t('settings.saveBtn')}
        </button>
        <button className={styles.launchBtn} onClick={onLaunch} disabled={isLaunchBlocked}>
          {t('settings.launchBotBtn')}
        </button>
      </div>
    </div>
  );
}

export default memo(ButtonsSettings);
