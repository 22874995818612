import Tabs, { ITabsProps } from '../../../StatisticPage/Tabs/Tabs';
import styles from '../BotSettings.module.css';

export default function TabsSettingsCard({ tabs, activeTab, onSetActiveTab }: ITabsProps) {
  return (
    <Tabs
      tabs={tabs}
      activeTab={activeTab}
      onSetActiveTab={onSetActiveTab}
      tabClassName={styles.tabs}
      activeTabClassName={styles.activeTab}
    />
  );
}
