import { ChangeEvent, FormEvent, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSignUpUserMutation } from '../../redux/services/signUpService';
import { Checkbox, Input } from '../CCLabsUI';
import { ESizing } from '../CCLabsUI/Input/Input';
import styles from './SignUp.module.css';

enum EResponseCodes {
  alreadyExist = 'email.list.exists',
  busy = 'email.busy',
  notFound = 'email.not_found',
}

enum ESuccessCode {
  ok = 'ok',
}

interface ResponseErrorMessages {
  [key: string]: string;
}

// TODO: Remove variable after adding ru/en version
const BETA_TEST_MESSAGE =
  'В данный момент идет закрытое бета-тестирование сервиса. Но мы будем очень рады, если вы присоединитесь к нам на открытом тестировании! Поэтому мы добавили вас в лист ожидания. Уведомление о дате начала открытого бета-тестирования мы пришлем на указанный адрес.';

const RESPONSE_ERROR_MESSAGES: ResponseErrorMessages = {
  [EResponseCodes.busy]:
    'Provided e-mail has been already registered and verified. If it is yours do not use it to create another account until you remove the current one.',
  [EResponseCodes.notFound]: BETA_TEST_MESSAGE,
  [EResponseCodes.alreadyExist]: BETA_TEST_MESSAGE,
};

const SignUp: React.FC = () => {
  const { t } = useTranslation('signUp');
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [isAccepted, setIsAccepted] = useState(false);
  const [isError, setIsError] = useState(false);
  const [responseMessage, setResponseMessage] = useState<string>();
  const [isSuccess, setIsSuccess] = useState(false);

  const [signUpUser] = useSignUpUserMutation();

  const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

  const isSubmitBtnDisabled = !(isAccepted && emailRegex.test(email));

  const onSetEmail = (e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value);

  const onSignUp = async (e: FormEvent) => {
    e.preventDefault();
    try {
      const {
        status: { code },
      } = await signUpUser(email).unwrap();

      if (code === ESuccessCode.ok) {
        return setIsSuccess(true);
      }

      if (Object.values(EResponseCodes).includes(code)) {
        setIsError(true);
        setResponseMessage(RESPONSE_ERROR_MESSAGES[code]);
      }
    } catch (error) {
      console.error(error);
      setIsError(true);
      setResponseMessage('Something went wrong');
    }
  };

  const isShowForm = !isError && !isSuccess;
  return (
    <div className={styles.container}>
      <div className={styles.formCard}>
        {isShowForm && (
          <>
            <h2>{t('signUp')}</h2>
            <form>
              <Input
                className={styles.form_input}
                name='email'
                label={t('label')}
                sizing={ESizing.medium}
                value={email}
                onChange={onSetEmail}
              />
              <div className={styles.user_agreement}>
                <Checkbox
                  className={styles.user_agreement_checkbox}
                  name='userAgreement'
                  label=''
                  checked={isAccepted}
                  onChange={() => setIsAccepted((state) => !state)}
                />
                <div>
                  <span>{t('accept')} </span>
                  <a className={styles.underline_dotted} href='#'>
                    {t('agreement')}
                  </a>
                  <span> {t('and')} </span>
                  <a className={styles.underline_dotted} href='#'>
                    {t('privacy')}
                  </a>
                </div>
              </div>
              <button
                className={styles.submit}
                type='submit'
                disabled={isSubmitBtnDisabled}
                onClick={onSignUp}
              >
                {t('submitBtn')}
              </button>
              <div className={styles.redirect}>
                <span>{t('haveAcc')}?</span>
                <span className={styles.sign_up}>
                  <Link to='/sign-in'>{t('login')}</Link>
                </span>
              </div>
            </form>
          </>
        )}
        {isError && (
          <>
            <p>{responseMessage}</p>
            <button
              className={styles.submit}
              onClick={() => {
                navigate('/');
              }}
            >
            На главную
            </button>
          </>
        )}
        {isSuccess && (
          <>
            <p>
            Вы успешно зарегистрировались! Мы выслали пароль от личного кабинета на указанный адрес
            электронной почты
            </p>
            <button
              className={styles.submit}
              onClick={() => {
                navigate('/sign-in');
              }}
            >
            На страницу входа
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default SignUp;
