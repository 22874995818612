import { PieChart, Pie, Cell } from 'recharts';
import cn from 'classnames';

import styles from './BotsWinrate.module.css';
import IconWithTooltip from '../../IconWithTooltip/IconWithTooltip';
import { useTranslation } from 'react-i18next';

const BotsWinrate = () => {
  const { t } = useTranslation('totalStatistics');
  const data = [
    { name: t('profit'), value: 1 },
    { name: t('loss'), value: 1 },
    { name: t('breakEven'), value: 6 },
  ];

  const colors = [
    { name: t('profit'), color: '#5BB659' },
    { name: t('loss'), color: '#FF4E4E' },
    { name: t('breakEven'), color: '#627EEA' },
  ];
  const chooseColor = (item: { name: string; value: number }) => {
    return colors?.find((el) => el.name === item.name)?.color;
  };
  return (
    <div className={styles.container}>
      <div className={cn(styles.header)}>
        <div className={styles.headerText}>
          {t('botsWinrate')}
          <IconWithTooltip id='bots-winrate-toolbar' content='Bots winrate' />
        </div>
        <span>{t('updatedHour')}</span>
      </div>
      <div className={styles.chartContainer}>
        <div className={styles.pieChartContainer}>
          <PieChart width={170} height={170}>
            <Pie
              style={{ outline: 'none' }}
              data={data}
              innerRadius={40}
              outerRadius={80}
              startAngle={-270}
              endAngle={-630}
              fill='#8884d8'
              dataKey='value'
              stroke='none'
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={chooseColor(entry)} />
              ))}
            </Pie>
          </PieChart>
        </div>
        <div className={styles.valuesList}>
          {data.map((item) => (
            <div key={item.name} className={styles.valuesRow}>
              <div className={styles.name}>
                <span
                  className={styles.valuesColor}
                  style={{ backgroundColor: chooseColor(item) }}
                />
                <span className={styles.valuesName}>{item.name}</span>
              </div>
              <span className={styles.valuesPercent}>{item.value}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BotsWinrate;
