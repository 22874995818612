import { useState } from 'react';
import ReactQuill from 'react-quill';
import { Input } from '../../../CCLabsUI';
import { ESizing } from '../../../CCLabsUI/Input/Input';
import ChooseGroup from '../ChooseGroupChanel/ChooseGroup';
import ChooseChannel from '../ChooseGroupChanel/ChooseChannel';

import styles from './CreateNotification.module.css';
import 'react-quill/dist/quill.snow.css';
import './quillStyles.css';
import { EGroup } from '../../UsersListPage/UsersList/UserList.types';
import { EChanel } from '../../NotificationsPage/NotificationList/Notification.types';

export default function CreateNotification() {
  const [topicText, setTopicText] = useState('');
  const [notifiText, setNotifiText] = useState('');
  const [selectedGroups, setSelectedGroups] = useState<EGroup[]>([]);
  const [selectedChannels, setSelectedChannels] = useState<EChanel[]>([]);

  const isSendButtonDisabled =
    notifiText.length === 0 || selectedGroups.length === 0 || selectedChannels.length === 0;
  const formats = [
    'size',
    'bold',
    'italic',
    'underline',
    'background',
    'indent',
    'list',
    'direction',
    'align',
    'image',
    'color',
  ];
  return (
    <div className={styles.container}>
      <div className={styles.textField}>
        <h3>Notifications</h3>
        <Input
          label='Topic'
          sizing={ESizing.medium}
          className={styles.topic}
          placeholder='Input topic title'
          value={topicText}
          onChange={(e) => setTopicText(e.target.value)}
        />
        <ReactQuill
          value={notifiText}
          onChange={setNotifiText}
          theme='snow'
          placeholder='I am your reach text editor.'
          className='custom-quill'
          formats={formats}
          modules={{
            toolbar: [
              'bold',
              'italic',
              'underline',
              { list: 'ordered' },
              { list: 'bullet' },
              { align: '' },
              { align: 'center' },
              { align: 'right' },
              { color: [] },
              'image',
              { size: ['small', false, 'large', 'huge'] },
              ,
            ],
          }}
        />
      </div>
      <hr />
      <div className={styles.buttons}>
        <ChooseGroup items={selectedGroups} setItems={setSelectedGroups} />
        <ChooseChannel items={selectedChannels} setItems={setSelectedChannels} />
        <button className={styles.send} disabled={isSendButtonDisabled}>
          Send
        </button>
      </div>
    </div>
  );
}
