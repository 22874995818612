import useWindowDimensions from './useWindowDimensions';

const MAX_MOBILE_WIDTH = 768;

function useTransformPair() {
  const { width } = useWindowDimensions();
  const isMobile = width <= MAX_MOBILE_WIDTH;

  function transformPair(pair: string) {
    const slashInsertion = isMobile ? '/\n' : '/';

    if (pair.endsWith('USDT')) {
      return pair.replace('USDT', `${slashInsertion}USDT`);
    } else if (pair.endsWith('USD')) {
      const match = pair.match(/([A-Z]+)(USD.*)/);
      if (match) {
        const [, firstPart, secondPart] = match;
        return `${firstPart}${slashInsertion}${secondPart}`;
      }
    } else {
      const spotCurrencies = ['USDT', 'USDC', 'BTC', 'EUR', 'ETH', 'BIT'];
      for (const currency of spotCurrencies) {
        if (pair.endsWith(currency)) {
          return pair.replace(currency, `${slashInsertion}${currency}`);
        }
      }
    }

    return pair;
  }

  return transformPair;
}

export default useTransformPair;
