import { useState, useEffect } from 'react';

import InfoBlock from './InfoBlock';
import SwitchToggle from '../../SwitchToggle/SwitchToggle';
import { useCurrentUserProfileQuery } from '../../../redux/services/settingProfileService';

import styles from './SecuritySettings.module.css';
import ModalWindows from './ModalWindows';
import { useTranslation } from 'react-i18next';

export default function AuthBlock() {
  const [isEmail, setIsEmail] = useState(true);
  const [isGoogle, setIsGoogle] = useState(false);
  const { data: userProfile } = useCurrentUserProfileQuery();
  const [isOpenCodeModal, setIsOpenCodeModal] = useState(false);
  const [isOpenEnableAuthModal, setIsOpenEnableAuthModal] = useState(false);

  const { t } = useTranslation('profilePage');

  useEffect(() => {
    if (userProfile) {
      setIsGoogle(!!userProfile?.setting.googleAuth);
    }
  }, [userProfile]);

  const switchEmail = () => {
    setIsEmail(!isEmail);
  };
  const switchGoogle = () => {
    if (!isGoogle) {
      setIsOpenCodeModal(!isOpenCodeModal);
    }
    if (isGoogle) {
      setIsOpenEnableAuthModal(!isOpenEnableAuthModal);
    }
  };

  return (
    <>
      <ModalWindows
        switchGoogle={switchGoogle}
        openCodeModal={isOpenCodeModal}
        setIsGoogleOn={() => setIsGoogle(true)} // todo: delete prop when backend is ready
      />

      <div className={styles.authenticationBlock}>
        <div className={styles.authenticationHeaderBlock}>
          <div className={styles.mainTitleAuthentication}>{t('authTitle')}</div>
          <div className={styles.authDescription}>{t('authSubtitle')}</div>
        </div>
        <div className={styles.toggleBlock}>
          <div className={styles.toggleBlockRow}>
            <SwitchToggle isSwitched={isEmail} action={switchEmail} />
            <div className={styles.authenticationTitle}>Email</div>
          </div>
          <div className={styles.toggleBlockRow}>
            <SwitchToggle isSwitched={isGoogle} action={switchGoogle} />
            <div>
              <div className={styles.authenticationTitle}>Google Authenticator</div>
            </div>
          </div>
        </div>
        <InfoBlock text={t('infoText')} />
      </div>
    </>
  );
}
