// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NewPasswordBlock_inputFields__s8CqK {
  font-size: 16px;
}

@media (max-width: 768px) {
  .NewPasswordBlock_inputFields__s8CqK {
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/NewPasswordBlock/NewPasswordBlock.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;;AAEA;EACE;IACE,WAAW;EACb;AACF","sourcesContent":[".inputFields {\n  font-size: 16px;\n}\n\n@media (max-width: 768px) {\n  .inputFields {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputFields": `NewPasswordBlock_inputFields__s8CqK`
};
export default ___CSS_LOADER_EXPORT___;
