import { ERisk } from '../../../BotsPage/BotsCard/RiskBot';
import { EType } from '../../../BotsPage/BotIcon/BotIcon';
import { EMarketType, EStrategyType } from '../../../../redux/types';

export enum EExchange {
  bybit = 'bybit',
  binance = 'binance',
  okx = 'okx',
  bitget = 'bitget',
}

export enum EMarket {
  futures = 'Futures',
  spot = 'Spot',
  usdtPerp = 'USDT Perp',
  inversePerp = 'Inverse Perp',
}

export interface IBoardListItem {
  id: number;
  place: number;
  botType: EType;
  name: string;
  strategies: EStrategyType;
  market: EMarketType;
  exchange: string;
  pair: string;
  runtime: number;
  risk: ERisk;
  pnl: number;
  count: number;
  isHedge: boolean;
  isTrendFlipper: boolean;
}
