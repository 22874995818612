import React, { useEffect, useRef, useState } from 'react';
import BotsStatusBar from './BotsStatusBar/BotsStatusBar';
import styles from './Bots.module.css';
import BotsSort, { ESortDirections } from './BotsSort/BotsSort';
import MyBotsList from './MyBotsList/MyBotsList';
import cn from 'classnames';
import BotSettings from './BotSettings/BotSettings';
import { useLocation } from 'react-router-dom';
import { ITab, Navigation } from '../Navigation/Navigation';
import Filter from '../FilterUserBots/Filter';
import { EFlowStatus, IBot } from '../../redux/types';
import { useAppDispatch, useAppSelector } from '../../redux/hooks/redux';
import { selectBots } from '../../redux/selectors/rootSelector';
import useRequestUserBots from '../../hooks/requests/useRequestUserBots';
import NoActiveBots from './NoActiveBots/NoActiveBots';
import { getUptimeSeconds } from './BotsSort/BotsSort.utils';
import { CSSTransition } from 'react-transition-group';
import { useTranslation } from 'react-i18next';
import { ClipLoader } from 'react-spinners';
import BotErrorModalPlay from './BotErrorModal/BotErrorModalPlay';
import { EBotActions, setBotAction } from '../../redux/slices/botsSlice';
import { errorStatuses } from './BotsPage.constants';
import BotErrorModalDelete from './BotErrorModal/BotErrorModalDelete';
import useSubscribeToBots from '../../hooks/subscribes/useSubscribeBots';

interface IBotsPage {
  navigationTabs: ITab[];
  navigationTitle: string;
}
function BotsPage({ navigationTabs, navigationTitle }: IBotsPage) {
  const { t } = useTranslation('botspage');
  const location = useLocation();
  const dispatch = useAppDispatch();
  const isSettingsOpen: boolean = location.state !== null;
  const [isSettingsOpened, setIsSettingOpened] = useState(isSettingsOpen);
  const [needUpdateSorting, setNeedUpdateSorting] = useState(true);
  const [sortDirection, setSortDirection] = useState(ESortDirections.newToOld);

  const { requestUserBots, isLoading } = useRequestUserBots();
  const bots = useAppSelector(selectBots).bots || [];
  const selectedBot = useAppSelector((state) => state.bots.selectedBot);
  const selectedBotAction = useAppSelector((state) => state.bots.selectedAction);
  const subscribeToBots = useSubscribeToBots();
  const [botsByStatus, setBotsByStatus] = useState<IBot[]>(bots);
  const [currentBots, setCurrentBots] = useState<IBot[]>(bots);
  const [needToClear, setNeedToClear] = useState(false);

  const isBotsListEmpty = !bots.length;
  const isCurrentBotsListEmpty = !currentBots.length;
  const isBotsSortVisible = !isSettingsOpened && !isCurrentBotsListEmpty;
  const isContentVisible = !isCurrentBotsListEmpty && !isLoading;
  const isNoBotsAlertVisible = isCurrentBotsListEmpty && !isLoading;

  const isCurrentErrorStatus = errorStatuses.includes(selectedBot?.flowStatus as EFlowStatus);
  const isBotErrorModalPlayVisible = selectedBotAction === EBotActions.run && isCurrentErrorStatus;
  const isBotErrorModalDeleteVisible =
    selectedBotAction === EBotActions.delete && isCurrentErrorStatus;

  const sortBots = () => {
    const newBots = [...currentBots].sort((prev, next) =>
      sortDirection === ESortDirections.newToOld
        ? getUptimeSeconds(prev) - getUptimeSeconds(next)
        : getUptimeSeconds(next) - getUptimeSeconds(prev),
    );
    setCurrentBots(newBots);
  };

  const clearCurrentBotAction = () => {
    dispatch(setBotAction(null));
  };

  useEffect(() => {
    setCurrentBots(bots);
    setBotsByStatus(bots);
    subscribeToBots();
  }, [bots]);

  useEffect(() => {
    void requestUserBots();
  }, []);

  useEffect(() => {
    if (needUpdateSorting) {
      sortBots();
      setNeedUpdateSorting(false);
    }
  }, [needUpdateSorting]);
  const prevIsSettingsOpened = useRef<boolean>(isSettingsOpened);

  useEffect(() => {
    prevIsSettingsOpened.current = isSettingsOpened;
  }, [isSettingsOpened]);

  return (
    <div className={styles.myBotsWrapper}>
      <div
        className={cn([styles.navigationBox], { [styles.navigationBoxHidden]: isSettingsOpened })}
      >
        <Navigation tabs={navigationTabs} title={navigationTitle} />
      </div>
      <div
        className={cn(styles.container, {
          [styles.marginContainer]: isSettingsOpened,
          [styles.extending]: isSettingsOpened,
          [styles.shrinkage]: prevIsSettingsOpened.current && !isSettingsOpened,
          [styles.list]: !isSettingsOpened,
        })}
      >
        <div
          className={cn({
            [styles.longList]: prevIsSettingsOpened.current && !isSettingsOpened,
            [styles.shortList]: isSettingsOpened,
          })}
        >
          <div className={styles.headerContainer}>
            <div className={styles.headerBar}>
              {!isBotsListEmpty && (
                <BotsStatusBar
                  disableDeletedTab
                  isSettingsOpened={isSettingsOpened}
                  bots={bots}
                  setBots={setCurrentBots}
                  setBotsByStatus={setBotsByStatus}
                  setNeedToClear={setNeedToClear}
                  setNeedUpdateSorting={setNeedUpdateSorting}
                />
              )}
              {isBotsSortVisible && (
                <CSSTransition
                  in={!isSettingsOpened}
                  timeout={300}
                  className={styles.fade}
                  unmountOnExit
                >
                  <BotsSort
                    sortDirection={sortDirection}
                    setSortDirection={setSortDirection}
                    setNeedUpdateSorting={setNeedUpdateSorting}
                  />
                </CSSTransition>
              )}
            </div>
            {isContentVisible && (
              <Filter
                isCompact={isSettingsOpened}
                calendar={false}
                bots={botsByStatus}
                setBots={setCurrentBots}
                needToClear={needToClear}
                setNeedToClear={setNeedToClear}
                setNeedUpdateSorting={setNeedUpdateSorting}
              />
            )}
          </div>
          <div className={styles.centerContainer}>
            {isLoading && <ClipLoader size={55} color='#fff' />}
            {isNoBotsAlertVisible && <NoActiveBots />}
          </div>
          {isContentVisible && (
            <MyBotsList
              bots={currentBots}
              openInfoBlock={setIsSettingOpened}
              isInfoBlockOpened={isSettingsOpened}
              isSubBotsList={false}
              cardTooltip={t('cardTooltip')}
              isShownHeader={true}
            />
          )}
        </div>
        <CSSTransition in={isSettingsOpened} timeout={300} unmountOnExit>
          <div className={cn(styles.BotSettings, { [styles.fade]: !isSettingsOpened })}>
            <BotSettings setIsSettingsOpen={setIsSettingOpened} />
          </div>
        </CSSTransition>
      </div>

      {isBotErrorModalPlayVisible && <BotErrorModalPlay closeModal={clearCurrentBotAction} />}
      {isBotErrorModalDeleteVisible && <BotErrorModalDelete closeModal={clearCurrentBotAction} />}
    </div>
  );
}

export default BotsPage;
