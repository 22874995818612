// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Choose_container__ndsjI {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.Choose_container__ndsjI h4 {
  margin: 0;
  color: #fff;
  font-family: Jost;
  font-size: 24px;
  font-weight: 400;
  line-height: 22px;
}

.Choose_groups__wEaFJ {
  display: flex;
  flex-wrap: wrap;
  column-gap: 40px;
  height: 40px;
  row-gap: 20px;
  max-height: 200px;
  overflow-y: auto;
}

.Choose_checkbox__vIT1d {
  height: 30px;
  display: flex;
  align-items: center;
}

.Choose_group__4uOwY {
  box-sizing: border-box;
  display: flex;
  width: 93px;
  height: 34px;
  width: max-content;
  padding: 5px 16px;
  justify-content: center;
  align-items: center;
  background-color: var(--border-white-02);
  border-radius: 104px;
  color: #fff;
  font-size: 16px;
  font-weight: normal;
}

.Choose_selectedGroup__HPBCa {
  background-color: var(--purple-background);
}
`, "",{"version":3,"sources":["webpack://./src/components/AdminPage/NewNotificationPage/ChooseGroupChanel/Choose.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,SAAS;EACT,WAAW;EACX,iBAAiB;EACjB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,eAAe;EACf,gBAAgB;EAChB,YAAY;EACZ,aAAa;EACb,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,sBAAsB;EACtB,aAAa;EACb,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,iBAAiB;EACjB,uBAAuB;EACvB,mBAAmB;EACnB,wCAAwC;EACxC,oBAAoB;EACpB,WAAW;EACX,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,0CAA0C;AAC5C","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n}\n\n.container h4 {\n  margin: 0;\n  color: #fff;\n  font-family: Jost;\n  font-size: 24px;\n  font-weight: 400;\n  line-height: 22px;\n}\n\n.groups {\n  display: flex;\n  flex-wrap: wrap;\n  column-gap: 40px;\n  height: 40px;\n  row-gap: 20px;\n  max-height: 200px;\n  overflow-y: auto;\n}\n\n.checkbox {\n  height: 30px;\n  display: flex;\n  align-items: center;\n}\n\n.group {\n  box-sizing: border-box;\n  display: flex;\n  width: 93px;\n  height: 34px;\n  width: max-content;\n  padding: 5px 16px;\n  justify-content: center;\n  align-items: center;\n  background-color: var(--border-white-02);\n  border-radius: 104px;\n  color: #fff;\n  font-size: 16px;\n  font-weight: normal;\n}\n\n.selectedGroup {\n  background-color: var(--purple-background);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Choose_container__ndsjI`,
	"groups": `Choose_groups__wEaFJ`,
	"checkbox": `Choose_checkbox__vIT1d`,
	"group": `Choose_group__4uOwY`,
	"selectedGroup": `Choose_selectedGroup__HPBCa`
};
export default ___CSS_LOADER_EXPORT___;
