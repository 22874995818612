import { Dispatch, SetStateAction } from 'react';
import cn from 'classnames';

import styles from '../StatusUsersBar/StatusUserBar.module.css';

interface IButtonsStatus {
  setAllUsers: () => void;
  setActive: () => void;
  setBlocked: () => void;
  active: number;
  blocked: number;
  activeTab: string;
  setActiveTab: Dispatch<SetStateAction<string>>;
}

export default function ButtonsStatusBar({
  setActive,
  setAllUsers,
  setBlocked,
  active,
  blocked,
  activeTab,
  setActiveTab,
}: IButtonsStatus) {
  const getClassNames = (users: string) => cn({ [styles.activeTab]: activeTab === users });

  return (
    <div className={styles.statusBtns}>
      <button
        onClick={() => {
          setAllUsers();
          setActiveTab('all');
        }}
        className={getClassNames('all')}
      >
        Все юзеры
      </button>
      <button
        onClick={() => {
          setActive();
          setActiveTab('active');
        }}
        className={getClassNames('active')}
      >
        Активные({active})
      </button>
      <button
        onClick={() => {
          setBlocked();
          setActiveTab('blocked');
        }}
        className={getClassNames('blocked')}
      >
        Заблокированные({blocked})
      </button>
    </div>
  );
}
