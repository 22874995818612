/* eslint-disable @typescript-eslint/naming-convention */
export interface ISaveUserBybitKeys {
  sessionId: string | null;
  bybtKey: string;
  bybtSecretKey: string;
  token: string | null;
}
export interface IGenerateSecret {
  qrCode: string;
  secretCode: string;
}
export interface IGoodleAuth {
  token: string;
  sessionId: string;
}
export interface IDisableGoogleAuth {
  setting: {
    settingId: string;
    googleAuth: string;
  };
  password: string;
  key: string;
}
export type TSettingsName =
  | 'pushNotification'
  | 'exchangeErrors'
  | 'renewAccount'
  | 'apiKeys'
  | 'launchBotNotification'
  | 'reachedThePriceLevel'
  | 'botIsPaused'
  | 'botIsInactive'
  | 'botIsStopped'
  | 'botIsOutOfTradingRange'
  | 'changingTheTrend'
  | 'botReachedMaxTrigger'
  | 'entirePositionTakeProfit'
  | 'rangeStop'
  | 'equityStop'
  | 'telegramNotification'
  | 'emailNotification';

export type ISettingProfile = Record<TSettingsName, boolean>;

export type TFunctionality =
  | 'findAllUsers'
  | 'findOneUser'
  | 'updateUser'
  | 'userBotsStat'
  | 'twoFAOn'
  | 'twoFAOff'
  | 'saveKeys'
  | 'findUserSettings'
  | 'updateUserSettings'
  | 'createBot'
  | 'findUserBots'
  | 'leaderboardView'
  | 'updateBotSettings'
  | 'algorithmsView'
  | 'whiteListAdd'
  | 'whiteListRemove'
  | 'whiteListView'
  | 'whiteListUpdate'
  | 'getGroups';

// TODO дописать если понадобится
export interface IUserProfile {
  userId: string;
  setting: { settingId: string; googleAuth: string };
  group: { groupId: string; groupName: string }[];
}

export type TGroup = 'admin' | 'user' | 'sudo';

export enum EClientErrors {
  notFound = 404,
  unprocessableEntity = 422,
  forbidden = 403,
  badRequest = 400,
  unauthorized = 401,
  largePayload = 413,
}

export enum EResponseMessage {
  notFound = 'uuid.not_found.session',
}

export enum EMarketType {
  spot = 'MARKET_SPOT',
  usdtPerp = 'MARKET_USDT_PERPETUAL',
  inversePerp = 'MARKET_INVERSE_PERPETUAL',
}

export enum EMode {
  auto = 'MODE_AUTO',
  pro = 'MODE_PRO',
  no = 'NO',
}

export enum EStrategy {
  gridTronik = 'STRATEGY_GRID_TRONIK',
}

export enum EStrategyType {
  spot = 'STRATEGY_TYPE_SPOT',
  short = 'STRATEGY_TYPE_SHORT',
  long = 'STRATEGY_TYPE_LONG',
}

export enum EExchangeType {
  bybit = 'EXCHANGE_BYBIT',
  bitget = 'EXCHANGE_BITGET',
  binance = 'EXCHANGE_BINANCE',
  okx = 'EXCHANGE_OKX',
}

export enum EFlowStatus {
  runAutoConfig = 'FLOW_STATUS_RUNNING_AUTOCONFIG',
  runPro = 'FLOW_STATUS_RUNNING_PRO',
  runAuto = 'FLOW_STATUS_RUNNING_AUTO',
  new = 'FLOW_STATUS_STOPPED_NEW',
  pausedRequest = 'FLOW_STATUS_PAUSED_REQUEST',
  pausedEmergency = 'FLOW_STATUS_PAUSED_EMERGENCY',
  stopping = 'FLOW_STATUS_STOPPING',
  stoppedRegular = 'FLOW_STATUS_STOPPED_REGULAR',
  stoppedRequest = 'FLOW_STATUS_STOPPED_REQUEST',
  stoppedEmergency = 'FLOW_STATUS_STOPPED_EMERGENCY',
  removed = 'FLOW_STATUS_REMOVED',
  crushed = 'FLOW_STATUS_CRUSHED',
}

export interface IBot {
  flowId: string;
  flowStatus: EFlowStatus;
  strategy: EStrategy;
  flowName: string;
  strategyType: EStrategyType;
  exchangeType: EExchangeType;
  marketType: EMarketType;
  instrument: string;
  pnl: number;
  uptime: string;
  mode: EMode | 'NO';
  useReverse: boolean;
  hasSubbots: boolean;
  uuid: string;
}

export interface IFlowContext {
  exchange?: EExchangeType;
  market?: EMarketType;
  instrument?: string;
  strategyType?: EStrategyType;
  strategy?: EStrategy;
}

export interface IMultiplierInOrderQuantity {
  ratio: number;
  maxQuantity: number; // MAX Entry order quantity
  usePercent: false;
}

export interface IMultiplierInOrderDistance {
  ratio: number;
  maxDistance: number;
  usePercent: boolean;
}

export interface ITradingRange {
  lowerBound: string; // To (low)
  upperBound: number; // From (high)
  usePercent: false;
}

export interface ITrailingTrigger {
  profitPercent: number; // Profit
  startDistance: number; // Distance from price
  usePercent: boolean; // for startDistance
}

export interface IEquityStop {
  minEquity: number;
  usePercent: boolean;
}

export interface IGlobalParameters {
  lowRiskProfit: {
    firstTimeFrame: string;
    secondTimeFrame: string;
  };
  midRiskProfit: {
    firstTimeFrame: string;
    secondTimeFrame: string;
  };
  filter: {
    timeFrame: string;
    hmaFastestPeriod: number;
    hmaFastPeriod: number;
    hmaSlowPeriod: number;
  };
  renkoForTrend: {
    size: number;
    usePercent: boolean;
  } | null;
  renkoForFilter: {
    size: number;
    usePercent: boolean;
  } | null;
  rsiPeriod: number;
  atrPeriod: number;
  unitedDealType: string;
  switchOffEntpForHedge: boolean;
  useAdvancedRsi: boolean;
  useMultipleRsi: boolean;
  multipleRsi: [
    {
      firstTimeFrame: string;
      secondTimeFrame: string;
    },
  ];
  maxActiveTriggerMultiplier: number;
  isApplyMultiplierForTrailingBuy: boolean;
  usePauseInContrTrend: boolean;
  useEmaBands: boolean;
  emaBandsSettings: {
    pauseCounterdiapOrders: boolean;
    onStrategySignal: boolean;
    emaLength: number;
    emaSource: string;
    atrLength: number;

    // Below are the TrendFlipper settings
    // "ATR multiplier upper"
    atrMultiplierUp: number;
    // "ATR multiplier lower"
    atrMultiplierDown: number;
    params: {
      // "Timeframe 1"
      firstTimeFrame: string;
      // "Timeframe 2"
      secondTimeFrame: string;
    };
  };

  // "Volatility-based Auto-adjustment" switcher in Bot Settings
  convertFixedToAtr: boolean;

  convertParams: {
    convertTf: string;
    convertAtrPeriod: number;
    minAtrPeriod: number;
    lookback: number;
  };

  // "SqueezeArmor" switcher in Advantages
  useAntiSqueeze: boolean;

  antiSqueezeParams: {
    antiSqueezeTf: string;
    antiSqueezeAtrLength: number;
    antiSqueezeAtrUp: number;
    antiSqueezeAtrDown: number;
  };
}

export interface IInOrderQuantity {
  value: number; // Input under "Entry order quantity" block title
  usePercent: false;
}

export interface IInOrderDistance {
  value: number; // Input under "Grid Step" block title
  usePercent: boolean;
  startFrom: number | null; // Price level
}

export interface IStrategyParameters {
  useReverse: boolean; // TrendFlipper
  useAutoHedge: boolean; // HedgeGuard Mode

  // Entry order quantity
  inOrderQuantity: IInOrderQuantity;

  // Quantity multiplier in "Entry order quantity" block
  multiplierInOrderQuantity: IMultiplierInOrderQuantity | null;

  // Grid Step
  inOrderDistance: IInOrderDistance;

  // Grid step multiplier in "Grid Step" block
  multiplierInOrderDistance: IMultiplierInOrderDistance | null;

  trailingBuyDistance: number | null; // Trailing Distance

  tradingRange: ITradingRange | null;

  // Margin mode
  marginMode: 'Cross' | 'Isolated' | null;
  leverage: number | null;

  // Triggers
  triggerDistance: {
    choice: 'AllActive' | 'New';
    distance: number; // Trigger distance
    usePercent: boolean;
  };
  maxTriggerCount: number;

  trailingTrigger: ITrailingTrigger | null;

  // Take profit
  trailingTp: {
    minDistance: number;
    maxDistance: number;
    usePercent: boolean; // One for two inputs
  };

  // Stop loss
  equityStop: IEquityStop | null;

  // All that is below is not used in the UI
  entireTp: {
    minTriggerCount: number;
    choice: string;
    condition: string;
    value: number;
  };
  rangeStop: {
    choice: string;
    condition: string;
    value: number;
  };
  globalParameters: IGlobalParameters;
  applyAutoBalanceToTrigger: boolean;
  disableMinusPnlCheck: boolean;
  useCounterDistance: boolean;
  useBeastMode: boolean;
}

export interface IFlowParameters {
  flowContext: IFlowContext;
  strategyParameters: IStrategyParameters;
  flowName: string;
}

export interface IOrderStatistics {
  strategyType?: EStrategyType;
  inOrderTime: string;
  inOrderQuantity: number;
  inOrderPrice: number | string;
  profit: number | string;
  trailingTpTime: string;
  trailingTpQuantity: number;
  elapsedTime: string | number;
  stopQuantity: number;
  stopPrice: number | string;
  stopLabel?: string;
}

export interface IValidateParams {
  inOrderDistance: string;
  inOrderQuantity: string;
  triggerDistance: string;
  maxTriggerCount: string;
  tradingRange: string;
  trailingTp: string;
  useReverse: string;
  multiplierInOrderQuantity: string;
  multiplierInOrderDistance: string;
  trailingBuyDistance: string;
  useCounterDistance: string;
  useBeastMode: string;
  trailingTrigger: string;
  entireTp: string;
  rangeStop: string;
  equityStop: string;
  disableMinusPnlCheck: string;
  globalParameters: string;
  leverage: string;
  marginMode: string;
  useAutoHedge: string;
  applyAutoBalanceToTrigger: string;
}

export interface IFlowStatistics {
  flowId: string;
  flowOperationalStatistics: IFlowOperationalStatistics;
}

export interface IFlowOperationalStatistics {
  pnl: number | null;
  apr: number | null;
  equity: number | null;
  botCount: number | null;
  volume: number | null;
  totalRealizedPnl: number | null;
  dailyRealizedPnl: number | null;
  unrealizedPnl: number | null;
  risk: string;
  plnPoints: IPlnPoint[] | [];
  aprPoints: IAprPoint[] | [];
  duration: string | null;
  startTime: number | null;
  finishTime: number | null;
  coin: string;
  lastPauseTime: number | null;
  lastStopTime: number | null;
  lastParametersUpdateTime: number | null;
  durationSec: number | null;
}

export interface IPlnPoint {
  date: number;
  value: number;
}

export interface IAprPoint {
  date: number;
  value: number;
}

export interface IPnlData {
  totalRealizedPnl: number;
  dailyRealizedPnl: number;
  runningEquity: number;
}

export interface ITotalRealizedPnl {
  name: string;
  uv: number;
}
