import React, { useEffect, useState } from 'react';
import {
  useAddUserBybitKeysMutation,
  useDeleteUserBybitKeysMutation,
  useGetBybitKeysMutation,
} from '../../redux/services/settingProfileService';

import BibytIcon from '../../assets/Logo/BibytIcon';
import BinanceIcon from '../../assets/Logo/BinanceIcon';
import OkxIcon from '../../assets/Logo/OkxIcon';
import BitgetIcon from '../../assets/Logo/BitgetIcon';

import styles from './ExchangesSettings.module.css';
import ExchangesCard, { IExchangesCardProps } from './ExchangesCard/ExchangesCard';

import { IExchanges } from '../../types';
import { formatSnakeObjKeysToCamelCase } from '../../utils/formatSnakeToCamelCase';
import { setExchangesItems } from '../../redux/slices/exchangesSlice';
import { useAppDispatch } from '../../redux/hooks/redux';
import { useNavigateToSingIn } from '../../utils/navigateToSingIn';
import { EResponseMessage } from '../../redux/types';

export interface IBybitKeysResponse {
  status: IStatus;
  isSpotConnected: boolean;
  isFuturesConnected: boolean;
  areKeysExpired: boolean;
  keysExpirationDate: string;
}

export interface IStatus {
  code: string;
  message: string;
}

const ExchangesSettings: React.FC = () => {
  const dispatch = useAppDispatch();
  const sessionId = sessionStorage.getItem('session_id');
  const token = sessionStorage.getItem('token');
  const [addUserBybitKeys] = useAddUserBybitKeysMutation();
  const [deleteUserBybitKeys] = useDeleteUserBybitKeysMutation();
  const [getBybitKeys] = useGetBybitKeysMutation();
  const [currentLoadingId, setCurrentLoadingId] = useState<number | null>(null);
  const [exchanges, setExchanges] = useState<IExchanges[]>([
    {
      id: 1,
      icon: <BibytIcon width={73} height={24} />,
      isDisabled: false,
      isAvailable: true,
      hasConnect: false,
      name: 'ByBit',
      href: 'https://partner.bybit.com/b/delonix',
      isSpotConnected: false,
      isFuturesConnected: false,
      keysExpirationDate: '',
      areKeysExpired: true,
      hasError: false,
      defaultApiOpen: '',
      defaultApiSecret: '',
    },
    {
      id: 2,
      isDisabled: true,
      icon: <BinanceIcon />,
      isAvailable: false,
      name: 'Binance',
      href: 'https://www.bybit.com/ru-RU/register',
      defaultApiOpen: '',
      defaultApiSecret: '',
    },
    {
      id: 3,
      isDisabled: true,
      icon: <BitgetIcon />,
      isAvailable: false,
      name: 'Bitget',
      href: 'https://www.bitget.com/ru/register',
      defaultApiOpen: '',
      defaultApiSecret: '',
    },
    {
      id: 4,
      isDisabled: true,
      icon: <OkxIcon width={79} height={24} />,
      isAvailable: false,
      name: 'OKX',
      href: 'https://www.bybit.com/ru-RU/register',
      defaultApiOpen: '',
      defaultApiSecret: '',
    },
  ]);
  const [isBybitLoading, setIsBybitLoading] = useState(false);
  const navigateToSingIn = useNavigateToSingIn();
  useEffect(() => {
    const getKeys = async () => {
      setIsBybitLoading(true);
      try {
        const response = await getBybitKeys(null).unwrap();
        const formattedResponse = formatSnakeObjKeysToCamelCase(response) as IBybitKeysResponse;

        if (formattedResponse.status.code !== 'ok') {
          const { code } = formattedResponse.status;
          return code === EResponseMessage.notFound
            ? navigateToSingIn(code)
            : alert('Something went wrong');
        }

        const updates: IExchanges[] = exchanges.map((item) =>
          item.name === 'ByBit'
            ? {
              ...item,
              ...formattedResponse,
              hasConnect: true,
            }
            : item,
        );
        setExchanges(updates);

        const storedUpdates = updates.map((exchange) => {
          const copyExchange = { ...exchange };
          delete copyExchange.icon;
          return copyExchange;
        });

        dispatch(setExchangesItems(storedUpdates));
      } catch (error) {
        console.error('Error get bybit keys: ', error);
      } finally {
        setIsBybitLoading(false);
      }
    };

    void getKeys();
  }, []);

  const addApi: IExchangesCardProps['addApi'] = async (apiOpen, apiSecret, id) => {
    setCurrentLoadingId(id);
    const response = await addUserBybitKeys({
      token,
      sessionId,
      bybtKey: apiOpen,
      bybtSecretKey: apiSecret,
    }).unwrap();

    const hasConnect = response.status?.code === 'ok';
    if (hasConnect) {
      setCurrentLoadingId(null);
    }

    if (response.error) {
      const rejectedUpdates: IExchanges[] = exchanges.map((item) =>
        item.id === id ? { ...item, hasError: true } : item,
      );
      setExchanges(rejectedUpdates);
      setCurrentLoadingId(null);
      return;
    }

    const formattedResponse = formatSnakeObjKeysToCamelCase(response) as Partial<IExchanges>;

    const updates: IExchanges[] = exchanges.map((item) =>
      item.id === id
        ? {
          ...item,
          ...formattedResponse,
          hasConnect,
          defaultApiOpen: apiOpen,
          defaultApiSecret: apiSecret,
        }
        : item,
    );
    setExchanges(updates);
    const storedUpdates = updates.map((exchange) => {
      const copyExchange = { ...exchange };
      delete copyExchange.icon;
      return copyExchange;
    });

    dispatch(setExchangesItems(storedUpdates));
  };

  const deleteApi = async (id: number) => {
    const response = await deleteUserBybitKeys({ token, sessionId }).unwrap();
    const isSuccessDisconnection = response.status.code === 'ok';

    if (isSuccessDisconnection) {
      const updates: IExchanges[] = exchanges.map((item) =>
        item.id === id ? { ...item, hasConnect: false } : item,
      );

      setExchanges(updates);
      dispatch(setExchangesItems(updates));
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <h2 className={styles.title}>Exchanges</h2>
        <div className={styles.cards}>
          {exchanges.map((item) => {
            return (
              <ExchangesCard
                key={item.id}
                item={item}
                addApi={addApi}
                deleteApi={deleteApi}
                currentLoadingId={currentLoadingId}
                isLoadingConnection={isBybitLoading}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ExchangesSettings;
