export const snakeToCamelCase = (kebabValue: string): string => {
  const [firstPart, ...restSnakeParts] = kebabValue.split('_');

  const camelParts = restSnakeParts
    .map(([firstChar, ...restChars]) => `${firstChar?.toUpperCase()}${restChars.join('')}`)
    .join('');

  return `${firstPart}${camelParts}`;
};

export const formatSnakeObjKeysToCamelCase = (obj: object): object =>
  Object.entries(obj).reduce(
    (camelCaseObj, [key, value]) => ({
      ...camelCaseObj,
      [snakeToCamelCase(key)]: value,
    }),
    {},
  );

export const formatDeepSnakeObjKeysToCamelCase = (node: unknown): unknown => {
  const isNodeArray = Array.isArray(node);
  if (isNodeArray) {
    return node.map(item => formatDeepSnakeObjKeysToCamelCase(item));
  }

  const isNodeObject = typeof node === 'object' && node !== null;
  if (isNodeObject) {
    return Object.entries(node).reduce(
      (camelCaseObj, [key, value]) => ({
        ...camelCaseObj,
        [snakeToCamelCase(key)]: formatDeepSnakeObjKeysToCamelCase(value),
      }),
      {},
    );
  }

  return node;
};
