/* eslint-disable @typescript-eslint/no-magic-numbers */
import { useState } from 'react';
import cn from 'classnames';
import { Bar, BarChart, CartesianGrid, Cell, XAxis, YAxis } from 'recharts';

import styles from './DailyRealizedPNL.module.css';
import Tabs, { ITab } from '../Tabs/Tabs';
import IconWithTooltip from '../../IconWithTooltip/IconWithTooltip';
import { useTranslation } from 'react-i18next';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import {
  dataFor30days,
  dataFor7days,
  dataFor90days,
  getYTicks,
  ticksData,
} from '../StatisticsMain/StatisticsMain.utils';

const DailyRealizedPNL = (): JSX.Element => {
  const { width } = useWindowDimensions();
  const barChartWidth = Math.min(width * 0.75, 380);

  const [activeTab, setActiveTab] = useState('7');
  const { t } = useTranslation('totalStatistics');
  const tabs: ITab[] = [
    { value: '7', render: `7 ${t('days')}` },
    { value: '30', render: `30 ${t('days')}` },
    { value: '90', render: `90 ${t('days')}` },
  ];

  const data: Record<string, { date: string | number; value: number }[]> = {
    '7': dataFor7days,
    '30': dataFor30days,
    '90': dataFor90days,
  };

  const selectedData = data[activeTab];

  const XTicks = ticksData[activeTab];
  const YTicks = getYTicks(selectedData);
  return (
    <>
      <div className={styles.container}>
        <div className={cn(styles.header)}>
          <div className={styles.headerText}>
            {t('dailyPNL')}
            <IconWithTooltip id='daily-realized-PNL-toolbar' content='Daily realized PNL' />
          </div>
          <span>{t('updateLive')}</span>
        </div>
        <Tabs activeTab={activeTab} tabs={tabs} onSetActiveTab={(tab) => setActiveTab(tab.value)} />
        <div className={cn([styles.chartText, styles.cardContent])}>
          <BarChart width={barChartWidth} height={160} data={selectedData}>
            <CartesianGrid vertical={false} stroke='#afafaf' />
            <XAxis
              dataKey={'date'}
              ticks={XTicks}
              axisLine={false}
              stroke='white'
              tickMargin={15}
              tickLine={false}
            />
            <YAxis
              ticks={YTicks}
              tickLine={false}
              axisLine={false}
              scale='sqrt'
              stroke='#afafaf'
              domain={[0, 'dataMax']}
            />
            <Bar dataKey='value'>
              {selectedData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={entry.value && entry.value >= 0 ? '#60CF95' : '#FF4E4E'}
                />
              ))}
            </Bar>
          </BarChart>
        </div>
      </div>
    </>
  );
};

export default DailyRealizedPNL;
