import { memo } from 'react';
import CloseButton from '../../CloseButton/CloseButton';
import styles from './ConnectExchangesApiModal.module.css';

interface IConnectExchangesApiModal {
  closeModal: () => void;
  navigateToConnectApi: () => void;
}

const ConnectExchangesApiModal = ({
  closeModal,
  navigateToConnectApi,
}: IConnectExchangesApiModal) => {
  return (
    <div className={styles.container}>
      <div className={styles.modalContainer}>
        <CloseButton onClick={closeModal} className={styles.closeBtn} />
        <h3 className={styles.title}>Please, connect your API-key first</h3>
        <button className={styles.launchBtn} onClick={navigateToConnectApi}>
          Connect
        </button>
      </div>
    </div>
  );
};

export default memo(ConnectExchangesApiModal);
