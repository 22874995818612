import TitleCard from '../../BotsPage/BotSettings/Blocks/TitleCard';
import LineOfCard from './LineOfCard';
import styles from './Cards.module.css';
import { useTranslation } from 'react-i18next';

export default function TPSL() {
  const { t } = useTranslation('botsStats');
  return (
    <div className={styles.container}>
      <TitleCard title={t('tpsl')} />
      <LineOfCard title={t('trailingDistance')} value='$1,543,678' />
      <LineOfCard title={t('trailingQty')} value='10' />
      <LineOfCard title={t('rangeTo')} value='$1,543,678' />
      <LineOfCard title={t('rangeFrom')} value='$1,543,678' />
      <LineOfCard title={t('equityStop')} value='Value' />
    </div>
  );
}
