import { ERisk } from '../../../BotsPage/BotsCard/RiskBot';
import { EType } from '../../../BotsPage/BotIcon/BotIcon';
import { IBoardListItem } from './ListLeaderboard.types';
import { EExchangeType, EMarketType, EStrategyType } from '../../../../redux/types';

export const BOTS_LEADERBOARD: IBoardListItem[] = [
  {
    id: 1,
    place: 1,
    botType: EType.ultraGrid,
    name: 'KitKitych',
    strategies: EStrategyType.long,
    exchange: EExchangeType.bybit,
    market: EMarketType.spot,
    runtime: 1698687317046,
    risk: ERisk.low,
    pnl: 27137.97,
    count: 215,
    pair: 'BTC/USDT',
    isHedge: false,
    isTrendFlipper: true,
  },
  {
    id: 2,
    place: 2,
    botType: EType.ultraGrid,
    name: 'Paola',
    strategies: EStrategyType.long,
    exchange: EExchangeType.binance,
    market: EMarketType.spot,
    runtime: 1699400657046,
    risk: ERisk.low,
    pnl: 23025.34,
    count: 215,
    pair: 'ETH/USDT',
    isHedge: false,
    isTrendFlipper: true,
  },

  {
    id: 3,
    place: 3,
    botType: EType.ultraGrid,
    name: 'Jay',
    strategies: EStrategyType.long,
    exchange: EExchangeType.okx,
    runtime: 1700038157046,
    market: EMarketType.spot,
    risk: ERisk.low,
    pnl: 19331.22,
    count: 215,
    pair: 'BNB/USDT',
    isHedge: true,
    isTrendFlipper: true,
  },
  {
    id: 4,
    place: 4,
    botType: EType.ultraGrid,
    name: 'Bot5629',
    strategies: EStrategyType.long,
    exchange: EExchangeType.bybit,
    market: EMarketType.inversePerp,
    runtime: 1699672097046,
    risk: ERisk.low,
    pnl: 15341.71,
    count: 1520,
    pair: 'BTC/USDT',
    isHedge: false,
    isTrendFlipper: false,
  },
  {
    id: 5,
    place: 5,
    botType: EType.shooter,
    name: 'Bot97892',
    strategies: EStrategyType.short,
    market: EMarketType.inversePerp,
    exchange: EExchangeType.binance,
    runtime: 1700542397046,
    risk: ERisk.high,
    pnl: 9808.53,
    count: 2347,
    pair: 'ETH/USDT',
    isHedge: true,
    isTrendFlipper: false,
  },
  {
    id: 6,
    place: 6,
    botType: EType.ultraGrid,
    market: EMarketType.spot,
    name: 'Bot139',
    strategies: EStrategyType.short,
    exchange: EExchangeType.binance,
    runtime: 1700951357046,
    risk: ERisk.medium,
    pnl: 8893.81,
    count: 1278,
    pair: 'MATIC/USDT',
    isHedge: false,
    isTrendFlipper: true,
  },
  {
    id: 7,
    place: 7,
    botType: EType.fibo,
    name: 'Bot3374',
    strategies: EStrategyType.spot,
    market: EMarketType.spot,
    exchange: EExchangeType.bybit,
    runtime: 1701260297046,
    risk: ERisk.medium,
    pnl: 7350.01,
    count: 3421,
    pair: 'LINK/USDT',
    isHedge: true,
    isTrendFlipper: false,
  },
  {
    id: 8,
    place: 8,
    botType: EType.hybrid,
    name: 'Bot17649',
    strategies: EStrategyType.short,
    market: EMarketType.inversePerp,
    exchange: EExchangeType.bitget,
    runtime: 1700117957046,
    risk: ERisk.medium,
    pnl: 7117.90,
    count: 2609,
    pair: 'MATIC/USDT',
    isHedge: false,
    isTrendFlipper: false,
  },
  {
    id: 9,
    place: 9,
    botType: EType.shooter,
    market: EMarketType.inversePerp,
    name: 'Bot3874',
    strategies: EStrategyType.short,
    exchange: EExchangeType.bybit,
    runtime: 1701799997046,
    risk: ERisk.medium,
    pnl: 5210.87,
    count: 3956,
    pair: 'BTC/USDT',
    isHedge: true,
    isTrendFlipper: false,
  },
  {
    id: 10,
    place: 10,
    botType: EType.ultraGrid,
    name: 'Bot67950',
    strategies: EStrategyType.short,
    market: EMarketType.inversePerp,
    exchange: EExchangeType.okx,
    runtime: 1702097597046,
    risk: ERisk.low,
    pnl: 4899.58,
    count: 2456,
    pair: 'MATIC/USDT',
    isHedge: false,
    isTrendFlipper: false,
  },
];
