// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HeaderNotificationList_header__hfsiD {
  display: flex;
  justify-content: flex-end;
  width: 1640px;
  padding: 23px;
  align-items: center;
  color: var(--text-colored);
  font-size: 16px;
  font-weight: normal;
}

.HeaderNotificationList_header__hfsiD div {
  align-items: center;
}

.HeaderNotificationList_channel__PEuNk {
  display: flex;
  width: 330px;
}

.HeaderNotificationList_date__Io0Z2 {
  display: flex;
  width: 219px;
}

.HeaderNotificationList_date__Io0Z2 button {
  padding: 0;
  height: 20px;
}

.HeaderNotificationList_opened__C\\+bSl {
  width: 140px;
}

.HeaderNotificationList_read__DFlmU {
  width: 81px;
}

.HeaderNotificationList_descending__O\\+dmj {
  transform: rotate(180deg);
}
`, "",{"version":3,"sources":["webpack://./src/components/AdminPage/NotificationsPage/HeaderNotificationList/HeaderNotificationList.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,yBAAyB;EACzB,aAAa;EACb,aAAa;EACb,mBAAmB;EACnB,0BAA0B;EAC1B,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,YAAY;AACd;;AAEA;EACE,aAAa;EACb,YAAY;AACd;;AAEA;EACE,UAAU;EACV,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".header {\n  display: flex;\n  justify-content: flex-end;\n  width: 1640px;\n  padding: 23px;\n  align-items: center;\n  color: var(--text-colored);\n  font-size: 16px;\n  font-weight: normal;\n}\n\n.header div {\n  align-items: center;\n}\n\n.channel {\n  display: flex;\n  width: 330px;\n}\n\n.date {\n  display: flex;\n  width: 219px;\n}\n\n.date button {\n  padding: 0;\n  height: 20px;\n}\n\n.opened {\n  width: 140px;\n}\n\n.read {\n  width: 81px;\n}\n\n.descending {\n  transform: rotate(180deg);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `HeaderNotificationList_header__hfsiD`,
	"channel": `HeaderNotificationList_channel__PEuNk`,
	"date": `HeaderNotificationList_date__Io0Z2`,
	"opened": `HeaderNotificationList_opened__C+bSl`,
	"read": `HeaderNotificationList_read__DFlmU`,
	"descending": `HeaderNotificationList_descending__O+dmj`
};
export default ___CSS_LOADER_EXPORT___;
