import React from 'react';
import styles from './Footer.module.css';
import LogoImg from '../../../assets/img/Logo.svg';
import { useTranslation } from 'react-i18next';
import { TELEGRAM_SUPPORT_LINK } from '../../../constants';

const Footer: React.FC = () => {
  const { t } = useTranslation('footer');

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.promo}>
          <img className={styles.logo} src={LogoImg} />
          <div className={styles.social}>
            <img src='./images/social/telegram.png' />
            <img src='./images/social/discord.png' />
          </div>
          <div className={styles.rights}>{t('rights')}</div>
        </div>

        <div className={styles.nav}>
          <a href='#grid_bot'>{t('nav.link1')}</a>
          <a href='#about_us'>{t('nav.link2')}</a>
          <a href='#how_it_works'>{t('nav.link3')}</a>
          <a href='#roadmap'>{t('nav.link4')}</a>
          <a href='#ai'>{t('nav.link5')}</a>
        </div>

        <div className={styles.contacts}>
          <div>{t('contacts')}</div>
          <a className={styles.help} href={TELEGRAM_SUPPORT_LINK} target='_blank' rel='noreferrer' />
        </div>
      </div>
    </div>
  );
};

export default Footer;
