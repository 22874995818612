import StatisticNavigation from './StatisticNavigation/StatisticNavigation';
import Filter from '../../Filter/Filter';
import styles from './AdminStatisticPage.module.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

enum EPaths {
  summary = '/admin/stats/summary',
  users = '/admin/stats/users',
  bots = '/admin/stats/bot',
  trading = '/admin/stats/trading',
  equity = '/admin/stats/equity',
  pnl = '/admin/stats/pnl',
  average = '/admin/stats/average',
  settings = '/admin/stats/setting',
}

const title = {
  [EPaths.summary]: 'Summary data',
  [EPaths.users]: 'Users data',
  [EPaths.bots]: 'Bots data',
  [EPaths.trading]: 'Trading volume',
  [EPaths.equity]: 'Equity data',
  [EPaths.pnl]: 'PNL data',
  [EPaths.average]: 'Average values',
  [EPaths.settings]: 'Settings usage',
};

export default function AdminStatisticPage() {
  const navigator = useNavigate();
  const { pathname } = useLocation();
  useEffect(() => {
    if (pathname === '/admin/stats') {
      navigator('/admin/stats/summary');
    }
  });

  return (
    <div className={styles.container}>
      <StatisticNavigation />
      <Filter />
      <h2>{title[pathname as EPaths]}</h2>
    </div>
  );
}
