// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.JoinInput_join__xb0GL {
  position: relative;
  padding-right: 5%;
  box-sizing: border-box;
  width: 100%;
}

.JoinInput_join__xb0GL input {
  height: 44px;
}

.JoinInput_infoBlockWrapper__afSB1 {
  margin-top: 16px;
}

.JoinInput_join__xb0GL button {
  position: absolute;
  right: 0px;
  top: 0;
  padding-left: 30px;
  padding-right: 30px;
  height: 44px;
  text-align: center;
  font-size: 13px;
  font-weight: 700;
  color: rgb(0, 0, 0);
  background-color: var(--text-colored);
  border-radius: 0px 8px 8px 0px;
}

@media (max-width: 375px) {
  .JoinInput_join__xb0GL button {
    padding-left: 15px;
    padding-right: 15px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/JoinInput/JoinInput.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,sBAAsB;EACtB,WAAW;AACb;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,MAAM;EACN,kBAAkB;EAClB,mBAAmB;EACnB,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,mBAAmB;EACnB,qCAAqC;EACrC,8BAA8B;AAChC;;AAEA;EACE;IACE,kBAAkB;IAClB,mBAAmB;EACrB;AACF","sourcesContent":[".join {\n  position: relative;\n  padding-right: 5%;\n  box-sizing: border-box;\n  width: 100%;\n}\n\n.join input {\n  height: 44px;\n}\n\n.infoBlockWrapper {\n  margin-top: 16px;\n}\n\n.join button {\n  position: absolute;\n  right: 0px;\n  top: 0;\n  padding-left: 30px;\n  padding-right: 30px;\n  height: 44px;\n  text-align: center;\n  font-size: 13px;\n  font-weight: 700;\n  color: rgb(0, 0, 0);\n  background-color: var(--text-colored);\n  border-radius: 0px 8px 8px 0px;\n}\n\n@media (max-width: 375px) {\n  .join button {\n    padding-left: 15px;\n    padding-right: 15px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"join": `JoinInput_join__xb0GL`,
	"infoBlockWrapper": `JoinInput_infoBlockWrapper__afSB1`
};
export default ___CSS_LOADER_EXPORT___;
