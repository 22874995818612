import cn from 'classnames';
import styles from './CloseButton.module.css';
import { MouseEventHandler, memo } from 'react';

interface ICloseButton {
  color?: string;
  onClick: MouseEventHandler<HTMLButtonElement> | (() => void);
  className?: string;
}

function CloseButton({ color = '#fff', onClick, className }: ICloseButton) {
  return (
    <button className={cn(styles.container, className)} onClick={onClick}>
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z'
          fill={color}
        />
      </svg>
    </button>
  );
}

export default memo(CloseButton);
