import React, { ChangeEvent, useState } from 'react';
import styles from './SignIn.module.css';
import { Link } from 'react-router-dom';
import { Checkbox, Input } from '../CCLabsUI';
import { ESizing } from '../CCLabsUI/Input/Input';
import ForgotPasswordModal from '../ForgotPasswordModal';
import { useTranslation } from 'react-i18next';
import useSignIn from '../../hooks/requests/useSignIn';
import { ClipLoader } from 'react-spinners';

const SignIn: React.FC = () => {
  const { t } = useTranslation('signIn');

  const [isChecked, setIsChecked] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [userId, setUserId] = useState('');
  const [password, setPassword] = useState('');
  const [onSignIn, errorMessage, isError, setIsError, isLoading] = useSignIn(userId, password);

  const isSendButtonDisabled: boolean = isLoading || !userId || !password;

  const handleSetUserId = (e: ChangeEvent<HTMLInputElement>) => setUserId(e.target.value);
  const handleSetPassword = (e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value);

  const onCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const onForgetPasswordClick = () => {
    setIsModalVisible(true);
  };

  if (isModalVisible) {
    return <ForgotPasswordModal isInModalContainer actionClose={() => setIsModalVisible(false)} />;
  }

  const sendButtonContent = isLoading ? (
    <ClipLoader size={15} loading={isLoading} color='#fff' />
  ) : (
    t('submit')
  );
  return (
    <div className={styles.container}>
      <h2>{t('login')}</h2>
      <div className={styles.redirect}>
        <span>{t('noAccount')}</span>
        <span className={styles.sign_up}>
          <Link to='/sign-up'>{t('signUp')}</Link>
        </span>
      </div>
      {isError && <p className={styles.errorMessage}>{errorMessage}</p>}
      <div className={styles.inputFields}>
        <Input
          className={styles.form_input}
          onFocus={() => setIsError(false)}
          label={t('email')}
          sizing={ESizing.medium}
          value={userId}
          onChange={handleSetUserId}
          placeholder={t('emailPlaceholder')}
        />
        <Input
          className={styles.form_input}
          onFocus={() => setIsError(false)}
          label={t('password')}
          sizing={ESizing.medium}
          isPassword
          value={password}
          onChange={handleSetPassword}
          placeholder={t('passwordPlaceholder')}
        />
      </div>
      <Checkbox
        onChange={onCheckboxChange}
        className={styles.form_checkbox}
        label={t('rememberMe')}
        checked={isChecked}
      />
      <div className={styles.buttons}>
        <button
          className={styles.sendButton}
          type='submit'
          disabled={isSendButtonDisabled}
          onClick={onSignIn}
        >
          {sendButtonContent}
        </button>
        <a onClick={onForgetPasswordClick} className={styles.forget_password} href='#'>
          {t('forgotPassword')}
        </a>
      </div>
    </div>
  );
};

export default SignIn;
