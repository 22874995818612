import { Dispatch, SetStateAction } from 'react';
import { Checkbox, Input } from '../../../CCLabsUI';
import { ECheckboxVariants } from '../../../CCLabsUI/Checkbox/Checkbox';
import { ESizing } from '../../../CCLabsUI/Input/Input';
import styles from './StepCreateFunctionality.module.css';
import { IFeature } from './StepCreateFunctionality.types';

interface IStepCreateFunctionality {
  groupName: string;
  features: IFeature[];
  setGroupName: Dispatch<SetStateAction<string>>;
  setFeatures: Dispatch<SetStateAction<IFeature[]>>;
}

const StepCreateFunctionality = ({ groupName, features, setGroupName, setFeatures }: IStepCreateFunctionality) => {
  const onAllChange = (isChecked: boolean) => () => {
    const newFeatures = features.map(feature => ({ ...feature, checked: isChecked }));
    setFeatures(newFeatures);
  };

  const onItemChange = (name: string, checked: boolean) => () => {
    setFeatures((prevFeatures) => {
      const currentFeatureIndex = prevFeatures.findIndex(feature => feature.name === name);
      prevFeatures[currentFeatureIndex].checked = !checked;
      return [...prevFeatures];
    });
  };

  return (
    <div className={styles.container}>
      <Input
        className={styles.group_name}
        label='Group name'
        sizing={ESizing.medium}
        name='groupName'
        value={groupName}
        onInput={({ target }: React.ChangeEvent<HTMLInputElement>) => setGroupName(target.value)}
      />

      <div>
        <div className={styles.header}>
          <h4>Select features</h4>
          <div className={styles.controls}>
            <button onClick={onAllChange(true)}>Select all</button>
            <button onClick={onAllChange(false)}>Discard</button>
          </div>
        </div>
        <div className={styles.features}>
          {features.map(({ name, checked }) => (
            <Checkbox
              label={name}
              name={name}
              key={name}
              checked={checked}
              variant={ECheckboxVariants.secondary}
              onChange={onItemChange(name, checked)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default StepCreateFunctionality;
