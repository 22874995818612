import React, { useEffect } from 'react';
import cn from 'classnames';
import styles from './FutureOfProject.module.css';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

const FutureOfProject: React.FC = () => {
  const { t, i18n } = useTranslation('futureOfProject');
  const isRuLanguage = i18n.language === 'ru';

  const featureStyles = cn(styles.feature, { [styles.feature_ru]: isRuLanguage });

  const conclusionStyles = cn(styles.conclusion, { [styles.conclusion_ru]: isRuLanguage });
  const location = useLocation();

  useEffect(() => {
    if (location.hash.includes('ai')) {
      window.scrollTo({
        top: document.getElementById('ai')?.offsetTop,
        behavior: 'smooth',
      });
    }
  }, [location]);
  return (
    <div id='ai' className={styles.container}>
      <h2>
        {t('title.part1')} <br /> {t('title.part2')}{' '}
      </h2>
      <div className={styles.features}>
        <div className={featureStyles}>
          <h4>{t('item1.subtitle')}</h4>
          <ul>
            <li>{t('item1.li1')}</li>
            <li>{t('item1.li2')}</li>
            <li>{t('item1.li3')}</li>
            <li>{t('item1.li4')}</li>
          </ul>
          <div className={conclusionStyles}>{t('item1.conclusion')}</div>
        </div>

        <div className={featureStyles}>
          <h4>{t('item2.subtitle')}</h4>
          <ul>
            <li>{t('item2.li1')}</li>
            <li>{t('item2.li2')}</li>
            <li>{t('item2.li3')}</li>
            <li>{t('item2.li4')}</li>
          </ul>
          <div className={conclusionStyles}>{t('item2.conclusion')}</div>
        </div>

        <div className={featureStyles}>
          <h4>{t('item3.subtitle')}</h4>
          <ul>
            <li>{t('item3.li1')}</li>
            <li>{t('item3.li2')}</li>
            <li>{t('item3.li3')}</li>
            <li>{t('item3.li4')}</li>
          </ul>
          <div className={conclusionStyles}>{t('item3.conclusion')}</div>
        </div>
      </div>
    </div>
  );
};

export default FutureOfProject;
