import { useEffect, useState } from 'react';

import HeaderList from './HeaderList';
import UserListItem from '../UserListItem/UserListItem';
import Pagination from '../Pagination/Pagination';
import StatusUsersBar from '../StatusUsersBar/StatusUsersBar';
import RolesModalWindow from '../RolesModalWindow/RolesModalWindow';
import { USERS } from './users_mock';

import styles from './UserList.module.css';
import { IUser } from './UserList.types';
import { getArrayIndexes } from '../Pagination/pagination.utils';

export default function UsersList() {
  const [currentPage, setCurrentPage] = useState(1);
  const [dateValue, setDateValue] = useState<[Date, Date] | undefined>();
  const [userList, setUserList] = useState(USERS);
  const [isRolesModalOpened, setIsRolesModalOpened] = useState(false);
  const [totalPages, indexOfFirstUser, indexOfLastUser] = getArrayIndexes(userList, currentPage);

  const currentUsers: IUser[] = userList.slice(indexOfFirstUser, indexOfLastUser);
  const blockedUsers = USERS.filter((user) => !user.isActive);
  const activeUsers = USERS.filter((user) => user.isActive);

  useEffect(() => {
    if (dateValue) {
      const usersFilteredByDate = userList.filter(
        (user) => dateValue[1] >= user.dateTime && dateValue[0] <= user.dateTime,
      );
      setUserList(usersFilteredByDate);
    } else {
      setUserList(USERS);
    }
  }, [dateValue]);

  const onSetActiveUsers = () => setUserList(activeUsers);
  const onSetBlockedUsers = () => setUserList(blockedUsers);
  const onSetAllUsers = () => setUserList(USERS);
  return (
    <>
      <div className={styles.container}>
        <StatusUsersBar
          setActive={onSetActiveUsers}
          setAllUsers={onSetAllUsers}
          setBlocked={onSetBlockedUsers}
          blocked={blockedUsers.length}
          active={activeUsers.length}
          dateValue={dateValue}
          setDateValue={setDateValue}
          users={userList}
          setUsers={setUserList}
          onSettingsOpen={() => setIsRolesModalOpened(true)}
          setCurrentPage={setCurrentPage}
        />
        <div className={styles.list}>
          <HeaderList setUsers={setUserList} />
          {currentUsers.map((user) => (
            <UserListItem user={user} key={user.uid} />
          ))}
        </div>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          setCurrentPage={setCurrentPage}
        />
      </div>
      {isRolesModalOpened && <RolesModalWindow onClose={() => setIsRolesModalOpened(false)} />}
    </>
  );
}
