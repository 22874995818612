// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DailyRealizedPNL_container__ynhmF {
  box-sizing: border-box;
  width: 405px;
  max-width: 485px;
  padding: 20px;
  height: 295px;
}

.DailyRealizedPNL_header__qX-iw {
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
}

.DailyRealizedPNL_headerText__2C1eD {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 13px;
}

.DailyRealizedPNL_header__qX-iw span {
  border-bottom: 1px dotted var(--border-white-02);
  color: white;
  font-size: 13px;
  line-height: 17px;
}

.DailyRealizedPNL_cardContent__3okFr {
  margin-top: 25px;
  margin-left: -25px;
}

.DailyRealizedPNL_chartText__mup3y {
  font-size: 13px;
  font-weight: 400;
}

@media (max-width: 768px) {
  .DailyRealizedPNL_container__ynhmF {
    width: auto;
    max-width: none;
  }
  .DailyRealizedPNL_chartText__mup3y {
    width: 287px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/StatisticPage/DailyRealizedPNL/DailyRealizedPNL.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,YAAY;EACZ,gBAAgB;EAChB,aAAa;EACb,aAAa;AACf;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,QAAQ;EACR,eAAe;AACjB;;AAEA;EACE,gDAAgD;EAChD,YAAY;EACZ,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE;IACE,WAAW;IACX,eAAe;EACjB;EACA;IACE,YAAY;EACd;AACF","sourcesContent":[".container {\n  box-sizing: border-box;\n  width: 405px;\n  max-width: 485px;\n  padding: 20px;\n  height: 295px;\n}\n\n.header {\n  display: flex;\n  margin-bottom: 20px;\n  justify-content: space-between;\n  align-items: center;\n  font-weight: 400;\n}\n\n.headerText {\n  display: flex;\n  align-items: center;\n  gap: 8px;\n  font-size: 13px;\n}\n\n.header span {\n  border-bottom: 1px dotted var(--border-white-02);\n  color: white;\n  font-size: 13px;\n  line-height: 17px;\n}\n\n.cardContent {\n  margin-top: 25px;\n  margin-left: -25px;\n}\n\n.chartText {\n  font-size: 13px;\n  font-weight: 400;\n}\n\n@media (max-width: 768px) {\n  .container {\n    width: auto;\n    max-width: none;\n  }\n  .chartText {\n    width: 287px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `DailyRealizedPNL_container__ynhmF`,
	"header": `DailyRealizedPNL_header__qX-iw`,
	"headerText": `DailyRealizedPNL_headerText__2C1eD`,
	"cardContent": `DailyRealizedPNL_cardContent__3okFr`,
	"chartText": `DailyRealizedPNL_chartText__mup3y`
};
export default ___CSS_LOADER_EXPORT___;
