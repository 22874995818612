import React, { ChangeEvent, memo, useEffect, useState } from 'react';
import StrategyBot from '../../BotsPage/BotsCard/StrategyBot';
import Dropdown from '../../Filter/Dropdown/Dropdown';
import PairFilter from '../../Filter/PairFilter/PairFilter';
import { Exchanges } from '../Leaderboard/ListLeaderboard/ExchangesIcons';
import styles from './BotSetupHeader.module.css';
import { Input } from '../../CCLabsUI';
import { ESizing } from '../../CCLabsUI/Input/Input';
import { useTranslation } from 'react-i18next';
import useRequestExchangesFilterData from '../../../hooks/requests/useRequestExchangesFilterData';
import { getStrategies, renderMarketOption } from './utils';
import { EExchangeType, EMarketType, EStrategyType } from '../../../redux/types';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks/redux';
import { tempSettings } from '../../../redux/selectors/rootSelector';
import {
  setExchange,
  setFlowName,
  setInstrument,
  setMarketType,
  setStrategyType,
} from '../../../redux/slices/tempSettingsSlice';

function BotSetupHeader() {
  const { t } = useTranslation('filter');
  const {
    flowName,
    flowContext: { exchange, instrument, market: marketType, strategyType },
  } = useAppSelector(tempSettings);

  const dispatch = useAppDispatch();

  const sessionId = sessionStorage.getItem('session_id');
  const token = sessionStorage.getItem('token');

  const [exchangesOptions, setExchangeOptions] = useState<EExchangeType[]>([]);
  const [marketOptions, setMarketOptions] = useState<EMarketType[]>([]);
  const [strategyOptions, setStrategyOptions] = useState<EStrategyType[]>([]);
  const [pairsOptions, setPairsOptions] = useState<string[]>([]);

  const [requestExchangesFilterData] = useRequestExchangesFilterData({
    token,
    sessionId,
    marketType,
    setExchangeOptions,
    setMarketOptions,
    setPairsOptions,
  });

  useEffect(() => {
    void requestExchangesFilterData();
    setPairsOptions([]);
    dispatch(setStrategyType(undefined));
    setStrategyOptions(getStrategies(marketType));
  }, [marketType]);

  const onSetName = (e: ChangeEvent<HTMLInputElement>) => {
    const regex = /^[a-zA-Zа-яА-Я0-9\s]+$/;
    if (regex.test(e.target.value) || e.target.value === '') {
      dispatch(setFlowName(e.target.value));
    }
  };

  return (
    <div className={styles.container}>
      <Input
        label={t('name')}
        sizing={ESizing.medium}
        className={styles.input}
        placeholder={t('namePlaceholder')}
        value={flowName}
        onChange={onSetName}
      />
      <Dropdown
        label={t('exchange')}
        value={exchange}
        setValue={(exchange) => dispatch(setExchange(exchange))}
        options={exchangesOptions}
        renderOption={(exchange) => Exchanges[exchange]}
        hasSingleSelection
      />
      <Dropdown
        label={t('market')}
        value={marketType}
        setValue={(marketType) => dispatch(setMarketType(marketType))}
        options={marketOptions}
        renderOption={renderMarketOption}
        hasSingleSelection
      />
      <Dropdown
        label={t('strategy')}
        value={strategyType}
        setValue={(strategyType) => dispatch(setStrategyType(strategyType))}
        options={strategyOptions}
        renderOption={(strategy) => <StrategyBot strategy={strategy} />}
        hasSingleSelection
      />
      <PairFilter
        market={renderMarketOption(marketType)}
        pair={instrument}
        setPair={(instrument) => dispatch(setInstrument(instrument))}
        options={pairsOptions}
      />
    </div>
  );
}

export default memo(BotSetupHeader);
