import React, { useState } from 'react';
import cn from 'classnames';
import { PieChart, Pie, Cell, Tooltip } from 'recharts';

import styles from './EquityAllocation.module.css';

import CustomTooltip from './CustomTooltip';
import EquityAllocationModal from '../EquityAllocationModal/EquityAllocationModal';
import IconWithTooltip from '../../IconWithTooltip/IconWithTooltip';
import { colors, data, logos } from './EquityAllocation.constants';
import { useTranslation } from 'react-i18next';

const EquityAllocation = () => {
  const { t } = useTranslation('totalStatistics');
  const [isOpenModal, setIsOpenModal] = useState(false);
  const chooseColor = (item: { name: string; value: number }) => {
    return colors?.find((el) => el.name === item.name)?.color;
  };
  return (
    <div className={styles.contentContainer}>
      <div className={cn(styles.header)}>
        <div className={styles.headerText}>
          {t('equityAllocation')}
          <IconWithTooltip id='equity-allocation-toolbar' content='Equity allocation' />
        </div>
        <button className={styles.viewButton} onClick={() => setIsOpenModal(true)}>
          {t('viewAll')}
        </button>
      </div>
      <div className={styles.chartContainer}>
        <div className={styles.pieChartContainer}>
          <PieChart width={170} height={170}>
            <Pie
              data={data}
              innerRadius={40}
              outerRadius={80}
              startAngle={-270}
              endAngle={-630}
              fill='#8884d8'
              dataKey='value'
              stroke='none'
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={chooseColor(entry)} />
              ))}
            </Pie>
            <Tooltip
              wrapperStyle={{ outline: 'none' }}
              content={(data) => <CustomTooltip payload={data.payload} logos={logos} />}
            />
          </PieChart>
        </div>
        <div className={styles.valuesList}>
          {data.map((item) => (
            <div key={item.name} className={styles.valuesRow}>
              <div className={styles.name}>
                <span
                  key={`${item.name} color`}
                  className={styles.valuesColor}
                  style={{ backgroundColor: chooseColor(item) }}
                />
                <img
                  alt={item.name}
                  key={`${item.name} logo`}
                  className={styles.logo}
                  src={logos?.find((el) => el.name === item.name)?.logo}
                />
                <span key={`${item.name} name`}>{item.name}</span>
              </div>
              <span
                key={`${item.name} percent`}
                className={styles.valuesPercent}
              >{`${item.value}%`}</span>
            </div>
          ))}
        </div>
      </div>
      {isOpenModal && (
        <EquityAllocationModal
          actionClose={() => setIsOpenModal(false)}
          action={() => console.log('hello')}
          actionSubmit={() => console.log('hello')}
        />
      )}
    </div>
  );
};

export default EquityAllocation;
