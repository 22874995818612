import { Dispatch, SetStateAction, memo } from 'react';
import cn from 'classnames';
import styles from '../BotSettings.module.css';
import SwitchToggle from '../../../SwitchToggle/SwitchToggle';
import IconWithTooltip from '../../../IconWithTooltip/IconWithTooltip';

interface ISwitchBlockProps {
  title: string;
  subtitle?: string;
  isSwitched?: boolean;
  action?: Dispatch<SetStateAction<boolean>>;
  tooltip?: string;
  hasTooltip?: boolean;
}
function SwitchBlock({
  title,
  subtitle,
  isSwitched,
  action,
  tooltip,
  hasTooltip = true,
}: ISwitchBlockProps) {
  const onSwitch = () => (action ? action((state) => !state) : null);
  return (
    <div className={cn([styles.switchBox], [styles.switchText])}>
      <SwitchToggle action={onSwitch} isSwitched={isSwitched} />
      {title}
      <br />
      {subtitle}{' '}
      {hasTooltip && (
        <IconWithTooltip color='#FFF' content={tooltip ? tooltip : title} tooltipWidth={164} />
      )}
    </div>
  );
}

export default memo(SwitchBlock);
