import { useState } from 'react';
import cn from 'classnames';
import StopIcon from '../../../../assets/stop_icon.svg';
import styles from './ItemGroupList.module.css';
import { IGroup } from '../group.types';
import SureModalWindow from '../../UsersListPage/SureModalWindow/SureModalWindow';

interface IGroupListItemProps {
  group: IGroup;
}

export default function ItemGroupList({
  group: {
    botsCopied,
    botsCreated,
    dateTime,
    functionality,
    group,
    id,
    realizedPnl,
    runningBots,
    runningEquity,
    tradingVolume,
    usersQuantity,
    isActive,
  },
}: IGroupListItemProps) {
  const [isBanModalOpened, setIsBanModalOpened] = useState(false);
  const [isUserBanned, setIsUserBanned] = useState(!isActive);
  const onOpenBanModal = () => setIsBanModalOpened(true);
  const onCloseBanModal = () => setIsBanModalOpened(false);
  const onBanUser = () => {
    setIsUserBanned(true);
    onCloseBanModal();
  };
  const formatOption: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  };

  const date = new Intl.DateTimeFormat('ru', formatOption).format(dateTime);
  return (
    <>
      <div className={cn([styles.container], { [styles.isBanned]: isUserBanned })}>
        <div className={styles.date}>{date}</div>
        <div className={styles.uid}>{id}</div>
        <div className={styles.group}>{group}</div>
        <div className={styles.functionality}>{functionality}</div>
        <div className={styles.users}>{usersQuantity}</div>
        <div className={styles.trading}>{tradingVolume}</div>
        <div className={styles.pnl}>{realizedPnl}</div>
        <div className={styles.equity}>{runningEquity}</div>
        <div className={styles.botsTypeBox}>
          <div className={styles.botsType}>{runningBots.pro}</div>
          <div className={styles.botsType}>{runningBots.auto}</div>
        </div>
        <div className={styles.botsTypeBox}>
          <div className={styles.botsType}>{botsCreated.pro}</div>
          <div className={styles.botsType}>{botsCreated.auto}</div>
        </div>
        <div className={styles.botsTypeBox}>
          <div className={styles.botsType}>{botsCopied.pro}</div>
          <div className={styles.botsType}>{botsCopied.auto}</div>
        </div>
        <div className={styles.stopBtn}>
          <button onClick={onOpenBanModal}>
            <img src={StopIcon} />
          </button>
        </div>
      </div>
      {isBanModalOpened && <SureModalWindow onBan={onBanUser} onClose={onCloseBanModal} />}
    </>
  );
}
