import { useEffect, useState } from 'react';

import styles from './StatisticsMain.module.css';
import TotalRealizedPNL from '../TotalRealizedPNL/TotalRealizedPNL';
import DailyRealizedPNL from '../DailyRealizedPNL/DailyRealizedPNL';
import EquityAllocation from '../EquityAllocation/EquityAllocation';
import BotsWinrate from '../BotsWinrate/BotsWinrate';
import Tabs, { ITab } from '../Tabs/Tabs';
import EyeIcon from '../../../assets/EyeIcon';
import CurrenciesCards from '../CurrenciesCards/CurrenciesCards';
import { useTranslation } from 'react-i18next';
import useGetBotsStatistics from '../../../hooks/subscribes/useGetBotsStatistics';
import { useAppSelector } from '../../../redux/hooks/redux';

const currencyTabs: ITab[] = [{ value: 'USDT' }, { value: 'BTC' }, { value: 'ETH' }];

const StatisticsMain = (): JSX.Element => {
  const [activeCurrency, setActiveCurrency] = useState('USDT');
  const [activeMode, setActiveMode] = useState('All');
  const [isBluredAll, setIsBluredAll] = useState(false);
  const { t } = useTranslation('totalStatistics');
  const { getPnlStatistics, closeWsConnection } = useGetBotsStatistics();
  const statistics = useAppSelector((state) => state.statistics);
  const tabs: ITab[] = [
    { value: 'All', render: t('all') },
    { value: 'PRO mode', render: t('pro') },
    { value: 'AUTO mode', render: t('auto') },
  ];

  useEffect(() => {
    getPnlStatistics();
    return () => {
      closeWsConnection();
    };
  }, []);

  const dailyRealizedPnl = statistics.reduce((acc, flow) => {
    const {
      flowOperationalStatistics: { dailyRealizedPnl },
    } = flow;
    return dailyRealizedPnl ? dailyRealizedPnl : 0 + acc;
  }, 0);
  const totalRealizedPnl = statistics.reduce((acc, flow) => {
    const {
      flowOperationalStatistics: { totalRealizedPnl },
    } = flow;
    return totalRealizedPnl ? totalRealizedPnl : 0 + acc;
  }, 0);

  const currenciesCardsData = [
    {
      value: totalRealizedPnl,
      description: t('totalPNL'),
      activeCurrency,
      isBluredAll,
    },
    {
      value: 0,
      description: t('equity'),
      activeCurrency,
      isBluredAll,
    },
    {
      value: dailyRealizedPnl,
      description: t('dailyPNL'),
      activeCurrency,
      isBluredAll,
    },
  ];

  const onSetActiveTab = (tab: ITab) => {
    if (tab.value === 'AUTO mode') {
      return;
    }
    setActiveMode(tab.value);
  };

  return (
    <div className={styles.container}>
      <div className={styles.mainContent}>
        <div className={styles.headerContainer}>
          <Tabs
            activeTab={activeMode}
            className={styles.modeTabsBox}
            tabClassName={styles.modeTab}
            tabs={tabs}
            onSetActiveTab={onSetActiveTab}
          />
          <div
            onClick={() => setIsBluredAll((prevIsBlured) => !prevIsBlured)}
            className={styles.eyeIcon}
          >
            <EyeIcon isBlured={isBluredAll} width={24} height={24} color='#fff' />
          </div>
        </div>
        <div className={styles.mainContainerHeader}>
          <div className={styles.currenciesContainer}>
            <CurrenciesCards cards={currenciesCardsData} activeCurrency={activeCurrency} />
            <div className={styles.tabsContainer}>
              <Tabs
                activeTab={activeCurrency}
                tabs={currencyTabs}
                direction={'column'}
                onSetActiveTab={(tab) => setActiveCurrency(tab.value)}
                className={styles.currenciesTabsBox}
                tabClassName={styles.currenciesTab}
                activeTabClassName={styles.currenciesActiveTab}
              />
            </div>
          </div>
        </div>
        <div className={styles.statisticsMainCardsContainer}>
          <TotalRealizedPNL />
          <DailyRealizedPNL />
          <EquityAllocation />
          <BotsWinrate />
        </div>
      </div>
    </div>
  );
};

export default StatisticsMain;
