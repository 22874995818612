import { useAppDispatch } from '../../redux/hooks/redux';
import { useGetBotParametersMutation } from '../../redux/services/botService';
import { setParameters } from '../../redux/slices/botsSlice';
import { updateTempData } from '../../redux/slices/tempSettingsSlice';
import { EClientErrors, EResponseMessage, IBot, IFlowParameters } from '../../redux/types';
import { formatDeepSnakeObjKeysToCamelCase } from '../../utils/formatSnakeToCamelCase';
import { useNavigateToSingIn } from '../../utils/navigateToSingIn';

const useRequestBotParameters = () => {
  const dispatch = useAppDispatch();
  const [getBotParameters] = useGetBotParametersMutation();
  const navigateToSingIn = useNavigateToSingIn();
  const requestBotParameters = (bot: IBot) => {
    getBotParameters(bot.flowId)
      .unwrap()
      .then((response) => {
        if (response.status.code === EResponseMessage.notFound) {
          return navigateToSingIn(EClientErrors.unauthorized);
        }

        const formattedResponse = formatDeepSnakeObjKeysToCamelCase(response) as {
          flowParameters: IFlowParameters;
        };
        const responseParameters = formattedResponse.flowParameters;

        dispatch(setParameters(responseParameters));
        dispatch(updateTempData(responseParameters));
      })
      .catch((err) => {
        console.log('err: ', err);
        navigateToSingIn(err);
      });
  };

  return requestBotParameters;
};

export default useRequestBotParameters;
