import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { settingProfileApi } from '../services/settingProfileService';
import { settingSecurityApi } from '../services/settingSecurityService';
import { signUpApi } from '../services/signUpService';
import modalWindowSlice from '../slices/modalWindowSlice';
import functionalitySlice from '../slices/functionalitySlice';
import { adminStatsApi } from '../services/adminStatsService';
import botsSlice from '../slices/botsSlice';
import { waitListApi } from '../services/waitListService';
import navigationSlice from '../slices/navigationSlice';
import layoutSlice from '../slices/layoutSlice';
import validateParamsSlice from '../slices/validateeFlowParamsSlice';
import tempSettingsSlice from '../slices/tempSettingsSlice';
import { whiteListApi } from '../services/whiteListServices';
import adminStatSlice from '../slices/adminStatSlice';
import { exchangesApi } from '../services/exchangesService';
import { botApi } from '../services/botService';
import exchangesSlice from '../slices/exchangesSlice';
import profileSlice from '../slices/profileSlice';
import statisticSlice from '../slices/statisticSlice';

const rootReducer = combineReducers({
  [settingProfileApi.reducerPath]: settingProfileApi.reducer,
  [settingSecurityApi.reducerPath]: settingSecurityApi.reducer,
  [signUpApi.reducerPath]: signUpApi.reducer,
  [waitListApi.reducerPath]: waitListApi.reducer,
  [whiteListApi.reducerPath]: whiteListApi.reducer,
  [exchangesApi.reducerPath]: exchangesApi.reducer,
  [adminStatsApi.reducerPath]: adminStatsApi.reducer,
  [botApi.reducerPath]: botApi.reducer,
  modalWindow: modalWindowSlice,
  adminStat: adminStatSlice,
  layout: layoutSlice,
  functionality: functionalitySlice,
  bots: botsSlice,
  navigation: navigationSlice,
  validateParams: validateParamsSlice,
  tempSettings: tempSettingsSlice,
  exchanges: exchangesSlice,
  profile: profileSlice,
  statistics: statisticSlice,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      settingProfileApi.middleware,
      settingSecurityApi.middleware,
      signUpApi.middleware,
      waitListApi.middleware,
      whiteListApi.middleware,
      exchangesApi.middleware,
      adminStatsApi.middleware,
      botApi.middleware,
    ]),
});

// eslint-disable-next-line @typescript-eslint/naming-convention
export type RootState = ReturnType<typeof rootReducer>;
// eslint-disable-next-line @typescript-eslint/naming-convention
export type AppStore = typeof store;
// eslint-disable-next-line @typescript-eslint/naming-convention
export type AppDispatch = AppStore['dispatch'];
