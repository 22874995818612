/* eslint-disable max-len */

interface ISortArrow {
  color?: string;
}

export default function SortArrow({ color = '#9E96FA' }: ISortArrow) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='25' height='24' viewBox='0 0 25 24' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.79387 8.29607C6.18439 7.90131 6.81756 7.90131 7.20808 8.29607L12.501 13.5596L17.7939 8.29607C18.1844 7.90131 18.8176 7.90131 19.2081 8.29607C19.5986 8.69082 19.5986 9.33085 19.2081 9.72561L13.2081 15.7039C12.8176 16.0987 12.1844 16.0987 11.7939 15.7039L5.79387 9.72561C5.40335 9.33085 5.40335 8.69083 5.79387 8.29607Z'
        fill={color}
      />
    </svg>
  );
}
