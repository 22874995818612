import { useEffect, useState } from 'react';
import styles from './BotsStatisticPage.module.css';
import BotsStatusBar from '../BotsPage/BotsStatusBar/BotsStatusBar';
import BotsSort, { ESortDirections } from '../BotsPage/BotsSort/BotsSort';
import StatisticBlock from './StatisticBlock/StaticsticBlock';
import BotsList from './BotsListStatistic/BotsList/BotsList';
import MyBotsList from '../BotsPage/MyBotsList/MyBotsList';
import { selectBot } from '../../redux/slices/botsSlice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks/redux';
import Filter from '../FilterUserBots/Filter';
import { ITab, Navigation } from '../Navigation/Navigation';
import backArrow from '../../assets/General/back-arrow.svg';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { EFlowStatus, IBot } from '../../redux/types';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { formatDeepSnakeObjKeysToCamelCase } from '../../utils/formatSnakeToCamelCase';
import { subBotsMock } from '../BotsPage/BotsPage.mock';
import { v4 } from 'uuid';
import { getUptimeSeconds } from '../BotsPage/BotsSort/BotsSort.utils';
import { selectBots } from '../../redux/selectors/rootSelector';
import NoActiveBots from '../BotsPage/NoActiveBots/NoActiveBots';
const MOBILE_DEVICE_MAX_WIDTH = 768;

interface IBotsStatisticPage {
  navigationTabs: ITab[];
  navigationTitle: string;
}

const getFormattedSubBots = (flowId: string) => {
  const formattedSubBots = formatDeepSnakeObjKeysToCamelCase(subBotsMock) as {
    [key: string]: IBot[];
  };

  return formattedSubBots[flowId].map((bot: IBot) => ({ ...bot, uuid: v4() }));
};

export default function BotsStatisticPage({ navigationTabs, navigationTitle }: IBotsStatisticPage) {
  const { t } = useTranslation('botsStats');
  const dispatch = useAppDispatch();
  const [isStatisticsOpened, setIsStatisticsOpened] = useState(false);
  const [isMobileStatisticsOpened, setIsMobileStatisticsOpened] = useState(false);

  const allBots = useAppSelector(selectBots).bots || [];
  const bots = allBots.filter((botItem) => botItem.flowStatus !== EFlowStatus.new);
  const [subBots, setSubBots] = useState<IBot[]>();
  const [currentBots, setCurrentBots] = useState<IBot[]>(bots);
  const [needToClear, setNeedToClear] = useState(false);
  const [botsByStatus, setBotsByStatus] = useState<IBot[]>(bots);
  const [needUpdateSorting, setNeedUpdateSorting] = useState(true);
  const [sortDirection, setSortDirection] = useState(ESortDirections.newToOld);

  const { width } = useWindowDimensions();
  const isMobile = width <= MOBILE_DEVICE_MAX_WIDTH;
  const isSubbotListVisible = !isMobile || (isMobile && !isMobileStatisticsOpened);
  const isStatisticsVisible = !isMobile || !isSubbotListVisible;
  const isBotsListEmpty = !bots.length;
  const isCurrentBotsListEmpty = !currentBots.length;
  const isControlPanelVisible = !isStatisticsOpened && !isBotsListEmpty;

  const sortBots = () => {
    const newBots = [...currentBots].sort((prev, next) =>
      sortDirection === ESortDirections.newToOld
        ? getUptimeSeconds(prev) - getUptimeSeconds(next)
        : getUptimeSeconds(next) - getUptimeSeconds(prev),
    );
    setCurrentBots(newBots);
  };

  useEffect(() => {
    if (needUpdateSorting) {
      sortBots();
      setNeedUpdateSorting(false);
    }
  }, [needUpdateSorting]);

  const handleSetSubBots = (bot: IBot) => {
    dispatch(selectBot(bot));
    bot.hasSubbots ? setSubBots([bot, ...getFormattedSubBots(bot.flowId)]) : setSubBots([bot]);
  };

  const onSetSubBots = (bot: IBot) => {
    handleSetSubBots(bot);
    setIsStatisticsOpened(true);
  };

  const onOpenSubbotSettings = (isOpen: boolean) => {
    setIsStatisticsOpened(isOpen);
    setIsMobileStatisticsOpened(isOpen);
  };

  const onClickBack = () => {
    const isMobileStatisticsVisible = isMobile && !isSubbotListVisible;

    if (!isMobileStatisticsVisible) {
      setIsStatisticsOpened(false);
    } else {
      setIsMobileStatisticsOpened(false);
    }
  };

  return (
    <div className={styles.container}>
      <div
        className={cn(styles.navigationBox, { [styles.navigationBoxMargin]: isStatisticsOpened })}
      >
        <button
          className={cn(styles.backBtn, { [styles.hidden]: !isStatisticsOpened })}
          onClick={onClickBack}
        >
          <img src={backArrow} /> {t('back')}
        </button>
        <Navigation tabs={navigationTabs} title={navigationTitle} />
      </div>
      {isControlPanelVisible && (
        <div className={styles.wrapper}>
          <div className={styles.filtersBar}>
            <div className={styles.statusBar}>
              {!isBotsListEmpty && (
                <BotsStatusBar
                  isSettingsOpened={isStatisticsOpened}
                  bots={bots}
                  setBots={setCurrentBots}
                  setBotsByStatus={setBotsByStatus}
                  setNeedToClear={setNeedToClear}
                  setNeedUpdateSorting={setNeedUpdateSorting}
                  disableNewTab
                />
              )}
              {!isCurrentBotsListEmpty && (
                <BotsSort
                  sortDirection={sortDirection}
                  setSortDirection={setSortDirection}
                  setNeedUpdateSorting={setNeedUpdateSorting}
                />
              )}
            </div>
            {!isCurrentBotsListEmpty && (
              <Filter
                isCompact={false}
                calendar={false}
                bots={botsByStatus}
                setBots={setCurrentBots}
                needToClear={needToClear}
                setNeedToClear={setNeedToClear}
              />
            )}
          </div>
          <BotsList bots={currentBots} onSetSubBots={onSetSubBots} />
        </div>
      )}
      {isCurrentBotsListEmpty && (
        <div className={styles.centerContainer}><NoActiveBots /></div>
      )}

      {isStatisticsOpened && (
        <div className={styles.statistic}>
          {isSubbotListVisible && (
            <div className={styles.list}>
              <h4>Bot details</h4>

              <MyBotsList
                bots={subBots as unknown as IBot[]}
                isInfoBlockOpened={isStatisticsOpened}
                openInfoBlock={onOpenSubbotSettings}
                isSubBotsList={true}
                cardTooltip={t('cardTooltip')}
                isShownHeader={false}
              />
            </div>
          )}
          {isStatisticsVisible && (
            <StatisticBlock setIsStatisticsOpened={setIsStatisticsOpened} subBots={subBots} />
          )}
        </div>
      )}
    </div>
  );
}
