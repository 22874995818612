import { Dispatch, SetStateAction, useState } from 'react';
import cn from 'classnames';
import { IUser } from './UserList.types';
import SortArrow from '../../../../assets/General/SortArrow';
import styles from './UserList.module.css';
import { IGroup } from '../../GroupeListPage/group.types';

interface IFields {
  dateTime: Date;
  tradingVolume: number;
  realizedPnl: number;
  runningEquity: number;
  runningBots: number;
  botsCreated: number;
  botsCopied: number;
  usersQuantity?: number;
}

interface IHeaderButton {
  title: string;
  field: keyof IFields;
  className: string;
  setUsers?: Dispatch<SetStateAction<IUser[]>>;
  setGroup?: Dispatch<SetStateAction<IGroup[]>>;
}

export default function HeaderButton({
  field,
  title,
  className,
  setUsers,
  setGroup,
}: IHeaderButton) {
  const [isDescSort, setIsDescSort] = useState(true);
  const [isSortDesc, setIsSortDesc] = useState<{ [key: string]: boolean }>({
    dateTime: true,
    tradingVolume: true,
    realizedPnl: true,
    runningEquity: true,
    runningBots: true,
    botsCreated: true,
    botsCopied: true,
    usersQuantity: true,
  });

  function sortList(prevState: IUser[] | IGroup[]) {
    const sortedUsers = prevState.slice().sort((a, b) => {
      const valueA = a[field];
      const valueB = b[field];

      if (field === 'dateTime') {
        return (valueA as Date).getTime() - (valueB as Date).getTime();
      }
      if (typeof valueA === 'number' && typeof valueB === 'number') {
        return valueA - valueB;
      }
      if (typeof valueA === 'string' && typeof valueB === 'string') {
        return (valueA as string).localeCompare(valueB as string);
      }
      return 0;
    });

    return isDescSort ? sortedUsers.reverse() : sortedUsers;
  }

  function sortByField(field: keyof IFields) {
    if (setUsers) {
      setUsers((prevState) => sortList(prevState) as IUser[]);
    }

    if (setGroup) {
      setGroup((prevState) => sortList(prevState) as IGroup[]);
    }

    setIsDescSort((state) => !state);
    setIsSortDesc((state) => ({
      ...state,
      [field]: !state[field],
    }));
  }

  return (
    <div className={className}>
      <div className={styles.buttonContainer}>
        {title}
        <button
          onClick={() => sortByField(field)}
          className={cn({ [styles.ascArrow]: !isSortDesc[field] })}
        >
          <SortArrow color='#FFBE44' />
        </button>
      </div>
    </div>
  );
}
