import { EExchange } from '../../../LaunchBotPage/Leaderboard/ListLeaderboard/ListLeaderboard.types';
import { IUser, EGroup } from './UserList.types';

const RND = 1001;

export const USERS: IUser[] = [
  {
    dateTime: new Date(),
    uid: 'asdasdsa84684515',
    username: 'Lane Cooper',
    email: 'LaneCooper@gmail.com',
    group: EGroup.user,
    exchanges: [EExchange.binance, EExchange.bitget, EExchange.bybit, EExchange.okx],
    tradingVolume: Math.floor(Math.random() * RND),
    realizedPnl: Math.floor(Math.random() * RND),
    runningEquity: Math.floor(Math.random() * RND),
    runningBots: {
      auto: 65,
      pro: 45,
    },
    botsCreated: {
      auto: 65,
      pro: 45,
    },
    botsCopied: {
      auto: 65,
      pro: 45,
    },
    totalProfit: '/statistic/totalProfit',
    botHistory: '/statistic/bot',
    isActive: true,
  },
  {
    dateTime: new Date(),
    uid: '646asdsda8',
    username: 'Lane Cooper',
    email: 'LaneCooper@gmail.com',
    group: EGroup.user,
    exchanges: [EExchange.binance, EExchange.bitget, EExchange.bybit, EExchange.okx],
    tradingVolume: Math.floor(Math.random() * RND),
    realizedPnl: Math.floor(Math.random() * RND),
    runningEquity: Math.floor(Math.random() * RND),
    runningBots: {
      auto: 65,
      pro: 45,
    },
    botsCreated: {
      auto: 65,
      pro: 45,
    },
    botsCopied: {
      auto: 65,
      pro: 45,
    },
    totalProfit: '/statistic/totalProfit',
    botHistory: '/statistic/bot',
    isActive: true,
  },
  {
    dateTime: new Date(),
    uid: '979465128945asdasd',
    username: 'Lane Cooper',
    email: 'LaneCooper@gmail.com',
    group: EGroup.user,
    exchanges: [EExchange.binance, EExchange.bitget, EExchange.bybit, EExchange.okx],
    tradingVolume: Math.floor(Math.random() * RND),
    realizedPnl: Math.floor(Math.random() * RND),
    runningEquity: Math.floor(Math.random() * RND),
    runningBots: {
      auto: 65,
      pro: 45,
    },
    botsCreated: {
      auto: 65,
      pro: 45,
    },
    botsCopied: {
      auto: 65,
      pro: 45,
    },
    totalProfit: '/statistic/totalProfit',
    botHistory: '/statistic/bot',
    isActive: true,
  },
  {
    dateTime: new Date(),
    uid: '9784512sfd',
    username: 'Lane Cooper',
    email: 'LaneCooper@gmail.com',
    group: [EGroup.admin, EGroup.whitelist, EGroup.sudo, EGroup.user],
    exchanges: [EExchange.binance, EExchange.bitget, EExchange.bybit, EExchange.okx],
    tradingVolume: Math.floor(Math.random() * RND),
    realizedPnl: Math.floor(Math.random() * RND),
    runningEquity: Math.floor(Math.random() * RND),
    runningBots: {
      auto: 65,
      pro: 45,
    },
    botsCreated: {
      auto: 65,
      pro: 45,
    },
    botsCopied: {
      auto: 65,
      pro: 45,
    },
    totalProfit: '/statistic/totalProfit',
    botHistory: '/statistic/bot',
    isActive: false,
  },
  {
    dateTime: new Date(),
    uid: '984561188465156ads',
    username: 'Lane Cooper',
    email: 'LaneCooper@gmail.com',
    group: EGroup.user,
    exchanges: [EExchange.binance, EExchange.bitget, EExchange.bybit, EExchange.okx],
    tradingVolume: Math.floor(Math.random() * RND),
    realizedPnl: Math.floor(Math.random() * RND),
    runningEquity: Math.floor(Math.random() * RND),
    runningBots: {
      auto: 65,
      pro: 45,
    },
    botsCreated: {
      auto: 65,
      pro: 45,
    },
    botsCopied: {
      auto: 65,
      pro: 45,
    },
    totalProfit: '/statistic/totalProfit',
    botHistory: '/statistic/bot',
    isActive: true,
  },
  {
    dateTime: new Date(),
    uid: '412368451',
    username: 'Lane Cooper',
    email: 'LaneCooper@gmail.com',
    group: [EGroup.user, EGroup.admin],
    exchanges: [EExchange.binance, EExchange.bitget, EExchange.bybit, EExchange.okx],
    tradingVolume: Math.floor(Math.random() * RND),
    realizedPnl: Math.floor(Math.random() * RND),
    runningEquity: Math.floor(Math.random() * RND),
    runningBots: {
      auto: 65,
      pro: 45,
    },
    botsCreated: {
      auto: 65,
      pro: 45,
    },
    botsCopied: {
      auto: 65,
      pro: 45,
    },
    totalProfit: '/statistic/totalProfit',
    botHistory: '/statistic/bot',
    isActive: true,
  },
  {
    dateTime: new Date(),
    uid: 'adfsgdhfgrfe234365',
    username: 'Lane Cooper',
    email: 'LaneCooper@gmail.com',
    group: EGroup.user,
    exchanges: [EExchange.binance, EExchange.bitget, EExchange.bybit, EExchange.okx],
    tradingVolume: Math.floor(Math.random() * RND),
    realizedPnl: Math.floor(Math.random() * RND),
    runningEquity: Math.floor(Math.random() * RND),
    runningBots: {
      auto: 65,
      pro: 45,
    },
    botsCreated: {
      auto: 65,
      pro: 45,
    },
    botsCopied: {
      auto: 65,
      pro: 45,
    },
    totalProfit: '/statistic/totalProfit',
    botHistory: '/statistic/bot',
    isActive: false,
  },
  {
    dateTime: new Date(),
    uid: 'asdfdghyt54322456',
    username: 'Lane Cooper',
    email: 'LaneCooper@gmail.com',
    group: EGroup.whitelist,
    exchanges: [EExchange.binance, EExchange.bitget, EExchange.bybit, EExchange.okx],
    tradingVolume: Math.floor(Math.random() * RND),
    realizedPnl: Math.floor(Math.random() * RND),
    runningEquity: Math.floor(Math.random() * RND),
    runningBots: {
      auto: 65,
      pro: 45,
    },
    botsCreated: {
      auto: 65,
      pro: 45,
    },
    botsCopied: {
      auto: 65,
      pro: 45,
    },
    totalProfit: '/statistic/totalProfit',
    botHistory: '/statistic/bot',
    isActive: true,
  },
  {
    dateTime: new Date(),
    uid: '9788456dsf4fdsf',
    username: 'Lane Cooper',
    email: 'LaneCooper@gmail.com',
    group: EGroup.user,
    exchanges: [EExchange.binance, EExchange.bitget, EExchange.bybit, EExchange.okx],
    tradingVolume: Math.floor(Math.random() * RND),
    realizedPnl: Math.floor(Math.random() * RND),
    runningEquity: Math.floor(Math.random() * RND),
    runningBots: {
      auto: 65,
      pro: 45,
    },
    botsCreated: {
      auto: 65,
      pro: 45,
    },
    botsCopied: {
      auto: 65,
      pro: 45,
    },
    totalProfit: '/statistic/totalProfit',
    botHistory: '/statistic/bot',
    isActive: true,
  },
  {
    dateTime: new Date(),
    uid: 'sdf64465sfd465dsf46',
    username: 'Lane Cooper',
    email: 'LaneCooper@gmail.com',
    group: EGroup.sudo,
    exchanges: [EExchange.binance, EExchange.bitget, EExchange.bybit, EExchange.okx],
    tradingVolume: Math.floor(Math.random() * RND),
    realizedPnl: Math.floor(Math.random() * RND),
    runningEquity: Math.floor(Math.random() * RND),
    runningBots: {
      auto: 65,
      pro: 45,
    },
    botsCreated: {
      auto: 65,
      pro: 45,
    },
    botsCopied: {
      auto: 65,
      pro: 45,
    },
    totalProfit: '/statistic/totalProfit',
    botHistory: '/statistic/bot',
    isActive: true,
  },
  {
    dateTime: new Date(),
    uid: '645fsd45sfd4fds48',
    username: 'Lane Cooper',
    email: 'LaneCooper@gmail.com',
    group: EGroup.whitelist,
    exchanges: [EExchange.binance, EExchange.bitget, EExchange.bybit, EExchange.okx],
    tradingVolume: Math.floor(Math.random() * RND),
    realizedPnl: Math.floor(Math.random() * RND),
    runningEquity: Math.floor(Math.random() * RND),
    runningBots: {
      auto: 65,
      pro: 45,
    },
    botsCreated: {
      auto: 65,
      pro: 45,
    },
    botsCopied: {
      auto: 65,
      pro: 45,
    },
    totalProfit: '/statistic/totalProfit',
    botHistory: '/statistic/bot',
    isActive: true,
  },
  {
    dateTime: new Date(),
    uid: 'asdasd94844864161565',
    username: 'Lane Cooper',
    email: 'LaneCooper@gmail.com',
    group: EGroup.user,
    exchanges: [EExchange.binance, EExchange.bitget, EExchange.bybit, EExchange.okx],
    tradingVolume: Math.floor(Math.random() * RND),
    realizedPnl: Math.floor(Math.random() * RND),
    runningEquity: Math.floor(Math.random() * RND),
    runningBots: {
      auto: 65,
      pro: 45,
    },
    botsCreated: {
      auto: 65,
      pro: 45,
    },
    botsCopied: {
      auto: 65,
      pro: 45,
    },
    totalProfit: '/statistic/totalProfit',
    botHistory: '/statistic/bot',
    isActive: true,
  },
  {
    dateTime: new Date(),
    uid: '7945644579456asdsd',
    username: 'Lane Cooper',
    email: 'LaneCooper@gmail.com',
    group: EGroup.user,
    exchanges: [EExchange.binance, EExchange.bitget, EExchange.bybit, EExchange.okx],
    tradingVolume: Math.floor(Math.random() * RND),
    realizedPnl: Math.floor(Math.random() * RND),
    runningEquity: Math.floor(Math.random() * RND),
    runningBots: {
      auto: 65,
      pro: 45,
    },
    botsCreated: {
      auto: 65,
      pro: 45,
    },
    botsCopied: {
      auto: 65,
      pro: 45,
    },
    totalProfit: '/statistic/totalProfit',
    botHistory: '/statistic/bot',
    isActive: true,
  },
  {
    dateTime: new Date(),
    uid: '2e3rwfegtrfsad',
    username: 'Lane Cooper',
    email: 'LaneCooper@gmail.com',
    group: EGroup.user,
    exchanges: [EExchange.binance, EExchange.bitget, EExchange.bybit, EExchange.okx],
    tradingVolume: Math.floor(Math.random() * RND),
    realizedPnl: Math.floor(Math.random() * RND),
    runningEquity: Math.floor(Math.random() * RND),
    runningBots: {
      auto: 65,
      pro: 45,
    },
    botsCreated: {
      auto: 65,
      pro: 45,
    },
    botsCopied: {
      auto: 65,
      pro: 45,
    },
    totalProfit: '/statistic/totalProfit',
    botHistory: '/statistic/bot',
    isActive: true,
  },
  {
    dateTime: new Date(),
    uid: '32rqewfgethrgerwq',
    username: 'Lane Cooper',
    email: 'LaneCooper@gmail.com',
    group: EGroup.user,
    exchanges: [EExchange.binance, EExchange.bitget, EExchange.bybit, EExchange.okx],
    tradingVolume: Math.floor(Math.random() * RND),
    realizedPnl: Math.floor(Math.random() * RND),
    runningEquity: Math.floor(Math.random() * RND),
    runningBots: {
      auto: 65,
      pro: 45,
    },
    botsCreated: {
      auto: 65,
      pro: 45,
    },
    botsCopied: {
      auto: 65,
      pro: 45,
    },
    totalProfit: '/statistic/totalProfit',
    botHistory: '/statistic/bot',
    isActive: true,
  },
  {
    dateTime: new Date(),
    uid: '324t35yhetgrsfeadwefsd',
    username: 'Lane Cooper',
    email: 'LaneCooper@gmail.com',
    group: EGroup.user,
    exchanges: [EExchange.binance, EExchange.bitget, EExchange.bybit, EExchange.okx],
    tradingVolume: Math.floor(Math.random() * RND),
    realizedPnl: Math.floor(Math.random() * RND),
    runningEquity: Math.floor(Math.random() * RND),
    runningBots: {
      auto: 65,
      pro: 45,
    },
    botsCreated: {
      auto: 65,
      pro: 45,
    },
    botsCopied: {
      auto: 65,
      pro: 45,
    },
    totalProfit: '/statistic/totalProfit',
    botHistory: '/statistic/bot',
    isActive: true,
  },
  {
    dateTime: new Date(),
    uid: 'fdsgdbert423refs',
    username: 'Lane Cooper',
    email: 'LaneCooper@gmail.com',
    group: EGroup.whitelist,
    exchanges: [EExchange.binance, EExchange.bitget, EExchange.bybit, EExchange.okx],
    tradingVolume: Math.floor(Math.random() * RND),
    realizedPnl: Math.floor(Math.random() * RND),
    runningEquity: Math.floor(Math.random() * RND),
    runningBots: {
      auto: 65,
      pro: 45,
    },
    botsCreated: {
      auto: 65,
      pro: 45,
    },
    botsCopied: {
      auto: 65,
      pro: 45,
    },
    totalProfit: '/statistic/totalProfit',
    botHistory: '/statistic/bot',
    isActive: true,
  },
  {
    dateTime: new Date(),
    uid: '444dscdsfd',
    username: 'Lane Cooper',
    email: 'LaneCooper@gmail.com',
    group: EGroup.user,
    exchanges: [EExchange.binance, EExchange.bitget, EExchange.bybit, EExchange.okx],
    tradingVolume: Math.floor(Math.random() * RND),
    realizedPnl: Math.floor(Math.random() * RND),
    runningEquity: Math.floor(Math.random() * RND),
    runningBots: {
      auto: 65,
      pro: 45,
    },
    botsCreated: {
      auto: 65,
      pro: 45,
    },
    botsCopied: {
      auto: 65,
      pro: 45,
    },
    totalProfit: '/statistic/totalProfit',
    botHistory: '/statistic/bot',
    isActive: true,
  },
  {
    dateTime: new Date(),
    uid: 'sdf46ds15',
    username: 'Lane Cooper',
    email: 'LaneCooper@gmail.com',
    group: EGroup.user,
    exchanges: [EExchange.binance, EExchange.bitget, EExchange.bybit, EExchange.okx],
    tradingVolume: Math.floor(Math.random() * RND),
    realizedPnl: Math.floor(Math.random() * RND),
    runningEquity: Math.floor(Math.random() * RND),
    runningBots: {
      auto: 65,
      pro: 45,
    },
    botsCreated: {
      auto: 65,
      pro: 45,
    },
    botsCopied: {
      auto: 65,
      pro: 45,
    },
    totalProfit: '/statistic/totalProfit',
    botHistory: '/statistic/bot',
    isActive: false,
  },
  {
    dateTime: new Date(),
    uid: '78sf78',
    username: 'Lane Cooper',
    email: 'LaneCooper@gmail.com',
    group: EGroup.user,
    exchanges: [EExchange.binance, EExchange.bitget, EExchange.bybit, EExchange.okx],
    tradingVolume: Math.floor(Math.random() * RND),
    realizedPnl: Math.floor(Math.random() * RND),
    runningEquity: Math.floor(Math.random() * RND),
    runningBots: {
      auto: 65,
      pro: 45,
    },
    botsCreated: {
      auto: 65,
      pro: 45,
    },
    botsCopied: {
      auto: 65,
      pro: 45,
    },
    totalProfit: '/statistic/totalProfit',
    botHistory: '/statistic/bot',
    isActive: true,
  },
  {
    dateTime: new Date(),
    uid: 'dsfregawqw',
    username: 'Lane Cooper',
    email: 'LaneCooper@gmail.com',
    group: EGroup.whitelist,
    exchanges: [EExchange.binance, EExchange.bitget, EExchange.bybit, EExchange.okx],
    tradingVolume: Math.floor(Math.random() * RND),
    realizedPnl: Math.floor(Math.random() * RND),
    runningEquity: Math.floor(Math.random() * RND),
    runningBots: {
      auto: 65,
      pro: 45,
    },
    botsCreated: {
      auto: 65,
      pro: 45,
    },
    botsCopied: {
      auto: 65,
      pro: 45,
    },
    totalProfit: '/statistic/totalProfit',
    botHistory: '/statistic/bot',
    isActive: false,
  },
  {
    dateTime: new Date(),
    uid: 'fewvefafsa',
    username: 'Lane Cooper',
    email: 'LaneCooper@gmail.com',
    group: EGroup.user,
    exchanges: [EExchange.binance, EExchange.bitget, EExchange.bybit, EExchange.okx],
    tradingVolume: Math.floor(Math.random() * RND),
    realizedPnl: Math.floor(Math.random() * RND),
    runningEquity: Math.floor(Math.random() * RND),
    runningBots: {
      auto: 65,
      pro: 45,
    },
    botsCreated: {
      auto: 65,
      pro: 45,
    },
    botsCopied: {
      auto: 65,
      pro: 45,
    },
    totalProfit: '/statistic/totalProfit',
    botHistory: '/statistic/bot',
    isActive: true,
  },
  {
    dateTime: new Date(),
    uid: 'vfdgbrtherw',
    username: 'Lane Cooper',
    email: 'LaneCooper@gmail.com',
    group: EGroup.user,
    exchanges: [EExchange.binance, EExchange.bitget, EExchange.bybit, EExchange.okx],
    tradingVolume: Math.floor(Math.random() * RND),
    realizedPnl: Math.floor(Math.random() * RND),
    runningEquity: Math.floor(Math.random() * RND),
    runningBots: {
      auto: 65,
      pro: 45,
    },
    botsCreated: {
      auto: 65,
      pro: 45,
    },
    botsCopied: {
      auto: 65,
      pro: 45,
    },
    totalProfit: '/statistic/totalProfit',
    botHistory: '/statistic/bot',
    isActive: true,
  },
  {
    dateTime: new Date(),
    uid: 'nhgtfbdvsaa',
    username: 'Lane Cooper',
    email: 'LaneCooper@gmail.com',
    group: EGroup.whitelist,
    exchanges: [EExchange.binance, EExchange.bitget, EExchange.bybit, EExchange.okx],
    tradingVolume: Math.floor(Math.random() * RND),
    realizedPnl: Math.floor(Math.random() * RND),
    runningEquity: Math.floor(Math.random() * RND),
    runningBots: {
      auto: 65,
      pro: 45,
    },
    botsCreated: {
      auto: 65,
      pro: 45,
    },
    botsCopied: {
      auto: 65,
      pro: 45,
    },
    totalProfit: '/statistic/totalProfit',
    botHistory: '/statistic/bot',
    isActive: true,
  },
  {
    dateTime: new Date(),
    uid: 'sdczx1df81dfvdcz5s',
    username: 'Lane Cooper',
    email: 'LaneCooper@gmail.com',
    group: EGroup.user,
    exchanges: [EExchange.binance, EExchange.bitget, EExchange.bybit, EExchange.okx],
    tradingVolume: Math.floor(Math.random() * RND),
    realizedPnl: Math.floor(Math.random() * RND),
    runningEquity: Math.floor(Math.random() * RND),
    runningBots: {
      auto: 65,
      pro: 45,
    },
    botsCreated: {
      auto: 65,
      pro: 45,
    },
    botsCopied: {
      auto: 65,
      pro: 45,
    },
    totalProfit: '/statistic/totalProfit',
    botHistory: '/statistic/bot',
    isActive: true,
  },
  {
    dateTime: new Date(),
    uid: 'ds18as4as',
    username: 'Lane Cooper',
    email: 'LaneCooper@gmail.com',
    group: EGroup.user,
    exchanges: [EExchange.binance, EExchange.bitget, EExchange.bybit, EExchange.okx],
    tradingVolume: Math.floor(Math.random() * RND),
    realizedPnl: Math.floor(Math.random() * RND),
    runningEquity: Math.floor(Math.random() * RND),
    runningBots: {
      auto: 65,
      pro: 45,
    },
    botsCreated: {
      auto: 65,
      pro: 45,
    },
    botsCopied: {
      auto: 65,
      pro: 45,
    },
    totalProfit: '/statistic/totalProfit',
    botHistory: '/statistic/bot',
    isActive: true,
  },
  {
    dateTime: new Date(),
    uid: 'sfd4cz5sd4a8aa',
    username: 'Lane Cooper',
    email: 'LaneCooper@gmail.com',
    group: EGroup.user,
    exchanges: [EExchange.binance, EExchange.bitget, EExchange.bybit, EExchange.okx],
    tradingVolume: Math.floor(Math.random() * RND),
    realizedPnl: Math.floor(Math.random() * RND),
    runningEquity: Math.floor(Math.random() * RND),
    runningBots: {
      auto: 65,
      pro: 45,
    },
    botsCreated: {
      auto: 65,
      pro: 45,
    },
    botsCopied: {
      auto: 65,
      pro: 45,
    },
    totalProfit: '/statistic/totalProfit',
    botHistory: '/statistic/bot',
    isActive: true,
  },
  {
    dateTime: new Date(),
    uid: 'adsffvbgferfew4152',
    username: 'Lane Cooper',
    email: 'LaneCooper@gmail.com',
    group: EGroup.whitelist,
    exchanges: [EExchange.binance, EExchange.bitget, EExchange.bybit, EExchange.okx],
    tradingVolume: Math.floor(Math.random() * RND),
    realizedPnl: Math.floor(Math.random() * RND),
    runningEquity: Math.floor(Math.random() * RND),
    runningBots: {
      auto: 65,
      pro: 45,
    },
    botsCreated: {
      auto: 65,
      pro: 45,
    },
    botsCopied: {
      auto: 65,
      pro: 45,
    },
    totalProfit: '/statistic/totalProfit',
    botHistory: '/statistic/bot',
    isActive: true,
  },
  {
    dateTime: new Date(),
    uid: '9fs74d61xc5',
    username: 'Lane Cooper',
    email: 'LaneCooper@gmail.com',
    group: EGroup.user,
    exchanges: [EExchange.binance, EExchange.bitget, EExchange.bybit, EExchange.okx],
    tradingVolume: Math.floor(Math.random() * RND),
    realizedPnl: Math.floor(Math.random() * RND),
    runningEquity: Math.floor(Math.random() * RND),
    runningBots: {
      auto: 65,
      pro: 45,
    },
    botsCreated: {
      auto: 65,
      pro: 45,
    },
    botsCopied: {
      auto: 65,
      pro: 45,
    },
    totalProfit: '/statistic/totalProfit',
    botHistory: '/statistic/bot',
    isActive: false,
  },
  {
    dateTime: new Date(),
    uid: '9748esfad65cz1x',
    username: 'Lane Cooper',
    email: 'LaneCooper@gmail.com',
    group: EGroup.user,
    exchanges: [EExchange.binance, EExchange.bitget, EExchange.bybit, EExchange.okx],
    tradingVolume: Math.floor(Math.random() * RND),
    realizedPnl: Math.floor(Math.random() * RND),
    runningEquity: Math.floor(Math.random() * RND),
    runningBots: {
      auto: 65,
      pro: 45,
    },
    botsCreated: {
      auto: 65,
      pro: 45,
    },
    botsCopied: {
      auto: 65,
      pro: 45,
    },
    totalProfit: '/statistic/totalProfit',
    botHistory: '/statistic/bot',
    isActive: true,
  },
  {
    dateTime: new Date(),
    uid: '84sgdv51zxc',
    username: 'Lane Cooper',
    email: 'LaneCooper@gmail.com',
    group: EGroup.user,
    exchanges: [EExchange.binance, EExchange.bitget, EExchange.bybit, EExchange.okx],
    tradingVolume: Math.floor(Math.random() * RND),
    realizedPnl: Math.floor(Math.random() * RND),
    runningEquity: Math.floor(Math.random() * RND),
    runningBots: {
      auto: 65,
      pro: 45,
    },
    botsCreated: {
      auto: 65,
      pro: 45,
    },
    botsCopied: {
      auto: 65,
      pro: 45,
    },
    totalProfit: '/statistic/totalProfit',
    botHistory: '/statistic/bot',
    isActive: true,
  },
  {
    dateTime: new Date(),
    uid: '8s4gd5v1',
    username: 'Lane Cooper',
    email: 'LaneCooper@gmail.com',
    group: EGroup.user,
    exchanges: [EExchange.binance, EExchange.bitget, EExchange.bybit, EExchange.okx],
    tradingVolume: Math.floor(Math.random() * RND),
    realizedPnl: Math.floor(Math.random() * RND),
    runningEquity: Math.floor(Math.random() * RND),
    runningBots: {
      auto: 65,
      pro: 45,
    },
    botsCreated: {
      auto: 65,
      pro: 45,
    },
    botsCopied: {
      auto: 65,
      pro: 45,
    },
    totalProfit: '/statistic/totalProfit',
    botHistory: '/statistic/bot',
    isActive: true,
  },
  {
    dateTime: new Date(),
    uid: '7sd94v865',
    username: 'Lane Cooper',
    email: 'LaneCooper@gmail.com',
    group: EGroup.user,
    exchanges: [EExchange.binance, EExchange.bitget, EExchange.bybit, EExchange.okx],
    tradingVolume: Math.floor(Math.random() * RND),
    realizedPnl: Math.floor(Math.random() * RND),
    runningEquity: Math.floor(Math.random() * RND),
    runningBots: {
      auto: 65,
      pro: 45,
    },
    botsCreated: {
      auto: 65,
      pro: 45,
    },
    botsCopied: {
      auto: 65,
      pro: 45,
    },
    totalProfit: '/statistic/totalProfit',
    botHistory: '/statistic/bot',
    isActive: true,
  },
  {
    dateTime: new Date(),
    uid: '9w4ef8sd65',
    username: 'Lane Cooper',
    email: 'LaneCooper@gmail.com',
    group: EGroup.user,
    exchanges: [EExchange.binance, EExchange.bitget, EExchange.bybit, EExchange.okx],
    tradingVolume: Math.floor(Math.random() * RND),
    realizedPnl: Math.floor(Math.random() * RND),
    runningEquity: Math.floor(Math.random() * RND),
    runningBots: {
      auto: 65,
      pro: 45,
    },
    botsCreated: {
      auto: 65,
      pro: 45,
    },
    botsCopied: {
      auto: 65,
      pro: 45,
    },
    totalProfit: '/statistic/totalProfit',
    botHistory: '/statistic/bot',
    isActive: true,
  },
  {
    dateTime: new Date(),
    uid: 'rsg4d851v',
    username: 'Lane Cooper',
    email: 'LaneCooper@gmail.com',
    group: EGroup.user,
    exchanges: [EExchange.binance, EExchange.bitget, EExchange.bybit, EExchange.okx],
    tradingVolume: Math.floor(Math.random() * RND),
    realizedPnl: Math.floor(Math.random() * RND),
    runningEquity: Math.floor(Math.random() * RND),
    runningBots: {
      auto: 65,
      pro: 45,
    },
    botsCreated: {
      auto: 65,
      pro: 45,
    },
    botsCopied: {
      auto: 65,
      pro: 45,
    },
    totalProfit: '/statistic/totalProfit',
    botHistory: '/statistic/bot',
    isActive: true,
  },
  {
    dateTime: new Date(),
    uid: 'wf4e8dsvc52zx',
    username: 'Lane Cooper',
    email: 'LaneCooper@gmail.com',
    group: EGroup.whitelist,
    exchanges: [EExchange.binance, EExchange.bitget, EExchange.bybit, EExchange.okx],
    tradingVolume: Math.floor(Math.random() * RND),
    realizedPnl: Math.floor(Math.random() * RND),
    runningEquity: Math.floor(Math.random() * RND),
    runningBots: {
      auto: 65,
      pro: 45,
    },
    botsCreated: {
      auto: 65,
      pro: 45,
    },
    botsCopied: {
      auto: 65,
      pro: 45,
    },
    totalProfit: '/statistic/totalProfit',
    botHistory: '/statistic/bot',
    isActive: true,
  },
  {
    dateTime: new Date(),
    uid: 'bd48f4848zx',
    username: 'Lane Cooper',
    email: 'LaneCooper@gmail.com',
    group: EGroup.user,
    exchanges: [EExchange.binance, EExchange.bitget, EExchange.bybit, EExchange.okx],
    tradingVolume: Math.floor(Math.random() * RND),
    realizedPnl: Math.floor(Math.random() * RND),
    runningEquity: Math.floor(Math.random() * RND),
    runningBots: {
      auto: 65,
      pro: 45,
    },
    botsCreated: {
      auto: 65,
      pro: 45,
    },
    botsCopied: {
      auto: 65,
      pro: 45,
    },
    totalProfit: '/statistic/totalProfit',
    botHistory: '/statistic/bot',
    isActive: true,
  },
  {
    dateTime: new Date(),
    uid: 'cv26vcx6ads15af',
    username: 'Lane Cooper',
    email: 'LaneCooper@gmail.com',
    group: EGroup.user,
    exchanges: [EExchange.binance, EExchange.bitget, EExchange.bybit, EExchange.okx],
    tradingVolume: Math.floor(Math.random() * RND),
    realizedPnl: Math.floor(Math.random() * RND),
    runningEquity: Math.floor(Math.random() * RND),
    runningBots: {
      auto: 65,
      pro: 45,
    },
    botsCreated: {
      auto: 65,
      pro: 45,
    },
    botsCopied: {
      auto: 65,
      pro: 45,
    },
    totalProfit: '/statistic/totalProfit',
    botHistory: '/statistic/bot',
    isActive: true,
  },
  {
    dateTime: new Date(),
    uid: 'sd15ds84sd',
    username: 'Lane Cooper',
    email: 'LaneCooper@gmail.com',
    group: EGroup.user,
    exchanges: [EExchange.binance, EExchange.bitget, EExchange.bybit, EExchange.okx],
    tradingVolume: Math.floor(Math.random() * RND),
    realizedPnl: Math.floor(Math.random() * RND),
    runningEquity: Math.floor(Math.random() * RND),
    runningBots: {
      auto: 65,
      pro: 45,
    },
    botsCreated: {
      auto: 65,
      pro: 45,
    },
    botsCopied: {
      auto: 65,
      pro: 45,
    },
    totalProfit: '/statistic/totalProfit',
    botHistory: '/statistic/bot',
    isActive: true,
  },
  {
    dateTime: new Date(),
    uid: 'ds48sdf48ds',
    username: 'Lane Cooper',
    email: 'LaneCooper@gmail.com',
    group: EGroup.user,
    exchanges: [EExchange.binance, EExchange.bitget, EExchange.bybit, EExchange.okx],
    tradingVolume: Math.floor(Math.random() * RND),
    realizedPnl: Math.floor(Math.random() * RND),
    runningEquity: Math.floor(Math.random() * RND),
    runningBots: {
      auto: 65,
      pro: 45,
    },
    botsCreated: {
      auto: 65,
      pro: 45,
    },
    botsCopied: {
      auto: 65,
      pro: 45,
    },
    totalProfit: '/statistic/totalProfit',
    botHistory: '/statistic/bot',
    isActive: true,
  },
  {
    dateTime: new Date(),
    uid: 'sv15sd18sd48',
    username: 'Lane Cooper',
    email: 'LaneCooper@gmail.com',
    group: EGroup.user,
    exchanges: [EExchange.binance, EExchange.bitget, EExchange.bybit, EExchange.okx],
    tradingVolume: Math.floor(Math.random() * RND),
    realizedPnl: Math.floor(Math.random() * RND),
    runningEquity: Math.floor(Math.random() * RND),
    runningBots: {
      auto: 65,
      pro: 45,
    },
    botsCreated: {
      auto: 65,
      pro: 45,
    },
    botsCopied: {
      auto: 65,
      pro: 45,
    },
    totalProfit: '/statistic/totalProfit',
    botHistory: '/statistic/bot',
    isActive: true,
  },
  {
    dateTime: new Date(),
    uid: 'asd11Ssaczx',
    username: 'Lane Cooper',
    email: 'LaneCooper@gmail.com',
    group: EGroup.whitelist,
    exchanges: [EExchange.binance, EExchange.bitget, EExchange.bybit, EExchange.okx],
    tradingVolume: Math.floor(Math.random() * RND),
    realizedPnl: Math.floor(Math.random() * RND),
    runningEquity: Math.floor(Math.random() * RND),
    runningBots: {
      auto: 65,
      pro: 45,
    },
    botsCreated: {
      auto: 65,
      pro: 45,
    },
    botsCopied: {
      auto: 65,
      pro: 45,
    },
    totalProfit: '/statistic/totalProfit',
    botHistory: '/statistic/bot',
    isActive: true,
  },
  {
    dateTime: new Date(),
    uid: 'ds8sd15dsd',
    username: 'Lane Cooper',
    email: 'LaneCooper@gmail.com',
    group: EGroup.whitelist,
    exchanges: [EExchange.binance, EExchange.bitget, EExchange.bybit, EExchange.okx],
    tradingVolume: Math.floor(Math.random() * RND),
    realizedPnl: Math.floor(Math.random() * RND),
    runningEquity: Math.floor(Math.random() * RND),
    runningBots: {
      auto: 65,
      pro: 45,
    },
    botsCreated: {
      auto: 65,
      pro: 45,
    },
    botsCopied: {
      auto: 65,
      pro: 45,
    },
    totalProfit: '/statistic/totalProfit',
    botHistory: '/statistic/bot',
    isActive: true,
  },
  {
    dateTime: new Date(),
    uid: 'asffcfds415fsd',
    username: 'Lane Cooper',
    email: 'LaneCooper@gmail.com',
    group: EGroup.whitelist,
    exchanges: [EExchange.binance, EExchange.bitget, EExchange.bybit, EExchange.okx],
    tradingVolume: Math.floor(Math.random() * RND),
    realizedPnl: Math.floor(Math.random() * RND),
    runningEquity: Math.floor(Math.random() * RND),
    runningBots: {
      auto: 65,
      pro: 45,
    },
    botsCreated: {
      auto: 65,
      pro: 45,
    },
    botsCopied: {
      auto: 65,
      pro: 45,
    },
    totalProfit: '/statistic/totalProfit',
    botHistory: '/statistic/bot',
    isActive: true,
  },
  {
    dateTime: new Date(),
    uid: 'dsf8fsd1fds',
    username: 'Lane Cooper',
    email: 'LaneCooper@gmail.com',
    group: EGroup.whitelist,
    exchanges: [EExchange.binance, EExchange.bitget, EExchange.bybit, EExchange.okx],
    tradingVolume: Math.floor(Math.random() * RND),
    realizedPnl: Math.floor(Math.random() * RND),
    runningEquity: Math.floor(Math.random() * RND),
    runningBots: {
      auto: 65,
      pro: 45,
    },
    botsCreated: {
      auto: 65,
      pro: 45,
    },
    botsCopied: {
      auto: 65,
      pro: 45,
    },
    totalProfit: '/statistic/totalProfit',
    botHistory: '/statistic/bot',
    isActive: true,
  },
  {
    dateTime: new Date(),
    uid: 's1d5d115fd',
    username: 'Lane Cooper',
    email: 'LaneCooper@gmail.com',
    group: EGroup.user,
    exchanges: [EExchange.binance, EExchange.bitget, EExchange.bybit, EExchange.okx],
    tradingVolume: Math.floor(Math.random() * RND),
    realizedPnl: Math.floor(Math.random() * RND),
    runningEquity: Math.floor(Math.random() * RND),
    runningBots: {
      auto: 65,
      pro: 45,
    },
    botsCreated: {
      auto: 65,
      pro: 45,
    },
    botsCopied: {
      auto: 65,
      pro: 45,
    },
    totalProfit: '/statistic/totalProfit',
    botHistory: '/statistic/bot',
    isActive: true,
  },
  {
    dateTime: new Date(),
    uid: 'sdfdsfg2134356',
    username: 'Lane Cooper',
    email: 'LaneCooper@gmail.com',
    group: EGroup.user,
    exchanges: [EExchange.binance, EExchange.bitget, EExchange.bybit, EExchange.okx],
    tradingVolume: Math.floor(Math.random() * RND),
    realizedPnl: Math.floor(Math.random() * RND),
    runningEquity: Math.floor(Math.random() * RND),
    runningBots: {
      auto: 65,
      pro: 45,
    },
    botsCreated: {
      auto: 65,
      pro: 45,
    },
    botsCopied: {
      auto: 65,
      pro: 45,
    },
    totalProfit: '/statistic/totalProfit',
    botHistory: '/statistic/bot',
    isActive: true,
  },
  {
    dateTime: new Date(),
    uid: 's15sf48',
    username: 'Lane Cooper',
    email: 'LaneCooper@gmail.com',
    group: EGroup.user,
    exchanges: [EExchange.binance, EExchange.bitget, EExchange.bybit, EExchange.okx],
    tradingVolume: Math.floor(Math.random() * RND),
    realizedPnl: Math.floor(Math.random() * RND),
    runningEquity: Math.floor(Math.random() * RND),
    runningBots: {
      auto: 65,
      pro: 45,
    },
    botsCreated: {
      auto: 65,
      pro: 45,
    },
    botsCopied: {
      auto: 65,
      pro: 45,
    },
    totalProfit: '/statistic/totalProfit',
    botHistory: '/statistic/bot',
    isActive: true,
  },
  {
    dateTime: new Date(),
    uid: '18s1s1',
    username: 'Lane Cooper',
    email: 'LaneCooper@gmail.com',
    group: EGroup.sudo,
    exchanges: [EExchange.binance, EExchange.bitget, EExchange.bybit, EExchange.okx],
    tradingVolume: Math.floor(Math.random() * RND),
    realizedPnl: Math.floor(Math.random() * RND),
    runningEquity: Math.floor(Math.random() * RND),
    runningBots: {
      auto: 65,
      pro: 45,
    },
    botsCreated: {
      auto: 65,
      pro: 45,
    },
    botsCopied: {
      auto: 65,
      pro: 45,
    },
    totalProfit: '/statistic/totalProfit',
    botHistory: '/statistic/bot',
    isActive: false,
  },
  {
    dateTime: new Date(),
    uid: 'b185d1sd18ds',
    username: 'Lane Cooper',
    email: 'LaneCooper@gmail.com',
    group: EGroup.user,
    exchanges: [EExchange.binance, EExchange.bitget, EExchange.bybit, EExchange.okx],
    tradingVolume: Math.floor(Math.random() * RND),
    realizedPnl: Math.floor(Math.random() * RND),
    runningEquity: Math.floor(Math.random() * RND),
    runningBots: {
      auto: 65,
      pro: 45,
    },
    botsCreated: {
      auto: 65,
      pro: 45,
    },
    botsCopied: {
      auto: 65,
      pro: 45,
    },
    totalProfit: '/statistic/totalProfit',
    botHistory: '/statistic/bot',
    isActive: true,
  },
  {
    dateTime: new Date(),
    uid: 'fgf151bg515bfd',
    username: 'Lane Cooper',
    email: 'LaneCooper@gmail.com',
    group: EGroup.user,
    exchanges: [EExchange.binance, EExchange.bitget, EExchange.bybit, EExchange.okx],
    tradingVolume: Math.floor(Math.random() * RND),
    realizedPnl: Math.floor(Math.random() * RND),
    runningEquity: Math.floor(Math.random() * RND),
    runningBots: {
      auto: 65,
      pro: 45,
    },
    botsCreated: {
      auto: 65,
      pro: 45,
    },
    botsCopied: {
      auto: 65,
      pro: 45,
    },
    totalProfit: '/statistic/totalProfit',
    botHistory: '/statistic/bot',
    isActive: true,
  },
  {
    dateTime: new Date(),
    uid: 'n15fh11gf51',
    username: 'Lane Cooper',
    email: 'LaneCooper@gmail.com',
    group: EGroup.user,
    exchanges: [EExchange.binance, EExchange.bitget, EExchange.bybit, EExchange.okx],
    tradingVolume: Math.floor(Math.random() * RND),
    realizedPnl: Math.floor(Math.random() * RND),
    runningEquity: Math.floor(Math.random() * RND),
    runningBots: {
      auto: 65,
      pro: 45,
    },
    botsCreated: {
      auto: 65,
      pro: 45,
    },
    botsCopied: {
      auto: 65,
      pro: 45,
    },
    totalProfit: '/statistic/totalProfit',
    botHistory: '/statistic/bot',
    isActive: true,
  },
  {
    dateTime: new Date(),
    uid: 'gbd15fdb',
    username: 'Lane Cooper',
    email: 'LaneCooper@gmail.com',
    group: EGroup.whitelist,
    exchanges: [EExchange.binance, EExchange.bitget, EExchange.bybit, EExchange.okx],
    tradingVolume: Math.floor(Math.random() * RND),
    realizedPnl: Math.floor(Math.random() * RND),
    runningEquity: Math.floor(Math.random() * RND),
    runningBots: {
      auto: 65,
      pro: 45,
    },
    botsCreated: {
      auto: 65,
      pro: 45,
    },
    botsCopied: {
      auto: 65,
      pro: 45,
    },
    totalProfit: '/statistic/totalProfit',
    botHistory: '/statistic/bot',
    isActive: true,
  },
  {
    dateTime: new Date(),
    uid: 'zcx6215asd15as',
    username: 'Lane Cooper',
    email: 'LaneCooper@gmail.com',
    group: EGroup.user,
    exchanges: [EExchange.binance, EExchange.bitget, EExchange.bybit, EExchange.okx],
    tradingVolume: Math.floor(Math.random() * RND),
    realizedPnl: Math.floor(Math.random() * RND),
    runningEquity: Math.floor(Math.random() * RND),
    runningBots: {
      auto: 65,
      pro: 45,
    },
    botsCreated: {
      auto: 65,
      pro: 45,
    },
    botsCopied: {
      auto: 65,
      pro: 45,
    },
    totalProfit: '/statistic/totalProfit',
    botHistory: '/statistic/bot',
    isActive: true,
  },
  {
    dateTime: new Date(),
    uid: 'cdc151d5as15',
    username: 'Lane Cooper',
    email: 'LaneCooper@gmail.com',
    group: EGroup.user,
    exchanges: [EExchange.binance, EExchange.bitget, EExchange.bybit, EExchange.okx],
    tradingVolume: Math.floor(Math.random() * RND),
    realizedPnl: Math.floor(Math.random() * RND),
    runningEquity: Math.floor(Math.random() * RND),
    runningBots: {
      auto: 65,
      pro: 45,
    },
    botsCreated: {
      auto: 65,
      pro: 45,
    },
    botsCopied: {
      auto: 65,
      pro: 45,
    },
    totalProfit: '/statistic/totalProfit',
    botHistory: '/statistic/bot',
    isActive: true,
  },
  {
    dateTime: new Date(),
    uid: 'zx15z5xc15c',
    username: 'Lane Cooper',
    email: 'LaneCooper@gmail.com',
    group: EGroup.user,
    exchanges: [EExchange.binance, EExchange.bitget, EExchange.bybit, EExchange.okx],
    tradingVolume: Math.floor(Math.random() * RND),
    realizedPnl: Math.floor(Math.random() * RND),
    runningEquity: Math.floor(Math.random() * RND),
    runningBots: {
      auto: 65,
      pro: 45,
    },
    botsCreated: {
      auto: 65,
      pro: 45,
    },
    botsCopied: {
      auto: 65,
      pro: 45,
    },
    totalProfit: '/statistic/totalProfit',
    botHistory: '/statistic/bot',
    isActive: true,
  },
  {
    dateTime: new Date(),
    uid: 'svd15d15vsd15vsd',
    username: 'Lane Cooper',
    email: 'LaneCooper@gmail.com',
    group: EGroup.user,
    exchanges: [EExchange.binance, EExchange.bitget, EExchange.bybit, EExchange.okx],
    tradingVolume: Math.floor(Math.random() * RND),
    realizedPnl: Math.floor(Math.random() * RND),
    runningEquity: Math.floor(Math.random() * RND),
    runningBots: {
      auto: 65,
      pro: 45,
    },
    botsCreated: {
      auto: 65,
      pro: 45,
    },
    botsCopied: {
      auto: 65,
      pro: 45,
    },
    totalProfit: '/statistic/totalProfit',
    botHistory: '/statistic/bot',
    isActive: false,
  },
  {
    dateTime: new Date(),
    uid: 'dsv151sdv1515dsv',
    username: 'Lane Cooper',
    email: 'LaneCooper@gmail.com',
    group: EGroup.user,
    exchanges: [EExchange.binance, EExchange.bitget, EExchange.bybit, EExchange.okx],
    tradingVolume: Math.floor(Math.random() * RND),
    realizedPnl: Math.floor(Math.random() * RND),
    runningEquity: Math.floor(Math.random() * RND),
    runningBots: {
      auto: 65,
      pro: 45,
    },
    botsCreated: {
      auto: 65,
      pro: 45,
    },
    botsCopied: {
      auto: 65,
      pro: 45,
    },
    totalProfit: '/statistic/totalProfit',
    botHistory: '/statistic/bot',
    isActive: true,
  },
  {
    dateTime: new Date(),
    uid: 'sv48d15vsd15sdv',
    username: 'Lane Cooper',
    email: 'LaneCooper@gmail.com',
    group: EGroup.user,
    exchanges: [EExchange.binance, EExchange.bitget, EExchange.bybit, EExchange.okx],
    tradingVolume: Math.floor(Math.random() * RND),
    realizedPnl: Math.floor(Math.random() * RND),
    runningEquity: Math.floor(Math.random() * RND),
    runningBots: {
      auto: 65,
      pro: 45,
    },
    botsCreated: {
      auto: 65,
      pro: 45,
    },
    botsCopied: {
      auto: 65,
      pro: 45,
    },
    totalProfit: '/statistic/totalProfit',
    botHistory: '/statistic/bot',
    isActive: true,
  },
  {
    dateTime: new Date(),
    uid: 'adsf154waszx',
    username: 'Lane Cooper',
    email: 'LaneCooper@gmail.com',
    group: EGroup.user,
    exchanges: [EExchange.binance, EExchange.bitget, EExchange.bybit, EExchange.okx],
    tradingVolume: Math.floor(Math.random() * RND),
    realizedPnl: Math.floor(Math.random() * RND),
    runningEquity: Math.floor(Math.random() * RND),
    runningBots: {
      auto: 65,
      pro: 45,
    },
    botsCreated: {
      auto: 65,
      pro: 45,
    },
    botsCopied: {
      auto: 65,
      pro: 45,
    },
    totalProfit: '/statistic/totalProfit',
    botHistory: '/statistic/bot',
    isActive: true,
  },
  {
    dateTime: new Date(),
    uid: 'dsfvcz51',
    username: 'Lane Cooper',
    email: 'LaneCooper@gmail.com',
    group: EGroup.user,
    exchanges: [EExchange.binance, EExchange.bitget, EExchange.bybit, EExchange.okx],
    tradingVolume: Math.floor(Math.random() * RND),
    realizedPnl: Math.floor(Math.random() * RND),
    runningEquity: Math.floor(Math.random() * RND),
    runningBots: {
      auto: 65,
      pro: 45,
    },
    botsCreated: {
      auto: 65,
      pro: 45,
    },
    botsCopied: {
      auto: 65,
      pro: 45,
    },
    totalProfit: '/statistic/totalProfit',
    botHistory: '/statistic/bot',
    isActive: true,
  },
  {
    dateTime: new Date(),
    uid: 'dsvfz5c1',
    username: 'Lane Cooper',
    email: 'LaneCooper@gmail.com',
    group: EGroup.whitelist,
    exchanges: [EExchange.binance, EExchange.bitget, EExchange.bybit, EExchange.okx],
    tradingVolume: Math.floor(Math.random() * RND),
    realizedPnl: Math.floor(Math.random() * RND),
    runningEquity: Math.floor(Math.random() * RND),
    runningBots: {
      auto: 65,
      pro: 45,
    },
    botsCreated: {
      auto: 65,
      pro: 45,
    },
    botsCopied: {
      auto: 65,
      pro: 45,
    },
    totalProfit: '/statistic/totalProfit',
    botHistory: '/statistic/bot',
    isActive: true,
  },
  {
    dateTime: new Date(),
    uid: 'v5f4v5f445',
    username: 'Lane Cooper',
    email: 'LaneCooper@gmail.com',
    group: EGroup.user,
    exchanges: [EExchange.binance, EExchange.bitget, EExchange.bybit, EExchange.okx],
    tradingVolume: Math.floor(Math.random() * RND),
    realizedPnl: Math.floor(Math.random() * RND),
    runningEquity: Math.floor(Math.random() * RND),
    runningBots: {
      auto: 65,
      pro: 45,
    },
    botsCreated: {
      auto: 65,
      pro: 45,
    },
    botsCopied: {
      auto: 65,
      pro: 45,
    },
    totalProfit: '/statistic/totalProfit',
    botHistory: '/statistic/bot',
    isActive: true,
  },
  {
    dateTime: new Date(),
    uid: 'b485f4fb48f',
    username: 'Lane Cooper',
    email: 'LaneCooper@gmail.com',
    group: EGroup.user,
    exchanges: [EExchange.binance, EExchange.bitget, EExchange.bybit, EExchange.okx],
    tradingVolume: Math.floor(Math.random() * RND),
    realizedPnl: Math.floor(Math.random() * RND),
    runningEquity: Math.floor(Math.random() * RND),
    runningBots: {
      auto: 65,
      pro: 45,
    },
    botsCreated: {
      auto: 65,
      pro: 45,
    },
    botsCopied: {
      auto: 65,
      pro: 45,
    },
    totalProfit: '/statistic/totalProfit',
    botHistory: '/statistic/bot',
    isActive: false,
  },
  {
    dateTime: new Date(),
    uid: 'db45bv4545dvb',
    username: 'Lane Cooper',
    email: 'LaneCooper@gmail.com',
    group: EGroup.whitelist,
    exchanges: [EExchange.binance, EExchange.bitget, EExchange.bybit, EExchange.okx],
    tradingVolume: Math.floor(Math.random() * RND),
    realizedPnl: Math.floor(Math.random() * RND),
    runningEquity: Math.floor(Math.random() * RND),
    runningBots: {
      auto: 65,
      pro: 45,
    },
    botsCreated: {
      auto: 65,
      pro: 45,
    },
    botsCopied: {
      auto: 65,
      pro: 45,
    },
    totalProfit: '/statistic/totalProfit',
    botHistory: '/statistic/bot',
    isActive: false,
  },
  {
    dateTime: new Date(),
    uid: 'asd54dfs45',
    username: 'Lane Cooper',
    email: 'LaneCooper@gmail.com',
    group: EGroup.user,
    exchanges: [EExchange.binance, EExchange.bitget, EExchange.bybit, EExchange.okx],
    tradingVolume: Math.floor(Math.random() * RND),
    realizedPnl: Math.floor(Math.random() * RND),
    runningEquity: Math.floor(Math.random() * RND),
    runningBots: {
      auto: 65,
      pro: 45,
    },
    botsCreated: {
      auto: 65,
      pro: 45,
    },
    botsCopied: {
      auto: 65,
      pro: 45,
    },
    totalProfit: '/statistic/totalProfit',
    botHistory: '/statistic/bot',
    isActive: true,
  },
  {
    dateTime: new Date(),
    uid: '5SDF45SDF45',
    username: 'Lane Cooper',
    email: 'LaneCooper@gmail.com',
    group: EGroup.user,
    exchanges: [EExchange.binance, EExchange.bitget, EExchange.bybit, EExchange.okx],
    tradingVolume: Math.floor(Math.random() * RND),
    realizedPnl: Math.floor(Math.random() * RND),
    runningEquity: Math.floor(Math.random() * RND),
    runningBots: {
      auto: 65,
      pro: 45,
    },
    botsCreated: {
      auto: 65,
      pro: 45,
    },
    botsCopied: {
      auto: 65,
      pro: 45,
    },
    totalProfit: '/statistic/totalProfit',
    botHistory: '/statistic/bot',
    isActive: true,
  },
  {
    dateTime: new Date(),
    uid: '64556116',
    username: 'Lane Cooper',
    email: 'LaneCooper@gmail.com',
    group: EGroup.user,
    exchanges: [EExchange.binance, EExchange.bitget, EExchange.bybit, EExchange.okx],
    tradingVolume: Math.floor(Math.random() * RND),
    realizedPnl: Math.floor(Math.random() * RND),
    runningEquity: Math.floor(Math.random() * RND),
    runningBots: {
      auto: 65,
      pro: 45,
    },
    botsCreated: {
      auto: 65,
      pro: 45,
    },
    botsCopied: {
      auto: 65,
      pro: 45,
    },
    totalProfit: '/statistic/totalProfit',
    botHistory: '/statistic/bot',
    isActive: true,
  },
];
