import cn from 'classnames';
import IconWithTooltip from '../../../../IconWithTooltip/IconWithTooltip';
import styles from './SettingsUsage.module.css';
import { selectAdminStats } from '../../../../../redux/selectors/rootSelector';
import { useAppSelector } from '../../../../../redux/hooks/redux';
import { prepareSettingsUsage } from '../utils';
export default function SettingsUsage() {

  const adminPanel = useAppSelector(selectAdminStats).adminPanelInfo;
  const { settingsUsage } = adminPanel;
  const preparedSettingsUsage = prepareSettingsUsage(settingsUsage);

  return (
    <div className={styles.container}>
      <div className={styles.advantages}>
        {preparedSettingsUsage.advantagesData.map((card) => (
          <div className={cn(styles.card, styles.border)} key={card.title}>
            <h4>{card.title}</h4>
            <div className={styles.row}>
              <p>
                {card.field} <IconWithTooltip color='#7854C9' />
              </p>
              <span>{card.value}</span>
            </div>
          </div>
        ))}
        <div className={styles.column}>
          <h4>{preparedSettingsUsage.marginMode.title}</h4>
          {preparedSettingsUsage.marginMode.fields.map((field) => (
            <div className={styles.row} key={field.field}>
              <p>
                {field.field} <IconWithTooltip color='#7854C9' />
              </p>
              <span>{field.value}</span>
            </div>
          ))}
        </div>
      </div>

      <div>
        <div className={cn(styles.column)}>
          <h4>{preparedSettingsUsage.entryOrders.title}</h4>
          <div className={styles.row}>
            <p>
              {preparedSettingsUsage.entryOrders.priceLevel.field} <IconWithTooltip color='#7854C9' />
            </p>
            <span>{preparedSettingsUsage.entryOrders.priceLevel.value}</span>
          </div>
          {preparedSettingsUsage.entryOrders.groups.map((group, index) => (
            <div className={styles.border} key={index}>
              {group.map((g) => (
                <div className={styles.row} key={g.field}>
                  <p>
                    {g.field} <IconWithTooltip color='#7854C9' />
                  </p>
                  <span>{g.value}</span>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
      <div className={styles.column}>
        <h4>{preparedSettingsUsage.triggers.title}</h4>
        {preparedSettingsUsage.triggers.fields.map((field) => (
          <div className={styles.row} key={field.field}>
            <p>
              {field.field} <IconWithTooltip color='#7854C9' />
            </p>
            <span>{field.value}</span>
          </div>
        ))}
        <div className={styles.border}>
          {preparedSettingsUsage.triggers.group.map((field) => (
            <div className={styles.row} key={field.field}>
              <p>
                {field.field} <IconWithTooltip color='#7854C9' />
              </p>
              <span>{field.value}</span>
            </div>
          ))}
        </div>
        <div className={styles.column}>
          <h4>{preparedSettingsUsage.takeProfit.title}</h4>
          {preparedSettingsUsage.takeProfit.fields.map((field) => (
            <div className={styles.row} key={field.field}>
              <p>
                {field.field} <IconWithTooltip color='#7854C9' />
              </p>
              <span>{field.value}</span>
            </div>
          ))}
          <div className={styles.border}>
            {preparedSettingsUsage.takeProfit.group.map((field) => (
              <div className={styles.row} key={field.field}>
                <p>
                  {field.field} <IconWithTooltip color='#7854C9' />
                </p>
                <span>{field.value}</span>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className={styles.column}>
        <h4>{preparedSettingsUsage.stopLoss.title}</h4>
        {preparedSettingsUsage.stopLoss.fields.map((field) => (
          <div className={styles.row} key={field.field}>
            <p>
              {field.field} <IconWithTooltip color='#7854C9' />
            </p>
            <span>{field.value}</span>
          </div>
        ))}
      </div>
    </div>
  );
}
