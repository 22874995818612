import React, { SetStateAction } from 'react';
import styles from './OrderHistory.module.css';
import SortArrow from '../../../assets/General/SortArrow';
import { IOrderStatistics } from '../../../redux/types';

interface IPaginationOrderListProps {
  endIndex: number;
  setEndIndex: React.Dispatch<SetStateAction<number>>;
  orders: IOrderStatistics[];
}

export default function PaginationOrdersList({
  endIndex,
  setEndIndex,
  orders,
}: IPaginationOrderListProps) {
  const differenceOfQtity: number = 9;
  const ordersQtity: number = 10;
  const minDifference: number = 20;
  const lastNumOfOrders: number = endIndex > orders.length ? orders.length : endIndex;
  const pages: string = `${endIndex - differenceOfQtity}-${lastNumOfOrders} of ${orders.length}`;
  const setNextPage = () =>
    setEndIndex((prevState) =>
      prevState >= orders.length ? ordersQtity : prevState + ordersQtity,
    );
  const setPrevPage = () =>
    setEndIndex((prevState) => (prevState < minDifference ? ordersQtity : prevState - ordersQtity));

  return (
    <div className={styles.paginationContainer}>
      <span className={styles.paginationText}>{pages}</span>
      <button onClick={setPrevPage} className={styles.arrowIconToLeft}>
        <SortArrow color='#fff' />
      </button>
      <button onClick={setNextPage} className={styles.arrowIconToRight}>
        <SortArrow color='#fff' />
      </button>
    </div>
  );
}
