// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/public/images/UI/CheckIcon.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("/public/images/UI/CheckIconSecondary.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Checkbox_container__yd8Up {
  position: relative;
  box-sizing: border-box;
  font-family: 'PTSans';
  font-size: 18px;
  font-weight: 400;
}

.Checkbox_container__yd8Up input {
  position: absolute;
  width: 0;
  height: 0;
  outline: none;
}

.Checkbox_container__yd8Up div {
  margin-left: 36px;
}
.Checkbox_container__yd8Up div::before {
  position: absolute;
  top: 3px;
  left: 0px;
  box-sizing: border-box;
  content: '';
  width: 24px;
  height: 24px;
  border: 1px solid var(--text-colored);
  border-radius: 5px;
}

.Checkbox_container__yd8Up input:checked + div::before {
  background-color: var(--text-colored);
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-position: center;
}

.Checkbox_container__yd8Up.Checkbox_secondary__ssfc5 div::before {
  border-color: transparent;
  background-color: rgba(255, 255, 255, 0.2);
}

.Checkbox_container__yd8Up.Checkbox_secondary__ssfc5 input:checked + div::before {
  background-color: rgba(255, 255, 255, 0.2);
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}
`, "",{"version":3,"sources":["webpack://./src/components/CCLabsUI/Checkbox/Checkbox.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,sBAAsB;EACtB,qBAAqB;EACrB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,aAAa;AACf;;AAEA;EACE,iBAAiB;AACnB;AACA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,sBAAsB;EACtB,WAAW;EACX,WAAW;EACX,YAAY;EACZ,qCAAqC;EACrC,kBAAkB;AACpB;;AAEA;EACE,qCAAqC;EACrC,yDAAwD;EACxD,4BAA4B;EAC5B,2BAA2B;AAC7B;;AAEA;EACE,yBAAyB;EACzB,0CAA0C;AAC5C;;AAEA;EACE,0CAA0C;EAC1C,yDAAiE;AACnE","sourcesContent":[".container {\n  position: relative;\n  box-sizing: border-box;\n  font-family: 'PTSans';\n  font-size: 18px;\n  font-weight: 400;\n}\n\n.container input {\n  position: absolute;\n  width: 0;\n  height: 0;\n  outline: none;\n}\n\n.container div {\n  margin-left: 36px;\n}\n.container div::before {\n  position: absolute;\n  top: 3px;\n  left: 0px;\n  box-sizing: border-box;\n  content: '';\n  width: 24px;\n  height: 24px;\n  border: 1px solid var(--text-colored);\n  border-radius: 5px;\n}\n\n.container input:checked + div::before {\n  background-color: var(--text-colored);\n  background-image: url('/public/images/UI/CheckIcon.png');\n  background-repeat: no-repeat;\n  background-position: center;\n}\n\n.container.secondary div::before {\n  border-color: transparent;\n  background-color: rgba(255, 255, 255, 0.2);\n}\n\n.container.secondary input:checked + div::before {\n  background-color: rgba(255, 255, 255, 0.2);\n  background-image: url('/public/images/UI/CheckIconSecondary.png');\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Checkbox_container__yd8Up`,
	"secondary": `Checkbox_secondary__ssfc5`
};
export default ___CSS_LOADER_EXPORT___;
