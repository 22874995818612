import { Navigate, Outlet } from 'react-router-dom';

interface ProtectedRouteProps {
  redirectPath?: string;
  children?: JSX.Element;
}

const ProtectedRoute = ({ redirectPath = '/', children }: ProtectedRouteProps): JSX.Element => {
  const hasToken: boolean = !!sessionStorage.getItem('token');

  if (!hasToken) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet />;
};

export default ProtectedRoute;
