import styles from './OrderHistory.module.css';
import RuntimeBot from '../BotsCard/RuntimeBot';
import { IOrderStatistics } from '../../../redux/types';

export interface IOrderListProps {
  order: IOrderStatistics;
}
export default function OrderListItem({
  order: {
    inOrderTime,
    inOrderQuantity,
    inOrderPrice,
    trailingTpTime,
    trailingTpQuantity,
    elapsedTime,
    stopQuantity,
    stopPrice,
    profit,
  },
}: IOrderListProps) {
  const formatOption: Intl.DateTimeFormatOptions = {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  };

  const orderTime: string = new Intl.DateTimeFormat('ru', formatOption).format(
    new Date(inOrderTime),
  );
  const trailingTime: string = new Intl.DateTimeFormat('ru', formatOption).format(
    new Date(trailingTpTime),
  );

  return (
    <div className={styles.listRow}>
      <div className={styles.column}>{orderTime}</div>
      <div className={styles.column}>{inOrderQuantity}</div>
      <div className={styles.column}>{inOrderPrice}</div>
      <div className={styles.column}>{trailingTime}</div>
      <div className={styles.column}>{trailingTpQuantity}</div>
      <div className={styles.column}>
        <RuntimeBot runtime={elapsedTime} />
      </div>
      <div className={styles.column}>{stopQuantity}</div>
      <div className={styles.column}>{stopPrice}</div>
      <div className={styles.column}>{profit}</div>
    </div>
  );
}
