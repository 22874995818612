// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NotificationList_container__dRFxR {
  margin: 0 5%;
  padding-bottom: 150px;
}

.NotificationList_header__AEdcQ {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.NotificationList_titleBox__ZoZ0K {
  display: flex;
  align-items: flex-end;
  gap: 40px;
}

.NotificationList_titleBox__ZoZ0K h3 {
  margin: 0;
  color: #fff;
  font-family: Jost;
  font-size: 24px;
  font-weight: 400;
  line-height: 22px;
}

.NotificationList_titleBox__ZoZ0K button {
  padding: 0;
  border-bottom: 1px dotted var(--link);
  color: var(--link);
  font-size: 18px;
  font-family: PTSans;
}

.NotificationList_list__Dio9- {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
`, "",{"version":3,"sources":["webpack://./src/components/AdminPage/NotificationsPage/NotificationList/NotificationList.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,WAAW;EACX,8BAA8B;AAChC;;AAEA;EACE,aAAa;EACb,qBAAqB;EACrB,SAAS;AACX;;AAEA;EACE,SAAS;EACT,WAAW;EACX,iBAAiB;EACjB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,UAAU;EACV,qCAAqC;EACrC,kBAAkB;EAClB,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX","sourcesContent":[".container {\n  margin: 0 5%;\n  padding-bottom: 150px;\n}\n\n.header {\n  display: flex;\n  width: 100%;\n  justify-content: space-between;\n}\n\n.titleBox {\n  display: flex;\n  align-items: flex-end;\n  gap: 40px;\n}\n\n.titleBox h3 {\n  margin: 0;\n  color: #fff;\n  font-family: Jost;\n  font-size: 24px;\n  font-weight: 400;\n  line-height: 22px;\n}\n\n.titleBox button {\n  padding: 0;\n  border-bottom: 1px dotted var(--link);\n  color: var(--link);\n  font-size: 18px;\n  font-family: PTSans;\n}\n\n.list {\n  display: flex;\n  flex-direction: column;\n  gap: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `NotificationList_container__dRFxR`,
	"header": `NotificationList_header__AEdcQ`,
	"titleBox": `NotificationList_titleBox__ZoZ0K`,
	"list": `NotificationList_list__Dio9-`
};
export default ___CSS_LOADER_EXPORT___;
