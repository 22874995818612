import { EClientErrors, EResponseMessage } from '../redux/types';
import { useNavigate } from 'react-router-dom';

export function useNavigateToSingIn() {
  const navigate = useNavigate();
  return function navigateToSingIn(error: EClientErrors | EResponseMessage | number) {
    if (
      (error && error === EClientErrors.unauthorized) ||
      EClientErrors.forbidden ||
      EResponseMessage.notFound
    ) {
      localStorage.removeItem('token');
      localStorage.removeItem('userId');
      sessionStorage.removeItem('session_id');
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('credentials');
      navigate('/log-in');
    }
    alert(error);
  };
}
