import React, { memo } from 'react';
import styles from './SwitchToggle.module.css';
import cn from 'classnames';

interface ISwitchToggle {
  isSwitched?: boolean;
  action?: () => void;
}

const SwitchToggle: React.FC<ISwitchToggle> = (props) => {
  return (
    <div
      className={
        props.isSwitched ? styles.toggleButtonContainerOff : styles.toggleButtonContainerOn
      }
      onClick={props.action}
    >
      <div className={cn(styles.toggle, props.isSwitched ? styles.toggleOff : styles.toggleOn)} />
    </div>
  );
};

export default memo(SwitchToggle);
