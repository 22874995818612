import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import SortArrow from '../../../assets/General/SortArrow';
import cn from 'classnames';
import styles from './Dropdown.module.css';
import CloseButton from '../../CloseButton/CloseButton';

interface IFilter<T> {
  value?: T;
  setValue: Dispatch<SetStateAction<T | undefined>>;
  options: T[];
  renderOption: (option: T) => React.ReactNode;
  label: string;
  size?: ESizing;
  hasSingleSelection?: boolean;
  isDisabled?: boolean;
}

export enum ESizing {
  large = 'large',
  medium = 'medium',
}

export default function Dropdown<T>({
  value,
  setValue,
  options = [],
  renderOption,
  label,
  size,
  hasSingleSelection = false,
  isDisabled,
}: IFilter<T>) {
  const [isOpen, setIsOpen] = useState(false);
  const handleToggleDropdown = () => setIsOpen((state) => !state);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const handleOutsideClick = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const handleChooseFilter = (filter: T) => {
    setIsOpen(false);
    if (value === filter) {
      return setValue(undefined);
    }
    setValue(filter);
  };

  useEffect(() => {
    const isSingleOption = hasSingleSelection && options.length === 1;
    if (isSingleOption) {
      handleChooseFilter(options[0]);
    }
  }, [options]);

  const onBreakFilter = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setValue(undefined);
  };

  return (
    <div
      className={cn(
        styles.filter,
        { [styles.activeFilter]: value && !isDisabled },
        { [styles.medium]: size === ESizing.medium },
      )}
      ref={dropdownRef}
    >
      <label onClick={handleToggleDropdown} className={styles.label}>
        {value ? renderOption(value) : label}
        {!value && (
          <span className={cn({ [styles.rotateArrow]: isOpen })}>
            <SortArrow color='#FFF' />
          </span>
        )}
      </label>
      {value && <CloseButton onClick={onBreakFilter} className={styles.closeButton} />}
      {isOpen && (
        <div className={styles.dropdown}>
          {options.map((option, index) => (
            <span key={index} onClick={() => handleChooseFilter(option)}>
              {renderOption(option)}
            </span>
          ))}
          {isDisabled && <span className={styles.soon}>Soon</span>}
        </div>
      )}
    </div>
  );
}
