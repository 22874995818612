import { v4 as uuid } from 'uuid';

interface IResponse {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  waitlisted_users: string[];
};

export const createNewUserList = (response: IResponse) => response.waitlisted_users.map((email: string) => ({
  dateTime: new Date(),
  uid: `00000${uuid().split('-')[0]}`,
  username: '',
  email,
  group: [],
  exchanges: [],
  tradingVolume: 0,
  realizedPnl: 0,
  runningEquity: 0,
  runningBots: {
    auto: 0,
    pro: 0,
  },
  botsCreated: {
    auto: 0,
    pro: 0,
  },
  botsCopied: {
    auto: 0,
    pro: 0,
  },
  totalProfit: '/statistic/totalProfit',
  botHistory: '/statistic/bot',
  isActive: true,
}));
