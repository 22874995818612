export const getHowToStartSteps = () => [
  {
    text: 'howToStartDescription1',
  },
  {
    text: 'howToStartDescription2',
  },
  {
    text: 'howToStartDescription3',
    imageName: 'register.png',
  },
  {
    text: 'howToStartDescription4',
  },
  {
    text: 'howToStartDescription5',
  },
  {
    text: 'howToStartDescription6',
    imageName: 'login.png',
  },
  {
    text: 'howToStartDescription7',
    imageName: 'changePassword.png',
  },
];
