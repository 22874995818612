/* eslint-disable max-len */
interface IIconProps {
  color: string;
}
export default function SuspendIcon({ color }: IIconProps) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='17' viewBox='0 0 16 17' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.7 8.10645C14.7 11.8068 11.7003 14.8064 8 14.8064C4.29969 14.8064 1.3 11.8068 1.3 8.10645C1.3 4.40614 4.29969 1.40645 8 1.40645C11.7003 1.40645 14.7 4.40614 14.7 8.10645ZM16 8.10645C16 12.5247 12.4183 16.1064 8 16.1064C3.58172 16.1064 0 12.5247 0 8.10645C0 3.68817 3.58172 0.106445 8 0.106445C12.4183 0.106445 16 3.68817 16 8.10645ZM4.51977 4.62621C4.8085 4.33748 5.27664 4.33748 5.56538 4.62621L8 7.06084L10.4346 4.62621C10.7234 4.33748 11.1915 4.33748 11.4802 4.62621C11.769 4.91495 11.769 5.38308 11.4802 5.67182L9.04561 8.10644L11.4802 10.5411C11.769 10.8298 11.769 11.2979 11.4802 11.5867C11.1915 11.8754 10.7234 11.8754 10.4346 11.5867L8 9.15205L5.56538 11.5867C5.27664 11.8754 4.8085 11.8754 4.51977 11.5867C4.23103 11.2979 4.23103 10.8298 4.51977 10.5411L6.95439 8.10644L4.51977 5.67182C4.23103 5.38308 4.23103 4.91495 4.51977 4.62621Z'
        fill={color}
      />
    </svg>
  );
}
