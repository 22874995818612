import { memo, useEffect, useState } from 'react';
import SettingsCardTitle from './TitleCard';
import TabsSettingsCard from './TabsSettingsCard';
import InputSettings from '../InputSettings/InputSettings';
import SwitchBlock from './SwitchBlock';
import { ITab } from '../../../StatisticPage/Tabs/Tabs';
import { CARD_CLASSNAMES, INNER_CARD_CLASSES } from '../SettingsBlock/SettingsBlock.constants';
import styles from '../BotSettings.module.css';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks/redux';
import { tempSettings } from '../../../../redux/selectors/rootSelector';
import {
  changeMaxTriggersCount,
  changeTrailingProfit,
  changeTrailingTriggersDistance,
  toggleTrailingTriggersDistanceUsePercent,
  changeTriggersDistance,
  toggleTriggersDistanceUsePercent,
  changeTriggersMode,
  toggleTrailingTrigger,
} from '../../../../redux/slices/tempSettingsSlice';
import { checkIsFloat, checkIsInteger, checkValidTriggersValues } from './input.utils';

function Triggers() {
  const { t } = useTranslation('botspage');
  const tabs: ITab[] = [
    { value: 'AllActive', render: t('settings.triggers.tabs.all') },
    { value: 'New', render: t('settings.triggers.tabs.new') },
  ];
  const {
    strategyParameters: { triggerDistance, maxTriggerCount, trailingTrigger },
  } = useAppSelector(tempSettings);
  const { validation } = useAppSelector((state) => state.validateParams);
  const dispatch = useAppDispatch();

  const onToggleTriggersDistanceUsePercent = () => {
    dispatch(toggleTriggersDistanceUsePercent());
  };
  const onToggleTrailingTriggersDistanceUsePercent = () => {
    dispatch(toggleTrailingTriggersDistanceUsePercent());
  };

  const triggersTooltip = `${t('tooltips.triggers.title.p1')} \n${t('tooltips.triggers.title.p2')}`;

  const [validationStatuses, setValidationStatuses] = useState(
    checkValidTriggersValues(
      triggerDistance.distance,
      maxTriggerCount,
      trailingTrigger,
      validation,
    ),
  );

  useEffect(() => {
    setValidationStatuses(
      checkValidTriggersValues(
        triggerDistance.distance,
        maxTriggerCount,
        trailingTrigger,
        validation,
      ),
    );
  }, [validation]);

  return (
    <div className={CARD_CLASSNAMES}>
      <SettingsCardTitle title={t('settings.triggers.title')} tooltip={triggersTooltip} />
      <div className={styles.cardSubTitle}>{t('settings.triggers.tabs.title')}:</div>
      <TabsSettingsCard
        tabs={tabs}
        activeTab={triggerDistance.choice}
        onSetActiveTab={(tab) => dispatch(changeTriggersMode(tab.value as 'AllActive' | 'New'))}
      />
      <InputSettings
        value={triggerDistance.distance}
        usePercent={triggerDistance.usePercent}
        onChange={(e) =>
          checkIsFloat(e.target.value) && dispatch(changeTriggersDistance(e.target.value))
        }
        label={t('settings.triggers.distance')}
        setUsePercent={onToggleTriggersDistanceUsePercent}
        tooltip={t('tooltips.triggers.triggerDistance')}
        hasError={!validationStatuses.isTriggerDistanceValid}
        errorMessage='Must be bigger than 0'
      />
      <InputSettings
        isPercentVisible={false}
        value={maxTriggerCount}
        onChange={(e) =>
          checkIsInteger(e.target.value) && dispatch(changeMaxTriggersCount(Number(e.target.value)))
        }
        label={t('settings.triggers.maxCount')}
        tooltip={t('tooltips.triggers.maxTriggerCount')}
        usePercent={false}
        hasError={!validationStatuses.isMaxTriggerCountValid}
        errorMessage='Must be bigger than 0'
      />
      <div className={INNER_CARD_CLASSES}>
        <SwitchBlock
          title={t('settings.triggers.trailing')}
          isSwitched={trailingTrigger !== null}
          action={() => dispatch(toggleTrailingTrigger())}
          tooltip={t('tooltips.triggers.triggersTrailing')}
        />
        <InputSettings
          value={trailingTrigger?.profitPercent}
          onChange={(e) =>
            checkIsInteger(e.target.value) && dispatch(changeTrailingProfit(Number(e.target.value)))
          }
          label={t('settings.triggers.profit')}
          isNumbersVisible={false}
          usePercent={true}
          tooltip={t('tooltips.triggers.profit')}
          readonly={trailingTrigger === null}
          hasError={!validationStatuses.isTrailingTriggerProfitValid}
          errorMessage='Must be bigger than 0'
        />
        <InputSettings
          value={trailingTrigger?.startDistance}
          usePercent={trailingTrigger?.usePercent}
          onChange={(e) =>
            checkIsFloat(e.target.value) && dispatch(changeTrailingTriggersDistance(e.target.value))
          }
          label={t('settings.triggers.distanceFromPrice')}
          setUsePercent={onToggleTrailingTriggersDistanceUsePercent}
          tooltip={t('tooltips.triggers.distance')}
          readonly={trailingTrigger === null}
          hasError={!validationStatuses.isTrailingTriggerDistanceValid}
          errorMessage='Must be bigger than 0'
        />
      </div>
    </div>
  );
}

export default memo(Triggers);
