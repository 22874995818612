import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { IUser } from '../UsersList/UserList.types';
import ButtonsStatusBar from '../ButtonsStatusBar/ButtonsStatusBar';
import DateFilter from '../DateFilter/DateFilter';
import SearchBar from '../SearchBar/SearchBar';
import styles from './StatusUserBar.module.css';

interface IStatusUserBar {
  setActive: () => void;
  setBlocked: () => void;
  setAllUsers: () => void;
  active: number;
  blocked: number;
  dateValue: [Date, Date] | undefined;
  setDateValue: Dispatch<SetStateAction<[Date, Date] | undefined>>;
  users: IUser[];
  setUsers: Dispatch<SetStateAction<IUser[] | []>>;
  onSettingsOpen: () => void;
  setCurrentPage: Dispatch<SetStateAction<number>>;
}

export default function StatusUsersBar({
  setActive,
  setBlocked,
  setAllUsers,
  active,
  blocked,
  dateValue,
  setDateValue,
  users,
  setUsers,
  onSettingsOpen,
  setCurrentPage,
}: IStatusUserBar) {
  const [activeTab, setActiveTab] = useState('all');
  const [isShowCalendar, setIsShowCalendar] = useState(false);
  const [dateFrom, setDateFrom] = useState<string>('');
  const [dateTo, setDateTo] = useState<string>('');
  const [searchValue, setSearchValue] = useState('');

  const formatOption: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
  };

  useEffect(() => {
    if (dateValue) {
      setDateFrom(new Intl.DateTimeFormat('ru', formatOption).format(dateValue[0]));
      setDateTo(new Intl.DateTimeFormat('ru', formatOption).format(dateValue[1]));
    } else {
      setDateFrom('');
      setDateTo('');
    }
  }, [dateValue]);

  const onSetSearchValue = (e: React.ChangeEvent<HTMLInputElement>) =>
    setSearchValue(e.target.value.toLowerCase());

  const onSearch = () => {
    const searchResult = users.filter(
      (user) =>
        user.uid.toLowerCase().includes(searchValue) ||
        user.username.toLowerCase().includes(searchValue) ||
        user.email.toLowerCase().includes(searchValue),
    );
    setUsers(searchResult);
    setCurrentPage(1);
  };

  return (
    <div className={styles.container}>
      <div className={styles.filters}>
        <ButtonsStatusBar
          setActive={setActive}
          setAllUsers={setAllUsers}
          setBlocked={setBlocked}
          active={active}
          blocked={blocked}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <div className={styles.dateBox}>
          <h4>Список юзеров</h4>
          <DateFilter
            dateFrom={dateFrom}
            dateTo={dateTo}
            dateValue={dateValue}
            setDateValue={setDateValue}
            setIsShowCalendar={setIsShowCalendar}
            isShowCalendar={isShowCalendar}
          />
          <button
            className={styles.resetBtn}
            onClick={() => {
              setAllUsers();
              setDateValue(undefined);
              setSearchValue('');
              setActiveTab('all');
            }}
          >
            Reset filter
          </button>
        </div>
      </div>
      <SearchBar
        searchValue={searchValue}
        onSearch={onSearch}
        onSetSearchValue={onSetSearchValue}
        onSettingsOpen={onSettingsOpen}
        buttonTitle='Создать новую группу'
      />
    </div>
  );
}
