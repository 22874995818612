import React, { useEffect, useState } from 'react';
import styles from './AuthorizedHeader.module.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import cn from 'classnames';
import LogoImg from '../../../assets/img/Logo.svg';
import useLogout from '../../../hooks/useLogout';
import LanguageMenu from '../LanguageMenu/LanguageMenu';
import { useGetUserAccessMutation } from '../../../redux/services/signUpService';
import { useTranslation } from 'react-i18next';
import { PATHS } from './AuthorizedHeader.constants';
import { useAppDispatch } from '../../../redux/hooks/redux';
import { setAccess } from '../../../redux/slices/profileSlice';

interface IAauthorizedHeaderProps {
  hidden: { [x: string]: boolean };
  setIsMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const AuthorizedHeader: React.FC<IAauthorizedHeaderProps> = ({ hidden, setIsMenuOpen }) => {
  const { t } = useTranslation('authorizedHeader');
  const location = useLocation();
  const currentRoute: string = location.pathname;
  const doFinishSession = useLogout();
  const getLinkStyle = (path: string) => cn({ [styles.nav_active]: currentRoute.includes(path) });
  const [userAccessLabel, setUserAccessLabel] = useState('');
  const hasAdminAccess = userAccessLabel === 'ACCESS_ADMIN';
  const navigate = useNavigate();
  const token = sessionStorage.getItem('token');
  const sessionId = sessionStorage.getItem('session_id');
  const [userAccess] = useGetUserAccessMutation();
  const dispatch = useAppDispatch();
  useEffect(() => {
    async function fetchData() {
      const { access_label: accessLabel } = await userAccess({ token, sessionId }).unwrap();
      setUserAccessLabel(accessLabel);
      dispatch(setAccess(accessLabel));
    }

    fetchData().catch((error) => console.error('Error calling fetchData():', error));
  }, []);

  const onMenuBtnClick = () => {
    setIsMenuOpen(false);
  };

  return (
    <div>
      <div className={styles.container}>
        <div className={styles.logo} onClick={() => navigate('bots/mybots')}>
          <img src={LogoImg} />
        </div>
        <div className={styles.nav}>
          {PATHS.map((item) => (
            <Link key={item.path} className={getLinkStyle(item.linkStyle)} to={item.path}>
              {t(item.labelKey)}
            </Link>
          ))}
          {hasAdminAccess && (
            <Link className={getLinkStyle('/admin')} to='admin/userlist'>
              {t('admin')}
            </Link>
          )}
        </div>

        <div className={styles.additional_info}>
          <div className={styles.container_language}>
            <LanguageMenu />
          </div>
          <button className={styles.notification}>notification</button>
          <button className={cn(styles.logout, hidden)} onClick={doFinishSession}>
            {t('logout')}
          </button>
        </div>
      </div>
      <div className={cn(styles.mobileNav, hidden)}>
        {PATHS.map((item) => (
          <Link
            key={item.path}
            className={getLinkStyle(item.linkStyle)}
            to={item.path}
            onClick={onMenuBtnClick}
          >
            {t(item.labelKey)}
          </Link>
        ))}
        {hasAdminAccess && (
          <Link className={getLinkStyle('/admin')} to='admin/userlist' onClick={onMenuBtnClick}>
            {t('admin')}
          </Link>
        )}
        <button className={styles.logout} onClick={doFinishSession}>
          {t('logout')}
        </button>
      </div>
    </div>
  );
};

export default AuthorizedHeader;
