import { ChangeEvent, Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from '../../../CCLabsUI';
import { ESizing } from '../../../CCLabsUI/Input/Input';

import styles from './UpdateForm.module.css';
import NewPasswordBlock from '../../../NewPasswordBlock/NewPasswordBlock';

interface IUpdateForm {
  email: string;
  currentPassword: string;
  newPassword: string;
  confirmedPassword: string;
  setEmail: Dispatch<SetStateAction<string>>;
  setCurrentPassword: Dispatch<SetStateAction<string>>;
  setNewPassword: Dispatch<SetStateAction<string>>;
  setConfirmedPassword: Dispatch<SetStateAction<string>>;
  onSubmitForm: () => void;
}

export default function UpdateForm({
  email,
  currentPassword,
  newPassword,
  confirmedPassword,
  setEmail,
  setCurrentPassword,
  setNewPassword,
  setConfirmedPassword,
  onSubmitForm,
}: IUpdateForm) {
  const [errorCurrentPassword, setIsErrorCurrentPassword] = useState(false);
  const [isValidNewPassword, setIsValidNewPassword] = useState(false);
  const { t } = useTranslation('passwordUpdate');

  const isReadyForConfirm: boolean = Boolean(
    isValidNewPassword &&
      !errorCurrentPassword &&
      email &&
      newPassword &&
      confirmedPassword &&
      currentPassword,
  );
  // Оставил закоментированный код ниже до подтягивания методов

  // const [checkPassword] = useCheckPasswordMutation();

  const onBlurInputCurrentPassword = () => {
    if (!currentPassword) {
      return;
    }
    // try {
    //   await checkPassword({ currentPassword }).unwrap();
    //   setIsErrorCurrentPassword(false);
    // } catch (error: unknown) {
    //   if ((error as { status: number }).status === 403) {
    //     setIsErrorCurrentPassword(true);
    //   }
    // }
  };

  const onFormSubmit = () => {
    if (isReadyForConfirm) {
      onSubmitForm();
    }
  };

  return (
    <div className={styles.passwordForm}>
      <div className={styles.passwordUpdateTitle}>{t('title')}</div>
      <form
        action='submit'
        className={styles.passwordBlock}
        onSubmit={(e: React.FormEvent) => {
          e.preventDefault();
          onFormSubmit();
        }}
      >
        <Input
          id='email'
          label={t('emailPlaceholder')}
          sizing={ESizing.large}
          value={email}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
          className={styles.inputFields}
          placeholder={t('emailPlaceholder')}
        />
        <Input
          id='current-password'
          autoComplete="new-password"
          label={t('current')}
          sizing={ESizing.large}
          placeholder={t('currentPlaceholder')}
          isPassword={true}
          isValid={!errorCurrentPassword}
          value={currentPassword}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setCurrentPassword(e.target.value);
            setIsErrorCurrentPassword(false);
          }}
          onBlur={onBlurInputCurrentPassword}
          className={styles.inputFields}
          errorMessage={t('incorrect')}
        />
        <NewPasswordBlock
          newPassword={newPassword}
          confirmedPassword={confirmedPassword}
          setNewPassword={setNewPassword}
          setConfirmedPassword={setConfirmedPassword}
          setIsValidNewPassword={setIsValidNewPassword}
        />
        <button
          type='submit'
          className={styles.updatePasswordBtn}
          onClick={onFormSubmit}
          disabled={!isReadyForConfirm}
        >
          {t('updateBtn')}
        </button>
      </form>
    </div>
  );
}
