import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { ITab, Navigation } from '../Navigation/Navigation';
import SettingsProfile from '../SettingsPage/SettingsProfile/SettingsProfile';
import styles from './Page.module.css';

function ProfileSettings() {
  const { t } = useTranslation('tabs');
  const tabs: ITab[] = [
    { id: 2, name: t('profile.profile'), link: '/settings/profile' },
    { id: 4, name: t('profile.security'), link: '/settings/security' },
  ];
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <Navigation tabs={tabs} title={t('profile.profile')} />
        <SettingsProfile />
      </div>
    </div>
  );
}

export default memo(ProfileSettings);
