/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { CSSProperties } from 'react';

interface IBibytIconProps {
  color?: string;
  width?: number;
  height?: number;
  opacity?: number;
  strokeWidth?: number;
  fillColor?: string;
  style?: CSSProperties;
}

function BibytIcon({
  width = 58,
  height = 25,
  strokeWidth = 1,
  fillColor = '#F7A600',
  opacity = 1,
  style,
}: IBibytIconProps) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 75 25'
      fill='none'
    >
      <path d='M53.2217 18.8305V0.328613H57.0235V18.8305H53.2217Z' fill='#F7A600' />
      <path
        d='M8.91887 24.3274H0.768555V5.82556H8.59112C12.3929 5.82556 14.6082 7.85241 14.6082 11.0229C14.6082 13.0751 13.1855 14.4014 12.2007 14.843C13.3762 15.3624 14.8807 16.5315 14.8807 19.0014C14.8807 22.4563 12.3929 24.3274 8.91887 24.3274ZM8.2902 9.0484H4.57034V13.3101H8.2902C9.90362 13.3101 10.8064 12.4524 10.8064 11.1785C10.8064 9.90608 9.90362 9.0484 8.2902 9.0484ZM8.53601 16.5584H4.57034V21.106H8.53601C10.2596 21.106 11.079 20.0672 11.079 18.8188C11.079 17.5718 10.2581 16.5584 8.53601 16.5584Z'
        fill='white'
      />
      <path
        d='M26.4787 16.7397V24.3276H22.7037V16.7397L16.8506 5.82568H20.9801L24.618 13.2833L28.2009 5.82568H32.3304L26.4787 16.7397Z'
        fill='white'
      />
      <path
        d='M43.1064 24.3276H34.9561V5.82568H42.7787C46.5804 5.82568 48.7957 7.85253 48.7957 11.023C48.7957 13.0753 47.373 14.4015 46.3882 14.8431C47.5637 15.3625 49.0683 16.5316 49.0683 19.0015C49.0683 22.4565 46.5804 24.3276 43.1064 24.3276ZM42.4777 9.04852H38.7578V13.3102H42.4777C44.0911 13.3102 44.9939 12.4525 44.9939 11.1787C44.9939 9.9062 44.0911 9.04852 42.4777 9.04852ZM42.7235 16.5585H38.7578V21.1061H42.7235C44.4471 21.1061 45.2665 20.0673 45.2665 18.8189C45.2665 17.572 44.4471 16.5585 42.7235 16.5585Z'
        fill='white'
      />
      <path
        d='M69.2828 9.0484V24.3289H65.481V9.0484H60.3936V5.82556H74.3702V9.0484H69.2828Z'
        fill='white'
      />
    </svg>
  );
}

export default React.memo(BibytIcon);
