// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Leaderboard_wrapper__jDAxr {
  position: relative;
}

.Leaderboard_container__HLSMF {
  display: flex;
  flex-direction: column;
  width: 737px;
  height: max-content;
  border: 1px solid var(--border-white-02);
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.04);
}

.Leaderboard_disabled__z5xPL {
  background-color: rgba(29, 28, 28, 0.6);
  pointer-events: none;
  opacity: 0.4;
}

.Leaderboard_soon__v6ey9 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 40px;
  font-weight: 400;
  color: white;
  z-index: 1000;
}

.Leaderboard_filter__JxSfs {
  margin: 0 0 20px 20px;
}

@media (max-width: 768px) {
  .Leaderboard_container__HLSMF {
    width: 100%;
    box-sizing: border-box;
  }
  .Leaderboard_filter__JxSfs {
    margin: 0 30px 16px 30px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/LaunchBotPage/Leaderboard/Leaderboard.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,mBAAmB;EACnB,wCAAwC;EACxC,mBAAmB;EACnB,2CAA2C;AAC7C;;AAEA;EACE,uCAAuC;EACvC,oBAAoB;EACpB,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,eAAe;EACf,gBAAgB;EAChB,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE;IACE,WAAW;IACX,sBAAsB;EACxB;EACA;IACE,wBAAwB;EAC1B;AACF","sourcesContent":[".wrapper {\n  position: relative;\n}\n\n.container {\n  display: flex;\n  flex-direction: column;\n  width: 737px;\n  height: max-content;\n  border: 1px solid var(--border-white-02);\n  border-radius: 10px;\n  background-color: rgba(255, 255, 255, 0.04);\n}\n\n.disabled {\n  background-color: rgba(29, 28, 28, 0.6);\n  pointer-events: none;\n  opacity: 0.4;\n}\n\n.soon {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  font-size: 40px;\n  font-weight: 400;\n  color: white;\n  z-index: 1000;\n}\n\n.filter {\n  margin: 0 0 20px 20px;\n}\n\n@media (max-width: 768px) {\n  .container {\n    width: 100%;\n    box-sizing: border-box;\n  }\n  .filter {\n    margin: 0 30px 16px 30px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Leaderboard_wrapper__jDAxr`,
	"container": `Leaderboard_container__HLSMF`,
	"disabled": `Leaderboard_disabled__z5xPL`,
	"soon": `Leaderboard_soon__v6ey9`,
	"filter": `Leaderboard_filter__JxSfs`
};
export default ___CSS_LOADER_EXPORT___;
