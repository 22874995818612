import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import styles from './OrderHistory.module.css';
import HeaderOrdersList from './HeaderOrdersList';

import OrderListItem from './OrderListItem';
import PaginationOrdersList from './PaginationOrdersList';
import CloseButton from '../../CloseButton/CloseButton';
import { useTranslation } from 'react-i18next';
import AvailableOnDesktopOverlay from '../../BlockingOverlay/BlockingOverlay';
import { useRequestBotOrders } from '../../../hooks/requests/useRequestBotOrders';
import { IOrderStatistics } from '../../../redux/types';
import { useAppSelector } from '../../../redux/hooks/redux';
import { INITIAL_BOTS } from './__mockData__';

const root: unknown = document.getElementById('root');

interface IOrderHistoryProps {
  onClose: () => void;
}
export default function OrderHistory({ onClose }: IOrderHistoryProps) {
  const { t } = useTranslation('ordersModal');
  const ORDERS_ON_PAGE: number = 10;
  const [endIndex, setEndIndex] = useState<number>(ORDERS_ON_PAGE);
  const listRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (listRef.current) {
      listRef.current.scroll({ top: 0, behavior: 'smooth' });
    }
  }, [endIndex]);
  const [totalOrderCount, setTotalOrderCount] = useState<number>(0);
  const [orders, setOrders] = useState<IOrderStatistics[]>([]);

  const requestBotOrders = useRequestBotOrders();
  const { access } = useAppSelector((state) => state.profile);
  useEffect(() => {
    const fetchData = async () => {
      if (access === 'ACCESS_DEMO') {
        setOrders(INITIAL_BOTS);
        setTotalOrderCount(INITIAL_BOTS.length);
        return;
      }
      const ordersResponse = (await requestBotOrders()) as {
        totalOrderCount: number;
        orders: IOrderStatistics[];
      };
      setTotalOrderCount(ordersResponse.totalOrderCount);
      setOrders(ordersResponse.orders);
    };

    void fetchData();
  }, []);

  return createPortal(
    <div className={styles.container}>
      <div className={styles.modalContainer}>
        <div className={styles.blockingOverlay}>
          <AvailableOnDesktopOverlay />
        </div>
        <div className={styles.title}>
          {t('title')} ({totalOrderCount})
        </div>
        <HeaderOrdersList />

        <div className={styles.list} ref={listRef}>
          {orders.length !== 0 ? (
            orders
              .slice(endIndex - ORDERS_ON_PAGE, endIndex)
              .map((order: IOrderStatistics, index) =>
                /* TODO: Change key from index to id */
                index < ORDERS_ON_PAGE ? <OrderListItem order={order} key={index} /> : null,
              )
          ) : (
            <p>No orders yet</p>
          )}
        </div>
        <PaginationOrdersList endIndex={endIndex} setEndIndex={setEndIndex} orders={orders} />
        <button className={styles.closeButton}>
          <CloseButton onClick={onClose} />
        </button>
      </div>
    </div>,
    root as HTMLElement,
  );
}
