// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CalendarComponent_calendarComponent__cqkR0 {
  box-sizing: border-box;
  position: absolute;
  top: 55px;
  display: flex;
  width: 350px;
  max-height: 325px;
  padding: 30px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 1px solid var(--border-white-02);
  background: var(--black-background-card);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
}

abbr {
  text-decoration: none;
}

.CalendarComponent_tile__cLtCC {
  padding: 8px;
}

.react-calendar__tile--active {
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/AdminPage/UsersListPage/Calendar/CalendarComponent.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,kBAAkB;EAClB,SAAS;EACT,aAAa;EACb,YAAY;EACZ,iBAAiB;EACjB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,mBAAmB;EACnB,wCAAwC;EACxC,wCAAwC;EACxC,mCAA2B;UAA3B,2BAA2B;AAC7B;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".calendarComponent {\n  box-sizing: border-box;\n  position: absolute;\n  top: 55px;\n  display: flex;\n  width: 350px;\n  max-height: 325px;\n  padding: 30px;\n  justify-content: center;\n  align-items: center;\n  border-radius: 10px;\n  border: 1px solid var(--border-white-02);\n  background: var(--black-background-card);\n  backdrop-filter: blur(10px);\n}\n\nabbr {\n  text-decoration: none;\n}\n\n.tile {\n  padding: 8px;\n}\n\n:global(.react-calendar__tile--active) {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"calendarComponent": `CalendarComponent_calendarComponent__cqkR0`,
	"tile": `CalendarComponent_tile__cLtCC`
};
export default ___CSS_LOADER_EXPORT___;
