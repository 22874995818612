import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { initialFlowParameters } from './constants';
import { IFlowParameters } from '../types';
const tempSettingsSlice = createSlice({
  name: 'tempSettings',
  initialState: initialFlowParameters,
  reducers: {
    updateTempData: (state: IFlowParameters, action: PayloadAction<IFlowParameters>) => {
      return (state = action.payload);
    },
    clearTempData: () => {
      return initialFlowParameters;
    },
    toggleTrendFlipper: (state) => {
      state.strategyParameters.useReverse = !state.strategyParameters.useReverse;
    },
    changeAtrMultiplierUpper: (state, action) => {
      state.strategyParameters.globalParameters.emaBandsSettings.atrMultiplierUp = action.payload;
    },
    changeAtrMultiplierLower: (state, action) => {
      state.strategyParameters.globalParameters.emaBandsSettings.atrMultiplierDown = action.payload;
    },
    setFirstTimeFrame: (state, action) => {
      state.strategyParameters.globalParameters.emaBandsSettings.params.firstTimeFrame =
        action.payload;
    },
    setSecondTimeFrame: (state, action) => {
      state.strategyParameters.globalParameters.emaBandsSettings.params.secondTimeFrame =
        action.payload;
    },
    toggleAntiSqueeze: (state) => {
      state.strategyParameters.globalParameters.useAntiSqueeze =
        !state.strategyParameters.globalParameters.useAntiSqueeze;
    },
    toggleVolatilityAdjustment: (state) => {
      state.strategyParameters.globalParameters.convertFixedToAtr =
        !state.strategyParameters.globalParameters.convertFixedToAtr;
    },
    toggleHedgeGuard: (state) => {
      state.strategyParameters.useAutoHedge = !state.strategyParameters.useAutoHedge;
    },
    changeEntryOrderMode: (state, action) => {
      state.strategyParameters.inOrderDistance.startFrom =
        action.payload === 'fromLaunch' ? null : 0;
    },
    changePriceLevel: (state, action) => {
      state.strategyParameters.inOrderDistance.startFrom = action.payload;
    },
    changeQtyEntryOrder: (state, action) => {
      state.strategyParameters.inOrderQuantity.value = action.payload;
    },
    toggleQtyMultiplier: (state) => {
      if (state.strategyParameters.multiplierInOrderQuantity === null) {
        state.strategyParameters.multiplierInOrderQuantity = {
          ratio: 0,
          maxQuantity: 0,
          usePercent: false,
        };
      } else {
        state.strategyParameters.multiplierInOrderQuantity = null;
      }
    },
    changeQtyMultiplierRatio: (state, action) => {
      if (state.strategyParameters.multiplierInOrderQuantity) {
        state.strategyParameters.multiplierInOrderQuantity.ratio = action.payload;
      }
    },
    changeEntryOrderQty: (state, action) => {
      if (state.strategyParameters.multiplierInOrderQuantity) {
        state.strategyParameters.multiplierInOrderQuantity.maxQuantity = action.payload;
      }
    },
    changeGridStep: (state, action) => {
      state.strategyParameters.inOrderDistance.value = action.payload;
    },
    toggleGridStepUsePercent: (state) => {
      state.strategyParameters.inOrderDistance.usePercent =
        !state.strategyParameters.inOrderDistance.usePercent;
    },
    toggleGridStepMultiplier: (state) => {
      if (state.strategyParameters.multiplierInOrderDistance === null) {
        state.strategyParameters.multiplierInOrderDistance = {
          ratio: 0,
          maxDistance: 0,
          usePercent: false,
        };
      } else {
        state.strategyParameters.multiplierInOrderDistance = null;
      }
    },
    changeGridStepRatio: (state, action) => {
      if (state.strategyParameters.multiplierInOrderDistance) {
        state.strategyParameters.multiplierInOrderDistance.ratio = action.payload;
      }
    },
    changeMaxGridStep: (state, action) => {
      if (state.strategyParameters.multiplierInOrderDistance) {
        state.strategyParameters.multiplierInOrderDistance.maxDistance = action.payload;
      }
    },
    toggleMaxGridStepUsePercent: (state) => {
      if (state.strategyParameters.multiplierInOrderDistance) {
        state.strategyParameters.multiplierInOrderDistance.usePercent =
          !state.strategyParameters.multiplierInOrderDistance.usePercent;
      }
    },
    toggleTrailingBuy: (state) => {
      if (state.strategyParameters.trailingBuyDistance === null) {
        state.strategyParameters.trailingBuyDistance = 0;
      } else {
        state.strategyParameters.trailingBuyDistance = null;
      }
    },
    changeTailingDistance: (state, action) => {
      state.strategyParameters.trailingBuyDistance = action.payload;
    },
    toggleTradingRange: (state) => {
      if (state.strategyParameters.tradingRange === null) {
        state.strategyParameters.tradingRange = {
          lowerBound: '0.000000001',
          upperBound: 1000000,
          usePercent: false,
        };
      } else {
        state.strategyParameters.tradingRange = null;
      }
    },
    changeTradingRangeTo: (state, action) => {
      if (state.strategyParameters.tradingRange) {
        state.strategyParameters.tradingRange.lowerBound = action.payload;
      }
    },
    changeTradingRangeFrom: (state, action) => {
      if (state.strategyParameters.tradingRange) {
        state.strategyParameters.tradingRange.upperBound = action.payload;
      }
    },
    changeMarginMode: (state, action: PayloadAction<'Cross' | 'Isolated' | null>) => {
      state.strategyParameters.marginMode = action.payload;
    },
    changeLeverage: (state: IFlowParameters, action) => {
      state.strategyParameters.leverage = action.payload;
    },
    changeTriggersMode: (state, action: PayloadAction<'AllActive' | 'New'>) => {
      state.strategyParameters.triggerDistance.choice = action.payload;
    },
    changeTriggersDistance: (state, action) => {
      state.strategyParameters.triggerDistance.distance = action.payload;
    },
    toggleTriggersDistanceUsePercent: (state) => {
      state.strategyParameters.triggerDistance.usePercent =
        !state.strategyParameters.triggerDistance.usePercent;
    },
    changeMaxTriggersCount: (state, action) => {
      state.strategyParameters.maxTriggerCount = action.payload;
    },
    toggleTrailingTrigger: (state) => {
      if (state.strategyParameters.trailingTrigger === null) {
        state.strategyParameters.trailingTrigger = {
          profitPercent: 0,
          startDistance: 0,
          usePercent: false,
        };
      } else {
        state.strategyParameters.trailingTrigger = null;
      }
    },
    changeTrailingProfit: (state, action) => {
      if (state.strategyParameters.trailingTrigger) {
        state.strategyParameters.trailingTrigger.profitPercent = action.payload;
      }
    },
    changeTrailingTriggersDistance: (state, action) => {
      if (state.strategyParameters.trailingTrigger) {
        state.strategyParameters.trailingTrigger.startDistance = action.payload;
      }
    },
    toggleTrailingTriggersDistanceUsePercent: (state) => {
      if (state.strategyParameters.trailingTrigger) {
        state.strategyParameters.trailingTrigger.usePercent =
          !state.strategyParameters.trailingTrigger.usePercent;
      }
    },
    changeTakeProfitMinDistance: (state, action) => {
      state.strategyParameters.trailingTp.minDistance = action.payload;
    },
    toggleTakeProfitDistanceUsePercent: (state) => {
      state.strategyParameters.trailingTp.usePercent =
        !state.strategyParameters.trailingTp.usePercent;
    },
    changeTakeProfitMaxDistance: (state, action) => {
      state.strategyParameters.trailingTp.maxDistance = action.payload;
    },
    toggleEquityStop: (state) => {
      if (state.strategyParameters.equityStop === null) {
        state.strategyParameters.equityStop = {
          minEquity: 0,
          usePercent: false,
        };
      } else {
        state.strategyParameters.equityStop = null;
      }
    },
    changeMinEquity: (state, action) => {
      if (state.strategyParameters.equityStop) {
        state.strategyParameters.equityStop.minEquity = action.payload;
      }
    },
    toggleMinEquityUsePercent: (state) => {
      if (state.strategyParameters.equityStop) {
        state.strategyParameters.equityStop.usePercent =
          !state.strategyParameters.equityStop.usePercent;
      }
    },
    setStrategyType: (state, action) => {
      state.flowContext.strategyType = action.payload;
    },
    setExchange: (state, action) => {
      state.flowContext.exchange = action.payload;
    },
    setInstrument: (state, action) => {
      state.flowContext.instrument = action.payload;
    },
    setMarketType: (state, action) => {
      state.flowContext.market = action.payload;
    },
    setFlowName: (state, action) => {
      state.flowName = action.payload;
    },
  },
});

export const {
  updateTempData,
  clearTempData,
  toggleTrendFlipper,
  toggleHedgeGuard,
  changeEntryOrderMode,
  changePriceLevel,
  changeQtyEntryOrder,
  changeQtyMultiplierRatio,
  changeEntryOrderQty,
  changeGridStep,
  toggleGridStepUsePercent,
  changeGridStepRatio,
  changeMaxGridStep,
  toggleMaxGridStepUsePercent,
  changeTailingDistance,
  changeTradingRangeTo,
  changeTradingRangeFrom,
  toggleGridStepMultiplier,
  toggleQtyMultiplier,
  toggleTradingRange,
  toggleTrailingBuy,
  changeMarginMode,
  changeLeverage,
  changeTriggersMode,
  changeTriggersDistance,
  toggleTriggersDistanceUsePercent,
  changeMaxTriggersCount,
  toggleTrailingTrigger,
  changeTrailingProfit,
  changeTrailingTriggersDistance,
  toggleTrailingTriggersDistanceUsePercent,
  changeTakeProfitMinDistance,
  toggleTakeProfitDistanceUsePercent,
  changeTakeProfitMaxDistance,
  toggleEquityStop,
  changeMinEquity,
  toggleMinEquityUsePercent,
  changeAtrMultiplierUpper,
  changeAtrMultiplierLower,
  setFirstTimeFrame,
  setSecondTimeFrame,
  toggleAntiSqueeze,
  toggleVolatilityAdjustment,
  setStrategyType,
  setExchange,
  setInstrument,
  setMarketType,
  setFlowName,
} = tempSettingsSlice.actions;
export default tempSettingsSlice.reducer;
