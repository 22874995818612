import TitleCard from '../../BotsPage/BotSettings/Blocks/TitleCard';

import styles from './AlgorithmCard.module.css';
import { IAlgorithm } from '../LaunchBotPage';
import BotIcon from '../../BotsPage/BotIcon/BotIcon';
import IconWithTooltip from '../../IconWithTooltip/IconWithTooltip';
import StrategyBot from '../../BotsPage/BotsCard/StrategyBot';
import { useTranslation } from 'react-i18next';
import { EStrategyType } from '../../../redux/types';
import { memo } from 'react';
interface IAlgorithmCard {
  algorithm: IAlgorithm;
  onOpenSettings: () => void;
}

function AlgorithmCard({
  algorithm: { algorithm, apr, description, launched },
  onOpenSettings,
}: IAlgorithmCard): JSX.Element {
  const { t } = useTranslation('launchBotPage');
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.icon}>
          <BotIcon icon={algorithm} />
        </div>
        <div className={styles.algorithm}>
          <h3 className={styles.title}>
            {algorithm} <IconWithTooltip />
          </h3>
          <div className={styles.strategies}>
            <StrategyBot strategy={EStrategyType.long} />
            <StrategyBot strategy={EStrategyType.short} />
            <div className={styles.trendFlipper}>TrendFlipper</div>
          </div>
        </div>
      </div>
      <div className={styles.description}>{description}</div>
      <div className={styles.infoBlock}>
        <div className={styles.info}>
          <span>{t('highestAPR')}</span> {apr}
        </div>
        <div className={styles.info}>
          <span>{t('launched')}</span> {launched.toLocaleString()}
        </div>
      </div>
      <TitleCard title={t('launch')} />
      <div className={styles.launchBlock}>
        <div className={styles.launchBtns}>
          <button className={styles.autoBtn} disabled={true}>
            {t('auto')}
          </button>
          <button className={styles.proBtn} onClick={onOpenSettings}>
            {t('pro')}
          </button>
        </div>
      </div>
    </div>
  );
}

export default memo(AlgorithmCard);
