import React, { useEffect } from 'react';
import binanceImg from '../../../assets/img/binance.svg';
import bitgetImg from '../../../assets/img/bitget.svg';
import unionImg from '../../../assets/img/UnionIcon.svg';
import styles from './HowItWorks.module.css';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { Link, useLocation } from 'react-router-dom';

const HowItWorks: React.FC = () => {
  const { t, i18n } = useTranslation('howItWorks');
  const isEnglish = i18n.language === 'en';
  const containerStyle = cn(styles.container, { [styles.container_ru]: !isEnglish });
  const location = useLocation();

  useEffect(() => {
    if (location.hash.includes('how_it_works')) {
      window.scrollTo({
        top: document.getElementById('how_it_works')?.offsetTop,
        behavior: 'smooth',
      });
    }
  }, [location]);
  return (
    <div id='how_it_works' className={containerStyle}>
      <h2>{t('title')}</h2>
      <ul>
        <li>
          {t('step1.part1')}
          <br />
          {t('step1.part2')}
        </li>
        <li>
          {t('step2.part1')}
          <br />
          {t('step2.part2')}
        </li>
        <li>
          {t('step3.part1')}
          <br />
          {t('step3.part2')}
        </li>
        <li>
          {t('step4.part1')}
          <br />
          {t('step4.part2')}
        </li>
      </ul>
      <div className={styles.soon}>
        <div>{t('soon')}</div>
        <img className={styles.bitget} src={bitgetImg} />
        <img className={styles.binance} src={binanceImg} />
        <img className={styles.union} src={unionImg} />
      </div>
      <Link to='/how-to-connect' className={styles.button}>
        {t('button')}
      </Link>
    </div>
  );
};

export default HowItWorks;
