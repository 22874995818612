// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HeaderGroupList_listHeader__0Y4xV {
  display: flex;
  width: max-content;
  gap: 25px;
}

.HeaderGroupList_listHeader__0Y4xV {
  display: flex;
  width: max-content;
  color: var(--text-colored);
  font-weight: normal;
}

.HeaderGroupList_listHeader__0Y4xV div {
  display: flex;
  align-items: center;
}

.HeaderGroupList_date__9TnE9 {
  width: 160px;
}

.HeaderGroupList_uid__lJXXX {
  width: 110px;
}

.HeaderGroupList_group__Y9uPs {
  width: 200px;
}

.HeaderGroupList_functionality__87Q7h {
  width: 500px;
}

.HeaderGroupList_users__D8q2H {
  width: 200px;
}

.HeaderGroupList_trading__10-NK {
  width: 190px;
}

.HeaderGroupList_pnl__-Lufk {
  width: 197px;
}

.HeaderGroupList_equity__WeiIM {
  width: 210px;
}

.HeaderGroupList_runningBotsBox__DAgb1 {
  flex-direction: column;
  box-sizing: border-box;
  width: 200px;
}
.HeaderGroupList_botsTypeBox__qdEwh {
  width: 70%;
  justify-content: space-between;
}
`, "",{"version":3,"sources":["webpack://./src/components/AdminPage/GroupeListPage/HeaderGroupList/HeaderGroupList.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;EAClB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,0BAA0B;EAC1B,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,sBAAsB;EACtB,sBAAsB;EACtB,YAAY;AACd;AACA;EACE,UAAU;EACV,8BAA8B;AAChC","sourcesContent":[".listHeader {\n  display: flex;\n  width: max-content;\n  gap: 25px;\n}\n\n.listHeader {\n  display: flex;\n  width: max-content;\n  color: var(--text-colored);\n  font-weight: normal;\n}\n\n.listHeader div {\n  display: flex;\n  align-items: center;\n}\n\n.date {\n  width: 160px;\n}\n\n.uid {\n  width: 110px;\n}\n\n.group {\n  width: 200px;\n}\n\n.functionality {\n  width: 500px;\n}\n\n.users {\n  width: 200px;\n}\n\n.trading {\n  width: 190px;\n}\n\n.pnl {\n  width: 197px;\n}\n\n.equity {\n  width: 210px;\n}\n\n.runningBotsBox {\n  flex-direction: column;\n  box-sizing: border-box;\n  width: 200px;\n}\n.botsTypeBox {\n  width: 70%;\n  justify-content: space-between;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"listHeader": `HeaderGroupList_listHeader__0Y4xV`,
	"date": `HeaderGroupList_date__9TnE9`,
	"uid": `HeaderGroupList_uid__lJXXX`,
	"group": `HeaderGroupList_group__Y9uPs`,
	"functionality": `HeaderGroupList_functionality__87Q7h`,
	"users": `HeaderGroupList_users__D8q2H`,
	"trading": `HeaderGroupList_trading__10-NK`,
	"pnl": `HeaderGroupList_pnl__-Lufk`,
	"equity": `HeaderGroupList_equity__WeiIM`,
	"runningBotsBox": `HeaderGroupList_runningBotsBox__DAgb1`,
	"botsTypeBox": `HeaderGroupList_botsTypeBox__qdEwh`
};
export default ___CSS_LOADER_EXPORT___;
