import cn from 'classnames';
import styles from './PNLTable.module.css';

import { selectAdminStats } from '../../../../../redux/selectors/rootSelector';
import { useAppSelector } from '../../../../../redux/hooks/redux';
import { preparePnlData } from '../utils';

export default function PNLTable() {
  const formatOption: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  };

  const adminPanel = useAppSelector(selectAdminStats).adminPanelInfo;
  const { pnlData } = adminPanel;
  const preparedPnlData = preparePnlData(pnlData);

  return (
    <div className={styles.table}>
      <div className={cn(styles.group, styles.firstGroup)} />
      <div className={cn(styles.group, styles.secondGroup)} />
      <div className={styles.header}>
        <div className={styles.cell}>Date</div>
        <div className={styles.cell}>Total PNL</div>
        <div className={styles.cell}>Daily PNL</div>
        <div className={styles.columnGroup}>
          <div>
            <div className={styles.columnsTitle}>Average by user</div>
          </div>
          <div className={styles.modeBox}>
            <div className={styles.cell}>All</div>
            <div className={styles.cell}>Pro</div>
            <div className={styles.cell}>Auto</div>
          </div>
        </div>
        <div className={styles.columnGroup}>
          <div>
            <div className={styles.columnsTitle}>Average by bot</div>
          </div>
          <div className={styles.modeBox}>
            <div className={styles.cell}>All</div>
            <div className={styles.cell}>Pro</div>
            <div className={styles.cell}>Auto</div>
          </div>
        </div>
      </div>
      <div className={styles.tableData}>
        {preparedPnlData.map(({ id, date, totalPnl, dailyPnl, averageUsers, averageBots }) => (
          <div className={styles.row} key={id}>
            <div className={styles.cell}>
              {new Intl.DateTimeFormat('ru', formatOption).format(date)}
            </div>
            <div className={styles.cell}>{totalPnl}</div>
            <div className={styles.cell}>{dailyPnl}</div>
            <div className={styles.modeBox}>
              <div className={styles.cell}>{averageUsers.all}</div>
              <div className={styles.cell}>{averageUsers.pro}</div>
              <div className={styles.cell}>{averageUsers.auto}</div>
            </div>
            <div className={styles.modeBox}>
              <div className={styles.cell}>{averageBots.all}</div>
              <div className={styles.cell}>{averageBots.pro}</div>
              <div className={styles.cell}>{averageBots.auto}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
