/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/naming-convention */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BASE_URL } from '../../constants';

export const signUpApi = createApi({
  reducerPath: 'signUpApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers) => {
      headers.set('accept', 'application/json');
      headers.set('Content-Type', 'application/json');
    },
  }),
  endpoints: (build) => ({
    login: build.mutation({
      query: (payload) => ({
        url: `/v1/login?user_id=${payload.userId}`,
        method: 'POST',
        body: payload?.body,
      }),
    }),
    forgotPassword: build.mutation({
      query: (payload) => ({
        url: '/v1/users',
        method: 'PUT',
        body: payload,
      }),
    }),
    changePassword: build.mutation({
      query: (payload) => ({
        url: `/v1/users/${payload.userId}/password`,
        method: 'PUT',
        body: payload.body,
        headers: {
          Authorization: `Bearer ${payload.token}`,
        },
      }),
    }),
    getSessionId: build.mutation({
      query: (payload) => ({
        url: `/v1/users/${payload.userId}/sessions`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${payload.access_token}`,
        },
      }),
    }),
    getUserAccess: build.mutation({
      query: (payload) => ({
        url: `/v1/sessions/${payload.sessionId}/access`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${payload.token}`,
        },
      }),
    }),
    confirmOperation: build.mutation({
      query: ({ userId, operation_id, access_token, credentials }) => ({
        url: `/v1/users/${userId}/operations/${operation_id}`,
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
        body: credentials.body,
      }),
    }),
    finishAllSessions: build.mutation({
      query: ({ userId, access_token }) => ({
        url: `/v1/users/${userId}/sessions`,
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }),
    }),
    cancelAnyOperations: build.mutation({
      query: ({ userId, access_token, credentials }) => ({
        url: `/v1/users/${userId}/operations`,
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
        body: credentials.body,
      }),
    }),
    finishSession: build.mutation({
      query: (payload) => ({
        url: `/v1/sessions/${payload.sessionId}`,
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${payload.token}`,
        },
      }),
    }),
    signUpUser: build.mutation({
      query: (payload) => ({
        url: '/v1/users',
        method: 'POST',
        body: {
          email: payload,
        },
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useForgotPasswordMutation,
  useChangePasswordMutation,
  useGetSessionIdMutation,
  useGetUserAccessMutation,
  useConfirmOperationMutation,
  useFinishAllSessionsMutation,
  useCancelAnyOperationsMutation,
  useFinishSessionMutation,
  useSignUpUserMutation,
} = signUpApi;
