import styles from './MyBotsList.module.css';
import ListHeader from './ListHeader';
import BotCard from '../BotsCard/BotCard';
import { openBlock } from '../../../redux/slices/navigationSlice';
import { IBot } from '../../../redux/types';
import { useAppDispatch } from '../../../redux/hooks/redux';
import useRequestBotParameters from '../../../hooks/requests/useRequestBotParameters';
import { selectBot } from '../../../redux/slices/botsSlice';
import IconWithTooltip from '../../IconWithTooltip/IconWithTooltip';

export interface IBotsList {
  bots: IBot[];
  openInfoBlock: (isOpen: boolean) => void;
  isInfoBlockOpened: boolean;
  isSubBotsList: boolean;
  cardTooltip: string;
  isShownHeader: boolean;
}

export default function MyBotsList({
  bots,
  openInfoBlock,
  isInfoBlockOpened,
  isSubBotsList,
  cardTooltip,
  isShownHeader,
}: IBotsList) {
  const dispatch = useAppDispatch();
  const requestBotParameters = useRequestBotParameters();

  const onBotClick = (bot: IBot) => (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    openInfoBlock(true);
    dispatch(openBlock());
    !isSubBotsList && requestBotParameters(bot);
    dispatch(selectBot(bot));
  };

  return (
    <div className={styles.list}>
      {isShownHeader && <ListHeader isInfoBlockOpened={isInfoBlockOpened} />}
      {bots.map((bot) => (
        <IconWithTooltip
          key={`${bot.uuid}`}
          element={
            <BotCard
              bot={bot}
              onClick={onBotClick(bot)}
              isInfoBlockOpened={isInfoBlockOpened}
              isSubBotCard={isSubBotsList}
            />
          }
          content={cardTooltip}
        />
      ))}
    </div>
  );
}
