import BotCard from '../BotCard/BotCard';

import styles from './BotsList.module.css';
import BotCardShape from '../BotCard/BotCardShape';
import { IBot } from '../../../../redux/types';
import IconWithTooltip from '../../../IconWithTooltip/IconWithTooltip';
import { useTranslation } from 'react-i18next';
import { prepareStatus } from '../../../BotsPage/BotsPage.utils';
import { EStatus } from '../../../BotsPage/BotsCard/ActivityBotIcon';

interface IFullBotsList {
  bots: IBot[];
  onSetSubBots: (bot: IBot) => void;
}

export default function BotsList({ bots, onSetSubBots: onSetSubBots }: IFullBotsList) {
  const { t } = useTranslation('botsStats');

  return (
    <div className={styles.botsList}>
      {bots.map((bot) => {
        const isBotDisabled: boolean =
          prepareStatus(bot.flowStatus) !== EStatus.active &&
          prepareStatus(bot.flowStatus) !== EStatus.new;
        if (bot.hasSubbots) {
          return (
            <BotCardShape key={bot.uuid} isDisabled={isBotDisabled}>
              <IconWithTooltip
                element={
                  <BotCard bot={bot} onSetSubBots={onSetSubBots} isDisabled={isBotDisabled} />
                }
                content={t('cardTooltip')}
              />
            </BotCardShape>
          );
        } else {
          return (
            <IconWithTooltip
              element={
                <BotCard
                  bot={bot}
                  key={bot.uuid}
                  onSetSubBots={onSetSubBots}
                  isDisabled={isBotDisabled}
                />
              }
              content={t('cardTooltip')}
              key={bot.uuid}
            />
          );
        }
      })}
    </div>
  );
}
