import cn from 'classnames';
import styles from './BotsCard.module.css';
import { EStrategyType } from '../../../redux/types';

interface IRiskIcon {
  strategy?: EStrategyType;
  trendFlipper?: boolean;
}

enum EStrategyIcon {
  short = 'Short',
  long = 'Long',
  spot = 'Spot',
}

export default function StrategyBot({ strategy, trendFlipper = false }: IRiskIcon) {
  const strategyIcon = {
    [EStrategyType.short]: EStrategyIcon.short,
    [EStrategyType.long]: EStrategyIcon.long,
    [EStrategyType.spot]: EStrategyIcon.spot,
  };

  if (!strategy) {
    return <>The strategy is not set</>;
  }

  return (
    <div
      className={cn([
        styles.strategy,
        { [styles.strategyLong]: strategy === EStrategyType.long },
        { [styles.strategyShort]: strategy === EStrategyType.short },
        { [styles.trendFlipper]: trendFlipper },
      ])}
    >
      {trendFlipper ? 'TrendFlipper' : strategyIcon[strategy]}
    </div>
  );
}
