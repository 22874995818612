import { useTranslation } from 'react-i18next';
import styles from './BotCard.module.css';
import ActivityBotIcon from '../../../BotsPage/BotsCard/ActivityBotIcon';
import BotIcon from '../../../BotsPage/BotIcon/BotIcon';
import StrategyBot from '../../../BotsPage/BotsCard/StrategyBot';
import MarketBot from '../../../BotsPage/BotsCard/MarketBot';
import RiskBot, { ESizing } from '../../../BotsPage/BotsCard/RiskBot';
import PnlValue from '../../../BotsPage/BotsCard/PnlValue';
import RuntimeBot from '../../../BotsPage/BotsCard/RuntimeBot';
import { IBot } from '../../../../redux/types';
import CopyIcon from '../../../../assets/General/CopyIcon';
import IconWithTooltip from '../../../IconWithTooltip/IconWithTooltip';
import cn from 'classnames';
import { Exchanges } from '../../../LaunchBotPage/Leaderboard/ListLeaderboard/ExchangesIcons';
import useTransformPair from '../../../../hooks/useTransformPair';
export interface IBotCard {
  bot: IBot;
  onSetSubBots: (bot: IBot) => void;
  isDisabled: boolean;
}

export enum EMode {
  pro = 'PRO',
  auto = 'AUTO',
}

export default function BotCard({ bot, onSetSubBots, isDisabled }: IBotCard) {
  const { t } = useTranslation('botspage');
  const transformPair = useTransformPair();
  const onCopySettings = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
  };

  const MAX_NAME_LENGTH = 20;
  const shouldCutName = bot.flowName.length > MAX_NAME_LENGTH;
  const botName = shouldCutName ? `${bot.flowName.slice(0, MAX_NAME_LENGTH - 1)}...` : bot.flowName;

  const botMode = bot.mode === 'NO' ? 'PRO' : t(`botCard.mode.${bot.mode.replace('MODE_', '')}`);

  return (
    <div className={styles.wrapper}>
      <ActivityBotIcon bot={bot} />
      <div
        className={cn([styles.container], { [styles.nonactive]: isDisabled })}
        onClick={() => onSetSubBots(bot)}
      >
        <div className={styles.contentHeader}>
          <span className={styles.modeBox}>{botMode}</span>
          <div className={styles.botName}>
            <BotIcon icon={bot.strategy} height={24} width={24} />{' '}
            <IconWithTooltip content={bot.flowName} element={botName} />
          </div>
          <div className={styles.activityStatus} />
        </div>
        <div className={styles.content}>
          <div className={styles.botInfo}>
            <div className={styles.strategyBox}>
              <StrategyBot strategy={bot.strategyType} trendFlipper={bot.useReverse} />
            </div>
            <div className={styles.info}>
              <div className={styles.exchange}>
                {Exchanges[bot.exchangeType]}
                <MarketBot market={bot.marketType} />
                {transformPair(bot.instrument)}
              </div>
              <div className={styles.pairRisk}>
                <RiskBot />
                <div className={styles.pnl}>
                  PNL: <PnlValue value={bot.pnl} size={ESizing.small} char='USD' />
                </div>
                <div className={styles.runtimeBox}>
                  {t('botCard.runtime.time')}: <RuntimeBot runtime={bot.uptime} />
                </div>
              </div>
            </div>
          </div>
          <button className={styles.copyBtn} onClick={onCopySettings}>
            <CopyIcon />
          </button>
        </div>
      </div>
    </div>
  );
}
