import IconWithTooltip from '../../../IconWithTooltip/IconWithTooltip';
import { useTranslation } from 'react-i18next';
import styles from '../BotSettings.module.css';
import { memo } from 'react';

function BalanceInfoBlock() {
  const { t } = useTranslation('botspage');
  return (
    <div className={styles.balanceInfo}>
      <IconWithTooltip color='#fff' height={24} width={24} />
      <div className={styles.infoText}>
        {t('settings.balanceInfo.info')}
        <a href='https://www.bybit.com/fiat/deposit/'> {t('settings.balanceInfo.link')}</a>
      </div>
    </div>
  );
}

export default memo(BalanceInfoBlock);
