import { useEffect, useState } from 'react';

import styles from './ProfileSettings.module.css';
import { Input } from '../../CCLabsUI';
import { ESizing } from '../../CCLabsUI/Input/Input';
import { useTranslation } from 'react-i18next';
import { useCurrentUserEmailQuery } from '../../../redux/services/settingProfileService';
import getHiddenEmail from '../../../utils/getHiddenEmail';
import ChangeEmailModal from '../ChangeEmailModal/ChangeEmailModal';

const ProfileSettings: React.FC = () => {
  const { t } = useTranslation('profilePage');

  const sessionId = sessionStorage.getItem('session_id');
  const token = sessionStorage.getItem('token');
  const userId = localStorage.getItem('userId');

  const { data } = useCurrentUserEmailQuery({ sessionId, token });
  const hiddenEmail = getHiddenEmail(data?.mail);

  const [email, setEmail] = useState<string>('');

  const [isChangeModalVisible, setIsChangeModalVisible] = useState(false);

  useEffect(() => {
    setEmail(hiddenEmail);
  }, [data?.mail]);

  const onPressUpdate = () => {
    setIsChangeModalVisible(true);
  };

  const onCloseChangeEmailModal = () => setIsChangeModalVisible(false);

  if (isChangeModalVisible) {
    return <ChangeEmailModal actionClose={onCloseChangeEmailModal} currentEmail={hiddenEmail} />;
  }

  return (
    <div className={styles.main}>
      <div className={styles.mainTitle}>{t('title')}</div>
      <Input
        id='id'
        sizing={ESizing.large}
        label={t('uuid')}
        placeholder='UIDz1ply8'
        disabled
        value={userId ?? ''}
        className={styles.input}
      />
      <Input
        id='email'
        autoComplete='off'
        label={t('email')}
        sizing={ESizing.large}
        placeholder='user@example.com'
        value={email}
        className={styles.input}
        disabled
      />
      <button onClick={onPressUpdate}>{t('updateBtn')}</button>
    </div>
  );
};

export default ProfileSettings;
