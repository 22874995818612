import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import SearchBar from '../../UsersListPage/SearchBar/SearchBar';
import NotificationItem from '../NotificationItem/NotificationItem';
import { NOTIFICATIONS } from './notifications-mocks';
import styles from './NotificationList.module.css';
import HeaderNotificationList from '../HeaderNotificationList/HeaderNotificationList';
import Pagination from '../../UsersListPage/Pagination/Pagination';
import { getArrayIndexes } from '../../UsersListPage/Pagination/pagination.utils';

export default function NotificationList() {
  const [searchValue, setSearchValue] = useState('');
  const [notifications, setNotifications] = useState(NOTIFICATIONS);
  const [isDescSort, setIsDescSort] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, indexOfFirstUser, indexOfLastUser] = getArrayIndexes(
    notifications,
    currentPage,
  );
  const navigate = useNavigate();
  const onSearch = () => {
    setNotifications(
      NOTIFICATIONS.filter(
        (notification) =>
          notification.title.toLowerCase().includes(searchValue) ||
          notification.message.toLowerCase().includes(searchValue),
      ),
    );
    setCurrentPage(1);
  };

  useEffect(() => onSearch(), [searchValue]);
  const onSetSearchValue = (e: React.ChangeEvent<HTMLInputElement>) =>
    setSearchValue(e.target.value);

  const onSortNotifications = () => {
    isDescSort
      ? setNotifications(notifications.sort((a, b) => a.date.getTime() - b.date.getTime()))
      : setNotifications(notifications.sort((a, b) => b.date.getTime() - a.date.getTime()));
    setIsDescSort((state) => !state);
  };

  const onResetFilter = () => {
    setNotifications([...NOTIFICATIONS]);
    setIsDescSort(false);
    setSearchValue('');
  };
  const onCreateNewNotification = () => navigate('/admin/notifications/create');
  const currentNotifications = notifications.slice(indexOfFirstUser, indexOfLastUser);
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.titleBox}>
          <h3>Notifications</h3>
          <button onClick={onResetFilter}>Reset filter</button>
        </div>
        <SearchBar
          onSearch={onSearch}
          onSetSearchValue={onSetSearchValue}
          searchValue={searchValue}
          onCreateNew={onCreateNewNotification}
          // onSettingsOpen будет потом, пока ТЗ на эту кнопку нет
          onSettingsOpen={() => {
            console.log('settings');
          }}
          buttonTitle='Создать новое сообщение'
        />
      </div>
      <HeaderNotificationList onSort={onSortNotifications} isDescSort={isDescSort} />
      <div className={styles.list}>
        {currentNotifications.map((notification) => (
          <NotificationItem key={notification.id} notification={notification} />
        ))}
      </div>
      <Pagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
      />
    </div>
  );
}
