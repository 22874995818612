import { createSlice } from '@reduxjs/toolkit';

const navigationSlice = createSlice({
  name: 'navigation',
  initialState: {
    isBlockOpened: false,
  },
  reducers: {
    openBlock(state) {
      state.isBlockOpened = true;
    },
    closeBlock(state) {
      state.isBlockOpened = false;
    },
  },
});

export const { openBlock, closeBlock } = navigationSlice.actions;

export default navigationSlice.reducer;
