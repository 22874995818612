// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NoActiveBots_container__YpP5g {
  background-color: rgba(255, 255, 255, 0.04);
  border: 1px solid var(--border-white-02);
  padding: 120px 0;
  border-radius: 10px;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.NoActiveBots_noActiveBotText__owkMz {
  font-size: 28px;
  color: white;
  font-weight: 400;
  font-family: PTSans;
}

.NoActiveBots_createBotBtn__837Hx {
  background-color: var(--text-colored);
  color: var(--main-background);
  padding: 12px 24px;
  border-radius: 8px;
  font-family: PTSans;
  font-size: 16px;
  font-weight: 700;
}

@media (max-width: 768px) {
  .NoActiveBots_container__YpP5g {
    width: calc(100vw - 32px);
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/BotsPage/NoActiveBots/NoActiveBots.module.css"],"names":[],"mappings":"AAAA;EACE,2CAA2C;EAC3C,wCAAwC;EACxC,gBAAgB;EAChB,mBAAmB;EACnB,gBAAgB;EAChB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,WAAW;AACb;;AAEA;EACE,eAAe;EACf,YAAY;EACZ,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,qCAAqC;EACrC,6BAA6B;EAC7B,kBAAkB;EAClB,kBAAkB;EAClB,mBAAmB;EACnB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE;IACE,yBAAyB;EAC3B;AACF","sourcesContent":[".container {\n  background-color: rgba(255, 255, 255, 0.04);\n  border: 1px solid var(--border-white-02);\n  padding: 120px 0;\n  border-radius: 10px;\n  margin-top: 16px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n}\n\n.noActiveBotText {\n  font-size: 28px;\n  color: white;\n  font-weight: 400;\n  font-family: PTSans;\n}\n\n.createBotBtn {\n  background-color: var(--text-colored);\n  color: var(--main-background);\n  padding: 12px 24px;\n  border-radius: 8px;\n  font-family: PTSans;\n  font-size: 16px;\n  font-weight: 700;\n}\n\n@media (max-width: 768px) {\n  .container {\n    width: calc(100vw - 32px);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `NoActiveBots_container__YpP5g`,
	"noActiveBotText": `NoActiveBots_noActiveBotText__owkMz`,
	"createBotBtn": `NoActiveBots_createBotBtn__837Hx`
};
export default ___CSS_LOADER_EXPORT___;
