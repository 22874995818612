import React from 'react';
import styles from './HowToConnectPresentation.module.css';
import { useTranslation } from 'react-i18next';

export default function HowToConnectPresentation() {
  const { t } = useTranslation('howToConnect');

  return (
    <div className={styles.container}>
      <div className={styles.promo}>
        <h2>{t('howToConnectHeader')}</h2>
      </div>
    </div>
  );
};
