// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PasswordUpdate_container__T8ElE {
  position: fixed;
  background: linear-gradient(180deg, rgba(17, 17, 17, 0.7) 0%, rgba(0, 0, 0, 0.7) 100%);
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
  top: 0px;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: PTSans;
  font-weight: 400;
  z-index: 1;
}

.PasswordUpdate_passwordUpdateContent__UuSRI {
  position: relative;
  width: max-content;
  padding: 40px;
  padding-top: 32px;
  border: 1px solid var(--border-white-02);
  border-radius: 10px;
  background: var(--black-background-card);
}

.PasswordUpdate_closeBtn__aSOS8 {
  position: absolute;
  top: 20px;
  right: 20px;
}

@media (max-width: 768px) {
  .PasswordUpdate_container__T8ElE {
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
    padding: 0 16px;
    overflow: auto;
    z-index: 1;
  }

  .PasswordUpdate_passwordUpdateContent__UuSRI{ 
    width: 100%;
    margin-top: 14vh;
  }

  .PasswordUpdate_passwordUpdateContent__UuSRI {
    padding: 32px 24px 36px 24px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/SettingsPage/PasswordUpdate/PasswordUpdate.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,sFAAsF;EACtF,mCAA2B;UAA3B,2BAA2B;EAC3B,QAAQ;EACR,YAAY;EACZ,aAAa;EACb,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,mBAAmB;EACnB,gBAAgB;EAChB,UAAU;AACZ;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,aAAa;EACb,iBAAiB;EACjB,wCAAwC;EACxC,mBAAmB;EACnB,wCAAwC;AAC1C;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;AACb;;AAEA;EACE;IACE,WAAW;IACX,2BAA2B;IAC3B,uBAAuB;IACvB,sBAAsB;IACtB,eAAe;IACf,cAAc;IACd,UAAU;EACZ;;EAEA;IACE,WAAW;IACX,gBAAgB;EAClB;;EAEA;IACE,4BAA4B;EAC9B;AACF","sourcesContent":[".container {\n  position: fixed;\n  background: linear-gradient(180deg, rgba(17, 17, 17, 0.7) 0%, rgba(0, 0, 0, 0.7) 100%);\n  backdrop-filter: blur(20px);\n  top: 0px;\n  width: 100vw;\n  height: 100vh;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-family: PTSans;\n  font-weight: 400;\n  z-index: 1;\n}\n\n.passwordUpdateContent {\n  position: relative;\n  width: max-content;\n  padding: 40px;\n  padding-top: 32px;\n  border: 1px solid var(--border-white-02);\n  border-radius: 10px;\n  background: var(--black-background-card);\n}\n\n.closeBtn {\n  position: absolute;\n  top: 20px;\n  right: 20px;\n}\n\n@media (max-width: 768px) {\n  .container {\n    width: 100%;\n    justify-content: flex-start;\n    align-items: flex-start;\n    box-sizing: border-box;\n    padding: 0 16px;\n    overflow: auto;\n    z-index: 1;\n  }\n\n  .passwordUpdateContent{ \n    width: 100%;\n    margin-top: 14vh;\n  }\n\n  .passwordUpdateContent {\n    padding: 32px 24px 36px 24px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `PasswordUpdate_container__T8ElE`,
	"passwordUpdateContent": `PasswordUpdate_passwordUpdateContent__UuSRI`,
	"closeBtn": `PasswordUpdate_closeBtn__aSOS8`
};
export default ___CSS_LOADER_EXPORT___;
