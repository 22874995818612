// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProSettingsModal_container__74ps9 {
  position: absolute;
  top: 0;
  padding: 100px 0;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  background: rgb(10, 8, 23);
  z-index: 5;
}

.ProSettingsModal_modalContainer__JR\\+XV {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 40px 60px;
  border-radius: 10px;
  border: 1px solid var(--border-white-02);
  background: var(--black-background-card);
  color: #fff;
}

.ProSettingsModal_title__XCcoH {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin: 0;
  color: white;
  font-family: PTSans;
  font-size: 20px;
  font-weight: 400;
  line-height: 22px;
}

@media (max-width: 768px) {
  .ProSettingsModal_container__74ps9 {
    position: relative;
    background: transparent;
    -webkit-backdrop-filter: initial;
            backdrop-filter: initial;
    padding: 0;
    width: calc(100vw - 32px);
  }
  .ProSettingsModal_modalContainer__JR\\+XV {
    background: transparent;
    border: 0;
    padding: 0;
  }
  .ProSettingsModal_title__XCcoH {
    display: none;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/LaunchBotPage/ProBotSettings/ProSettingsModal.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,MAAM;EACN,gBAAgB;EAChB,aAAa;EACb,WAAW;EACX,uBAAuB;EACvB,mBAAmB;EACnB,0BAA0B;EAC1B,UAAU;AACZ;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,SAAS;EACT,kBAAkB;EAClB,mBAAmB;EACnB,wCAAwC;EACxC,wCAAwC;EACxC,WAAW;AACb;;AAEA;EACE,aAAa;EACb,2BAA2B;EAC3B,WAAW;EACX,SAAS;EACT,YAAY;EACZ,mBAAmB;EACnB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE;IACE,kBAAkB;IAClB,uBAAuB;IACvB,gCAAwB;YAAxB,wBAAwB;IACxB,UAAU;IACV,yBAAyB;EAC3B;EACA;IACE,uBAAuB;IACvB,SAAS;IACT,UAAU;EACZ;EACA;IACE,aAAa;EACf;AACF","sourcesContent":[".container {\n  position: absolute;\n  top: 0;\n  padding: 100px 0;\n  display: flex;\n  width: 100%;\n  justify-content: center;\n  align-items: center;\n  background: rgb(10, 8, 23);\n  z-index: 5;\n}\n\n.modalContainer {\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 20px;\n  padding: 40px 60px;\n  border-radius: 10px;\n  border: 1px solid var(--border-white-02);\n  background: var(--black-background-card);\n  color: #fff;\n}\n\n.title {\n  display: flex;\n  justify-content: flex-start;\n  width: 100%;\n  margin: 0;\n  color: white;\n  font-family: PTSans;\n  font-size: 20px;\n  font-weight: 400;\n  line-height: 22px;\n}\n\n@media (max-width: 768px) {\n  .container {\n    position: relative;\n    background: transparent;\n    backdrop-filter: initial;\n    padding: 0;\n    width: calc(100vw - 32px);\n  }\n  .modalContainer {\n    background: transparent;\n    border: 0;\n    padding: 0;\n  }\n  .title {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ProSettingsModal_container__74ps9`,
	"modalContainer": `ProSettingsModal_modalContainer__JR+XV`,
	"title": `ProSettingsModal_title__XCcoH`
};
export default ___CSS_LOADER_EXPORT___;
