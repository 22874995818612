import { useEffect } from 'react';
import Presentation from './Presentation/Presentation';
import AboutUs from './AboutUs/AboutUs';
import GridBot from './GridBot/GridBot';
import HowItWorks from './HowItWorks/HowItWorks';
import BeFirst from './BeFirst/BeFirst';
import SmartTrading from './SmartTrading/SmartTrading';
import FutureOfProject from './FutureOfProject/FutureOfProject';
import Footer from './Footer/Footer';
import JoinBetaTesting from './JoinBetaTesting/JoinBetaTesting';
import { NavigateFunction, useLocation, useNavigate, Location } from 'react-router-dom';

import styles from './MainPage.module.css';
import Roadmap from './Roadmap/Roadmap';

const MainPage: React.FC = () => {
  const location: Location = useLocation();
  const navigate: NavigateFunction = useNavigate();
  const hasToken: boolean = !!sessionStorage.getItem('token');
  const stageCredentials: string | null = sessionStorage.getItem('credentials');

  const checkCredentials = () => {
    if (process.env.REACT_APP_IS_STAGING !== 'true') {
      sessionStorage.setItem('credentials', 'true');
      return true;
    }
    if (stageCredentials === 'true') {
      return true;
    }
    const password = prompt('Enter password');
    if (password === process.env.REACT_APP_PASSWORD) {
      sessionStorage.setItem('credentials', 'true');
      return true;
    }
    checkCredentials();
  };

  useEffect(() => {
    if (hasToken) {
      navigate('/bots/mybots');
    }
  }, []);

  useEffect(() => {
    const params: URLSearchParams = new URLSearchParams(location.search);
    const targetElement = params.get('scrollTo');

    if (targetElement) {
      const element: HTMLElement = document.getElementById(targetElement) as HTMLElement;
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location.search]);

  return (
    <>
      {checkCredentials() ? (
        <div className={styles.container}>
          <Presentation />
          <a href='#' className='up' />
          <AboutUs />
          <GridBot />
          <div className={styles.centerBlock}>
            <HowItWorks />
            <BeFirst />
          </div>
          <SmartTrading />
          <Roadmap />
          <FutureOfProject />
          <JoinBetaTesting />
          <Footer />
        </div>
      ) : null}
    </>
  );
};

export default MainPage;
