const currentDate = new Date();
const formatOption: Intl.DateTimeFormatOptions = {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
};
const today = new Intl.DateTimeFormat('it', formatOption).format(currentDate);
const WEEK_MILLISECONDS = 518400000;
const MONTH_MILLISECONDS = 2505600000;
const THREE_MONTHS_MILLISECONDS = 7689600000;
export const weekAgo = new Intl.DateTimeFormat('it', formatOption).format(
  Number(currentDate) - WEEK_MILLISECONDS,
);
export const monthAgo = new Intl.DateTimeFormat('it', formatOption).format(
  Number(currentDate) - MONTH_MILLISECONDS,
);
export const threeMonthAgo = new Intl.DateTimeFormat('it', formatOption).format(
  Number(currentDate) - THREE_MONTHS_MILLISECONDS,
);
export const ticksData: Record<string, string[]> = {
  '7': [weekAgo, today],
  '30': [monthAgo, today],
  '90': [threeMonthAgo, today],
};

export function generateDateValues(endDate: Date): { date: string; value: number }[] {
  const currentDate = new Date();
  const dateValues = [];
  const DECIMAL_PLACES = 2;
  while (currentDate >= endDate) {
    dateValues.push({
      date: new Intl.DateTimeFormat('it', formatOption).format(currentDate),
      value: +(Math.random() * 2 - 1).toFixed(DECIMAL_PLACES),
    });

    currentDate.setDate(currentDate.getDate() - 1);
  }

  return dateValues.reverse();
}

export const dataFor7days = generateDateValues(new Date(Number(currentDate) - WEEK_MILLISECONDS));

export const dataFor30days = generateDateValues(new Date(Number(currentDate) - MONTH_MILLISECONDS));

export const dataFor90days = generateDateValues(
  new Date(Number(currentDate) - THREE_MONTHS_MILLISECONDS),
);

export function getYTicks(data: { date: string | number; value: number }[]) {
  const QUARTER = 0.25;
  const THREE_QUARTERS = 0.75;
  const values = data.map((item) => item.value);
  const minValue = Math.min(...values);
  const maxValue = Math.max(...values);
  const averageValue = +(values.reduce((sum, value) => sum + value, 0) / values.length).toFixed(2);
  const yTicks = [
    minValue,
    maxValue,
    averageValue,
    0,
    +(minValue * QUARTER).toFixed(2),
    +(maxValue * THREE_QUARTERS).toFixed(2),
  ].sort((a, b) => a - b);
  return yTicks;
}
