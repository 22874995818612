import { ReactElement } from 'react';
import cn from 'classnames';
import styles from './BotCard.module.css';

interface IBotCardShape {
  children: ReactElement;
  isDisabled: boolean;
}

export default function BotCardShape({ children, isDisabled }: IBotCardShape) {
  return (
    <div className={cn([styles.container], { [styles.nonactiveContainer]: isDisabled })}>
      <div className={styles.cardShape}>
        <div className={styles.cardShape}>
          <div className={styles.shapeContent}>{children}</div>
        </div>
      </div>
    </div>
  );
}
