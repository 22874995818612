/* eslint-disable max-len */
export default function PlayIcon() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='18' height='17' viewBox='0 0 18 17' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.6124 1.81327C4.82979 1.69458 5.09464 1.70407 5.30299 1.838L14.7822 7.93175C14.976 8.05634 15.0931 8.27092 15.0931 8.5013C15.0931 8.73169 14.976 8.94627 14.7822 9.07085L5.30299 15.1646C5.09464 15.2985 4.82979 15.308 4.6124 15.1893C4.39501 15.0706 4.25977 14.8427 4.25977 14.5951V2.40755C4.25977 2.15987 4.39501 1.93195 4.6124 1.81327ZM5.61393 3.64774V13.3549L13.1639 8.5013L5.61393 3.64774Z'
        fill='white'
      />
    </svg>
  );
}
