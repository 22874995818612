import React from 'react';
import cn from 'classnames';
import styles from './Checkbox.module.css';

export enum ECheckboxVariants {
  primary = 'primary',
  secondary = 'secondary',
}

export interface ICheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  variant?: ECheckboxVariants;
}

const Checkbox: React.FC<ICheckboxProps> = ({
  label,
  variant = ECheckboxVariants.primary,
  className = '',
  id,
  name,
  ...props
}) => {
  const classNames = cn(
    className,
    { [styles.secondary]: variant === ECheckboxVariants.secondary },
    styles.container,
  );

  return (
    <label htmlFor={id} className={classNames}>
      <input id={id} name={name} type='checkbox' {...props} />
      <div>{label}</div>
    </label>
  );
};

export default Checkbox;
