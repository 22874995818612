import { createPortal } from 'react-dom';
import { useSelector } from 'react-redux';
import BotIcon, { EType } from '../BotIcon/BotIcon';
import { selectBot } from '../../../redux/selectors/rootSelector';
import PnlValue from '../BotsCard/PnlValue';
import StrategyBot from '../BotsCard/StrategyBot';
import RiskBot from '../BotsCard/RiskBot';
import RuntimeBot from '../BotsCard/RuntimeBot';
import CloseButton from '../../CloseButton/CloseButton';
import BibytIcon from '../../../assets/Logo/BibytIcon';
import backgroundImage from '../../../assets/share-card-background.svg';
import clockIcon from '../../../assets/clockIcon.svg';
import { download, email, discord, facebook, still, twitter, telegram } from './logos';
import styles from './ShareCard.module.css';
import QR_IMAGE from '../../../assets/img/QrGoogleAuth.png';

interface IShareCardProps {
  onClose: () => void;
}

const root: unknown = document.getElementById('root');

export default function ShareCard({ onClose }: IShareCardProps) {
  const selectedBot = useSelector(selectBot);

  return selectedBot !== null
    ? createPortal(
      <div className={styles.container}>
        <div className={styles.modalContainer}>
          <div className={styles.title}>
            <BotIcon icon={EType.ultraGrid} />
            {selectedBot?.strategy.toUpperCase().replace(' ', ' - ')}
          </div>
          <div className={styles.table}>
            <img src={backgroundImage} />
            <div className={styles.tableContent}>
              <div className={styles.tableKeys}>
                <div className={styles.tableKey}>APR:</div>
                <div className={styles.tableKey}>PNL:</div>
                <div className={styles.tableKey}>Pair:</div>
                <div className={styles.tableKey}>Strategy:</div>
                <div className={styles.tableKey}>Risk:</div>
              </div>
              <div className={styles.tableValues}>
                <div className={styles.tableValue}>
                  <PnlValue value={selectedBot.pnl} />
                </div>
                <div className={styles.tableValue}>{selectedBot.instrument}</div>
                <div className={styles.tableValue}>
                  <StrategyBot strategy={selectedBot?.strategyType} />
                </div>
                <div className={styles.tableValue}>
                  <RiskBot />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.runtime}>
            <img src={clockIcon} />
            <RuntimeBot runtime={selectedBot.uptime} />
          </div>
          <div className={styles.qrBlock}>
            <BibytIcon />
            <div className={styles.qrCopy}>
              <p>Scan the QR code to copy bot settings</p>
              <img src={QR_IMAGE} width={60} />
            </div>
          </div>
          <div className={styles.sharePlatforms}>
            <div className={styles.source}>
              <button>
                <img src={download} />
              </button>
                Download
            </div>
            <div className={styles.source}>
              <button>
                <img src={email} />
              </button>
                Email
            </div>
            <div className={styles.source}>
              <button>
                <img src={discord} />
              </button>
                Discord
            </div>
            <div className={styles.source}>
              <button>
                <img src={facebook} />
              </button>
                Facebook
            </div>
            <div className={styles.source}>
              <button>
                <img src={still} />
              </button>
                Still
            </div>
            <div className={styles.source}>
              <button>
                <img src={twitter} />
              </button>
                Twitter
            </div>
            <div className={styles.source}>
              <button>
                <img src={telegram} />
              </button>
                Telegram
            </div>
          </div>
          <button className={styles.closeBtn}>
            <CloseButton onClick={onClose} />
          </button>
        </div>
      </div>,
      root as HTMLElement,
    )
    : null;
}
