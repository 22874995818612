// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ChangeEmailModal_modalContainer__PY-oz {
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  position: fixed;
  background: rgb(10, 8, 23);
  z-index: 200;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ChangeEmailModal_modal__miVuE {
  position: relative;
  border-radius: 10px;
  border: 1px solid var(--border-white-02);
  background: var(--black-background-card);
  padding: 40px;
  padding-top: 24px;
  width: 432px;
  box-sizing: border-box;
}

.ChangeEmailModal_modal__miVuE h3 {
  margin: 0;
  margin-bottom: 32px;
  font-size: 28px;
  font-weight: 400;
}

.ChangeEmailModal_closeButton__OSWl\\+ {
  position: absolute;
  right: 20px;
  top: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.ChangeEmailModal_confirmBtn__TxFct {
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  margin-top: 36px;
  width: max-content;
  border-radius: 10px;
  background: var(--text-colored);
  color: black;
  font-family: PTSans;
  font-size: 16px;
  font-weight: 700;
}

.ChangeEmailModal_confirmBtn__TxFct:disabled {
  opacity: 0.5;
}

.ChangeEmailModal_input__xM43r {
  font-size: 16px;
}
.ChangeEmailModal_input__xM43r input {
  border-color: var(--card-background);
  background-color: rgba(255, 255, 255, 0.08);
}
`, "",{"version":3,"sources":["webpack://./src/components/SettingsPage/ChangeEmailModal/ChangeEmailModal.module.css"],"names":[],"mappings":"AAAA;EACE,MAAM;EACN,OAAO;EACP,YAAY;EACZ,aAAa;EACb,eAAe;EACf,0BAA0B;EAC1B,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,wCAAwC;EACxC,wCAAwC;EACxC,aAAa;EACb,iBAAiB;EACjB,YAAY;EACZ,sBAAsB;AACxB;;AAEA;EACE,SAAS;EACT,mBAAmB;EACnB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,SAAS;EACT,aAAa;EACb,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;EAChB,kBAAkB;EAClB,mBAAmB;EACnB,+BAA+B;EAC/B,YAAY;EACZ,mBAAmB;EACnB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,eAAe;AACjB;AACA;EACE,oCAAoC;EACpC,2CAA2C;AAC7C","sourcesContent":[".modalContainer {\n  top: 0;\n  left: 0;\n  width: 100vw;\n  height: 100vh;\n  position: fixed;\n  background: rgb(10, 8, 23);\n  z-index: 200;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.modal {\n  position: relative;\n  border-radius: 10px;\n  border: 1px solid var(--border-white-02);\n  background: var(--black-background-card);\n  padding: 40px;\n  padding-top: 24px;\n  width: 432px;\n  box-sizing: border-box;\n}\n\n.modal h3 {\n  margin: 0;\n  margin-bottom: 32px;\n  font-size: 28px;\n  font-weight: 400;\n}\n\n.closeButton {\n  position: absolute;\n  right: 20px;\n  top: 20px;\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n}\n\n.confirmBtn {\n  display: flex;\n  padding: 12px 24px;\n  justify-content: center;\n  align-items: center;\n  margin-top: 36px;\n  width: max-content;\n  border-radius: 10px;\n  background: var(--text-colored);\n  color: black;\n  font-family: PTSans;\n  font-size: 16px;\n  font-weight: 700;\n}\n\n.confirmBtn:disabled {\n  opacity: 0.5;\n}\n\n.input {\n  font-size: 16px;\n}\n.input input {\n  border-color: var(--card-background);\n  background-color: rgba(255, 255, 255, 0.08);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalContainer": `ChangeEmailModal_modalContainer__PY-oz`,
	"modal": `ChangeEmailModal_modal__miVuE`,
	"closeButton": `ChangeEmailModal_closeButton__OSWl+`,
	"confirmBtn": `ChangeEmailModal_confirmBtn__TxFct`,
	"input": `ChangeEmailModal_input__xM43r`
};
export default ___CSS_LOADER_EXPORT___;
