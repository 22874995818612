// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StepGroupOverview_container__JQOvA h4 {
  margin-top: 41px;
  font-weight: 400;
}

.StepGroupOverview_group_name__lgTut {
  margin-top: 18px;
  margin-left: 12px;
  width: max-content;
  font-weight: 400;
  color: rgb(255, 255, 255);
}

.StepGroupOverview_controls__STGJn {
  margin-left: -5px;
  margin-top: 48px;
}

.StepGroupOverview_controls__STGJn button {
  margin-left: 0px;
  margin-right: 57px;
  color: var(--text-colored-light);
  font-size: 16px;
  font-weight: 400;
  font-family: 'PTSans';
}

.StepGroupOverview_controls__STGJn button:nth-child(3) {
  font-size: 24px;
}

.StepGroupOverview_controls__STGJn button:nth-child(3) span {
  margin-left: 10px;
  font-size: 16px;
}

.StepGroupOverview_functionality__gD15D h4 {
  margin-top: 37px;
  margin-left: 7px;
  font-family: 'Jost';
  font-size: 23px;
  font-weight: 400;
  color: var(--text-colored-light);
}

.StepGroupOverview_features__9Qm8N {
  margin-top: 55px;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: flex-start;
  height: 323px;
  width: max-content;
}

.StepGroupOverview_features_item__XT1Qr {
  margin-bottom: 35px;
  margin-right: 27px;
}
`, "",{"version":3,"sources":["webpack://./src/components/AdminPage/CreateGroup/StepGroupOverview/StepGroupOverview.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;EAClB,gBAAgB;EAChB,yBAAyB;AAC3B;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,gCAAgC;EAChC,eAAe;EACf,gBAAgB;EAChB,qBAAqB;AACvB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,mBAAmB;EACnB,eAAe;EACf,gBAAgB;EAChB,gCAAgC;AAClC;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,2BAA2B;EAC3B,eAAe;EACf,uBAAuB;EACvB,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;EACnB,kBAAkB;AACpB","sourcesContent":[".container h4 {\n  margin-top: 41px;\n  font-weight: 400;\n}\n\n.group_name {\n  margin-top: 18px;\n  margin-left: 12px;\n  width: max-content;\n  font-weight: 400;\n  color: rgb(255, 255, 255);\n}\n\n.controls {\n  margin-left: -5px;\n  margin-top: 48px;\n}\n\n.controls button {\n  margin-left: 0px;\n  margin-right: 57px;\n  color: var(--text-colored-light);\n  font-size: 16px;\n  font-weight: 400;\n  font-family: 'PTSans';\n}\n\n.controls button:nth-child(3) {\n  font-size: 24px;\n}\n\n.controls button:nth-child(3) span {\n  margin-left: 10px;\n  font-size: 16px;\n}\n\n.functionality h4 {\n  margin-top: 37px;\n  margin-left: 7px;\n  font-family: 'Jost';\n  font-size: 23px;\n  font-weight: 400;\n  color: var(--text-colored-light);\n}\n\n.features {\n  margin-top: 55px;\n  margin-right: auto;\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  flex-wrap: wrap;\n  align-items: flex-start;\n  height: 323px;\n  width: max-content;\n}\n\n.features_item {\n  margin-bottom: 35px;\n  margin-right: 27px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `StepGroupOverview_container__JQOvA`,
	"group_name": `StepGroupOverview_group_name__lgTut`,
	"controls": `StepGroupOverview_controls__STGJn`,
	"functionality": `StepGroupOverview_functionality__gD15D`,
	"features": `StepGroupOverview_features__9Qm8N`,
	"features_item": `StepGroupOverview_features_item__XT1Qr`
};
export default ___CSS_LOADER_EXPORT___;
