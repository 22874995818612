import React, { useState, useRef, useEffect } from 'react';
import styles from './QrCodeModal.module.css';
import CloseButton from '../../CloseButton/CloseButton';
import ValidateIcon from '../../../assets/invalidinput-icon.svg';
import CopyIcon from '../../../assets/QrCodeModal/copy-icon.svg';
import { useSendCodeMutation } from '../../../redux/services/settingSecurityService';
import { useCurrentUserProfileQuery } from '../../../redux/services/settingProfileService';
import QR_IMAGE from '../../../assets/img/QrGoogleAuth.png'; // todo: delete when backend is ready
import { Input } from '../../CCLabsUI';
import { ESizing } from '../../CCLabsUI/Input/Input';
import { useTranslation } from 'react-i18next';
interface IQrCodeModal {
  isOpen?: boolean;
  isSendCode?: boolean;
  inputValue?: string;
  secretCode: string;
  qrImage: string;
  children?: JSX.Element | JSX.Element[];
  actionClose: () => void;
  actionSwitchGoogle: () => void;
}

const MOCK_CODE_PHRASE = 'XRC6UZPFVHDQ36NE';

const QrCodeModal: React.FC<IQrCodeModal> = (props) => {
  const [codeValue, setCodeValue] = useState('');
  const [sendCode] = useSendCodeMutation();
  const [secretCodeValue, setSecretCodeValue] = useState(''); // eslint-disable-line
  const [isCodeError, setIsCodeError] = useState(false);
  const { data: userProfile } = useCurrentUserProfileQuery();
  const { t } = useTranslation('profilePage');
  const timeout: number = 2000;
  const inputSecretRef: React.RefObject<HTMLInputElement> = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isCodeError) {
      setTimeout(() => setIsCodeError(false), timeout);
    }
  }, [isCodeError]);

  const onSubmitQrCode = async () => {
    const mockedCodeValue = '123456'; // todo: delete when backend is ready
    const isMockedMode = codeValue === mockedCodeValue;
    if (isMockedMode) {
      props.actionSwitchGoogle();
      props.actionClose();
      return;
    }

    if (!userProfile?.userId) {
      return;
    }
    try {
      const dataSendCode = await sendCode({
        userId: userProfile?.userId,
        twoFactorAuthenticationCode: codeValue,
      }).unwrap();

      if (dataSendCode.token) {
        props.actionSwitchGoogle();
        props.actionClose();
      }
    } catch (error) {
      const { status } = error as { status: string }; // todo: add status to enum
      if (status === 'FETCH_ERROR') {
        setIsCodeError(true);
      }
    }
  };

  const copyToClipboard = () => {
    if (inputSecretRef.current) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      navigator.clipboard.writeText(inputSecretRef.current.value);
    }
  };

  return (
    <div className={styles.modalContainer}>
      <div className={styles.modal}>
        <div className={styles.closeButton}>
          <CloseButton onClick={props.actionClose} />
        </div>
        <div className={styles.textBlock}>
          <div className={styles.textBlockRow}>
            <div className={styles.textBlockNumber}>1.</div>
            <div className={styles.description}>
              <div className={styles.textHeaderFirst}>
                <div className={styles.title}>{t('authForm.title1')}</div>
              </div>
              <div className={styles.descriptionText}>{t('authForm.description1')}</div>
            </div>
          </div>
          <div className={styles.textBlockRow}>
            <div className={styles.textBlockNumber}>2.</div>
            <div className={styles.description}>
              <div className={styles.textHeaderSecond}>
                <div className={styles.title}>
                  <p>{t('authForm.title2.p1')}</p>
                  <p>{t('authForm.title2.p2')}</p>
                </div>
              </div>
              <div className={styles.descriptionText}>
                <p>{t('authForm.description2')}</p>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.hint}>{t('authForm.hint')}</div>
        <div className={styles.googleAuth}>
          <form action='submit' className={styles.googleAuthForm}>
            <div className={styles.copyBlock}>
              <Input
                label=''
                sizing={ESizing.medium}
                value={props.secretCode || MOCK_CODE_PHRASE}
                onChange={(e) => setSecretCodeValue(e.target.value)}
              />
              <button type='button' className={styles.copyButton} onClick={copyToClipboard}>
                <img src={CopyIcon} />
              </button>
            </div>
            <Input
              label={t('authForm.confirmCode')}
              sizing={ESizing.medium}
              id='confirmCode'
              placeholder='Enter Google confirmation code'
              value={props.secretCode}
              onChange={(e) => setCodeValue(e.target.value)}
            />
            <button type='button' onClick={onSubmitQrCode} className={styles.confirmBtn}>
              {t('securityCheck.confirmBtn')}
            </button>
          </form>
          <img src={QR_IMAGE} className={styles.qrCodeImage} />
        </div>
        <div className={styles.validateInputBlock}>
          {isCodeError && (
            <div className={styles.validateInput}>
              <img src={ValidateIcon} />
              <div className={styles.validateInputText}>Incorrect code</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default QrCodeModal;
