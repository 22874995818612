// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MessageModalWindow_container__jUr-w {
  position: absolute;
  top: 0;
  padding: 0;
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
  z-index: 100;
}

.MessageModalWindow_modal__uN9UR {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  padding-right: 70px;
  border-radius: 10px;
  border: 1px solid var(--border-white-02);
  background: var(--black-background-card);
  color: white;
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
}

.MessageModalWindow_closeButton__T0O09 {
  position: absolute;

  top: 16px;
  right: 43px;
}
@media (max-width: 768px) {
  .MessageModalWindow_container__jUr-w {
    top: 50%;
    transform: translateY(-50%);
    width: calc(100vw - 32px);
    left: 16px;
  }
  .MessageModalWindow_modal__uN9UR {
    box-sizing: border-box;
    width: 100%;
    padding: 32px 24px 36px 24px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/ForgotPasswordModal/MessageModalWindow/MessageModalWindow.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,MAAM;EACN,UAAU;EACV,aAAa;EACb,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,mCAA2B;UAA3B,2BAA2B;EAC3B,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,wCAAwC;EACxC,wCAAwC;EACxC,YAAY;EACZ,eAAe;EACf,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;;EAElB,SAAS;EACT,WAAW;AACb;AACA;EACE;IACE,QAAQ;IACR,2BAA2B;IAC3B,yBAAyB;IACzB,UAAU;EACZ;EACA;IACE,sBAAsB;IACtB,WAAW;IACX,4BAA4B;EAC9B;AACF","sourcesContent":[".container {\n  position: absolute;\n  top: 0;\n  padding: 0;\n  display: flex;\n  width: 100vw;\n  height: 100vh;\n  justify-content: center;\n  align-items: center;\n  backdrop-filter: blur(20px);\n  z-index: 100;\n}\n\n.modal {\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 24px;\n  padding-right: 70px;\n  border-radius: 10px;\n  border: 1px solid var(--border-white-02);\n  background: var(--black-background-card);\n  color: white;\n  font-size: 16px;\n  font-weight: 400;\n  line-height: 160%;\n}\n\n.closeButton {\n  position: absolute;\n\n  top: 16px;\n  right: 43px;\n}\n@media (max-width: 768px) {\n  .container {\n    top: 50%;\n    transform: translateY(-50%);\n    width: calc(100vw - 32px);\n    left: 16px;\n  }\n  .modal {\n    box-sizing: border-box;\n    width: 100%;\n    padding: 32px 24px 36px 24px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `MessageModalWindow_container__jUr-w`,
	"modal": `MessageModalWindow_modal__uN9UR`,
	"closeButton": `MessageModalWindow_closeButton__T0O09`
};
export default ___CSS_LOADER_EXPORT___;
