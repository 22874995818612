import { EChanel, INotification } from './Notification.types';

export const NOTIFICATIONS: INotification[] = [
  {
    id: 1,
    title: 'Уведомление 1',
    message: 'Сообщение 1 Сообщение Сообщение Сообщение ',
    channels: [EChanel.telegram, EChanel.email, EChanel.app],
    date: new Date('2023-08-13T04:13:28.057Z'),
    opened: 24,
    read: 9,
  },
  {
    id: 2,
    title: 'Уведомление 2',
    message: 'Сообщение 2',
    date: new Date('2023-08-04T05:26:16.512Z'),
    channels: [EChanel.telegram, EChanel.email, EChanel.app],
    opened: 20,
    read: 28,
  },
  {
    id: 3,
    title: 'Уведомление 3',
    message: 'Сообщение 3',
    date: new Date('2023-09-02T05:22:10.002Z'),
    channels: [EChanel.telegram, EChanel.email, EChanel.app],
    opened: 16,
    read: 23,
  },
  {
    id: 4,
    title: 'Уведомление 4',
    message: 'Сообщение 4',
    date: new Date('2023-08-19T01:55:06.019Z'),
    channels: [EChanel.telegram, EChanel.email, EChanel.app],
    opened: 10,
    read: 28,
  },
  {
    id: 5,
    title: 'Уведомление 5',
    message: 'Сообщение 5',
    date: new Date('2023-08-21T17:33:34.235Z'),
    channels: [EChanel.telegram, EChanel.email, EChanel.app],
    opened: 8,
    read: 28,
  },
  {
    id: 6,
    title: 'Уведомление 6',
    message: 'Сообщение 6',
    date: new Date('2023-08-02T10:02:45.250Z'),
    channels: [EChanel.telegram, EChanel.email, EChanel.app],
    opened: 26,
    read: 16,
  },
  {
    id: 7,
    title: 'Уведомление 7',
    message: 'Сообщение 7',
    date: new Date('2023-08-16T16:05:27.765Z'),
    channels: [EChanel.telegram, EChanel.email, EChanel.app],
    opened: 17,
    read: 9,
  },
  {
    id: 8,
    title: 'Уведомление 8',
    message: 'Сообщение 8',
    date: new Date('2023-08-22T15:36:00.689Z'),
    channels: [EChanel.telegram, EChanel.email, EChanel.app],
    opened: 27,
    read: 17,
  },
  {
    id: 9,
    title: 'Уведомление 9',
    message: 'Сообщение 9',
    date: new Date('2023-08-23T08:15:19.535Z'),
    channels: [EChanel.telegram, EChanel.email, EChanel.app],
    opened: 16,
    read: 22,
  },
  {
    id: 10,
    title: 'Уведомление 10',
    message: 'Сообщение 10',
    date: new Date('2023-08-25T07:56:09.049Z'),
    channels: [EChanel.telegram, EChanel.email, EChanel.app],
    opened: 13,
    read: 18,
  },
  {
    id: 11,
    title: 'Уведомление 11',
    message: 'Сообщение 11',
    date: new Date('2023-08-03T04:13:35.076Z'),
    channels: [EChanel.telegram, EChanel.email, EChanel.app],
    opened: 1,
    read: 17,
  },
  {
    id: 12,
    title: 'Уведомление 12',
    message: 'Сообщение 12',
    date: new Date('2023-08-06T05:43:42.526Z'),
    channels: [EChanel.telegram, EChanel.email, EChanel.app],
    opened: 29,
    read: 12,
  },
  {
    id: 13,
    title: 'Уведомление 13',
    message: 'Сообщение 13',
    date: new Date('2023-08-12T06:11:03.688Z'),
    channels: [EChanel.telegram, EChanel.email, EChanel.app],
    opened: 25,
    read: 18,
  },
  {
    id: 14,
    title: 'Уведомление 14',
    message: 'Сообщение 14',
    date: new Date('2023-08-12T01:10:44.114Z'),
    channels: [EChanel.telegram, EChanel.email, EChanel.app],
    opened: 5,
    read: 23,
  },
  {
    id: 15,
    title: 'Уведомление 15',
    message: 'Сообщение 15',
    date: new Date('2023-08-09T03:29:21.588Z'),
    channels: [EChanel.telegram, EChanel.email, EChanel.app],
    opened: 2,
    read: 11,
  },
  {
    id: 16,
    title: 'Уведомление 16',
    message: 'Сообщение 16',
    date: new Date('2023-08-08T15:35:09.690Z'),
    channels: [EChanel.telegram, EChanel.email, EChanel.app],
    opened: 23,
    read: 7,
  },
  {
    id: 17,
    title: 'Уведомление 17',
    message: 'Сообщение 17',
    date: new Date('2023-09-02T11:20:20.577Z'),
    channels: [EChanel.telegram, EChanel.email, EChanel.app],
    opened: 15,
    read: 3,
  },
  {
    id: 18,
    title: 'Уведомление 18',
    message: 'Сообщение 18',
    date: new Date('2023-08-15T09:16:59.574Z'),
    channels: [EChanel.telegram, EChanel.email, EChanel.app],
    opened: 30,
    read: 17,
  },
  {
    id: 19,
    title: 'Уведомление 19',
    message: 'Сообщение 19',
    date: new Date('2023-08-20T16:04:39.474Z'),
    channels: [EChanel.telegram, EChanel.email, EChanel.app],
    opened: 3,
    read: 19,
  },
  {
    id: 20,
    title: 'Уведомление 20',
    message: 'Сообщение 20',
    date: new Date('2023-08-06T17:02:43.633Z'),
    channels: [EChanel.telegram, EChanel.email, EChanel.app],
    opened: 19,
    read: 27,
  },
  {
    id: 21,
    title: 'Уведомление 21',
    message: 'Сообщение 21',
    date: new Date('2023-08-30T22:55:31.073Z'),
    channels: [EChanel.telegram, EChanel.email, EChanel.app],
    opened: 2,
    read: 14,
  },
  {
    id: 22,
    title: 'Уведомление 22',
    message: 'Сообщение 22',
    date: new Date('2023-08-11T17:28:07.681Z'),
    channels: [EChanel.telegram, EChanel.email, EChanel.app],
    opened: 28,
    read: 11,
  },
  {
    id: 23,
    title: 'Уведомление 23',
    message: 'Сообщение 23',
    date: new Date('2023-08-25T06:32:56.964Z'),
    channels: [EChanel.telegram, EChanel.email, EChanel.app],
    opened: 17,
    read: 20,
  },
  {
    id: 24,
    title: 'Уведомление 24',
    message: 'Сообщение 24',
    date: new Date('2023-08-04T22:16:29.754Z'),
    channels: [EChanel.telegram, EChanel.email, EChanel.app],
    opened: 13,
    read: 25,
  },
  {
    id: 25,
    title: 'Уведомление 25',
    message: 'Сообщение 25',
    date: new Date('2023-08-28T18:36:52.430Z'),
    channels: [EChanel.telegram, EChanel.email, EChanel.app],
    opened: 28,
    read: 22,
  },
  {
    id: 26,
    title: 'Уведомление 26',
    message: 'Сообщение 26',
    date: new Date('2023-08-11T16:15:54.320Z'),
    channels: [EChanel.telegram, EChanel.email, EChanel.app],
    opened: 18,
    read: 27,
  },
  {
    id: 27,
    title: 'Уведомление 27',
    message: 'Сообщение 27',
    date: new Date('2023-08-27T11:32:38.308Z'),
    channels: [EChanel.telegram, EChanel.email, EChanel.app],
    opened: 21,
    read: 15,
  },
  {
    id: 28,
    title: 'Уведомление 28',
    message: 'Сообщение 28',
    date: new Date('2023-08-04T21:26:15.255Z'),
    channels: [EChanel.telegram, EChanel.email, EChanel.app],
    opened: 2,
    read: 28,
  },
  {
    id: 29,
    title: 'Уведомление 29',
    message: 'Сообщение 29',
    date: new Date('2023-08-05T03:46:50.345Z'),
    channels: [EChanel.telegram, EChanel.email, EChanel.app],
    opened: 5,
    read: 20,
  },
  {
    id: 30,
    title: 'Уведомление 30',
    message: 'Сообщение 30',
    date: new Date('2023-09-02T13:49:17.061Z'),
    channels: [EChanel.telegram, EChanel.email, EChanel.app],
    opened: 16,
    read: 18,
  },
  {
    id: 31,
    title: 'Уведомление 31',
    message: 'Сообщение 31',
    date: new Date('2023-08-29T12:00:34.417Z'),
    channels: [EChanel.telegram, EChanel.email, EChanel.app],
    opened: 2,
    read: 6,
  },
  {
    id: 32,
    title: 'Уведомление 32',
    message: 'Сообщение 32',
    date: new Date('2023-08-23T07:39:44.270Z'),
    channels: [EChanel.telegram, EChanel.email, EChanel.app],
    opened: 15,
    read: 28,
  },
  {
    id: 33,
    title: 'Уведомление 33',
    message: 'Сообщение 33',
    date: new Date('2023-08-31T17:48:21.322Z'),
    channels: [EChanel.telegram, EChanel.email, EChanel.app],
    opened: 22,
    read: 17,
  },
  {
    id: 34,
    title: 'Уведомление 34',
    message: 'Сообщение 34',
    date: new Date('2023-09-01T18:34:33.290Z'),
    channels: [EChanel.telegram, EChanel.email, EChanel.app],
    opened: 16,
    read: 3,
  },
  {
    id: 35,
    title: 'Уведомление 35',
    message: 'Сообщение 35',
    date: new Date('2023-08-26T17:50:47.469Z'),
    channels: [EChanel.telegram, EChanel.email, EChanel.app],
    opened: 16,
    read: 19,
  },
  {
    id: 36,
    title: 'Уведомление 36',
    message: 'Сообщение 36',
    date: new Date('2023-08-05T13:03:47.725Z'),
    channels: [EChanel.telegram, EChanel.email, EChanel.app],
    opened: 7,
    read: 5,
  },
  {
    id: 37,
    title: 'Уведомление 37',
    message: 'Сообщение 37',
    date: new Date('2023-09-01T14:05:11.371Z'),
    channels: [EChanel.telegram, EChanel.email, EChanel.app],
    opened: 7,
    read: 12,
  },
  {
    id: 38,
    title: 'Уведомление 38',
    message: 'Сообщение 38',
    date: new Date('2023-08-23T21:07:50.285Z'),
    channels: [EChanel.telegram, EChanel.email, EChanel.app],
    opened: 13,
    read: 30,
  },
  {
    id: 39,
    title: 'Уведомление 39',
    message: 'Сообщение 39',
    date: new Date('2023-08-30T10:29:03.297Z'),
    channels: [EChanel.telegram, EChanel.email, EChanel.app],
    opened: 27,
    read: 26,
  },
  {
    id: 40,
    title: 'Уведомление 40',
    message: 'Сообщение 40',
    date: new Date('2023-08-04T21:55:05.291Z'),
    channels: [EChanel.telegram, EChanel.email, EChanel.app],
    opened: 2,
    read: 30,
  },
];
