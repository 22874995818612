/* eslint-disable max-len */
import React from 'react';
import styles from './SmartTrading.module.css';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

const SmartTrading: React.FC = () => {
  const { t, i18n } = useTranslation('smartTrading');
  const isEnglish = i18n.language === 'en';
  const assetSelectionStyles = cn(styles.asset_selection, { [styles.en]: isEnglish });
  const wrapperStyles = cn(styles.wrapper, { [styles.wrapper_ru]: !isEnglish });
  const containerStyle = cn(styles.container, { [styles.container_ru]: !isEnglish });

  return (
    <div className={containerStyle}>
      <h2>{t('title')}</h2>
      <div className={wrapperStyles}>
        <div className={assetSelectionStyles}>{t('feature1')}</div>
        <div className={styles.investing}>{t('feature2')}</div>
      </div>
    </div>
  );
};

export default SmartTrading;
