import React, { memo } from 'react';
import CloseButton from '../../CloseButton/CloseButton';

import styles from './ConfirmLaunchModal.module.css';
import TitleCard from '../../BotsPage/BotSettings/Blocks/TitleCard';
import InputSettings from '../../BotsPage/BotSettings/InputSettings/InputSettings';
import { useTranslation } from 'react-i18next';

interface IConfirmLaunchModal {
  onClose: () => void;
  onLaunch?: () => void;
}

function ConfirmLaunchModal({ onClose, onLaunch }: IConfirmLaunchModal) {
  const { t } = useTranslation('launchBotModal');
  return (
    <div className={styles.container}>
      <div className={styles.modalContainer}>
        <CloseButton onClick={onClose} className={styles.closeBtn} />
        <h3 className={styles.title}>{t('title')}</h3>
        <div className={styles.block}>
          <h4>{t('subtitle')}</h4>
          <p>{t('description1')}</p>
          <p>{t('description2')}</p>
        </div>
        <div className={styles.examples}>
          <div className={styles.example}>
            <TitleCard title={t('qtyEntryOrder')} />
            <InputSettings value={100} isPercentVisible={false} readonly={true} />
          </div>
          <div className={styles.example}>
            <TitleCard title={t('maxCount')} />
            <InputSettings value={50} isPercentVisible={false} readonly={true} />
          </div>
        </div>
        <div className={styles.block}>
          <p>{t('soon')}</p>
          <h4>{t('descriptionAccept')}</h4>
          <p>{t('goBack')}</p>
        </div>
        <div className={styles.buttonsBlock}>
          <button className={styles.backBtn} onClick={onClose}>
            {t('backBtn')}
          </button>
          <button className={styles.launchBtn} onClick={onLaunch}>
            {t('launchBtn')}
          </button>
        </div>
      </div>
    </div>
  );
}

export default memo(ConfirmLaunchModal);
