import { memo, useState } from 'react';
import { createPortal } from 'react-dom';

import styles from '../PasswordUpdate/PasswordUpdate.module.css';
import CloseButton from '../../CloseButton/CloseButton';
import UpdateForm from './UpdateForm/UpdateForm';
import ConfirmCredsChangeModal from '../ConfirmCredsChangeModal/ConfirmCredsChangeModal';
import SecurityCheckWarningModal from '../SecurityCheckWarningModal/SecurityCheckWarningModal';
import { useCurrentUserEmailQuery } from '../../../redux/services/settingProfileService';
import getHiddenEmail from '../../../utils/getHiddenEmail';
import useChangePassword from '../../../hooks/requests/useChangePassword';

const root: unknown = document.getElementById('root');
interface IPasswordUpdate {
  onClose: () => void;
}

const PasswordUpdate = ({ onClose }: IPasswordUpdate): JSX.Element => {
  const sessionId = sessionStorage.getItem('session_id');
  const token = sessionStorage.getItem('token');

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmedPassword, setConfirmedPassword] = useState('');
  const [email, setEmail] = useState('');
  const [operationId, setOperationId] = useState<string | null>(null);
  const [isSecurityCheckModalVisible, setIsSecurityCheckModalVisible] = useState(false);
  const [isChangeEmailModalVisible, setIsChangeEmailModalVisible] = useState(false);

  const [changePassword, submitVerificationCode] = useChangePassword();
  const { data, refetch } = useCurrentUserEmailQuery({ sessionId, token });
  const hiddenEmail = getHiddenEmail(data?.mail);

  const onSubmitForm = () => {
    setIsSecurityCheckModalVisible(true);
  };

  const onCloseSecurityCheck = () => {
    setIsSecurityCheckModalVisible(false);
  };

  const onCloseConfirmEmailChangeModal = () => {
    setIsChangeEmailModalVisible(false);
  };

  const onSubmitVerificationCode = async (code: string) => {
    if (operationId) {
      const res = await submitVerificationCode(code, operationId, currentPassword);
      await refetch();
      return res;
    }
    return null;
  };

  const onPressSendCode = async () => {
    const operaionId = await changePassword(newPassword, currentPassword);
    setIsSecurityCheckModalVisible(false);
    if (operaionId) {
      setOperationId(operaionId);
      setIsChangeEmailModalVisible(true);
    }
  };


  return createPortal(
    <div className={styles.container}>
      <div className={styles.passwordUpdateContent}>
        <CloseButton onClick={onClose} className={styles.closeBtn} />
        <UpdateForm
          email={email}
          currentPassword={currentPassword}
          newPassword={newPassword}
          confirmedPassword={confirmedPassword}
          setEmail={setEmail}
          setCurrentPassword={setCurrentPassword}
          setNewPassword={setNewPassword}
          setConfirmedPassword={setConfirmedPassword}
          onSubmitForm={onSubmitForm}
        />

        {isSecurityCheckModalVisible && (
          <SecurityCheckWarningModal
            currentEmail={hiddenEmail ?? ''}
            actionClose={onCloseSecurityCheck}
            actionSubmit={onPressSendCode}
          />
        )}
        {isChangeEmailModalVisible && (
          <ConfirmCredsChangeModal
            email={hiddenEmail}
            credType="password"
            actionClose={onCloseConfirmEmailChangeModal}
            actionSubmit={onSubmitVerificationCode}
            actionResend={onPressSendCode}
          />
        )}
      </div>
    </div>,
    root as HTMLElement,
  );
};

export default memo(PasswordUpdate);
