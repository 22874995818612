/* eslint-disable @typescript-eslint/no-floating-promises */
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Pagination from '../../UsersListPage/Pagination/Pagination';
import UserListItem from '../../UsersListPage/UserListItem/UserListItem';
import HeaderList from '../../UsersListPage/UsersList/HeaderList';
import styles from './StepChooseUser.module.css';
import { IUser } from '../../UsersListPage/UsersList/UserList.types';
import { getArrayIndexes } from '../../UsersListPage/Pagination/pagination.utils';
import { useGetWaitListUsersMutation } from '../../../../redux/services/waitListService';
import { createNewUserList } from '../utils';

interface IStepChooseUsers {
  groupName: string;
  userList: IUser[];
  setUserList: Dispatch<SetStateAction<IUser[]>>;
  selectedUsers: IUser[];
  setSelectedUsers: Dispatch<SetStateAction<IUser[]>>;
}

const StepChooseUsers = ({ groupName, userList, selectedUsers, setUserList, setSelectedUsers }: IStepChooseUsers) => {
  const sessionId = sessionStorage.getItem('session_id');
  const token = sessionStorage.getItem('token');
  const [requestWaitListUsers] = useGetWaitListUsersMutation();

  const [currentPage, setCurrentPage] = useState(1);
  const [responseError, setResponseError] = useState<string | null>(null);

  const [totalPages, indexOfFirstUser, indexOfLastUser] = getArrayIndexes(userList, currentPage);
  const currentUsers: IUser[] = userList.slice(indexOfFirstUser, indexOfLastUser);

  const isUserSelected = (user: IUser) => selectedUsers.some(({ uid }) => uid === user.uid);

  const onUserClick = (user: IUser) => () => {
    const newSelectedUsers = isUserSelected(user)
      ? selectedUsers.filter(({ uid }) => uid !== user.uid)
      : [...selectedUsers, user];

    setSelectedUsers(newSelectedUsers);
  };

  const getSelectedUserStyle = (user: IUser) => isUserSelected(user) ? styles.selected : '';

  useEffect(() => {
    const requestAsyncWaitListUsers = async () => {
      try {
        const response = await requestWaitListUsers({ sessionId, token }).unwrap();
        const newUserList = !!userList.length
          ? userList
          : createNewUserList(response);
        setUserList(newUserList);

        console.log('waitlist response: ', response);
        setResponseError(null);
      } catch (e) {
        console.log('error waitList: ', e);
        const { data } = e as { data: { detail: string}};
        setResponseError(`responseError: ${data.detail}`);
      }
    };

    requestAsyncWaitListUsers();
  }, []);

  return (
    <div className={styles.container}>
      <div>
        <h4>Group name</h4>
        <div className={styles.group_name}>{groupName}</div>
      </div>
      <h3 className={styles.step_title}>Select users</h3>
      <div className={styles.list}>
        <HeaderList setUsers={setUserList} />
        {currentUsers.map((user) => (
          <div className={getSelectedUserStyle(user)} key={user.uid} onClick={onUserClick(user)}>
            <UserListItem user={user} />
          </div>
        ))}
      </div>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
      {!!responseError && <div className={styles.error}>{responseError}</div>}
    </div>
  );
};

export default StepChooseUsers;
