import cn from 'classnames';
import PauseIcon from '../../../assets/General/PauseIcon';
import CopyIcon from '../../../assets/General/CopyIcon';
import StopIcon from '../../../assets/General/StopIcon';
import PlayIcon from '../../../assets/General/PlayIcon';
import TrashIcon from '../../../assets/General/TrashIcon';
import styles from './BotsCard.module.css';
import { EFlowStatus, IBot } from '../../../redux/types';
import { useAppDispatch } from '../../../redux/hooks/redux';
import { EBotActions, selectBot, setBotAction } from '../../../redux/slices/botsSlice';

interface ActionsProps {
  flowStatus: EFlowStatus;
  isInfoBlockOpened: boolean;
  bot?: IBot;
}

export default function ActionsBlock({ flowStatus, isInfoBlockOpened, bot }: ActionsProps) {
  const dispatch = useAppDispatch();

  const setCurrentBotInfo = (currentAction: EBotActions) => {
    dispatch(selectBot(bot as IBot));
    dispatch(setBotAction(currentAction));
  };

  const setBotActive = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setCurrentBotInfo(EBotActions.run);
  };
  const setBotPaused = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
  };
  const setBotSuspend = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
  };
  const onDeleteBot = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setCurrentBotInfo(EBotActions.delete);
  };

  const onCopySettings = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
  };

  const activeStatuses = [EFlowStatus.runAuto, EFlowStatus.runAutoConfig, EFlowStatus.runPro];

  const isActive = activeStatuses.includes(flowStatus);
  const isPaused = flowStatus === EFlowStatus.pausedRequest;
  return (
    <div className={cn([styles.actionsBtn], { [styles.actionsBtnShort]: isInfoBlockOpened })}>
      {isActive ? (
        <button onClick={setBotPaused} className={styles.pauseBtn}>
          <PauseIcon />
        </button>
      ) : (
        <button onClick={setBotActive} className={styles.playBtn}>
          <PlayIcon />
        </button>
      )}
      <button className={styles.copyBtn} onClick={onCopySettings} disabled={true}>
        <CopyIcon />
      </button>
      {isActive || isPaused ? (
        <button onClick={setBotSuspend} className={styles.stopIcon}>
          <StopIcon />
        </button>
      ) : (
        <button onClick={onDeleteBot} className={styles.stopIcon}>
          <TrashIcon />
        </button>
      )}
    </div>
  );
}
