export const camelToSnakeCase = (camelValue: string): string => {
  return camelValue.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
};

export const formatCamelObjKeysToSnakeCase = (obj: object): object =>
  Object.entries(obj).reduce(
    (snakeCaseObj, [key, value]) => ({
      ...snakeCaseObj,
      [camelToSnakeCase(key)]: value,
    }),
    {},
  );

export const formatDeepCamelObjKeysToSnakeCase = (node: unknown): unknown => {
  const isNodeArray = Array.isArray(node);
  if (isNodeArray) {
    return node.map((item) => formatDeepCamelObjKeysToSnakeCase(item));
  }

  const isNodeObject = typeof node === 'object' && node !== null;
  if (isNodeObject) {
    return Object.entries(node).reduce(
      (snakeCaseObj, [key, value]) => ({
        ...snakeCaseObj,
        [camelToSnakeCase(key)]: formatDeepCamelObjKeysToSnakeCase(value),
      }),
      {},
    );
  }

  return node;
};
