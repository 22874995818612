import { useState, useEffect } from 'react';
import { EMode } from '../BotsStatisticPage/BotsListStatistic/BotCard/BotCard';
import {
  EExchange,
  EMarket,
} from '../LaunchBotPage/Leaderboard/ListLeaderboard/ListLeaderboard.types';
import Dropdown, { ESizing } from './Dropdown/Dropdown';
import { Exchanges } from '../LaunchBotPage/Leaderboard/ListLeaderboard/ExchangesIcons';
import { ECurrency } from './PairFilter/pair.type';
import FilterIcon from '../../assets/filter-icon.svg';
import styles from './Filter.module.css';
import SortArrow from '../../assets/General/SortArrow';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { emptyFn } from '../../constants';
import { renderUppercasedOption } from '../LaunchBotPage/BotSetupHeader/utils';
import { EStrategyType, IBot } from '../../redux/types';
import RiskBot, { ERisk } from '../BotsPage/BotsCard/RiskBot';
import StrategyBot from '../BotsPage/BotsCard/StrategyBot';

interface IFilter {
  isCompact?: boolean;
  size?: ESizing;
  calendar?: boolean;
  bots?: IBot[];
  setBots?: React.Dispatch<React.SetStateAction<IBot[]>>;
  needToClear?: boolean;
  setNeedToClear?: React.Dispatch<boolean>;
  setNeedUpdateSorting?: React.Dispatch<React.SetStateAction<boolean>>;
}

const renderExchangesOptions = (exchange: string) => exchange && Exchanges[exchange];

const renderRiskOption = (risk: ERisk) => {
  return (
    <div className={styles.riskWrapper}>
      <RiskBot enableIcon={false} risk={risk} />
    </div>
  );
};

const renderTypeOption = (option: string) =>
  option === 'STRATEGY_GRID_TRONIK' ? 'Ultra GRID Bot' : renderUppercasedOption(option);

export default function Filter({
  isCompact = false,
  size = ESizing.large,
  bots = [],
  setBots = emptyFn,
  needToClear = false,
  setNeedToClear = emptyFn,
  setNeedUpdateSorting = emptyFn,
}: IFilter) {
  const { t } = useTranslation('filter');
  const [mode, setMode] = useState<EMode>();
  const [strategy, setStrategy] = useState<EStrategyType | string>();
  const [exchange, setExchange] = useState<EExchange>();
  const [pair, setPair] = useState<ECurrency | string>();
  const [marketType, setMarketType] = useState<EMarket | string>();
  const [type, setType] = useState<string>();
  const [isShowDropdowns, setIsShowDropdowns] = useState(false);
  const toggleDropdowns = () => setIsShowDropdowns((state) => !state);
  const isShownCompactDropdowns = isShowDropdowns && isCompact;
  const isShownDropDowns = isShownCompactDropdowns || !isCompact;

  const getOptions = (optionsName: keyof IBot) =>
    Array.from(new Set(bots.map((bot) => bot[optionsName])));
  const modeOptions = getOptions('mode') as EMode[];
  const exchangesOptions = getOptions('exchangeType') as EExchange[];
  const defaultStrategyOptions = getOptions('strategyType') as string[];
  const haveBotsUseReverse = bots.some((bot) => bot.useReverse);
  const strategyOptions = haveBotsUseReverse
    ? ([...defaultStrategyOptions, 'TrendFlipper'] as string[])
    : defaultStrategyOptions;
  const pairsOptions = getOptions('instrument') as string[];
  const typeOptions = getOptions('strategy') as string[];

  const filters = {
    mode,
    exchangeType: exchange,
    marketType,
    strategyType: strategy,
    strategy: type,
    instrument: pair,
  };

  useEffect(() => {
    if (needToClear) {
      setMode(undefined);
      setExchange(undefined);
      setMarketType(undefined);
      setStrategy(undefined);
      setPair(undefined);
      setType(undefined);
      setNeedToClear(false);
    }
  }, [needToClear]);

  useEffect(() => {
    const activeFilters = Object.entries(filters).filter(([, val]) => val) as [
      keyof IBot,
      string | boolean,
    ][];
    const filteredBots = bots.filter((bot) =>
      activeFilters.every(([filterKey, filterVal]) =>
        filterVal === 'TrendFlipper' ? bot.useReverse : bot[filterKey] === filterVal,
      ),
    );

    setBots(filteredBots);
    setNeedUpdateSorting(true);
  }, [mode, exchange, marketType, strategy, pair]);

  return (
    <div className={styles.container}>
      {isCompact && (
        <div className={styles.compactFilter}>
          <img src={FilterIcon} /> <span>Filter</span>
          <button className={cn({ [styles.opened]: isShowDropdowns })} onClick={toggleDropdowns}>
            <SortArrow color='#fff' />
          </button>
        </div>
      )}
      {isShownDropDowns && (
        <div className={cn(styles.dropdowns, { [styles.compactDropdowns]: isCompact })}>
          <Dropdown
            label={t('mode')}
            value={mode}
            setValue={setMode}
            options={modeOptions}
            renderOption={(mode) => mode.replace('MODE_', '')}
            size={size}
          />
          <Dropdown
            label={t('strategy')}
            value={strategy}
            setValue={setStrategy}
            options={strategyOptions}
            renderOption={(strategy) => (
              <StrategyBot
                strategy={strategy as EStrategyType}
                trendFlipper={strategy === 'TrendFlipper'}
              />
            )}
            size={size}
          />
          <Dropdown
            label={t('exchange')}
            value={exchange}
            setValue={setExchange}
            options={exchangesOptions}
            renderOption={renderExchangesOptions}
            size={size}
          />
          <Dropdown
            label={t('pair')}
            value={pair}
            setValue={setPair}
            options={pairsOptions}
            renderOption={(pair) => pair}
            size={size}
          />
          <Dropdown
            label={t('risk')}
            setValue={emptyFn}
            options={[ERisk.high, ERisk.medium, ERisk.low]}
            renderOption={renderRiskOption}
            size={size}
            isDisabled
          />
          <Dropdown
            label={t('type')}
            value={type}
            setValue={setType}
            options={typeOptions}
            renderOption={renderTypeOption}
            size={size}
          />
        </div>
      )}
    </div>
  );
}
