// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Page_container__QeBXL {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Page_wrapper__AtXgx {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media (max-width: 768px) {
  .Page_container__QeBXL {
    align-items: flex-start;
  }

  .Page_wrapper__AtXgx {
    padding-left: 16px;
    padding-right: 16px;
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Pages/Page.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,sBAAsB;EACtB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE;IACE,uBAAuB;EACzB;;EAEA;IACE,kBAAkB;IAClB,mBAAmB;IACnB,WAAW;EACb;AACF","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.wrapper {\n  box-sizing: border-box;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n}\n\n@media (max-width: 768px) {\n  .container {\n    align-items: flex-start;\n  }\n\n  .wrapper {\n    padding-left: 16px;\n    padding-right: 16px;\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Page_container__QeBXL`,
	"wrapper": `Page_wrapper__AtXgx`
};
export default ___CSS_LOADER_EXPORT___;
