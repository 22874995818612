import styles from './BotsCard.module.css';
import cn from 'classnames';
import SuspendIcon from '../../../assets/General/SuspendIcon';
import WarningIcon from '../../../assets/General/WarningIcon';
import { useTranslation } from 'react-i18next';
import { prepareStatus } from '../BotsPage.utils';
import { IBot } from '../../../redux/types';
import TrashIcon from '../../../assets/General/TrashIcon';

interface IActivityProps {
  bot: IBot;
}

export enum EStatus {
  paused = 'paused',
  stopped = 'stopped',
  error = 'error',
  active = 'active',
  new = 'new',
  removed = 'removed',
}

export default function ActivityBotIcon({ bot }: IActivityProps) {
  const { t } = useTranslation('botspage');

  const status = prepareStatus(bot.flowStatus);

  if (status === EStatus.active) {
    return (
      <div className={cn([styles.activeIcon, styles.infoIcon])}>{t('botCard.activity.active')}</div>
    );
  }
  if (status === EStatus.new) {
    return <div className={cn([styles.infoIcon, styles.newIcon])}>{t('botCard.activity.new')}</div>;
  }
  if (status === EStatus.stopped) {
    return (
      <div className={cn([styles.stoppedIcon, styles.infoIcon])}>
        <WarningIcon height={16} width={16} color='#F38A3F' /> {t('botCard.activity.stopped')}
      </div>
    );
  }
  if (status === EStatus.paused) {
    return (
      <div className={cn([styles.pauseIcon, styles.infoIcon])}>{t('botCard.activity.paused')}</div>
    );
  }

  if (status === EStatus.removed) {
    return (
      <div className={cn([styles.errorIcon, styles.infoIcon])}>
        <TrashIcon color='#E83535' /> {t('botCard.activity.deleted')}
      </div>
    );
  }
  return (
    <div className={cn([styles.errorIcon, styles.infoIcon])}>
      <SuspendIcon color='#E83535' /> {t('botCard.activity.error')}
    </div>
  );
}
