import React, { memo } from 'react';
import { ITab, Navigation } from '../Navigation/Navigation';
import { useTranslation } from 'react-i18next';
import styles from './Page.module.css';
import LaunchBotPage from '../LaunchBotPage/LaunchBotPage';

function CreateBot() {
  const { t } = useTranslation('tabs');

  const tabs: ITab[] = [
    { id: 1, name: t('bots.mybots'), link: '/bots/mybots' },
    { id: 2, name: t('bots.launchBot'), link: '/bots/launchbot' },
  ];
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <Navigation tabs={tabs} title={t('bots.title')} />
        <LaunchBotPage />
      </div>
    </div>
  );
}

export default memo(CreateBot);
