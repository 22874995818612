import React from 'react';
import cn from 'classnames';
import styles from './BeFirst.module.css';
import JoinInput from '../../JoinInput/JoinInput';
import { useTranslation } from 'react-i18next';

const BeFirst: React.FC = () => {
  const { t, i18n } = useTranslation('beFirst');
  const isRuLanguage = i18n.language === 'ru';

  const inputStyles = cn(styles.join_container, { [styles.join_container_ru]: isRuLanguage });

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <h2>{t('title')}</h2>
        <p>
          {t('p1')}
          <br /> {isRuLanguage && t('p1_part2')}
        </p>
        <p>{t('p2')}</p>

        <div className={inputStyles}>
          <JoinInput />
        </div>

        <div className={styles.contacts}>
          <div>{t('updates')}</div>
          <img src='./images/social/telegram.png' />
          <img src='./images/social/discord.png' />
        </div>
      </div>
    </div>
  );
};

export default BeFirst;
