import { useTranslation } from 'react-i18next';
import styles from './BotErrorModalPlay.module.css';

interface IBotErrorModalPlay {
  closeModal: () => void;
}

const BotErrorModalPlay = ({
  closeModal,
}: IBotErrorModalPlay) => {
  const { t } = useTranslation('botspage');


  return (
    <div className={styles.container}>
      <div className={styles.modalContainer}>
        <h3 className={styles.title}>{t('modals.errorRun.title')}</h3>
        <p className={styles.description}>{t('modals.errorRun.description')}</p>

        <div className={styles.controls}>
          <button className={styles.backBtn} onClick={closeModal}>
            {t('modals.errorRun.back')}
          </button>
          <button className={styles.agreeBtn} onClick={closeModal}>
            {t('modals.errorRun.agree')}
          </button>
        </div>

        <button className={styles.closeBtn} onClick={closeModal}>Close</button>
      </div>
    </div>
  );
};

export default BotErrorModalPlay;
