import styles from './BotsCard.module.css';
import { useTranslation } from 'react-i18next';
interface IRuntimeProps {
  runtime: string | number;
}

export default function RuntimeBot({ runtime = '' }: IRuntimeProps) {
  const { t } = useTranslation('botspage');
  let days, hrs, min;
  const isUptimeFormat = `${runtime}`.includes('D');

  if (isUptimeFormat) {
    const [daysPart, hoursPart, minutesPart] = `${runtime}`.split(' ');
    days = daysPart.replace('D', '');
    hrs = hoursPart.replace('H', '');
    min = minutesPart.split(':')[0].replace('M', '');
  } else {
    const MILLISECONDS: number = 1000;
    const MINUTES: number = 3600;
    const HOURS: number = 24;
    const time: Date = new Date(Number(new Date()) - Number(new Date(runtime).getTime()));
    days = Math.floor(Number(time) / (MILLISECONDS * MINUTES * HOURS));
    hrs = time.getHours();
    min = time.getMinutes();
  }

  if (runtime === '') {
    days = 0; hrs = 0; min = 0;
  }

  return (
    <div className={styles.runtime}>{`${days}${t('botCard.runtime.d')} ${hrs}${t(
      'botCard.runtime.h',
    )}  ${min}${t('botCard.runtime.m')}`}</div>
  );
}
