import React from 'react';

interface IInfoIconProps {
  color?: string;
  width?: number;
  height?: number;
  id?: string;
}

function InfoIcon({ color = '#92f8e3', width = 15, height = 14, id }: IInfoIconProps) {
  return (
    <svg id={id} width={width} height={height} viewBox='0 0 15 14' fill='none'>
      <path
        d='M6.9735 3.33333H8.30507V4.66667H6.9735V3.33333ZM6.9735 6H8.30507V10H6.9735V6ZM7.63928 0C3.96416
        0 0.981445 2.98667 0.981445 6.66667C0.981445 10.3467 3.96416 13.3333 7.63928 13.3333C11.3144 13.3333
        14.2971 10.3467 14.2971 6.66667C14.2971 2.98667 11.3144 0 7.63928 0ZM7.63928 12C4.70318 12 2.31301 9.60667
        2.31301 6.66667C2.31301 3.72667 4.70318 1.33333 7.63928 1.33333C10.5754 1.33333 12.9656 3.72667 12.9656
        6.66667C12.9656 9.60667 10.5754 12 7.63928 12Z'
        fill={color}
      />
    </svg>
  );
}

export default React.memo(InfoIcon);
