/* eslint-disable camelcase */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { ISettingProfile, ISaveUserBybitKeys, IUserProfile } from '../types';
import { BASE_URL } from '../../constants';

interface IExchangeConnectionResponse {
  error?: object;
  status?: {
    code: string;
  };
}

export const settingProfileApi = createApi({
  reducerPath: 'settingProfileApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
  }),
  tagTypes: ['SettingsProfile'],
  endpoints: (build) => ({
    getSettingProfile: build.query<ISettingProfile, string>({
      query: (userId) => ({
        url: `setting-profile/${userId}`,
      }),
      providesTags: ['SettingsProfile'],
    }),

    addUserBybitKeys: build.mutation<IExchangeConnectionResponse, ISaveUserBybitKeys>({
      query: (payload) => {
        return {
          url: `/v1/sessions/${payload.sessionId}/exchanges/bybit/keys`,
          method: 'PUT',
          body: {
            api_key: payload.bybtKey,
            api_secret: payload.bybtSecretKey,
          },
          headers: {
            Authorization: `Bearer ${payload.token}`,
          },
        };
      },
    }),

    deleteUserBybitKeys: build.mutation({
      query: (payload) => {
        return {
          url: `/v1/sessions/${payload.sessionId}/exchanges/bybit/keys`,
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${payload.token}`,
          },
        };
      },
    }),

    currentUserProfile: build.query<IUserProfile, void>({
      query: () => 'auth/me',
    }),

    currentUserEmail: build.query({
      query: (payload) => {
        return {
          url: `/v1/sessions/${payload.sessionId}/mail`,
          headers: {
            Authorization: `Bearer ${payload.token}`,
          },
        };
      },
    }),

    changeEmail: build.mutation({
      query: (payload) => ({
        url: `/v1/users/${payload.userId}/mail`,
        method: 'PUT',
        body: payload?.body,
        headers: {
          Authorization: `Bearer ${payload.token}`,
        },
      }),
    }),
    getBybitKeys: build.mutation({
      query: () => ({
        url: `/v1/sessions/${sessionStorage.getItem('session_id')}/exchanges/bybit/keys`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      }),
    }),
  }),
});

export const {
  useGetSettingProfileQuery,
  useAddUserBybitKeysMutation,
  useDeleteUserBybitKeysMutation,
  useCurrentUserProfileQuery,
  useCurrentUserEmailQuery,
  useChangeEmailMutation,
  useGetBybitKeysMutation,
} = settingProfileApi;
