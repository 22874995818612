import styles from './BotsStatusBar.module.css';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import cn from 'classnames';
import { EStatus } from '../BotsCard/ActivityBotIcon';
import { emptyFn } from '../../../constants';
import { IBot } from '../../../redux/types';
import { prepareStatus } from '../BotsPage.utils';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import SortArrow from '../../../assets/General/SortArrow';
interface IBotsStatusBarProps {
  isSettingsOpened?: boolean;
  bots?: IBot[];
  setBots?: React.Dispatch<React.SetStateAction<IBot[]>>;
  setBotsByStatus?: React.Dispatch<React.SetStateAction<IBot[]>>;
  setNeedToClear?: React.Dispatch<boolean>;
  setNeedUpdateSorting?: React.Dispatch<boolean>;
  disableDeletedTab?: boolean;
  disableNewTab?: boolean;
}

enum ETabs {
  all = 'all',
  active = 'active',
  paused = 'paused',
  stopped = 'stopped',
  notActive = 'notActive',
  error = 'error',
  new = 'new',
  deleted = 'deleted',
}

export default function BotsStatusBar({
  isSettingsOpened,
  bots = [],
  setBots = emptyFn,
  setBotsByStatus = emptyFn,
  setNeedToClear = emptyFn,
  setNeedUpdateSorting = emptyFn,
  disableDeletedTab = false,
  disableNewTab = false,
}: IBotsStatusBarProps) {
  const { t } = useTranslation('botspage');
  const [activeTab, setActiveTab] = useState(ETabs.all);
  const { width } = useWindowDimensions();
  const MAX_TABLET_WIDTH = 950;
  const isTablet = width <= MAX_TABLET_WIDTH;

  const [areInactiveTabsVisible, setAreInactiveTabsVisible] = useState(false);

  const containerStyles = cn(styles.container, {
    [styles.containerCompactView]: isTablet,
    [styles.containerMenuView]: isTablet && areInactiveTabsVisible,
  });

  const botsByStatus: Record<EStatus, IBot[]> = {
    [EStatus.active]: [],
    [EStatus.paused]: [],
    [EStatus.new]: [],
    [EStatus.stopped]: [],
    [EStatus.error]: [],
    [EStatus.removed]: [],
  };

  bots.forEach(bot => {
    const status = prepareStatus(bot.flowStatus);
    if (botsByStatus[status]) {
      botsByStatus[status].push(bot);
    }
  });

  const activeBots = botsByStatus[EStatus.active];
  const pausedBots = botsByStatus[EStatus.paused];
  const newBots = botsByStatus[EStatus.new];
  const stoppedBots = botsByStatus[EStatus.stopped];
  const errorBots = botsByStatus[EStatus.error];
  const deletedBots = botsByStatus[EStatus.removed];

  const getClassNames = (tab: ETabs) => cn({ [styles.activeTab]: tab === activeTab });

  const onActiveFilterClick = (tabName: ETabs, filteredBots: IBot[]) => () => {
    setActiveTab(tabName);
    setBots(filteredBots);
    setBotsByStatus(filteredBots);
    setNeedToClear(true);
    setNeedUpdateSorting(true);
    setAreInactiveTabsVisible(!areInactiveTabsVisible);
  };

  const onArrowClick = () => {
    setAreInactiveTabsVisible(!areInactiveTabsVisible);
  };

  return (
    <div className={containerStyles}>
      <button className={getClassNames(ETabs.all)} onClick={onActiveFilterClick(ETabs.all, bots)}>
        {t('statusbar.allBots')} ({bots.length})
      </button>
      <button
        className={getClassNames(ETabs.active)}
        onClick={onActiveFilterClick(ETabs.active, activeBots)}
      >
        {t('statusbar.active')} ({activeBots.length})
      </button>
      {!disableNewTab && (
        <button
          className={getClassNames(ETabs.new)}
          onClick={onActiveFilterClick(ETabs.new, newBots)}
        >
          {t('statusbar.new')} ({newBots.length})
        </button>
      )}
      {!isSettingsOpened ? (
        <>
          <button
            className={getClassNames(ETabs.paused)}
            onClick={onActiveFilterClick(ETabs.paused, pausedBots)}
          >
            {t('statusbar.paused')} ({pausedBots?.length})
          </button>
          <button
            className={getClassNames(ETabs.stopped)}
            onClick={onActiveFilterClick(ETabs.stopped, stoppedBots)}
          >
            {t('statusbar.stopped')} ({stoppedBots?.length})
          </button>
        </>
      ) : (
        <>
          <button
            className={getClassNames(ETabs.notActive)}
            onClick={() => setActiveTab(ETabs.notActive)}
          >
            {t('statusbar.notActive')} ({0})
          </button>
        </>
      )}
      <button
        className={getClassNames(ETabs.error)}
        onClick={onActiveFilterClick(ETabs.error, errorBots)}
      >
        {t('statusbar.error')} ({errorBots.length})
      </button>
      {!disableDeletedTab && (
        <button
          className={getClassNames(ETabs.deleted)}
          onClick={onActiveFilterClick(ETabs.deleted, deletedBots)}
        >
          {t('statusbar.deleted')} ({deletedBots.length})
        </button>
      )}

      <span className={styles.arrow} onClick={onArrowClick}><SortArrow color='#fff' /></span>
    </div>
  );
}
