import cn from 'classnames';
import styles from './BotsCard.module.css';
import { ESizing } from './RiskBot';

interface AprPnlProps {
  value: number;
  size?: ESizing;
  char?: string;
}

export default function PnlValue({ value, size = ESizing.medium, char = '%' }: AprPnlProps) {
  const isPositive = value > 0;
  return (
    <div
      className={cn(
        [styles.value],
        {
          [styles.positiveValue]: isPositive,
        },
        { [styles.valueMedium]: size === ESizing.medium },
        { [styles.valueSmall]: size === ESizing.small },
      )}
    >
      {` ${isPositive ? '+' : ''}${value.toLocaleString()} ${char}`}
    </div>
  );
}
