import Calendar from 'react-calendar';
import SortArrow from '../../../../assets/General/SortArrow';
import styles from './CalendarComponent.module.css';
import './CalendarComponents.css';

interface IProps {
  setShowCalendar: (showCalendar: boolean) => void;
  value: [Date, Date] | undefined;
  onChange: (value: [Date, Date] | undefined) => void;
}

const CalendarComponent = ({ setShowCalendar, value, onChange }: IProps) => {
  return (
    <div className={styles.calendarComponent}>
      <Calendar
        tileClassName={styles.tile}
        nextLabel={<SortArrow />}
        prevLabel={<SortArrow />}
        selectRange={true}
        returnValue={'range'}
        value={value}
        locale={'en-EN'}
        showNavigation={true}
        showNeighboringMonth={false}
        onChange={(value: unknown) => {
          onChange(value as [Date, Date]);
          setShowCalendar(false);
        }}
      />
    </div>
  );
};

export default CalendarComponent;
