import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IBot, IFlowParameters } from '../types';

export enum EBotActions {
  run = 'run',
  pause = 'pause',
  stop = 'stop',
  delete = 'delete',
}

export interface IInitialBotsState {
  bots?: IBot[];
  selectedBot: IBot | null;
  selectedFlowParameters: IFlowParameters | null;
  selectedAction: EBotActions | null;
}

export const initialState: IInitialBotsState = {
  bots: [],
  selectedBot: null,
  selectedFlowParameters: null,
  selectedAction: null,
};

const botsSlice = createSlice({
  name: 'bots',
  initialState,
  reducers: {
    selectBot(state: IInitialBotsState, action: PayloadAction<IBot | null>) {
      state.selectedBot = action.payload;
    },
    setBots(state, action) {
      state.bots = action.payload;
    },
    setParameters(state, action) {
      state.selectedFlowParameters = action.payload;
    },
    setBotAction(state, action) {
      state.selectedAction = action.payload;
    },
  },
});

export const { selectBot, setBots, setParameters, setBotAction } = botsSlice.actions;

export default botsSlice.reducer;
