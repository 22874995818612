import React from 'react';

import styles from './HeaderNotificationList.module.css';
import SortArrow from '../../../../assets/General/SortArrow';
import cn from 'classnames';

interface IHeadersProps {
  onSort: () => void;
  isDescSort: boolean;
}

export default function HeaderNotificationList({ onSort, isDescSort }: IHeadersProps) {
  return (
    <div className={styles.header}>
      <div className={styles.channel}>
        Channel
        <SortArrow color='#FFBE44' />
      </div>
      <div className={styles.date}>
        Date
        <button onClick={onSort} className={cn({ [styles.descending]: isDescSort })}>
          <SortArrow color='#FFBE44' />
        </button>
      </div>
      <div className={styles.opened}>Opened</div>
      <div className={styles.read}>Read</div>
    </div>
  );
}
