import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import cn from 'classnames';
import SureModalWindow from '../SureModalWindow/SureModalWindow';
import GroupIcon from '../GroupIcon/GroupIcon';
import { IUser } from '../UsersList/UserList.types';
import StopIcon from '../../../../assets/stop_icon.svg';
import MoreIcon from '../../../../assets/more-tooltip.svg';
import styles from './UserListItem.module.css';

interface IUsersList {
  user: IUser;
}

export default function UserListItem({
  user: {
    botHistory,
    botsCopied,
    botsCreated,
    dateTime,
    email,
    exchanges,
    group,
    realizedPnl,
    runningBots,
    runningEquity,
    totalProfit,
    tradingVolume,
    uid,
    username,
    isActive,
  },
}: IUsersList) {
  const [isBanModalOpened, setIsBanModalOpened] = useState(false);
  const [isUserBanned, setIsUserBanned] = useState(!isActive);
  const onOpenBanModal = () => setIsBanModalOpened(true);
  const onCloseBanModal = () => setIsBanModalOpened(false);
  const onBanUser = () => {
    setIsUserBanned(true);
    onCloseBanModal();
  };

  const formatOption: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  };

  const date = new Intl.DateTimeFormat('ru', formatOption).format(dateTime);
  const MAX_INDEX = 4;
  const groupIcons = Array.isArray(group) ? (
    group.map((item, index) => (index < MAX_INDEX ? <GroupIcon group={item} key={item} /> : null))
  ) : (
    <GroupIcon group={group} />
  );
  const MAX_GROUP_LENGTH = 25;
  const isShowGroupTooltip = Array.isArray(group) && group.join('').length > MAX_GROUP_LENGTH;

  const groupTooltip = isShowGroupTooltip && (
    <>
      <button id='groups'>
        <img src={MoreIcon} />
      </button>
      <Tooltip anchorSelect='#groups' content={group.join(', ')} place='top' />
    </>
  );

  return (
    <>
      <div className={cn([styles.listRow], { [styles.isBanned]: isUserBanned })}>
        <div className={styles.dateTime}>{date}</div>
        <div className={styles.uid}>{uid}</div>
        <div className={styles.username}>{username}</div>
        <div className={styles.email}>{email}</div>
        <div className={styles.group}>
          {groupIcons} {groupTooltip}
        </div>
        <div className={styles.exchanges}>
          {exchanges.join(',')}
          <button id='exchanges'>
            <img src={MoreIcon} />
          </button>
          <Tooltip anchorSelect='#exchanges' content={exchanges.join(', ')} place='top' />
        </div>
        <div className={styles.trading}>{tradingVolume}</div>
        <div className={styles.pnl}>{realizedPnl}</div>
        <div className={styles.equity}>{runningEquity}</div>
        <div className={styles.botsTypeBox}>
          <div className={styles.botsType}>{runningBots.pro}</div>
          <div className={styles.botsType}>{runningBots.auto}</div>
        </div>
        <div className={styles.botsTypeBox}>
          <div className={styles.botsType}>{botsCreated.pro}</div>
          <div className={styles.botsType}>{botsCreated.auto}</div>
        </div>
        <div className={styles.botsTypeBox}>
          <div className={styles.botsType}>{botsCopied.pro}</div>
          <div className={styles.botsType}>{botsCopied.auto}</div>
        </div>
        <div className={styles.profit}>
          <NavLink to={totalProfit}>View</NavLink>
        </div>
        <div className={styles.botHistory}>
          <NavLink to={botHistory}>View</NavLink>
        </div>
        <div className={styles.stopBtn}>
          <button onClick={onOpenBanModal}>
            <img src={StopIcon} />
          </button>
        </div>
      </div>
      {isBanModalOpened && <SureModalWindow onBan={onBanUser} onClose={onCloseBanModal} />}
    </>
  );
}
