// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BotSetupHeader_container__ybCGG {
  display: flex;
  gap: 20px;
  align-items: flex-end;
  height: 60px;
  margin-bottom: 55px;
}

.BotSetupHeader_input__geQ8O {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: -5px;
  margin-right: 22px;
}

.BotSetupHeader_input__geQ8O input {
  height: 34px;
  width: 230px;
  border-radius: 8px;
  background-color: transparent;
}

@media (max-width: 768px) {
  .BotSetupHeader_container__ybCGG {
    flex-wrap: wrap;
    height: auto;
    margin-bottom: 32px;
    padding: 0 16px;
  }

  .BotSetupHeader_input__geQ8O {
    width: calc(100vw - 32px);
    margin-right: 0;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/LaunchBotPage/BotSetupHeader/BotSetupHeader.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,SAAS;EACT,qBAAqB;EACrB,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,yBAAyB;EACzB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,6BAA6B;AAC/B;;AAEA;EACE;IACE,eAAe;IACf,YAAY;IACZ,mBAAmB;IACnB,eAAe;EACjB;;EAEA;IACE,yBAAyB;IACzB,eAAe;EACjB;AACF","sourcesContent":[".container {\n  display: flex;\n  gap: 20px;\n  align-items: flex-end;\n  height: 60px;\n  margin-bottom: 55px;\n}\n\n.input {\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-end;\n  margin-bottom: -5px;\n  margin-right: 22px;\n}\n\n.input input {\n  height: 34px;\n  width: 230px;\n  border-radius: 8px;\n  background-color: transparent;\n}\n\n@media (max-width: 768px) {\n  .container {\n    flex-wrap: wrap;\n    height: auto;\n    margin-bottom: 32px;\n    padding: 0 16px;\n  }\n\n  .input {\n    width: calc(100vw - 32px);\n    margin-right: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `BotSetupHeader_container__ybCGG`,
	"input": `BotSetupHeader_input__geQ8O`
};
export default ___CSS_LOADER_EXPORT___;
