// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AdminStatisticPage_container__XMvOV {
  display: flex;
  flex-direction: column;
  margin: 20px 5%;
  gap: 20px;
}

.AdminStatisticPage_container__XMvOV h2 {
  margin-top: 20px;
  color: white;
  font-size: 24px;
  font-weight: 400;
  line-height: 22px;
}
`, "",{"version":3,"sources":["webpack://./src/components/AdminPage/AdminStatisticsPage/AdminStatisticPage.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,eAAe;EACf,SAAS;AACX;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,eAAe;EACf,gBAAgB;EAChB,iBAAiB;AACnB","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  margin: 20px 5%;\n  gap: 20px;\n}\n\n.container h2 {\n  margin-top: 20px;\n  color: white;\n  font-size: 24px;\n  font-weight: 400;\n  line-height: 22px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `AdminStatisticPage_container__XMvOV`
};
export default ___CSS_LOADER_EXPORT___;
