/* eslint-disable max-len */
import React from 'react';

interface IPercentIconProps {
  color?: string;
  width?: number;
  height?: number;
}

function PercentIcon({ width = 9, height = 10 }: IPercentIconProps) {
  return (
    <svg width={width} height={height} viewBox='0 0 9 10'>
      <path d='M2.25 4.18262C1.76667 4.18262 1.35417 4.01178 1.0125 3.67012C0.670833 3.32845 0.5 2.91595 0.5 2.43262C0.5 1.94928 0.670833 1.53678 1.0125 1.19512C1.35417 0.853451 1.76667 0.682617 2.25 0.682617C2.73333 0.682617 3.14583 0.853451 3.4875 1.19512C3.82917 1.53678 4 1.94928 4 2.43262C4 2.91595 3.82917 3.32845 3.4875 3.67012C3.14583 4.01178 2.73333 4.18262 2.25 4.18262ZM2.25 3.18262C2.45833 3.18262 2.63533 3.10962 2.781 2.96362C2.927 2.81795 3 2.64095 3 2.43262C3 2.22428 2.927 2.04728 2.781 1.90162C2.63533 1.75562 2.45833 1.68262 2.25 1.68262C2.04167 1.68262 1.86467 1.75562 1.719 1.90162C1.573 2.04728 1.5 2.22428 1.5 2.43262C1.5 2.64095 1.573 2.81795 1.719 2.96362C1.86467 3.10962 2.04167 3.18262 2.25 3.18262ZM6.75 8.68262C6.26667 8.68262 5.85417 8.51178 5.5125 8.17012C5.17083 7.82845 5 7.41595 5 6.93262C5 6.44928 5.17083 6.03678 5.5125 5.69512C5.85417 5.35345 6.26667 5.18262 6.75 5.18262C7.23333 5.18262 7.64583 5.35345 7.9875 5.69512C8.32917 6.03678 8.5 6.44928 8.5 6.93262C8.5 7.41595 8.32917 7.82845 7.9875 8.17012C7.64583 8.51178 7.23333 8.68262 6.75 8.68262ZM6.75 7.68262C6.95833 7.68262 7.13533 7.60962 7.281 7.46362C7.427 7.31795 7.5 7.14095 7.5 6.93262C7.5 6.72428 7.427 6.54728 7.281 6.40162C7.13533 6.25562 6.95833 6.18262 6.75 6.18262C6.54167 6.18262 6.36467 6.25562 6.219 6.40162C6.073 6.54728 6 6.72428 6 6.93262C6 7.14095 6.073 7.31795 6.219 7.46362C6.36467 7.60962 6.54167 7.68262 6.75 7.68262ZM1.2 8.68262L0.5 7.98262L7.8 0.682617L8.5 1.38262L1.2 8.68262Z' />
    </svg>
  );
}

export default React.memo(PercentIcon);
