import { Dispatch, ChangeEvent, SetStateAction, useState } from 'react';
import onPasswordValidate from '../../utils/onPasswordValidate';
import PasswordValidationBlock from '../PasswordValidation/PasswordValidationBlock';
import { Input } from '../CCLabsUI';
import { ESizing } from '../CCLabsUI/Input/Input';
import styles from './NewPasswordBlock.module.css';
import { useTranslation } from 'react-i18next';

interface INewPasswordBlockProps {
  newPassword: string;
  confirmedPassword: string;
  setNewPassword: Dispatch<SetStateAction<string>>;
  setConfirmedPassword: Dispatch<SetStateAction<string>>;
  setIsValidNewPassword: Dispatch<SetStateAction<boolean>>;
}

export default function NewPasswordBlock({
  newPassword,
  confirmedPassword,
  setNewPassword,
  setConfirmedPassword,
  setIsValidNewPassword,
}: INewPasswordBlockProps) {
  const { t } = useTranslation('passwordUpdate');
  const [lowercasePass, setLowercasePass] = useState(false);
  const [uppercasePass, setUppercasePass] = useState(false);
  const [digitPass, setDigitPass] = useState(false);
  const [symPass, setSymPass] = useState(false);
  const [lengthPass, setLengthPass] = useState(false);
  const [isNoMatch, setIsNoMatch] = useState(false);
  const [isValidPassword, setIsValidPassword] = useState(true);
  const onCheckChars = (value: string) => {
    const pattern = /^[A-Za-z0-9!@#$%^&*()_+,-./:;<=>?@[\]^_`{|}~]*$/;
    return pattern.test(value);
  };
  const isValidNewPassword = lowercasePass && uppercasePass && digitPass && symPass && lengthPass;

  setIsValidNewPassword(isValidNewPassword && newPassword === confirmedPassword);
  const validationFields = {
    lowercasePass,
    symPass,
    uppercasePass,
    lengthPass,
    digitPass,
  };

  const onSetNewPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!onCheckChars(e.target.value)) {
      return;
    }

    setNewPassword(e.target.value);
    onPasswordValidate(
      e,
      setLengthPass,
      setLowercasePass,
      setUppercasePass,
      setDigitPass,
      setSymPass,
    );
  };
  const blurConfirmHandler = () => {
    setIsNoMatch(confirmedPassword !== newPassword);
  };

  const blurNewPasswordHandler = () => {
    setIsValidPassword(isValidNewPassword);
  };
  return (
    <>
      <Input
        id='new-password'
        label={t('new')}
        sizing={ESizing.large}
        placeholder={t('new')}
        isPassword={true}
        value={newPassword}
        onChange={onSetNewPassword}
        className={styles.inputFields}
        onBlur={blurNewPasswordHandler}
        isValid={isValidPassword}
        errorMessage={t('error')}
        pattern='[A-Za-z0-9!@#$%^&*()_+,-./:;<=>?@[\]^_`{|}~]*'
      />
      {newPassword.length !== 0 && <PasswordValidationBlock validationFields={validationFields} />}
      <Input
        id='new-password-confirm'
        label={t('confirmNew')}
        sizing={ESizing.large}
        placeholder={t('confirmNew')}
        isPassword={true}
        value={confirmedPassword}
        onChange={(e: ChangeEvent<HTMLInputElement>) => setConfirmedPassword(e.target.value)}
        onBlur={blurConfirmHandler}
        className={styles.inputFields}
        isValid={!isNoMatch}
        errorMessage={t('noMatch')}
      />
    </>
  );
}
