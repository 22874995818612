import { EStrategyType } from '../../../redux/types';
import { EMarket } from '../Leaderboard/ListLeaderboard/ListLeaderboard.types';

export const renderUppercasedOption = (option: string) =>
  option
    .split('_')
    .filter((word, i) => i > 0)
    .join(' ');

export const renderStrategyOption = (option: string) => {
  if (option === 'TrendFlipper') {
    return option;
  }
  const trimmedOption = option.replace('STRATEGY_TYPE', '');
  return renderUppercasedOption(trimmedOption);
};

export const getStrategies = (market = '') =>
  market === 'MARKET_SPOT' ? [EStrategyType.spot] : [EStrategyType.long, EStrategyType.short];

export const renderMarketOption = (option = '') => {
  const optionByResponse: Record<string, EMarket> = {
    MARKET_SPOT: EMarket.spot,
    MARKET_USDT_PERPETUAL: EMarket.usdtPerp,
    MARKET_INVERSE_PERPETUAL: EMarket.inversePerp,
  };

  return optionByResponse[option] ?? renderUppercasedOption(option);
};
