import cn from 'classnames';
import TitleCard from '../../BotsPage/BotSettings/Blocks/TitleCard';
import LineOfCard from './LineOfCard';
import PnlValue from '../../BotsPage/BotsCard/PnlValue';
import RiskBot, { ESizing } from '../../BotsPage/BotsCard/RiskBot';
import RuntimeBot from '../../BotsPage/BotsCard/RuntimeBot';
import APRCharts from '../APRCharts/APRCharts';
import PNLCharts from '../PNLChart/PNLChart';

import styles from './Cards.module.css';
import { useTranslation } from 'react-i18next';
import { IBot } from '../../../redux/types';

interface ISummary {
  bot: IBot | null;
  subBots?: IBot[];
}

export default function Summary({ bot, subBots }: ISummary) {
  const { t } = useTranslation('botsStats');
  return (
    <div className={cn([styles.container, styles.summaryCard])}>
      <TitleCard title={t('summary')} />
      <LineOfCard title={t('totalPnl')} value='+36,879.97' positive={true} />
      <LineOfCard title='PNL %' value={<PnlValue value={11669.78} size={ESizing.small} />} />
      <LineOfCard title='APR %' value={<PnlValue value={11669.78} size={ESizing.small} />} />
      <LineOfCard title={t('risk')} value={<RiskBot risk='LOW' size={ESizing.small} />} />
      <LineOfCard title={t('equity')} value='$1,543,678' />
      <LineOfCard title={t('totalTrading')} value='$1,543,678' />
      <LineOfCard title={t('runtime')} value={<RuntimeBot runtime={String(new Date())} />} />
      {bot && bot.useReverse && (
        <LineOfCard title={t('subbots')} value={subBots && subBots.length} />
      )}
      <div className={styles.divisor} />
      <LineOfCard title='PNL %' value={<PNLCharts />} isColumn={true} />
      <LineOfCard title='APR %' value={<APRCharts />} isColumn={true} />
    </div>
  );
}
