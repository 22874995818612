import React from 'react';
import cn from 'classnames';
import styles from './MenuButton.module.css';

export interface IMenuButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  label: string;
  dropdown?: boolean;
}

const MenuButton: React.FC<IMenuButtonProps> = ({
  className = '',
  label,
  dropdown = false,
  ...props
}) => {
  const classNames = cn(className, { [styles.dropdown]: dropdown }, styles.button);

  return (
    <button className={classNames} {...props} type='button'>
      {label}
    </button>
  );
};

export default MenuButton;
