import React, { useEffect, useState } from 'react';
import styles from './LanguageMenu.module.css';
import { ELanguages } from '../../../types';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../redux/hooks/redux';

const LanguageMenu: React.FC = () => {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(ELanguages.en);
  const [isMenuVisible, setIsMenuVisible] = useState(false);

  const isMobileMenuOpen = useAppSelector((state) => state.layout.isMobileMenuOpen);

  useEffect(() => {
    if (isMobileMenuOpen) {
      setIsMenuVisible(false);
    }
  }, [isMobileMenuOpen]);

  const onLanguageButtonClick = () => {
    setIsMenuVisible(!isMenuVisible);
  };

  const onLanguageItemClick = (currentLanguage: ELanguages) => async () => {
    setLanguage(currentLanguage);
    setIsMenuVisible(false);
    await i18n.changeLanguage(currentLanguage.toLowerCase());
  };

  return (
    <div className={styles.container}>
      <button className={styles.control} onClick={onLanguageButtonClick}>
        {language}
      </button>
      {isMenuVisible && (
        <div className={styles.menu}>
          <button onClick={onLanguageItemClick(ELanguages.ru)}>{ELanguages.ru}</button>
          <button onClick={onLanguageItemClick(ELanguages.en)}>{ELanguages.en}</button>
        </div>
      )}
    </div>
  );
};

export default LanguageMenu;
