import React, { useRef } from 'react';
import { v4 as uuid } from 'uuid';
import styles from './Popup.module.css';
import { useOnClickOutside } from '../../../hooks/useOnClickOutside';
import { IPopupProps } from '../../../types';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';


const Popup: React.FC<IPopupProps> = ({ popupContent, popupTitle, setIsPopupOpen }) => {
  const { i18n } = useTranslation('gridBot');
  const isEnglish = i18n.language === 'en';
  const onPopupClose = () => setIsPopupOpen(false);
  const frame = useRef(null);

  useOnClickOutside(frame, () => {
    setIsPopupOpen(false);
  });

  const frameStyle = cn(styles.frame, { [styles.frame_ru]: !isEnglish });
  const isHedgeGuardContent = popupTitle.includes('HedgeGuard');
  const popupStyle = cn(styles.container, { [styles.container_hedgeguard]: isHedgeGuardContent });

  return (
    <div className={popupStyle}>
      <div className={frameStyle} ref={frame}>
        <h3>{popupTitle}</h3>
        {popupContent.map((text) => <p key={uuid()}>{text}</p>)}
        <button onClick={onPopupClose}>Close</button>
      </div>
    </div>
  );
};

export default Popup;
