import { EGroup, IUser } from '../UsersListPage/UsersList/UserList.types';
import { USERS } from '../UsersListPage/UsersList/users_mock';
import { IGroup } from './group.types';

const users = USERS.filter((user) => user.group.includes(EGroup.user));
const admins = USERS.filter((user) => user.group.includes(EGroup.admin));
const sudos = USERS.filter((user) => user.group.includes(EGroup.sudo));
const whitelists = USERS.filter((user) => user.group.includes(EGroup.whitelist));

const getTypeCount = (array: IUser[], field: string) => {
  return array.reduce((acc, user) => {
    const keys = field.split('.');
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let value: any = user; // не смог решить по другому без any

    for (const key of keys) {
      if (typeof value === 'object' && value !== null && key in value) {
        value = value[key];
      } else {
        value = undefined;
        break;
      }
    }

    if (typeof value === 'number') {
      return acc + value;
    }
    return acc;
  }, 0);
};

export const GROUPS: IGroup[] = [
  {
    id: 1,
    dateTime: new Date('09.01.2023'),
    group: EGroup.user,
    users: users,
    usersQuantity: users.length,
    functionality:
      'findOneUser, updateUser, userBotsStat, twoFAOn, twoFAOff, saveKeys, findUserSettings, updateUserSettings, createBot, findUserBots, leaderboardView, updateBotSettings, algorithmsView, createGroup',
    tradingVolume: getTypeCount(users, 'tradingVolume'),
    realizedPnl: getTypeCount(users, 'realizedPnl'),
    runningEquity: getTypeCount(users, 'runningEquity'),
    runningBots: {
      auto: getTypeCount(users, 'runningBots.auto'),
      pro: getTypeCount(users, 'runningBots.pro'),
    },
    botsCreated: {
      auto: getTypeCount(users, 'botsCreated.auto'),
      pro: getTypeCount(users, 'botsCreated.pro'),
    },
    botsCopied: {
      auto: getTypeCount(users, 'botsCopied.auto'),
      pro: getTypeCount(users, 'botsCopied.pro'),
    },
    isActive: true,
  },
  {
    id: 2,
    dateTime: new Date('09.02.2023'),
    group: EGroup.admin,
    users: admins,
    usersQuantity: admins.length,
    functionality:
      'findOneUser, updateUser, userBotsStat, twoFAOn, twoFAOff, saveKeys, findUserSettings, updateUserSettings, createBot, findUserBots, leaderboardView, updateBotSettings, algorithmsView, createGroup',
    tradingVolume: getTypeCount(admins, 'tradingVolume'),
    realizedPnl: getTypeCount(admins, 'realizedPnl'),
    runningEquity: getTypeCount(admins, 'runningEquity'),
    runningBots: {
      auto: getTypeCount(admins, 'runningBots.auto'),
      pro: getTypeCount(admins, 'runningBots.pro'),
    },
    botsCreated: {
      auto: getTypeCount(admins, 'botsCreated.auto'),
      pro: getTypeCount(admins, 'botsCreated.pro'),
    },
    botsCopied: {
      auto: getTypeCount(admins, 'botsCopied.auto'),
      pro: getTypeCount(admins, 'botsCopied.pro'),
    },
    isActive: true,
  },

  {
    id: 3,
    dateTime: new Date('09.03.2023'),
    group: EGroup.sudo,
    users: sudos,
    usersQuantity: sudos.length,
    functionality:
      'findOneUser, updateUser, userBotsStat, twoFAOn, twoFAOff, saveKeys, findUserSettings, updateUserSettings, createBot, findUserBots, leaderboardView, updateBotSettings, algorithmsView, createGroup',
    tradingVolume: getTypeCount(sudos, 'tradingVolume'),
    realizedPnl: getTypeCount(sudos, 'realizedPnl'),
    runningEquity: getTypeCount(sudos, 'runningEquity'),
    runningBots: {
      auto: getTypeCount(sudos, 'runningBots.auto'),
      pro: getTypeCount(sudos, 'runningBots.pro'),
    },
    botsCreated: {
      auto: getTypeCount(sudos, 'botsCreated.auto'),
      pro: getTypeCount(sudos, 'botsCreated.pro'),
    },
    botsCopied: {
      auto: getTypeCount(sudos, 'botsCopied.auto'),
      pro: getTypeCount(sudos, 'botsCopied.pro'),
    },
    isActive: true,
  },
  {
    id: 4,
    dateTime: new Date('09.04.2023'),
    group: EGroup.whitelist,
    users: whitelists,
    usersQuantity: whitelists.length,
    functionality:
      'findOneUser, updateUser, userBotsStat, twoFAOn, twoFAOff, saveKeys, findUserSettings, updateUserSettings, createBot, findUserBots, leaderboardView, updateBotSettings, algorithmsView, createGroup',
    tradingVolume: getTypeCount(whitelists, 'tradingVolume'),
    realizedPnl: getTypeCount(whitelists, 'realizedPnl'),
    runningEquity: getTypeCount(whitelists, 'runningEquity'),
    runningBots: {
      auto: getTypeCount(whitelists, 'runningBots.auto'),
      pro: getTypeCount(whitelists, 'runningBots.pro'),
    },
    botsCreated: {
      auto: getTypeCount(whitelists, 'botsCreated.auto'),
      pro: getTypeCount(whitelists, 'botsCreated.pro'),
    },
    botsCopied: {
      auto: getTypeCount(whitelists, 'botsCopied.auto'),
      pro: getTypeCount(whitelists, 'botsCopied.pro'),
    },
    isActive: true,
  },
];
