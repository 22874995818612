// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../assets/img/Dropdown.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../../assets/img/DropDownHover.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MenuButton_button__nn2ey {
  font-family: 'PTSans';
  font-size: 20px;
  font-weight: 700;
  cursor: pointer;
  color: rgb(255, 255, 255);
}

.MenuButton_button__nn2ey:hover {
  color: rgb(178, 178, 178);
}

.MenuButton_button__nn2ey:active {
  text-decoration: underline;
}

.MenuButton_button__nn2ey.MenuButton_dropdown__xC0O9 {
  position: relative;
}

.MenuButton_button__nn2ey.MenuButton_dropdown__xC0O9::before {
  position: absolute;
  right: -17px;
  bottom: 3px;
  content: '';
  width: 19px;
  height: 19px;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-position: center;
  background-repeat: no-repeat;
}

.MenuButton_button__nn2ey.MenuButton_dropdown__xC0O9:hover::before {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
  background-position: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/CCLabsUI/MenuButton/MenuButton.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,eAAe;EACf,gBAAgB;EAChB,eAAe;EACf,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,WAAW;EACX,WAAW;EACX,WAAW;EACX,YAAY;EACZ,yDAAyD;EACzD,2BAA2B;EAC3B,4BAA4B;AAC9B;;AAEA;EACE,yDAA8D;EAC9D,2BAA2B;AAC7B","sourcesContent":[".button {\n  font-family: 'PTSans';\n  font-size: 20px;\n  font-weight: 700;\n  cursor: pointer;\n  color: rgb(255, 255, 255);\n}\n\n.button:hover {\n  color: rgb(178, 178, 178);\n}\n\n.button:active {\n  text-decoration: underline;\n}\n\n.button.dropdown {\n  position: relative;\n}\n\n.button.dropdown::before {\n  position: absolute;\n  right: -17px;\n  bottom: 3px;\n  content: '';\n  width: 19px;\n  height: 19px;\n  background-image: url('../../../assets/img/Dropdown.svg');\n  background-position: center;\n  background-repeat: no-repeat;\n}\n\n.button.dropdown:hover::before {\n  background-image: url('../../../assets/img/DropDownHover.svg');\n  background-position: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `MenuButton_button__nn2ey`,
	"dropdown": `MenuButton_dropdown__xC0O9`
};
export default ___CSS_LOADER_EXPORT___;
