import { Dispatch, SetStateAction } from 'react';
import SortArrow from '../../../../assets/General/SortArrow';
import HeaderButton from './HeaderButton';
import { IUser } from './UserList.types';
import styles from './UserList.module.css';

interface IHeaderList {
  setUsers: Dispatch<SetStateAction<IUser[]>>;
}

export default function HeaderList({ setUsers }: IHeaderList) {
  return (
    <div className={styles.listHeader}>
      <HeaderButton
        className={styles.dateTime}
        field='dateTime'
        title='Date/Time'
        setUsers={setUsers}
      />
      <div className={styles.uid}>UID</div>
      <div className={styles.username}>Username</div>
      <div className={styles.email}>Email</div>
      <div className={styles.group}>
        Group <SortArrow color='#FFBE44' />
      </div>
      <div className={styles.exchanges}>
        Exchanges <SortArrow color='#FFBE44' />
      </div>
      <HeaderButton
        field='tradingVolume'
        title='Trading volume'
        className={styles.trading}
        setUsers={setUsers}
      />
      <HeaderButton
        field='realizedPnl'
        setUsers={setUsers}
        title='Realized PNL'
        className={styles.pnl}
      />
      <HeaderButton
        field='runningEquity'
        setUsers={setUsers}
        title='Running equity'
        className={styles.equity}
      />
      <div className={styles.runningBotsBox}>
        <HeaderButton
          field='runningBots'
          setUsers={setUsers}
          title='Running Bots'
          className={styles.runningBotsBox}
        />
        <div className={styles.botsTypeBox}>
          <div className={styles.botsType}>Pro</div>
          <div className={styles.botsType}>Auto</div>
        </div>
      </div>
      <div className={styles.runningBotsBox}>
        <HeaderButton
          field='botsCreated'
          setUsers={setUsers}
          title='Bots created'
          className={styles.runningBotsBox}
        />
        <div className={styles.botsTypeBox}>
          <div className={styles.botsType}>Pro</div>
          <div className={styles.botsType}>Auto</div>
        </div>
      </div>
      <div className={styles.runningBotsBox}>
        <HeaderButton
          field='botsCopied'
          setUsers={setUsers}
          title='Bots copied'
          className={styles.runningBotsBox}
        />
        <div className={styles.botsTypeBox}>
          <div className={styles.botsType}>Pro</div>
          <div className={styles.botsType}>Auto</div>
        </div>
      </div>
      <div className={styles.profit}>
        Total profit <SortArrow color='#FFBE44' />
      </div>
      <div className={styles.botHistory}>
        Bots history <SortArrow color='#FFBE44' />
      </div>
      <div className={styles.stopBtn} />
    </div>
  );
}
