/* eslint-disable max-len */
import * as React from 'react';

interface IWarningIconProps {
  color?: string;
  width?: number;
  height?: number;
  strokeColor?: string;
}

function WarningIcon({ color = '#E45770', width = 22, height = 20 }: IWarningIconProps) {
  return (
    <svg width={width} height={height}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.13156 3.52867L15.0448 13.4798C15.3255 13.9522 14.9747 14.5426 14.4133 14.5426H2.58672C2.02533 14.5426 1.67447 13.9522 1.95516 13.4798L7.86844 3.52867C8.14913 3.0563 8.85087 3.05631 9.13156 3.52867ZM6.69554 2.87073C7.49752 1.52112 9.50248 1.52111 10.3045 2.87073L16.2177 12.8219C17.0197 14.1715 16.0172 15.8585 14.4133 15.8585H2.58672C0.982758 15.8585 -0.0197236 14.1715 0.782259 12.8219L6.69554 2.87073ZM8.5 5.40704C8.93153 5.40704 9.28136 5.74692 9.28136 6.1662V9.9408C9.28136 10.3601 8.93153 10.7 8.5 10.7C8.06847 10.7 7.71865 10.3601 7.71865 9.9408V6.1662C7.71865 5.74692 8.06847 5.40704 8.5 5.40704ZM9.38554 12.2721C9.38554 12.7473 8.98908 13.1325 8.50001 13.1325C8.01094 13.1325 7.61447 12.7473 7.61447 12.2721C7.61447 11.7969 8.01094 11.4117 8.50001 11.4117C8.98908 11.4117 9.38554 11.7969 9.38554 12.2721Z'
        fill={color}
      />
    </svg>
  );
}

export default React.memo(WarningIcon);
