// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.APRCharts_tooltipContainer__MxRlA {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 116.959px;
  height: 59.84px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.05);
}
.APRCharts_label__C5\\+kX {
  font-family: PTSans;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: #afafaf;
}
.APRCharts_charData__FiOiP {
  display: flex;
  width: 92px;
  height: 12px;
  padding: 5px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  color: rgb(255, 78, 78);
}

.APRCharts_dotContainer__PYp4p {
  width: 8px;
  height: 8px;
  background: rgb(219, 59, 33);
}
`, "",{"version":3,"sources":["webpack://./src/components/BotsStatisticPage/APRCharts/APRCharts.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;EAChB,eAAe;EACf,kBAAkB;EAClB,qCAAqC;AACvC;AACA;EACE,mBAAmB;EACnB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,cAAc;AAChB;AACA;EACE,aAAa;EACb,WAAW;EACX,YAAY;EACZ,YAAY;EACZ,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;EAClB,uBAAuB;AACzB;;AAEA;EACE,UAAU;EACV,WAAW;EACX,4BAA4B;AAC9B","sourcesContent":[".tooltipContainer {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  width: 116.959px;\n  height: 59.84px;\n  border-radius: 4px;\n  background: rgba(255, 255, 255, 0.05);\n}\n.label {\n  font-family: PTSans;\n  font-size: 13px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 24px;\n  color: #afafaf;\n}\n.charData {\n  display: flex;\n  width: 92px;\n  height: 12px;\n  padding: 5px;\n  justify-content: center;\n  align-items: center;\n  border-radius: 5px;\n  color: rgb(255, 78, 78);\n}\n\n.dotContainer {\n  width: 8px;\n  height: 8px;\n  background: rgb(219, 59, 33);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltipContainer": `APRCharts_tooltipContainer__MxRlA`,
	"label": `APRCharts_label__C5+kX`,
	"charData": `APRCharts_charData__FiOiP`,
	"dotContainer": `APRCharts_dotContainer__PYp4p`
};
export default ___CSS_LOADER_EXPORT___;
