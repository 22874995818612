import { createSlice } from '@reduxjs/toolkit';
import { IFlowStatistics } from '../types';

const initialState: IFlowStatistics[] = [
  {
    flowOperationalStatistics: {
      pnl: null,
      apr: null,
      equity: null,
      botCount: null,
      volume: null,
      totalRealizedPnl: null,
      dailyRealizedPnl: null,
      unrealizedPnl: null,
      risk: '',
      plnPoints: [],
      aprPoints: [],
      duration: null,
      startTime: null,
      finishTime: null,
      coin: '',
      lastPauseTime: null,
      lastStopTime: null,
      lastParametersUpdateTime: null,
      durationSec: null,
    },
    flowId: '',
  },
];

const statisticSlice = createSlice({
  name: 'statistics',
  initialState,
  reducers: {
    setStatistics(state, action) {
      return (state = action.payload);
    },
  },
});

export const { setStatistics } = statisticSlice.actions;

export default statisticSlice.reducer;
