import { useEffect, useState } from 'react';

import StepGroupOverview from './StepGroupOverview/StepGroupOverview';
import StepChooseUsers from './StepChooseUsers/StepChooseUsers';
import StepCreateFunctionality from './StepCreateFunctionality/StepCreateFunctionality';
import { useNavigate } from 'react-router-dom';
import styles from './CreateGroup.module.css';
import SearchBar from '../UsersListPage/SearchBar/SearchBar';
import cn from 'classnames';
import { IUser } from '../UsersListPage/UsersList/UserList.types';
import { INITIAL_FEATURES, LAST_STEP } from './constants';
import { useAddUserToWhiteListMutation } from '../../../redux/services/whiteListServices';


const CreateGroup = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [groupName, setGroupName] = useState('');
  const [features, setFeatures] = useState(INITIAL_FEATURES);
  const [userList, setUserList] = useState([] as IUser[]);
  const [selectedUsers, setSelectedUsers] = useState<IUser[]>([]);
  const [responseError, setResponseError] = useState<string | null>(null);
  const [requestAddUserToWhiteList] = useAddUserToWhiteListMutation();
  const sessionId = sessionStorage.getItem('session_id');
  const token = sessionStorage.getItem('token');

  const Steps = [
    <StepCreateFunctionality
      key={0}
      groupName={groupName}
      setGroupName={setGroupName}
      features={features}
      setFeatures={setFeatures}
    />,
    <StepChooseUsers
      key={1}
      groupName={groupName}
      userList={userList}
      setUserList={setUserList}
      selectedUsers={selectedUsers}
      setSelectedUsers={setSelectedUsers}
    />,
    <StepGroupOverview
      key={2}
      groupName={groupName}
      features={features}
      setFeatures={setFeatures}
      userList={userList}
      setUserList={setUserList}
      selectedUsers={selectedUsers}
      setSelectedUsers={setSelectedUsers}
    />,
  ];

  const currentStep = Steps[step];
  const onPrevClick = () => setStep(prevStep => prevStep - 1);

  const onNextClick = async () => {
    setStep(prevStep => prevStep + 1);

    if (step < LAST_STEP) {
      return;
    }

    const hasFeatureWhiteList = features.find(({ name }) => name === 'white list')?.checked;

    if (hasFeatureWhiteList) {
      const requestWhiteListUsers = () => Promise.allSettled(
        selectedUsers.map(async user => requestAddUserToWhiteList({
          sessionId,
          body: { email: user.email },
          token,
        })),
      );

      try {
        const responses = await requestWhiteListUsers();
        const hasError = responses
          .filter((response) => response.status === 'fulfilled')
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .some((fulfilledResponse: any) => fulfilledResponse?.value?.error);

        if (hasError || !responses.length) {
          throw new Error('whitelist response error');
        }

        setResponseError(null);
        alert('users was successfully added to whitelist');

      } catch (e) {
        setResponseError(`${e}`);
      }
    }
  };

  const stepIndicatorStyle = cn(styles.steps_indicator, styles[`step_${step + 1}`]);
  const btnNextText = step < LAST_STEP ? 'Next' : 'Save';

  const onSetSearchValue = (e: React.ChangeEvent<HTMLInputElement>) =>
    setSearchValue(e.target.value.toLowerCase());

  const onSearch = () => {
    console.log('not implemented yet');
  };

  const onSettingsOpen = () => {
    console.log('not implemented yet');
  };

  useEffect(() => {
    const isStepOut = step < 0 || step > LAST_STEP;
    if (isStepOut) {
      navigate('/admin/grouplist');
    }

  }, [currentStep]);

  useEffect(() => {
    if (responseError) {
      alert(responseError);
    }
  }, [responseError]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h3>Create a group</h3>
        <div className={stepIndicatorStyle}>
          <div className={styles.step_item}>1<div>Choose functionality</div></div>
          <div className={styles.step_item}>2<div>Choose users</div></div>
          <div className={styles.step_item}>3<div>Group overview</div></div>
        </div>
        <SearchBar
          searchValue={searchValue}
          onSearch={onSearch}
          onSetSearchValue={onSetSearchValue}
          onSettingsOpen={onSettingsOpen}
          isButtonVisible={false}
        />
      </div>
      {currentStep}
      <div className={styles.controls}>
        <button className={styles.controls_back} onClick={onPrevClick}>Back</button>
        <button className={styles.controls_next} onClick={onNextClick}>{btnNextText}</button>
      </div>
    </div>
  );
};

export default CreateGroup;

