// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HowToStart_container__qlGM6 {
    margin-top: 70px;
}

.HowToStart_container__qlGM6 h2 {
    font-family: PTSans;
    color: white;
    margin-bottom: 32px;
}

.HowToStart_container__qlGM6 ol {
    padding-left: 20px;
}

.HowToStart_container__qlGM6 li {
    margin-bottom: 24px;
    width: 100%;
}

.HowToStart_container__qlGM6 li p {
    margin: 0;
    width: 100%;
    font-size: 16px;
    font-family: PTSans;
    color: white;
    font-weight: 400;
    line-height:  22.4px;
}

.HowToStart_container__qlGM6 li p .HowToStart_textLink__hZirU {
    color: var(--text-colored);
    font-size: 16px;
    font-family: PTSans;
    font-weight: 400;
}

.HowToStart_container__qlGM6 li p .HowToStart_textLink__hZirU:visited {
    color: var(--text-colored);
}

.HowToStart_container__qlGM6 li img {
    margin: 16px 0;
    margin-left: -20px;
    width: 100%;
}

@media (max-width: 768px){
    .HowToStart_container__qlGM6 h2 {
        font-size: 22px;
        text-align: center;
    }
    .HowToStart_container__qlGM6 ol {
        padding: 0 20px;
    }
    .HowToStart_container__qlGM6 ol li img {
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        zoom: 2
    }   
}   `, "",{"version":3,"sources":["webpack://./src/components/HowToConnectPage/HowToStart/HowToStart.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;IACnB,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,SAAS;IACT,WAAW;IACX,eAAe;IACf,mBAAmB;IACnB,YAAY;IACZ,gBAAgB;IAChB,oBAAoB;AACxB;;AAEA;IACI,0BAA0B;IAC1B,eAAe;IACf,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,cAAc;IACd,kBAAkB;IAClB,WAAW;AACf;;AAEA;IACI;QACI,eAAe;QACf,kBAAkB;IACtB;IACA;QACI,eAAe;IACnB;IACA;QACI,iBAAiB;QACjB,kBAAkB;QAClB,WAAW;QACX;IACJ;AACJ","sourcesContent":[".container {\n    margin-top: 70px;\n}\n\n.container h2 {\n    font-family: PTSans;\n    color: white;\n    margin-bottom: 32px;\n}\n\n.container ol {\n    padding-left: 20px;\n}\n\n.container li {\n    margin-bottom: 24px;\n    width: 100%;\n}\n\n.container li p {\n    margin: 0;\n    width: 100%;\n    font-size: 16px;\n    font-family: PTSans;\n    color: white;\n    font-weight: 400;\n    line-height:  22.4px;\n}\n\n.container li p .textLink {\n    color: var(--text-colored);\n    font-size: 16px;\n    font-family: PTSans;\n    font-weight: 400;\n}\n\n.container li p .textLink:visited {\n    color: var(--text-colored);\n}\n\n.container li img {\n    margin: 16px 0;\n    margin-left: -20px;\n    width: 100%;\n}\n\n@media (max-width: 768px){\n    .container h2 {\n        font-size: 22px;\n        text-align: center;\n    }\n    .container ol {\n        padding: 0 20px;\n    }\n    .container ol li img {\n        margin-left: auto;\n        margin-right: auto;\n        width: 100%;\n        zoom: 2\n    }   \n}   "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `HowToStart_container__qlGM6`,
	"textLink": `HowToStart_textLink__hZirU`
};
export default ___CSS_LOADER_EXPORT___;
