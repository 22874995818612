// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.geetest_box {
  background-color: var(--main-background) !important;
}

.geetest_box_wrap .geetest_ai_detect {
  background: none !important;
}

.geetest_title {
  color: var(--text-black) !important;
}
.geetest_status_bar {
  background-color: transparent !important;
}
.geetest_btn {
  background-image: linear-gradient(
    180deg,
    var(--text-colored) 0%,
    var(--text-colored) 100%
  ) !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/ForgotPasswordModal/captcha.css"],"names":[],"mappings":"AAAA;EACE,mDAAmD;AACrD;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,mCAAmC;AACrC;AACA;EACE,wCAAwC;AAC1C;AACA;EACE;;;;cAIY;AACd","sourcesContent":[".geetest_box {\n  background-color: var(--main-background) !important;\n}\n\n.geetest_box_wrap .geetest_ai_detect {\n  background: none !important;\n}\n\n.geetest_title {\n  color: var(--text-black) !important;\n}\n.geetest_status_bar {\n  background-color: transparent !important;\n}\n.geetest_btn {\n  background-image: linear-gradient(\n    180deg,\n    var(--text-colored) 0%,\n    var(--text-colored) 100%\n  ) !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
