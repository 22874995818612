/* eslint-disable max-len */
import * as React from 'react';

interface ICopyIconProps {
  color?: string;
  width?: number;
  height?: number;
}

function CopyIcon({ color = '#fff', width = 18, height = 17 }: ICopyIconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 18 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.10521 2.60326C3.23219 2.47628 3.40441 2.40495 3.58398 2.40495H9.67773C9.85731 2.40495 10.0295 2.47628 10.1565 2.60326C10.2835 2.73024 10.3548 2.90246 10.3548 3.08203V3.75911C10.3548 4.13306 10.658 4.4362 11.0319 4.4362C11.4058 4.4362 11.709 4.13306 11.709 3.75911V3.08203C11.709 2.54331 11.495 2.02665 11.114 1.64572C10.7331 1.26479 10.2165 1.05078 9.67773 1.05078H3.58398C3.04526 1.05078 2.52861 1.26479 2.14767 1.64572C1.76674 2.02665 1.55273 2.54331 1.55273 3.08203V9.17578C1.55273 9.7145 1.76674 10.2312 2.14767 10.6121C2.52861 10.993 3.04526 11.207 3.58398 11.207H4.26107C4.63501 11.207 4.93815 10.9039 4.93815 10.5299C4.93815 10.156 4.63501 9.85287 4.26107 9.85287H3.58398C3.40441 9.85287 3.23219 9.78153 3.10521 9.65455C2.97824 9.52757 2.9069 9.35536 2.9069 9.17578V3.08203C2.9069 2.90246 2.97824 2.73024 3.10521 2.60326ZM8.32357 5.79036C7.78485 5.79036 7.26819 6.00437 6.88726 6.3853C6.50632 6.76624 6.29232 7.28289 6.29232 7.82162V13.9154C6.29232 14.4541 6.50632 14.9707 6.88726 15.3517C7.26819 15.7326 7.78485 15.9466 8.32357 15.9466H14.4173C14.956 15.9466 15.4727 15.7326 15.8536 15.3517C16.2346 14.9707 16.4486 14.4541 16.4486 13.9154V7.82162C16.4486 7.28289 16.2346 6.76624 15.8536 6.3853C15.4727 6.00437 14.956 5.79036 14.4173 5.79036H8.32357ZM7.8448 7.34284C7.97178 7.21587 8.14399 7.14453 8.32357 7.14453H14.4173C14.5969 7.14453 14.7691 7.21587 14.8961 7.34284C15.0231 7.46982 15.0944 7.64204 15.0944 7.82162V13.9154C15.0944 14.0949 15.0231 14.2672 14.8961 14.3941C14.7691 14.5211 14.5969 14.5924 14.4173 14.5924H8.32357C8.14399 14.5924 7.97178 14.5211 7.8448 14.3941C7.71782 14.2672 7.64648 14.0949 7.64648 13.9154V7.82162C7.64648 7.64204 7.71782 7.46982 7.8448 7.34284Z'
        fill={color}
      />
    </svg>
  );
}

export default React.memo(CopyIcon);
