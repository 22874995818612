// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UpdateForm_passwordForm__FbWEy {
  width: 422px;
}

.UpdateForm_passwordUpdateTitle__k1XER {
  margin-bottom: 32px;
  color: white;
  font-size: 24px;
  line-height: 22px;
}

.UpdateForm_passwordBlock__Tyrfc {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.UpdateForm_updatePasswordBtn__cP-s7 {
  display: inline-flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: var(--text-colored);
  width: max-content;
  font-family: PTSans;
  font-size: 16px;
  font-weight: 700;
  color: #000;
}

.UpdateForm_updatePasswordBtn__cP-s7:disabled {
  opacity: 0.3;
}

@media (max-width: 768px) {
  .UpdateForm_passwordForm__FbWEy {
    width: 100%;
  }

  .UpdateForm_updatePasswordBtn__cP-s7 { 
    margin-top: 4px;
    width: 100%
   }
}
`, "",{"version":3,"sources":["webpack://./src/components/SettingsPage/PasswordUpdate/UpdateForm/UpdateForm.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,mBAAmB;EACnB,YAAY;EACZ,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,oBAAoB;EACpB,kBAAkB;EAClB,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;EAClB,+BAA+B;EAC/B,kBAAkB;EAClB,mBAAmB;EACnB,eAAe;EACf,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,YAAY;AACd;;AAEA;EACE;IACE,WAAW;EACb;;EAEA;IACE,eAAe;IACf;GACD;AACH","sourcesContent":[".passwordForm {\n  width: 422px;\n}\n\n.passwordUpdateTitle {\n  margin-bottom: 32px;\n  color: white;\n  font-size: 24px;\n  line-height: 22px;\n}\n\n.passwordBlock {\n  display: flex;\n  flex-direction: column;\n  gap: 32px;\n}\n\n.updatePasswordBtn {\n  display: inline-flex;\n  padding: 12px 24px;\n  justify-content: center;\n  align-items: center;\n  border-radius: 8px;\n  background: var(--text-colored);\n  width: max-content;\n  font-family: PTSans;\n  font-size: 16px;\n  font-weight: 700;\n  color: #000;\n}\n\n.updatePasswordBtn:disabled {\n  opacity: 0.3;\n}\n\n@media (max-width: 768px) {\n  .passwordForm {\n    width: 100%;\n  }\n\n  .updatePasswordBtn { \n    margin-top: 4px;\n    width: 100%\n   }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"passwordForm": `UpdateForm_passwordForm__FbWEy`,
	"passwordUpdateTitle": `UpdateForm_passwordUpdateTitle__k1XER`,
	"passwordBlock": `UpdateForm_passwordBlock__Tyrfc`,
	"updatePasswordBtn": `UpdateForm_updatePasswordBtn__cP-s7`
};
export default ___CSS_LOADER_EXPORT___;
