import React from 'react';
import { createPortal } from 'react-dom';
import styles from './MessageModalWindow.module.css';
import CloseButton from '../../CloseButton/CloseButton';

const root: unknown = document.getElementById('root');

interface IMessageModalProps {
  actionClose: () => void;
  message: string;
}
export default function MessageModalWindow({ actionClose, message }: IMessageModalProps) {
  return createPortal(
    <div className={styles.container}>
      <div className={styles.modal}>
        <CloseButton onClick={actionClose} className={styles.closeButton} />
        {message}
      </div>
    </div>,
    root as HTMLElement,
  );
}
