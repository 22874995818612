import UltraGrid from '../../../assets/ultra-grid.svg';
import Fibo from '../../../assets/fibo.svg';
import Hybrid from '../../../assets/hybrid.svg';
import Shooter from '../../../assets/shooter.svg';
import { EStrategy } from '../../../redux/types';

interface IBotIconProps {
  icon: EType | EStrategy;
  width?: number;
  height?: number;
}
export enum EType {
  ultraGrid = 'ultra grid bot',
  fibo = 'fibo',
  shooter = 'shooter',
  hybrid = 'hybrid',
  gridBot = 'STRATEGY_GRID_TRONIK',
}

const BotIcon = ({ icon, width = 41, height = 41 }: IBotIconProps) => {
  const botIcon = {
    [EType.ultraGrid]: UltraGrid,
    [EType.shooter]: Shooter,
    [EType.fibo]: Fibo,
    [EType.hybrid]: Hybrid,
    [EStrategy.gridTronik]: UltraGrid,
  };
  return <img src={botIcon[icon]} width={width} height={height} />;
};

export default BotIcon;
