// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProfileSettings_main__cOaoY {
  width: 364px;
  display: flex;
  flex-direction: column;
  font-family: PTSans;
  color: #fff;
  line-height: 24px;
  font-weight: 400;
}

.ProfileSettings_main__cOaoY .ProfileSettings_input__vT8c5 input {
  margin-bottom: 32px;
  border-color: var(--card-background);
  background-color: rgba(255, 255, 255, 0.08);
  color: rgba(255, 255, 255, 0.3);
}

.ProfileSettings_mainTitle__s3Hcd {
  font-size: 24px;
  margin-bottom: 40px;
  line-height: 22px;
}

.ProfileSettings_fieldError__6eypC {
  right: 40px;
}

.ProfileSettings_main__cOaoY > button {
  display: flex;
  padding: 12px 24px;
  width: max-content;
  justify-self: flex-start;
  background: var(--text-colored);
  border-radius: 10px;
  color: black;
  font-weight: 700;
  font-family: PTSans;
  font-size: 14px;
}

.ProfileSettings_main__cOaoY .ProfileSettings_disabled__yMDeU {
  opacity: 0.5;
}

@media screen and (max-width: 768px) {
  .ProfileSettings_main__cOaoY {
    width: 300px;
  }
}

@media (max-width: 768px) {
  .ProfileSettings_main__cOaoY {
    width: 100%;
  }

  .ProfileSettings_main__cOaoY button {
    width: 100%;
    justify-content: center;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/SettingsPage/ProfileSettings/ProfileSettings.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,WAAW;EACX,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;EACnB,oCAAoC;EACpC,2CAA2C;EAC3C,+BAA+B;AACjC;;AAEA;EACE,eAAe;EACf,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,kBAAkB;EAClB,wBAAwB;EACxB,+BAA+B;EAC/B,mBAAmB;EACnB,YAAY;EACZ,gBAAgB;EAChB,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE;IACE,YAAY;EACd;AACF;;AAEA;EACE;IACE,WAAW;EACb;;EAEA;IACE,WAAW;IACX,uBAAuB;EACzB;AACF","sourcesContent":[".main {\n  width: 364px;\n  display: flex;\n  flex-direction: column;\n  font-family: PTSans;\n  color: #fff;\n  line-height: 24px;\n  font-weight: 400;\n}\n\n.main .input input {\n  margin-bottom: 32px;\n  border-color: var(--card-background);\n  background-color: rgba(255, 255, 255, 0.08);\n  color: rgba(255, 255, 255, 0.3);\n}\n\n.mainTitle {\n  font-size: 24px;\n  margin-bottom: 40px;\n  line-height: 22px;\n}\n\n.fieldError {\n  right: 40px;\n}\n\n.main > button {\n  display: flex;\n  padding: 12px 24px;\n  width: max-content;\n  justify-self: flex-start;\n  background: var(--text-colored);\n  border-radius: 10px;\n  color: black;\n  font-weight: 700;\n  font-family: PTSans;\n  font-size: 14px;\n}\n\n.main .disabled {\n  opacity: 0.5;\n}\n\n@media screen and (max-width: 768px) {\n  .main {\n    width: 300px;\n  }\n}\n\n@media (max-width: 768px) {\n  .main {\n    width: 100%;\n  }\n\n  .main button {\n    width: 100%;\n    justify-content: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `ProfileSettings_main__cOaoY`,
	"input": `ProfileSettings_input__vT8c5`,
	"mainTitle": `ProfileSettings_mainTitle__s3Hcd`,
	"fieldError": `ProfileSettings_fieldError__6eypC`,
	"disabled": `ProfileSettings_disabled__yMDeU`
};
export default ___CSS_LOADER_EXPORT___;
