/* eslint-disable @typescript-eslint/indent */
/* eslint-disable @typescript-eslint/typedef */ // для useState
import React, { SetStateAction, useState } from 'react';
import Summary from '../StatisticsCards/Summary';
import Profits from '../StatisticsCards/Profits';
import Triggers from '../StatisticsCards/Triggers';
import Position from '../StatisticsCards/Postition';
import TPSL from '../StatisticsCards/TPSL';
import Timing from '../StatisticsCards/Timing';

import shareIcon from '../../../assets/shareIcon.svg';
import styles from './StatisticBlock.module.css';
import ShareCard from '../../BotsPage/ShareCard/ShareCard';
import OrderHistory from '../../BotsPage/OrdersHistory/OrderHistory';
import BotSettingsModal from '../BotSettingsModal/BotSettingsModal';

import backArrow from '../../../assets/General/back-arrow.svg';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../redux/hooks/redux';
import { selectBot } from '../../../redux/selectors/rootSelector';
import { prepareStatus } from '../../BotsPage/BotsPage.utils';
import { EStatus } from '../../BotsPage/BotsCard/ActivityBotIcon';
import { IBot } from '../../../redux/types';

interface IStatisticBlock {
  setIsStatisticsOpened: React.Dispatch<SetStateAction<boolean>>;
  subBots?: IBot[];
}
export default function StatisticBlock({ setIsStatisticsOpened, subBots }: IStatisticBlock) {
  const [isOrderModalOpen, setIsOrderModalOpen] = useState(false);
  const [isShareCardOpen, setIsShareCardOpen] = useState(false);
  const [isBotSettingsModalOpen, setIsBotSettingsModalOpen] = useState(false);
  const onCloseModalWindow = () => {
    isOrderModalOpen
      ? setIsOrderModalOpen(false)
      : isShareCardOpen
      ? setIsShareCardOpen(false)
      : setIsBotSettingsModalOpen(false);
  };
  const { t } = useTranslation('botsStats');
  const selectedBot = useAppSelector(selectBot);

  const isLessStatistic = prepareStatus(selectedBot?.flowStatus || '') !== EStatus.active;
  return (
    <div className={styles.container}>
      <div>
        <h4 className={styles.statisticsTitleMobile}>Bot details</h4>
        <button className={styles.backBtn} onClick={() => setIsStatisticsOpened(false)}>
          <img src={backArrow} /> {t('backBtn')}
        </button>
      </div>

      {isLessStatistic ? (
        <div className={styles.lessStatisticBlock}>
          <Summary bot={selectedBot} subBots={subBots} />
          <Timing />
        </div>
      ) : (
        <div className={styles.statisticBlock}>
          <Summary bot={selectedBot} subBots={subBots} />
          <Profits />
          <Triggers />
          <Position />
          <TPSL />
          <Timing />
        </div>
      )}

      <div className={styles.statisticBtns}>
        <button className={styles.settingsBtn} onClick={() => setIsBotSettingsModalOpen(true)}>
          {t('settings')}
        </button>
        <button className={styles.ordersBtn} onClick={() => setIsOrderModalOpen(true)}>
          {t('orders')}
        </button>
        <button
          className={styles.shareBtn}
          onClick={() => setIsShareCardOpen(true)}
          disabled={true}
        >
          <img src={shareIcon} />
          <span>{t('share')}</span>
        </button>
      </div>
      {isShareCardOpen && <ShareCard onClose={onCloseModalWindow} />}
      {isOrderModalOpen && <OrderHistory onClose={onCloseModalWindow} />}
      {isBotSettingsModalOpen && <BotSettingsModal onClose={onCloseModalWindow} />}
    </div>
  );
}
