import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import GeeTest from 'react-geetest-v4';
import cn from 'classnames';
import { OnSuccessFn } from 'react-geetest-v4/dist/interface';
import { useForgotPasswordMutation } from '../../redux/services/signUpService';
import styles from './ForgotPasswordModal.module.css';
import './captcha.css';
import onEmailValidate from '../../utils/onEmailValidate';
import { Input } from '../CCLabsUI';
import { ESizing } from '../CCLabsUI/Input/Input';
import MessageModalWindow from './MessageModalWindow/MessageModalWindow';
import CloseButton from '../CloseButton/CloseButton';

interface ForgotPasswordModalProps {
  isOpen?: boolean;
  isCorrectCode?: boolean;
  inputValue?: string;
  isInModalContainer?: boolean;
  actionClose: () => void;
  action?: () => void;
  actionSubmit?: () => void;
}

enum EResponseCodes {
  notFound = 'email.not_found',
  ok = 'ok',
  invalid = 'email.invalid',
}

const ForgotPasswordModal = ({
  actionClose,
  isInModalContainer = false,
}: ForgotPasswordModalProps) => {
  const { t } = useTranslation('forgotPassword');
  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [isCaptchaVisible, setIsCaptchaVisible] = useState(false);
  const [forgotPassword] = useForgotPasswordMutation();
  const [responseMessage, setResponseMessage] = useState('');
  const [isShowModalWindow, setIsShowModalWindow] = useState(false);
  const containerStyles = cn(styles.container, {
    [styles.containerUnderCaptcha]: isCaptchaVisible,
  });

  const RESPONSE_MESSAGES = {
    [EResponseCodes.notFound]: t('notFound'),
    [EResponseCodes.invalid]: t('notFound'),
    [EResponseCodes.ok]: t('success'),
  };

  useEffect(() => {
    const onRequestNewPassword = async () => {
      try {
        const {
          status: { code, message },
        } = await forgotPassword({ email }).unwrap();
        if (Object.values(EResponseCodes).includes(code)) {
          return setResponseMessage(RESPONSE_MESSAGES[code as EResponseCodes]);
        }
        setResponseMessage(message);
      } catch (error) {
        console.error(error);
      }
    };
    if (isShowModalWindow) {
      onRequestNewPassword().catch((error) => console.error(error));
    }
  }, [isShowModalWindow]);

  const onInputFocus = () => {
    setIsEmailValid(true);
  };

  const onInputBlur = () => {
    setIsEmailValid(onEmailValidate(email));
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const currentEmail = e.target.value;
    setEmail(currentEmail);
    setIsSubmitDisabled(!onEmailValidate(currentEmail));
  };

  const onCaptchaClose = () => {
    setIsCaptchaVisible(false);
  };

  const onUpdatePasswordClick = () => {
    setIsCaptchaVisible(true);
  };

  const onCaptchaSuccess: OnSuccessFn = () => {
    setIsCaptchaVisible(false);
    setIsShowModalWindow(true);
  };

  if (isShowModalWindow) {
    return <MessageModalWindow actionClose={actionClose} message={responseMessage} />;
  }

  return (
    <div className={containerStyles}>
      <div className={styles.forgotPasswordContainer}>
        {isInModalContainer && <CloseButton className={styles.closeButton} onClick={actionClose} />}
        <h2>{t('passwordUpdate')}</h2>
        <Input
          className={styles.form_input}
          label={t('email')}
          placeholder='info@gmail.com'
          name='email'
          sizing={ESizing.medium}
          isValid={isEmailValid}
          onFocus={onInputFocus}
          onBlur={onInputBlur}
          onChange={onInputChange}
        />
        <GeeTest
          captchaId={process.env.REACT_APP_CAPTCHA_SITE_KEY || ''}
          onSuccess={onCaptchaSuccess}
          product={'bind'}
          onClose={onCaptchaClose}
        >
          <button
            className={styles.submit}
            onClick={onUpdatePasswordClick}
            disabled={isSubmitDisabled}
          >
            {t('submitBtn')}
          </button>
        </GeeTest>
      </div>
    </div>
  );
};

export default ForgotPasswordModal;
