import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  imageName: string;
  className?: string;
}

function LocalizedImage({ imageName, className }: Props) {
  const { i18n } = useTranslation();
  const locale = i18n.language;

  const imagePath = `/images/${locale}/${imageName}`;

  return (
    <img className={className} src={imagePath} alt="" />
  );
}

export default LocalizedImage;
