/* eslint-disable max-len */
export default function PauseIcon() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='18' height='17' viewBox='0 0 18 17' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.25977 3.08333C4.25977 2.70939 4.56291 2.40625 4.93685 2.40625H7.64518C8.01912 2.40625 8.32227 2.70939 8.32227 3.08333V13.9167C8.32227 14.2906 8.01912 14.5938 7.64518 14.5938H4.93685C4.56291 14.5938 4.25977 14.2906 4.25977 13.9167V3.08333ZM5.61393 3.76042V13.2396H6.9681V3.76042H5.61393ZM9.67643 3.08333C9.67643 2.70939 9.97957 2.40625 10.3535 2.40625H13.0618C13.4358 2.40625 13.7389 2.70939 13.7389 3.08333V13.9167C13.7389 14.2906 13.4358 14.5938 13.0618 14.5938H10.3535C9.97957 14.5938 9.67643 14.2906 9.67643 13.9167V3.08333ZM11.0306 3.76042V13.2396H12.3848V3.76042H11.0306Z'
        fill='white'
      />
    </svg>
  );
}
