import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  adminPanelInfo: {
    summary: [],
    tradingVolume: [],
    usersData: [],
    botsData: [],
    equityData: [],
    pnlData: [],
    averageValues: [],
    settingsUsage: {},
  },
};

const adminStatSlice = createSlice({
  name: 'adminStatSlice',
  initialState,
  reducers: {
    setAdminStat(state, action) {
      state.adminPanelInfo = action.payload;
    },
  },
});

export const { setAdminStat } = adminStatSlice.actions;

export default adminStatSlice.reducer;
