import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import SortArrow from '../../../assets/General/SortArrow';
import { ECurrency } from './pair.type';
import searchIcon from '../../../assets/search-icon.svg';
import styles from './PairFilter.module.css';
import { ESizing } from '../Dropdown/Dropdown';
import { ClipLoader } from 'react-spinners';
import useTransformPair from '../../../hooks/useTransformPair';

interface IPairFilter {
  options: string[];
  pair?: ECurrency | string;
  setPair: Dispatch<SetStateAction<ECurrency | string | undefined>>;
  size?: ESizing;
  market?: string;
}

export default function PairFilter({
  pair,
  setPair,
  size,
  options = [],
  market = '',
}: IPairFilter) {
  const { t } = useTranslation('filter');
  const [isOpen, setIsOpen] = useState(false);
  const [currentOptions, setCurrentOptions] = useState(options);
  const transformPair = useTransformPair();

  const handleToggleDropdown = () => setIsOpen((state) => !state);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const handleOutsideClick = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    setCurrentOptions(options);
  }, [options]);

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const handleChooseFilter = (selectedValue: string) => {
    setIsOpen(false);
    if (pair === selectedValue) {
      return setPair(undefined);
    }
    setPair(selectedValue);
    setCurrentOptions(options);
  };

  const onSearchInput = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const newCurrentOptions = options.filter((option) =>
      option.toLowerCase().includes(evt.target.value.toLowerCase()),
    );
    setCurrentOptions(newCurrentOptions);
  };

  return (
    <div
      className={cn(
        styles.filter,
        { [styles.activeFilter]: pair },
        { [styles.medium]: size === ESizing.medium },
      )}
      ref={dropdownRef}
    >
      <label onClick={handleToggleDropdown} className={styles.label}>
        {pair ? pair : t('pair')}
        <span className={cn({ [styles.rotateArrow]: isOpen })}>
          <SortArrow color='#FFF' />
        </span>
      </label>
      {isOpen && (
        <div className={styles.dropdown}>
          <div className={styles.searchBox}>
            <img src={searchIcon} />
            <input type='search' placeholder={market} onInput={onSearchInput} />
          </div>

          <div className={styles.optionsFrame}>
            {options.length ? (
              currentOptions.map((option) => (
                <span key={option} onClick={() => handleChooseFilter(option)}>
                  {transformPair(option)}
                </span>
              ))
            ) : (
              <div className={styles.spinner}>
                <ClipLoader size={35} loading={true} color='#fff' />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
