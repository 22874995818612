import React, { useEffect } from 'react';
import cn from 'classnames';
import styles from './Roadmap.module.css';
import RoadmapImg from '../../../assets/img/Roadmap.svg';
import RoadmapEnglishImg from '../../../assets/img/RoadmapEnglish.svg';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

const Roadmap: React.FC = () => {
  const { i18n } = useTranslation();
  const isEnglishLanguage = i18n.language === 'en';
  const imgSrc = isEnglishLanguage ? RoadmapEnglishImg : RoadmapImg;
  const location = useLocation();

  useEffect(() => {
    if (location.hash.includes('roadmap')) {
      window.scrollTo({
        top: document.getElementById('roadmap')?.offsetTop,
        behavior: 'smooth',
      });
    }
  }, [location]);
  return (
    <div id='roadmap' className={styles.container}>
      <h2>Roadmap</h2>
      <div className={styles.img_wrapper}>
        <img className={cn({ [styles.enImg]: isEnglishLanguage })} src={imgSrc} />
      </div>
    </div>
  );
};

export default Roadmap;
