/* eslint-disable @typescript-eslint/indent */
import { useEffect, useState } from 'react';
import {
  useCurrentUserProfileQuery,
  useGetSettingProfileQuery,
} from '../../../redux/services/settingProfileService';
import { TSettingsName } from '../../../redux/types';
import SwitchToggle from '../../SwitchToggle/SwitchToggle';
import ProfileSettings from '../ProfileSettings/ProfileSettings';

import styles from './SettingsProfile.module.css';
import { useTranslation } from 'react-i18next';

interface IProfileNotificationSettings {
  id: TSettingsName;
  text: string;
  status: boolean;
}

const SettingsProfile: React.FC = () => {
  const { t } = useTranslation('profilePage');
  const { data: userProfile } = useCurrentUserProfileQuery();
  const { data } = useGetSettingProfileQuery(userProfile?.userId || '');

  const INITIAL_NOTIFY_STATE: IProfileNotificationSettings[] = [
    {
      id: 'exchangeErrors',
      text: t('notifications.autotrading'),
      status: false,
    },
    {
      id: 'renewAccount',
      text: t('notifications.keyAdded'),
      status: false,
    },
    { id: 'apiKeys', text: t('notifications.keyExpiration'), status: false },
    { id: 'launchBotNotification', text: t('notifications.delonixPush'), status: false },
    { id: 'botIsPaused', text: t('notifications.botError'), status: false },
    { id: 'pushNotification', text: t('notifications.botStandby'), status: false },
  ];

  const [switchText, setSwitchText] =
    useState<IProfileNotificationSettings[]>(INITIAL_NOTIFY_STATE);

  const [messenger, setMessenger] = useState<
    {
      id: TSettingsName;
      text: string;
      status: boolean;
    }[]
  >([
    {
      id: 'emailNotification',
      text: t('channels.email'),
      status: false,
    },
    {
      id: 'telegramNotification',
      text: t('channels.telegram'),
      status: false,
    },
  ]);

  useEffect(() => {
    if (data) {
      const newStatusNotification = switchText.map((i) => {
        return { ...i, status: data[i.id] ?? false };
      });
      const newStatusMessenger = messenger.map((i) => {
        return { ...i, status: data[i.id] ?? false };
      });
      setSwitchText(newStatusNotification);
      setMessenger(newStatusMessenger);
    }
  }, [data]);

  // future functionality
  // eslint-disable-next-line
  const changeStatus = (id: TSettingsName) => {
    const [newStatus] = switchText.filter((item) => item.id === id);
    newStatus.status = !newStatus.status;
    setSwitchText(switchText.map((item) => (item.id === id ? (item = newStatus) : item)));
  };
  // future functionality
  // eslint-disable-next-line
  const changeCanalStatus = (id: TSettingsName) => {
    const [newStatus] = messenger.filter((item) => item.id === id);
    newStatus.status = !newStatus.status;
    setMessenger(messenger.map((item) => (item.id === id ? (item = newStatus) : item)));
  };

  return (
    <div className={styles.container}>
      <div className={styles.main}>
        <ProfileSettings />
        <div className={styles.soonContainer}>
          <p className={styles.soon}>Soon</p>
          <div className={styles.authenticationBlock}>
            <div className={styles.authenticationHeaderBlock}>
              <h3 className={styles.mainTitleAuthentication}>{t('titleNotify')}</h3>
              <h4 className={styles.authenticationTitle}>{t('subtitle')}</h4>
            </div>
            <div className={styles.toggleBlock}>
              <div className={styles.subTitle}>{t('notifications.title')}</div>
              {switchText.map((item) => {
                return (
                  <div className={styles.toggleBlockRow} key={item.id}>
                    <div className={styles.toggle}>
                      <SwitchToggle
                        isSwitched={item.status}
                        action={() => {
                          console.log('future functionality');
                          // changeStatus(item.id);
                        }}
                      />
                    </div>
                    <div className={styles.text}>{item.text}</div>
                  </div>
                );
              })}
            </div>
            <div className={styles.toggleBlockChannels}>
              <div className={styles.subTitle}>{t('channels.title')}</div>
              <div className={styles.toggleBlock}>
                {messenger.map((item) => {
                  return (
                    <div className={styles.toggleBlockRow} key={item.id}>
                      <SwitchToggle
                        isSwitched={item.status}
                        action={() => {
                          console.log('future functionality');
                          // changeCanalStatus(item.id);
                        }}
                      />
                      <div className={styles.text}>{item.text}</div>
                    </div>
                  );
                })}
              </div>
              <a className={styles.link}>{t('telegramBot')}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsProfile;
