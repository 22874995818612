// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.JoinClosedBetaTest_wrapper__J0bzi {
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.JoinClosedBetaTest_container__nIUaG h4 {
    margin: 0;
    color: white;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
}

.JoinClosedBetaTest_container__nIUaG p {
    margin: 0;
    color: white;
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
    white-space: pre-line;
}

.JoinClosedBetaTest_inputWrapper__ANbww {
    width: 390px;
}

.JoinClosedBetaTest_register__-5ASl {
    margin-top: 43px;
}

.JoinClosedBetaTest_registerLink__b5EDZ {
    display: flex;
    gap: 5px;
    margin-top: 16px;
}

.JoinClosedBetaTest_registerLink__b5EDZ a {
    color: var(--text-colored);
    text-decoration: underline;
}

@media (max-width: 426px) {
    .JoinClosedBetaTest_inputWrapper__ANbww {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

@media (max-width: 375px) {
    .JoinClosedBetaTest_inputWrapper__ANbww {
        width: 341px;
    }
}

@media (max-width: 320px) {
    .JoinClosedBetaTest_inputWrapper__ANbww {
        width: 280px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/HowToConnectPage/JoinClosedBetaTest/JoinClosedBetaTest.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;;AAEA;IACI,SAAS;IACT,YAAY;IACZ,eAAe;IACf,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,SAAS;IACT,YAAY;IACZ,eAAe;IACf,gBAAgB;IAChB,mBAAmB;IACnB,qBAAqB;AACzB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,QAAQ;IACR,gBAAgB;AACpB;;AAEA;IACI,0BAA0B;IAC1B,0BAA0B;AAC9B;;AAEA;IACI;QACI,aAAa;QACb,sBAAsB;QACtB,mBAAmB;IACvB;AACJ;;AAEA;IACI;QACI,YAAY;IAChB;AACJ;;AAEA;IACI;QACI,YAAY;IAChB;AACJ","sourcesContent":[".wrapper {\n    display: flex;\n    flex-direction: column;\n    gap: 32px;\n}\n\n.container h4 {\n    margin: 0;\n    color: white;\n    font-size: 20px;\n    font-style: normal;\n    font-weight: 700;\n}\n\n.container p {\n    margin: 0;\n    color: white;\n    font-size: 16px;\n    font-weight: 400;\n    line-height: 22.4px;\n    white-space: pre-line;\n}\n\n.inputWrapper {\n    width: 390px;\n}\n\n.register {\n    margin-top: 43px;\n}\n\n.registerLink {\n    display: flex;\n    gap: 5px;\n    margin-top: 16px;\n}\n\n.registerLink a {\n    color: var(--text-colored);\n    text-decoration: underline;\n}\n\n@media (max-width: 426px) {\n    .inputWrapper {\n        display: flex;\n        flex-direction: column;\n        align-items: center;\n    }\n}\n\n@media (max-width: 375px) {\n    .inputWrapper {\n        width: 341px;\n    }\n}\n\n@media (max-width: 320px) {\n    .inputWrapper {\n        width: 280px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `JoinClosedBetaTest_wrapper__J0bzi`,
	"container": `JoinClosedBetaTest_container__nIUaG`,
	"inputWrapper": `JoinClosedBetaTest_inputWrapper__ANbww`,
	"register": `JoinClosedBetaTest_register__-5ASl`,
	"registerLink": `JoinClosedBetaTest_registerLink__b5EDZ`
};
export default ___CSS_LOADER_EXPORT___;
