// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BotsSort_container__bVsk8 {
  display: flex;
  position: relative;
  margin-top: 2px;
  align-items: start;
  gap: 4px;
  z-index: 2;
}

.BotsSort_container__bVsk8 button,
.BotsSort_container__bVsk8 button span {
  display: flex;
  align-items: center;
  padding: 0;
}

.BotsSort_menu__OhRas {
  position: absolute;
  top: 35px;
  right: 0px;
  width: 198px;
  color: rgb(175, 175, 175);
  background-color: rgb(27, 26, 39);
  border: 1px solid var(--border-white-02);
  border-radius: 5px;
}

.BotsSort_menu__OhRas button {
  display: block;
  margin-left: 3px;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: center;
  font-size: 16px;
  width: 100%;
  cursor: pointer;
}

.BotsSort_container__bVsk8,
.BotsSort_menu__OhRas button {
  color: white;
  font-family: PTSans;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
}

@media (min-width: 769px) and (max-width: 950px) {
  .BotsSort_container__bVsk8 {
    margin-left: auto;
    z-index: 3;
  }
}

@media (max-width: 768px) {
  .BotsSort_container__bVsk8 {
    margin-left: auto;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/BotsPage/BotsSort/BotsSort.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,QAAQ;EACR,UAAU;AACZ;;AAEA;;EAEE,aAAa;EACb,mBAAmB;EACnB,UAAU;AACZ;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,UAAU;EACV,YAAY;EACZ,yBAAyB;EACzB,iCAAiC;EACjC,wCAAwC;EACxC,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,gBAAgB;EAChB,mBAAmB;EACnB,kBAAkB;EAClB,eAAe;EACf,WAAW;EACX,eAAe;AACjB;;AAEA;;EAEE,YAAY;EACZ,mBAAmB;EACnB,eAAe;EACf,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE;IACE,iBAAiB;IACjB,UAAU;EACZ;AACF;;AAEA;EACE;IACE,iBAAiB;EACnB;AACF","sourcesContent":[".container {\n  display: flex;\n  position: relative;\n  margin-top: 2px;\n  align-items: start;\n  gap: 4px;\n  z-index: 2;\n}\n\n.container button,\n.container button span {\n  display: flex;\n  align-items: center;\n  padding: 0;\n}\n\n.menu {\n  position: absolute;\n  top: 35px;\n  right: 0px;\n  width: 198px;\n  color: rgb(175, 175, 175);\n  background-color: rgb(27, 26, 39);\n  border: 1px solid var(--border-white-02);\n  border-radius: 5px;\n}\n\n.menu button {\n  display: block;\n  margin-left: 3px;\n  padding-top: 5px;\n  padding-bottom: 5px;\n  text-align: center;\n  font-size: 16px;\n  width: 100%;\n  cursor: pointer;\n}\n\n.container,\n.menu button {\n  color: white;\n  font-family: PTSans;\n  font-size: 16px;\n  line-height: 24px;\n  font-weight: 700;\n}\n\n@media (min-width: 769px) and (max-width: 950px) {\n  .container {\n    margin-left: auto;\n    z-index: 3;\n  }\n}\n\n@media (max-width: 768px) {\n  .container {\n    margin-left: auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `BotsSort_container__bVsk8`,
	"menu": `BotsSort_menu__OhRas`
};
export default ___CSS_LOADER_EXPORT___;
