import styles from '../BotSettings.module.css';
import cn from 'classnames';

export const CARD_CLASSNAMES: string = cn([styles.card], [styles.cardWrapper]);
export const INNER_CARD_CLASSES: string = cn([styles.cardWrapper], [styles.innerCard]);

export const TIMEFRAME_VALUES = {
  ru: {
    '1m': '1 минута',
    '3m': '3 минуты',
    '5m': '5 минут',
    '15m': '15 минут',
    '30m': '30 минут',
    '1h': '1 час',
    '2h': '2 часа',
    '4h': '4 часа',
    '6h': '6 часов',
    '8h': '8 часов',
    '12h': '12 часов',
    '1d': '1 день',
    '3d': '3 дня',
  },
  en: {
    '1m': '1 minute',
    '3m': '3 minutes',
    '5m': '5 minutes',
    '15m': '15 minutes',
    '30m': '30 minutes',
    '1h': '1 hour',
    '2h': '2 hours',
    '4h': '4 hours',
    '6h': '6 hours',
    '8h': '8 hours',
    '12h': '12 hours',
    '1d': '1 day',
    '3d': '3 days',
  },
};
