import { useState } from 'react';

import styles from './BotsSort.module.css';
import SortArrow from '../../../assets/General/SortArrow';
import { useTranslation } from 'react-i18next';
import { emptyFn } from '../../../constants';

export enum ESortDirections {
  newToOld = 'newToOld',
  oldToNew = 'oldToNew',
}

interface IBotsSort {
  sortDirection?: ESortDirections;
  setSortDirection?: React.Dispatch<React.SetStateAction<ESortDirections>>;
  setNeedUpdateSorting?: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function BotsSort({
  setNeedUpdateSorting = emptyFn,
  sortDirection = ESortDirections.newToOld,
  setSortDirection = emptyFn,
}: IBotsSort) {
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const { t } = useTranslation('botspage');

  const titleByDirection = {
    [ESortDirections.newToOld]: t('sort.newOld'),
    [ESortDirections.oldToNew]: t('sort.oldNew'),
  };
  const sortTitle = titleByDirection[sortDirection];

  const onSortClick = () => {
    setIsMenuVisible(!isMenuVisible);
  };

  const onSortItemClick = (direction: ESortDirections) => () => {
    setSortDirection(direction);
    setNeedUpdateSorting(true);
    setIsMenuVisible(false);
  };

  return (
    <>
      <div className={styles.container} onClick={onSortClick}>
        <span className={styles.title}>{sortTitle}</span>
        <button>
          {' '}
          <span>
            <SortArrow color='#fff' />
          </span>
        </button>

        {isMenuVisible && (
          <div className={styles.menu}>
            <button onClick={onSortItemClick(ESortDirections.newToOld)}>{t('sort.newOld')}</button>
            <button onClick={onSortItemClick(ESortDirections.oldToNew)}>{t('sort.oldNew')}</button>
          </div>
        )}
      </div>
    </>
  );
}
