// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/img/BeFirstBackground.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --wrapper-width: 1200px;
}

.MainPage_container__n0ma7 {
  margin-left: auto;
  margin-right: auto;
}
.MainPage_container__n0ma7 h2 {
  font-family: 'PTSans';
}

.MainPage_centerBlock__hEFAn {
  margin-left: auto;
  margin-right: auto;
  max-width: var(--wrapper-width);
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-size: 100% 840px;
  background-position: center 150px; 
}

@media (max-width: 1100px) {
  .MainPage_centerBlock__hEFAn {
    background-size: cover;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/MainPage/MainPage.module.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;AACzB;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;AACpB;AACA;EACE,qBAAqB;AACvB;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,+BAA+B;EAC/B,yDAA+D;EAC/D,4BAA4B;EAC5B,2BAA2B;EAC3B,iCAAiC;AACnC;;AAEA;EACE;IACE,sBAAsB;EACxB;AACF","sourcesContent":[":root {\n  --wrapper-width: 1200px;\n}\n\n.container {\n  margin-left: auto;\n  margin-right: auto;\n}\n.container h2 {\n  font-family: 'PTSans';\n}\n\n.centerBlock {\n  margin-left: auto;\n  margin-right: auto;\n  max-width: var(--wrapper-width);\n  background-image: url('../../assets/img/BeFirstBackground.svg');\n  background-repeat: no-repeat;\n  background-size: 100% 840px;\n  background-position: center 150px; \n}\n\n@media (max-width: 1100px) {\n  .centerBlock {\n    background-size: cover;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `MainPage_container__n0ma7`,
	"centerBlock": `MainPage_centerBlock__hEFAn`
};
export default ___CSS_LOADER_EXPORT___;
