import { useEffect, useState } from 'react';
import StatusGroupBar from '../StatusGroupBar/StatusGroupBar';
import { GROUPS } from '../group.mock';
import ItemGroupList from '../ItemGroupList/ItemGroupList';
import HeaderGroupList from '../HeaderGroupList/HeaderGroupList';
import { getArrayIndexes } from '../../UsersListPage/Pagination/pagination.utils';
import Pagination from '../../UsersListPage/Pagination/Pagination';
import { IGroup } from '../group.types';

import styles from './GroupList.module.css';

export default function GroupList() {
  const [groups, setGroups] = useState(GROUPS);
  const [dateValue, setDateValue] = useState<[Date, Date] | undefined>();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, indexOfFirstUser, indexOfLastUser] = getArrayIndexes(groups, currentPage);
  const currentGroups: IGroup[] = groups.slice(indexOfFirstUser, indexOfLastUser);

  useEffect(() => {
    if (dateValue) {
      const usersFilteredByDate = groups.filter(
        (group) => dateValue[1] >= group.dateTime && dateValue[0] <= group.dateTime,
      );
      setGroups(usersFilteredByDate);
    } else {
      setGroups(GROUPS);
    }
  }, [dateValue]);

  return (
    <div className={styles.container}>
      <StatusGroupBar
        groups={groups}
        setGroups={setGroups}
        dateValue={dateValue}
        setDateValue={setDateValue}
        onSettingsOpen={() => console.log('settings')}
        setAllGroups={() => setGroups(GROUPS)}
        setCurrentPage={setCurrentPage}
      />
      <div className={styles.list}>
        <HeaderGroupList setGroups={setGroups} />
        {currentGroups.map((group) => (
          <ItemGroupList group={group} key={group.id} />
        ))}
      </div>
      <Pagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
      />
    </div>
  );
}
