import { memo, useState } from 'react';
import cn from 'classnames';
import styles from './Header.module.css';
import { useTranslation } from 'react-i18next';
enum ESwitchTabs {
  daily = 1,
  weekly = 0,
}

function HeaderLeaderboard() {
  const [active, setActive] = useState(ESwitchTabs.daily);
  const switchHandler = () => setActive(Number(!active));
  const getSwitchTabClasses = (tab: ESwitchTabs) =>
    cn([styles.switchTab], { [styles.activeTab]: tab === active });
  const { t } = useTranslation('launchBotPage');
  return (
    <div className={styles.header}>
      <h3 className={styles.title}>{t('leaderboard')}</h3>
      <div className={styles.switchBox}>
        <button onClick={switchHandler} className={getSwitchTabClasses(ESwitchTabs.daily)}>
          {t('daily')}
        </button>
        <button onClick={switchHandler} className={getSwitchTabClasses(ESwitchTabs.weekly)}>
          {t('weekly')}
        </button>
      </div>
    </div>
  );
}

export default memo(HeaderLeaderboard);
