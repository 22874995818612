import StatisticsCurrencyValue from './StatisticsCurrencyValue/StatisticsCurrencyValue';

interface CurrencyCard {
  value: number;
  description: string;
  isBluredAll: boolean;
  activeCurrency: string;
}

interface CurrenciesCardsProps {
  cards: CurrencyCard[];
  activeCurrency: string;
}

const btcValue: number = 0.0000344;
const ethValue: number = 0.0005478;

export default function CurrenciesCards({ cards, activeCurrency }: CurrenciesCardsProps) {
  const setCoinValue = (card: CurrencyCard) => {
    const coinValue = {
      USDT: card.value,
      BTC: card.value * btcValue,
      ETH: card.value * ethValue,
    };

    return coinValue[activeCurrency as keyof typeof coinValue];
  };

  return (
    <>
      {cards.map((card) => {
        return (
          <StatisticsCurrencyValue
            key={card.description}
            value={setCoinValue(card)}
            activeCurrency={card.activeCurrency}
            isBluredAll={card.isBluredAll}
            description={card.description}
          />
        );
      })}
    </>
  );
}
