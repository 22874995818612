/* eslint-disable @typescript-eslint/no-floating-promises */
import { memo, useEffect, useState } from 'react';

import { EMode } from '../BotsStatisticPage/BotsListStatistic/BotCard/BotCard';
import RiskBot, { ERisk } from '../BotsPage/BotsCard/RiskBot';
import Dropdown, { ESizing } from './Dropdown/Dropdown';
import { Exchanges } from '../LaunchBotPage/Leaderboard/ListLeaderboard/ExchangesIcons';
import { ECurrency } from './PairFilter/pair.type';
import FilterIcon from '../../assets/filter-icon.svg';

import styles from './Filter.module.css';
import SortArrow from '../../assets/General/SortArrow';
import cn from 'classnames';
import DateFilter from '../AdminPage/UsersListPage/DateFilter/DateFilter';
import { useGetUserStatsMutation } from '../../redux/services/adminStatsService';
import { useAppDispatch } from '../../redux/hooks/redux';
import { setAdminStat } from '../../redux/slices/adminStatSlice';
import { formatDeepSnakeObjKeysToCamelCase } from '../../utils/formatSnakeToCamelCase';
import { useTranslation } from 'react-i18next';
import { MILLISECONDS_DIVIDER } from './Filter.constants';
import useRequestExchangesFilterData from '../../hooks/requests/useRequestExchangesFilterData';
import { EExchangeType, EMarketType, EStrategyType } from '../../redux/types';

interface IFilter {
  isCompact?: boolean;
  size?: ESizing;
  calendar?: boolean;
}

const renderUppercasedOption = (option: string) =>
  option
    .toLowerCase()
    .split('_')
    .filter((_, i) => i > 0)
    .join(' ');

function Filter({ isCompact = false, size = ESizing.large, calendar = true }: IFilter) {
  const { t } = useTranslation('filter');
  const dispatch = useAppDispatch();
  const [mode, setMode] = useState<EMode>();
  const [strategy, setStrategy] = useState<EStrategyType | string>();
  const [exchange, setExchange] = useState<EExchangeType>();
  const [pair, setPair] = useState<ECurrency | string>();
  const [risk, setRisk] = useState<ERisk>();
  const [marketType, setMarketType] = useState<EMarketType>();
  const [isShowDropdowns, setIsShowDropdowns] = useState(false);
  const toggleDropdowns = () => setIsShowDropdowns((state) => !state);
  const isShownCompactDropdowns = isShowDropdowns && isCompact;
  const isShownDropDowns = isShownCompactDropdowns || !isCompact;
  const [isShowCalendar, setIsShowCalendar] = useState(false);
  const [dateValue, setDateValue] = useState<[Date, Date] | undefined>();
  const [dateFrom, setDateFrom] = useState<string>('');
  const [dateTo, setDateTo] = useState<string>('');
  const formatOption: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
  };

  const [requestStats] = useGetUserStatsMutation();
  const sessionId = sessionStorage.getItem('session_id');
  const token = sessionStorage.getItem('token');

  const [exchangesOptions, setExchangeOptions] = useState<EExchangeType[]>([]);
  const [marketOptions, setMarketOptions] = useState<EMarketType[]>([]);
  const [pairsOptions, setPairsOptions] = useState<string[]>([]);

  const [requestExchangesFilterData] = useRequestExchangesFilterData({
    token,
    sessionId,
    marketType,
    setExchangeOptions,
    setMarketOptions,
    setPairsOptions,
  });

  useEffect(() => {
    requestExchangesFilterData();
  }, [marketType]);

  useEffect(() => {
    // todo: remove handlers from useEffect; bind to date input change events
    if (dateValue) {
      setDateFrom(new Intl.DateTimeFormat('ru', formatOption).format(dateValue[0]));
      setDateTo(new Intl.DateTimeFormat('ru', formatOption).format(dateValue[1]));

      const requestStatsAsync = async () => {
        const startDate = Math.floor(
          (new Date(dateValue[0]) as unknown as number) / MILLISECONDS_DIVIDER,
        );
        const endDate = Math.floor(
          (new Date(dateValue[1]) as unknown as number) / MILLISECONDS_DIVIDER,
        );

        try {
          const response = await requestStats({
            sessionId,
            token,
            body: { start: startDate, end: endDate },
          }).unwrap();
          dispatch(setAdminStat(formatDeepSnakeObjKeysToCamelCase(response.admin_panel_info)));
        } catch (e) {
          console.log('error admin stats: ', e);
        }
      };

      requestStatsAsync();
    } else {
      setDateFrom('');
      setDateTo('');
    }
  }, [dateValue]);

  return (
    <div className={styles.container}>
      {isCompact && (
        <div className={styles.compactFilter}>
          <img src={FilterIcon} /> <span>Filter</span>
          <button className={cn({ [styles.opened]: isShowDropdowns })} onClick={toggleDropdowns}>
            <SortArrow color='#fff' />
          </button>
        </div>
      )}
      {isShownDropDowns && (
        <div className={cn(styles.dropdowns, { [styles.compactDropdowns]: isCompact })}>
          <Dropdown
            label={t('mode')}
            value={mode}
            setValue={setMode}
            options={[EMode.pro, EMode.auto]}
            renderOption={(mode) => mode}
            size={size}
          />
          <Dropdown
            label={t('exchange')}
            value={exchange}
            setValue={setExchange}
            options={exchangesOptions}
            renderOption={(exchange) => exchange && Exchanges[exchange]}
            size={size}
          />
          <Dropdown
            label={t('market')}
            value={marketType}
            setValue={setMarketType}
            options={marketOptions}
            renderOption={renderUppercasedOption}
            size={size}
          />
          {/* todo: update when changes Filter.tsx functionality */}
          <Dropdown
            label={t('strategy')}
            value={strategy}
            setValue={setStrategy}
            options={[]}
            renderOption={renderUppercasedOption}
            size={size}
          />
          <Dropdown
            label={t('pair')}
            value={pair}
            setValue={setPair}
            options={pairsOptions}
            renderOption={(pair) => pair}
            size={size}
          />
          <Dropdown
            label={t('risk')}
            value={risk}
            setValue={setRisk}
            options={[ERisk.high, ERisk.medium, ERisk.low]}
            renderOption={(risk) => <RiskBot risk={risk} />}
            size={size}
          />
          {calendar && (
            <DateFilter
              dateFrom={dateFrom}
              dateTo={dateTo}
              dateValue={dateValue}
              setDateValue={setDateValue}
              setIsShowCalendar={setIsShowCalendar}
              isShowCalendar={isShowCalendar}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default memo(Filter);
