import cn from 'classnames';
import styles from './BotsCard.module.css';
import IconWithTooltip from '../../IconWithTooltip/IconWithTooltip';

interface IRiskBotProps {
  risk?: string;
  size?: ESizing;
  enableIcon?: boolean;
  label?: string;
}

export enum ESizing {
  medium = 'medium',
  small = 'small',
}

export enum ERisk {
  high = 'HIGH',
  medium = 'MEDIUM',
  low = 'LOW',
}

export default function RiskBot({
  enableIcon = true,
  risk = ERisk.low,
  size = ESizing.medium,
  label,
}: IRiskBotProps) {
  const riskContent = {
    [ERisk.high]: 'High risk',
    [ERisk.medium]: 'Mid risk',
    [ERisk.low]: 'Low risk',
  };

  return (
    <div
      className={cn(
        [styles.risk],
        { [styles.riskMedium]: size === ESizing.medium },
        { [styles.riskSmall]: size === ESizing.small },
        { [styles.highRisk]: risk === ERisk.high },
        { [styles.mediumRisk]: risk === ERisk.medium },
        { [styles.lowRisk]: risk === ERisk.low && !label },
        { [styles.riskLabel]: label },
      )}
    >
      {label || riskContent[risk as ERisk]}{' '}
      {enableIcon && <IconWithTooltip content={'Soon'} color={label ? '#afafaf' : '#74e29980'} />}
    </div>
  );
}
