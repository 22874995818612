import { createSlice } from '@reduxjs/toolkit';

const profileSlice = createSlice({
  initialState: {
    access: '',
  },
  name: 'profileSlice',
  reducers: {
    setAccess: (state, action) => {
      state.access = action.payload;
    },
  },
});

export const { setAccess } = profileSlice.actions;

export default profileSlice.reducer;
