// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GroupIcon_icon__UPw7I {
  box-sizing: border-box;
  display: flex;
  height: 24px;
  width: max-content;
  padding: 5px 10px;
  justify-content: center;
  align-items: center;
  background-color: rgb(120, 190, 135);
  border-radius: 10px;
  color: #000;
  font-size: 15px;
}

.GroupIcon_admin__eCx5o {
  background-color: var(--purple-background);
}

.GroupIcon_sudo__M9sep {
  background-color: var(--secondary-text);
  color: #fff;
}

.GroupIcon_whitelist__AUIVW {
  background-color: rgb(255, 219, 151);
}
`, "",{"version":3,"sources":["webpack://./src/components/AdminPage/UsersListPage/GroupIcon/GroupIcon.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,aAAa;EACb,YAAY;EACZ,kBAAkB;EAClB,iBAAiB;EACjB,uBAAuB;EACvB,mBAAmB;EACnB,oCAAoC;EACpC,mBAAmB;EACnB,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,0CAA0C;AAC5C;;AAEA;EACE,uCAAuC;EACvC,WAAW;AACb;;AAEA;EACE,oCAAoC;AACtC","sourcesContent":[".icon {\n  box-sizing: border-box;\n  display: flex;\n  height: 24px;\n  width: max-content;\n  padding: 5px 10px;\n  justify-content: center;\n  align-items: center;\n  background-color: rgb(120, 190, 135);\n  border-radius: 10px;\n  color: #000;\n  font-size: 15px;\n}\n\n.admin {\n  background-color: var(--purple-background);\n}\n\n.sudo {\n  background-color: var(--secondary-text);\n  color: #fff;\n}\n\n.whitelist {\n  background-color: rgb(255, 219, 151);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": `GroupIcon_icon__UPw7I`,
	"admin": `GroupIcon_admin__eCx5o`,
	"sudo": `GroupIcon_sudo__M9sep`,
	"whitelist": `GroupIcon_whitelist__AUIVW`
};
export default ___CSS_LOADER_EXPORT___;
