import { CSSProperties, ReactNode, memo } from 'react';
import styles from './Tabs.module.css';
import cn from 'classnames';

export interface ITab {
  value: string;
  render?: ReactNode;
  className?: string;
  style?: CSSProperties;
}

export interface ITabsProps {
  activeTab: string | null;
  tabs: ITab[];
  readOnly?: boolean;
  direction?: string;
  width?: number;
  height?: number;
  tabClassName?: string;
  activeTabClassName?: string;
  className?: string;
  onSetActiveTab: (activeTab: ITab) => void;
  disabled?: boolean;
}

function Tabs({
  disabled = false,
  activeTab,
  tabs,
  readOnly = false,
  direction = 'row',
  onSetActiveTab,
  tabClassName,
  activeTabClassName,
  className,
}: ITabsProps): JSX.Element {
  const choseStyle = (index: number, array: ITab[]): string | undefined => {
    if (index === 0) {
      return direction === 'row' ? styles.leftBorderRounded : styles.topBorderRounded;
    } else if (index === array.length - 1) {
      return direction === 'row' ? styles.rightBorderRounded : styles.bottomBorderRounded;
    }
  };

  const getTabClassNames = (item: ITab, index: number, array: ITab[]) => {
    const isEditableTabActive = activeTab === item.value && !readOnly;
    const defaultTabClassName = tabClassName || styles.statisticsSingleTab;
    const defaultActiveTabClassName = activeTabClassName || styles.activeTab;

    return cn(
      item.className,
      defaultTabClassName,
      styles.tab,
      { [defaultActiveTabClassName]: isEditableTabActive },
      choseStyle(index, array),
    );
  };

  return (
    <div
      className={cn([
        className ? className : styles.statisticsTabs,
        className,
        direction === 'row' ? '' : styles.directionColumn,
      ])}
    >
      {tabs.map((item, index, array) => {
        return (
          <div
            key={item.value}
            style={item.style}
            className={getTabClassNames(item, index, array)}
            onClick={() => disabled || onSetActiveTab(item)}
          >
            {item.render || item.value}
          </div>
        );
      })}
    </div>
  );
}

export default memo(Tabs);
