import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { ITab, Navigation } from '../Navigation/Navigation';
import StatisticsMain from '../StatisticPage/StatisticsMain/StatisticsMain';
import styles from './Page.module.css';

function TotalProfit() {
  const { t } = useTranslation('tabs');

  const tabs: ITab[] = [
    { id: 1, name: t('statistic.totalProfit'), link: '/statistic/totalProfit' },
    { id: 2, name: t('statistic.botStats'), link: '/statistic/bot' },
  ];
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <Navigation tabs={tabs} title={t('statistic.title')} />
        <StatisticsMain />
      </div>
    </div>
  );
}

export default memo(TotalProfit);
