// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CloseButton_container__ogTR0 {
  width: 20px;
  height: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/CloseButton/CloseButton.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,UAAU;AACZ","sourcesContent":[".container {\n  width: 20px;\n  height: 20px;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `CloseButton_container__ogTR0`
};
export default ___CSS_LOADER_EXPORT___;
