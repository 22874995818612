import TitleCard from '../../BotsPage/BotSettings/Blocks/TitleCard';
import LineOfCard from './LineOfCard';

import styles from './Cards.module.css';
import { useTranslation } from 'react-i18next';

export default function Postition() {
  const { t } = useTranslation('botsStats');
  return (
    <div className={styles.container}>
      <TitleCard title={t('position')} />
      <LineOfCard title={t('botPosition')} value='Value' />
      <LineOfCard title={t('exchangePosition')} value='Value' />
      <LineOfCard title={t('liquidationPrice')} value='$1,543,678' />
      <LineOfCard title={t('tradingVolume')} value='$1,543,678' />
    </div>
  );
}
