// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PasswordValidation_passwordValidationText__p7hXk {
  color: var(--text-black, #afafaf);
  font-family: PTSans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.PasswordValidation_box__YIUma {
  padding: 0;
  display: inline-flex;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.2);
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
}

.PasswordValidation_container__fGCC0 {
  display: flex;
  gap: 12px;
  margin-bottom: 5px;
}

@media (max-width: 768px) {
  .PasswordValidation_container__fGCC0 {
    margin-bottom: 12px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/SettingsPage/PasswordUpdate/PasswordValidation.module.css"],"names":[],"mappings":"AAAA;EACE,iCAAiC;EACjC,mBAAmB;EACnB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,UAAU;EACV,oBAAoB;EACpB,kBAAkB;EAClB,oCAAoC;EACpC,WAAW;EACX,YAAY;EACZ,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,SAAS;EACT,kBAAkB;AACpB;;AAEA;EACE;IACE,mBAAmB;EACrB;AACF","sourcesContent":[".passwordValidationText {\n  color: var(--text-black, #afafaf);\n  font-family: PTSans;\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 400;\n}\n\n.box {\n  padding: 0;\n  display: inline-flex;\n  border-radius: 5px;\n  background: rgba(255, 255, 255, 0.2);\n  width: 20px;\n  height: 20px;\n  justify-content: center;\n  align-items: center;\n}\n\n.container {\n  display: flex;\n  gap: 12px;\n  margin-bottom: 5px;\n}\n\n@media (max-width: 768px) {\n  .container {\n    margin-bottom: 12px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"passwordValidationText": `PasswordValidation_passwordValidationText__p7hXk`,
	"box": `PasswordValidation_box__YIUma`,
	"container": `PasswordValidation_container__fGCC0`
};
export default ___CSS_LOADER_EXPORT___;
