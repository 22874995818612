/* eslint-disable max-len */
import * as React from 'react';

interface IDoneIconProps {
  color?: string;
  width?: number;
  height?: number;
  strokeWidth?: number;
  style?: React.CSSProperties;
}

function DoneIcon({
  color = '#92F8E3',
  width = 15,
  height = 12,
  strokeWidth = 1,
  style,
  ...rest
}: IDoneIconProps) {
  return (
    <svg width={width} height={height} viewBox={'0 0 15 12'} style={style} {...rest}>
      <path
        d='M5.10039 8.92461L1.95039 5.77461L0.900391 6.82461L5.10039 11.0246L14.1004 2.02461L13.0504 0.974609L5.10039 8.92461Z'
        fill={color}
        strokeWidth={strokeWidth}
      />
    </svg>
  );
}

export default React.memo(DoneIcon);
