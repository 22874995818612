import usdtLogo from '../../../assets/usdt_logo.svg';
import bnbLogo from '../../../assets/bnb_logo.svg';
import ethLogo from '../../../assets/eth_logo.svg';
import btcLogo from '../../../assets/btc_logo.svg';
import maticLogo from '../../../assets/matic_logo.svg';

export const data = [
  { name: 'BTC', value: 50 },
  { name: 'USDT', value: 15 },
  { name: 'BNB', value: 25 },
  { name: 'ETH', value: 5 },
  { name: 'MATIC', value: 3 },
  { name: 'Others', value: 2 },
];

export const logos = [
  { name: 'BTC', logo: btcLogo },
  { name: 'USDT', logo: usdtLogo },
  { name: 'BNB', logo: bnbLogo },
  { name: 'ETH', logo: ethLogo },
  { name: 'MATIC', logo: maticLogo },
  { name: 'Others', logo: btcLogo },
];

export const colors = [
  { name: 'BTC', color: '#FF4F44' },
  { name: 'USDT', color: '#19E3CB' },
  { name: 'BNB', color: '#ECA51F' },
  { name: 'ETH', color: '#627EEA' },
  { name: 'MATIC', color: '#FFF500' },
  { name: 'Others', color: '#19E3CB' },
];
