import React from 'react';

const MIN_PASSWORD_LENGTH = 16;

const onPasswordValidate = (
  e: React.ChangeEvent<HTMLInputElement>,
  onSetLengthPass: (bool: boolean) => void,
  onSetLowerCasePass: (bool: boolean) => void,
  onSetUpperCasePass: (bool: boolean) => void,
  onSetDigitPass: (bool: boolean) => void,
  onSetSymPass: (bool: boolean) => void,
) => {
  const smallLetters: RegExp = /[а-яa-z]/;
  const digit: RegExp = /\d/;
  const specSym: RegExp = /[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/;
  const bigLetters: RegExp = /[А-ЯA-Z]/;

  if ((e.currentTarget as HTMLInputElement).value?.length >= MIN_PASSWORD_LENGTH) {
    onSetLengthPass(true);
  } else {
    onSetLengthPass(false);
  }
  if (smallLetters.test((e.currentTarget as HTMLInputElement).value)) {
    onSetLowerCasePass(true);
  } else {
    onSetLowerCasePass(false);
  }
  if (bigLetters.test((e.currentTarget as HTMLInputElement).value)) {
    onSetUpperCasePass(true);
  } else {
    onSetUpperCasePass(false);
  }
  if (digit.test((e.currentTarget as HTMLInputElement).value)) {
    onSetDigitPass(true);
  } else {
    onSetDigitPass(false);
  }
  if (specSym.test((e.currentTarget as HTMLInputElement).value)) {
    onSetSymPass(true);
  } else {
    onSetSymPass(false);
  }
};

export default onPasswordValidate;
