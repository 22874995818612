import React, { useState } from 'react';
import cn from 'classnames';
import styles from './Input.module.css';
import eyeOpen from '../../../assets/img/EyeOpen.svg';
import eyeClosed from '../../../assets/img/EyeClose.svg';

export enum ESizing {
  large = 'large',
  medium = 'medium',
}

export interface IInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  sizing: ESizing;
  placeholder?: string;
  isValid?: boolean;
  isPassword?: boolean;
  errorMessage?: string;
}

const Input: React.FC<IInputProps> = ({
  label,
  sizing,
  isValid = true,
  isPassword = false,
  className = '',
  placeholder = '',
  errorMessage = 'Error',
  id,
  name,
  ...props
}) => {
  const [inputType, setInputType] = useState(isPassword ? 'password' : 'text');
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const passwordIcon = isPasswordVisible ? eyeClosed : eyeOpen;

  const classNames = cn(
    className,
    { [styles.invalid]: !isValid },
    { [styles.password]: isPassword },
    { [styles.medium]: sizing === ESizing.medium },
    { [styles.password_visible]: isPasswordVisible },
    styles.container,
  );

  const togglePasswordVisibility = () => {
    if (isPassword) {
      setInputType((prevType) => (prevType === 'password' ? 'text' : 'password'));
      setIsPasswordVisible(!isPasswordVisible);
    }
  };

  return (
    <label htmlFor={id} className={classNames}>
      {label && <div>{label}</div>}
      <div className={styles.inputContainer}>
        <input
          id={id}
          name={name}
          type={inputType}
          placeholder={placeholder}
          {...props}
        />
        {isPassword && <img src={passwordIcon} onClick={togglePasswordVisibility} />}
      </div>
      {!isValid && <div className={styles.error_subtitle}>{errorMessage}</div>}
    </label>
  );
};

export default Input;
