import BinanceIcon from '../../../../assets/Logo/BinanceIcon';
import BitgetIcon from '../../../../assets/Logo/BitgetIcon';
import BibytIcon from '../../../../assets/Logo/BibytIcon';
import OkxIcon from '../../../../assets/Logo/OkxIcon';
import { ReactElement } from 'react';
import { EExchangeType } from '../../../../redux/types';

interface IExchange {
  [key: string]: ReactElement;
}

export const Exchanges: IExchange = {
  [EExchangeType.binance]: <BinanceIcon />,
  [EExchangeType.bitget]: <BitgetIcon />,
  [EExchangeType.bybit]: <BibytIcon />,
  [EExchangeType.okx]: <OkxIcon width={60} height={20} />,
};
