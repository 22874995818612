/* eslint-disable max-len */
export default function StopIcon() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='18' height='17' viewBox='0 0 18 17' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.90625 3.08333C2.90625 2.70939 3.20939 2.40625 3.58333 2.40625H14.4167C14.7906 2.40625 15.0938 2.70939 15.0938 3.08333V13.9167C15.0938 14.2906 14.7906 14.5938 14.4167 14.5938H3.58333C3.20939 14.5938 2.90625 14.2906 2.90625 13.9167V3.08333ZM4.26042 3.76042V13.2396H13.7396V3.76042H4.26042Z'
        fill='white'
      />
    </svg>
  );
}
