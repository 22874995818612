import { useCancelAnyOperationsMutation, useChangePasswordMutation, useConfirmOperationMutation } from '../../redux/services/signUpService';

enum EErrors {
  id = 'uuid.invalid.user',
  password = 'password.weak',
  exists = 'user.session.exists',
  awaiting = 'operation.awaiting',
}

/* eslint-disable camelcase */
function useChangePassword(): [
  (newPassword: string, currentPassword: string) => Promise<string | null>,
  (code: string, operation_id: string, currentPassword: string) => Promise<string | null>,
] {
  const token = sessionStorage.getItem('token');
  const userId = localStorage.getItem('userId');
  const [changePassword] = useChangePasswordMutation();
  const [confirmOperation] = useConfirmOperationMutation();
  const [cancelAnyOperations] = useCancelAnyOperationsMutation();

  const onChangePassword = async (newPassword: string, currentPassword: string) => {
    if (!token) {
      return null;
    }

    const body = {
      password: newPassword,
    };

    const credentials = {
      body: {
        password: currentPassword,
        code: '',
      },
    };
    try {
      const {
        operation_id,
        status: { code },
      } = await changePassword({ token, userId, body }).unwrap();
      const isAwaitingError = Object.values(EErrors).includes(code) && code === EErrors.awaiting;

      if (code === 'ok') {
        return operation_id;
      }
      if (isAwaitingError) {
        await cancelAnyOperations({ userId, access_token: token, credentials });
        const {
          operation_id,
        } = await changePassword({ token, userId, body }).unwrap();
        return operation_id;
      }

      return null;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const onSubmitVerificationCode = async (emailCode: string, operation_id: string, currentPassword: string) => {
    const credentials = {
      body: {
        password: currentPassword,
        code: emailCode,
      },
    };
    try {
      const {
        status: { code },
      } = await confirmOperation({ userId, operation_id, access_token: token, credentials }).unwrap();
      const isAwaitingError = Object.values(EErrors).includes(code) && code === EErrors.awaiting;

      if (code === 'ok') {
        return code;
      }
      if (isAwaitingError) {
        await cancelAnyOperations({ userId, access_token: token, credentials });
        await onSubmitVerificationCode(emailCode, operation_id, currentPassword);
      }

      return null;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  return [onChangePassword, onSubmitVerificationCode];
}

export default useChangePassword;
