import { Dispatch, SetStateAction } from 'react';

import PaginationButton from '../../../../assets/pagination-button.svg';
import styles from './Pagination.module.css';
import { getPaginationPages } from './pagination.utils';

interface IPagination {
  currentPage: number;
  totalPages: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
}

export default function Pagination({ currentPage, totalPages, setCurrentPage }: IPagination) {
  const pageNumbers = getPaginationPages(totalPages, currentPage);

  return (
    <div className={styles.container}>
      <div className={styles.pages}>{`${currentPage} of ${totalPages}`}</div>
      <div className={styles.pagination}>
        <button disabled={currentPage === 1} onClick={() => setCurrentPage((state) => state - 1)}>
          <img src={PaginationButton} />
        </button>
        {pageNumbers.map((pageNumber, index) => (
          <button
            key={index}
            onClick={() => {
              if (typeof pageNumber === 'number') {
                setCurrentPage(pageNumber);
              }
            }}
            className={currentPage === pageNumber ? styles.activePage : styles.page}
          >
            {pageNumber}
          </button>
        ))}
        <button
          disabled={currentPage === totalPages}
          onClick={() => setCurrentPage((state) => state + 1)}
        >
          <img src={PaginationButton} style={{ rotate: '180deg' }} />
        </button>
      </div>
    </div>
  );
}
