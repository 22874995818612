import { ReactElement, memo } from 'react';
import cn from 'classname';
import BotIcon from '../../../BotsPage/BotIcon/BotIcon';
import PnlValue from '../../../BotsPage/BotsCard/PnlValue';
import StrategyBot from '../../../BotsPage/BotsCard/StrategyBot';
import RiskBot, { ESizing } from '../../../BotsPage/BotsCard/RiskBot';
import RuntimeBot from '../../../BotsPage/BotsCard/RuntimeBot';
import ClockIcon from '../../../../assets/clockIcon.svg';
import PersonIcon from '../../../../assets/person.svg';
import styles from './List.module.css';
import { Exchanges } from './ExchangesIcons';
import MarketBot from '../../../BotsPage/BotsCard/MarketBot';
import { IBoardListItem } from './ListLeaderboard.types';
import { useTranslation } from 'react-i18next';
import { formatPlace } from '../../../../utils/formatBotPlaceNumber';

interface ILeaderboardList {
  bot: IBoardListItem;
}

function LeadersListItem({
  bot: {
    botType,
    count,
    exchange,
    name,
    pair,
    place,
    pnl,
    risk,
    runtime,
    market,
    isTrendFlipper,
    strategies,
  },
}: ILeaderboardList) {
  const exchangeIcon: ReactElement = Exchanges[exchange];
  const { t } = useTranslation('launchBotPage');
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <BotIcon icon={botType} height={23} width={24} /> {name}
      </div>
      <div className={styles.place}>{formatPlace(place)}</div>
      <div className={styles.values}>
        <div className={styles.columnKeys}>
          <div className={styles.row}>PNL:</div>
          <div className={styles.row}>{t('market')}:</div>
          <div className={styles.row}>{t('strategy')}:</div>
          <div className={styles.row}>{t('pair')}:</div>
          <div className={styles.row}>{t('risk')}:</div>
        </div>
        <div className={styles.columnValues}>
          <div className={styles.row}>
            <PnlValue value={pnl} char='USD' size={ESizing.small} />
          </div>
          <div className={styles.row}>
            <MarketBot market={market} />
          </div>
          <div className={styles.row}>
            {' '}
            <StrategyBot strategy={strategies} trendFlipper={isTrendFlipper} />
          </div>
          <div className={cn([styles.row, styles.pair])}>{pair}</div>
          <div className={styles.row}>
            <RiskBot enableIcon={false} risk={risk} />
          </div>
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.runtime}>
          <img src={ClockIcon} />
          <RuntimeBot runtime={runtime} />
        </div>
        <div className={styles.count}>
          <img src={PersonIcon} /> {count}
        </div>
      </div>
      <div className={styles.footer}>
        {exchangeIcon} <button>{t('copyBtn')}</button>
      </div>
    </div>
  );
}

export default memo(LeadersListItem);
