import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { ITab } from '../Navigation/Navigation';
import styles from './Page.module.css';
import BotsStatisticPage from '../BotsStatisticPage/BotsStatisticPage';

function BotsStatistics() {
  const { t } = useTranslation('tabs');

  const tabs: ITab[] = [
    { id: 1, name: t('statistic.totalProfit'), link: '/statistic/totalProfit' },
    { id: 2, name: t('statistic.botStats'), link: '/statistic/bot' },
  ];
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <BotsStatisticPage navigationTabs={tabs} navigationTitle={t('statistic.title')} />
      </div>
    </div>
  );
}

export default memo(BotsStatistics);
