import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import styles from './Team.module.css';

interface ITeam {
  id: number;
  title: string;
  avatar?: string;
  name: string | ReactNode;
  description: string | ReactNode;
}
export default function Team() {
  const { t } = useTranslation('aboutPage');
  const TEAM: ITeam[] = [
    {
      id: 1,
      title: 'Founder',
      avatar: './images/Team/founder.png',
      name: t('founder.name'),
      description: t('founder.description'),
    },
    {
      id: 2,
      title: 'Full stack Developer',
      avatar: './images/Team/amazonAvatar.png',
      name: 'CyberSage Y',
      description: t('softwareEngineer'),
    },
    {
      id: 3,
      title: 'Head of community',
      avatar: './images/Team/binanceAvatar.png',
      name: (
        <>
          {t('headCommunity.name')}{' '}
          <a href='https://www.binance.com/en/event/angels'>
            <img src='./images/Team/binance-miniIcon.svg' alt='link' />
          </a>
        </>
      ),
      description: t('headCommunity.description'),
    },
    {
      id: 4,
      title: 'TPM',
      avatar: './images/Team/amazonAvatar.png',
      name: 'Volt Voyager',
      description: (
        <>
          {t('tpm.span1')}{' '}
          <span className={styles.tesla}>
            <img src='./images/Team/tesla.svg' alt='Tesla' />
          </span>{' '}
          {t('tpm.span2')}
          <span className={styles.amazon}>
            <img src='./images/Team/amazon.svg' alt='Amazon' />
          </span>
          <br />
          {t('tpm.span3')}
        </>
      ),
    },
    {
      id: 5,
      title: 'CPO',
      name: 'Paola',
      description: t('cpo'),
    },
    {
      id: 6,
      title: 'Product manager',
      name: 'IIInfinity',
      description: t('iiinfinity'),
    },
    {
      id: 7,
      title: 'Product manager',
      name: 'Qwl manager',
      description: t('owlManager'),
    },
    {
      id: 8,
      title: 'Team lead',
      name: 'Iremoon',
      description: t('iremoon'),
    },
    {
      id: 9,
      title: 'Senior Frontend Developer',
      name: 'Anton',
      description: t('anton'),
    },
    {
      id: 10,
      title: 'Frontend Developer Middle+',
      name: 'Valeranur',
      description: t('valeranur'),
    },
    {
      id: 11,
      title: 'Backend Developer',
      name: 'Maj0r',
      description: (
        <>
          <>{t('boringWozniak.p1')}</> <br />
          <>{t('boringWozniak.p2')}</>
        </>
      ),
    },
    {
      id: 12,
      title: 'Backend Developer Senior',
      name: 'Monbear',
      description: t('monbear'),
    },
  ];

  return (
    <div className={styles.container}>
      <h3>{t('team')}</h3>
      <div className={styles.team}>
        <img className={styles.lines} />
        {TEAM.map((member, index) => (
          <div className={cn(styles.card, { [styles.oddCard]: index % 2 !== 0 })} key={member.id}>
            <div className={styles.cardHeader}>
              {member.avatar && <img src={member.avatar} alt='avatar' />}
              <div className={styles.person}>
                <p>{member.title}</p>
                <p>{member.name}</p>
              </div>
            </div>
            <p className={styles.description}>{member.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}
