import React, { useState } from 'react';
import styles from './ConfirmCredsChangeModal.module.css';
import CloseButton from '../../CloseButton/CloseButton';
import { Input } from '../../CCLabsUI';
import { ESizing } from '../../CCLabsUI/Input/Input';
import { useTranslation } from 'react-i18next';

enum Errors {
  wrongCode = '2fa.code.wrong',
  wrongPassword = 'password.wrong',
}

interface ResponseErrorMessages {
  [key: string]: string;
}

type CredType = 'password' | 'email';

interface IConfirmCredsChange {
  email: string;
  credType: CredType;
  actionClose: () => void;
  actionSubmit: (code: string) => Promise<string | null>;
  actionResend: () => Promise<void>;
}

const RESPONSE_ERROR_MESSAGES: ResponseErrorMessages = {
  [Errors.wrongPassword]: 'The password you entered is incorrect',
  [Errors.wrongCode]: 'The verification code you entered is incorrect',
};

const ConfirmCredsChange = ({ email, credType, actionClose, actionSubmit, actionResend }: IConfirmCredsChange) => {
  const { t } = useTranslation('profilePage');
  const successMessage = {
    password: t('securityCheck.passwordChangeSuccess'),
    email: t('securityCheck.emailChangeSuccess'),
  };

  const [code, setCode] = useState('');
  const [error, setError] = useState<string | undefined>();
  const [isSuccess, setIsSuccess] = useState(false);

  const isCodeInputEmpty = code.length === 0;
  const isInputValid = error === undefined;

  const onChangeCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCode(e.target.value);
  };

  const handleSubmit = async () => {
    setIsSuccess(true);
    const res = await actionSubmit(code);
    if (!res) {
      setError('Error');
    } else if (res === Errors.wrongCode || res === Errors.wrongPassword) {
      setError(RESPONSE_ERROR_MESSAGES[res]);
    } else {
      setError(undefined);
      setIsSuccess(true);
    }
  };

  const onClose = () => {
    setIsSuccess(false);
    actionClose();
  };

  const onClickResend = async () => {
    setCode('');
    await actionResend();
  };

  return (
    <div className={styles.modalContainer}>
      <div className={styles.modal}>
        <div className={styles.closeButton}>
          <CloseButton onClick={onClose} />
        </div>
        {isSuccess ? (
          <p className={styles.emailChangeSuccess}>{successMessage[credType]}</p>
        ) : (
          <div>
            <div className={styles.textBlock}>
              <h3>{t('securityCheck.title')}</h3>
            </div>
            <Input
              id='code'
              sizing={ESizing.large}
              label={t('securityCheck.enterVerificationCode', { email })}
              placeholder={t('securityCheck.confirmCode')}
              value={code}
              errorMessage={error}
              isValid={isInputValid}
              onChange={onChangeCode}
            />

            <div onClick={onClickResend} className={styles.resend}>
              {!isCodeInputEmpty && <p>{t('resend')}</p>}
            </div>

            <div>
              <button onClick={handleSubmit} className={styles.confirmBtn}>{t('securityCheck.confirmBtn')}</button>
            </div>
          </div>
        )}

      </div>
    </div>
  );
};

export default ConfirmCredsChange;
