const MOCKED_DATA = [
  'white list',
  'TA2',
  'PSSR',
  'PDI',
  'CCR',

  'TA3',
  'PSSR3',
  'PDI3',
  'CCR3',

  'TA24',
  'PSSR4',
  'PDI4',
  'CCR4',

  'TA25',
  'PSSR5',
  'PD5I',
  'CCR5',

  'TA26',
  'PSSR6',
  'PDI6',
  'CCR6',

  'TA27',
  'PSSR7',
  'PDI7',
  'CCR7',
];
export const INITIAL_FEATURES = MOCKED_DATA.map((dataItem) => ({
  name: dataItem,
  checked: false,
}));

export const LAST_STEP = 2;
