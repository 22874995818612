/* eslint-disable max-len */
import { memo } from 'react';
import styles from './PasswordValidation.module.css';

interface PasswordValidationProps {
  text: string;
  isMatch: boolean;
}

const passwordValidation = ({ text, isMatch }: PasswordValidationProps): JSX.Element => {
  return (
    <>
      {isMatch ? (
        <div className={styles.container}>
          <span className={styles.box}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='25'
              viewBox='0 0 24 25'
              fill='none'
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M19.7304 6.47765C20.1076 6.88106 20.0864 7.51386 19.683 7.89107L10.058 16.8911C9.67363 17.2505 9.07649 17.2505 8.69209 16.8911L4.31709 12.8011C3.91365 12.424 3.89234 11.7912 4.2695 11.3877C4.64667 10.9843 5.27947 10.963 5.68292 11.3401L9.37493 14.7917L18.317 6.43022C18.7204 6.05302 19.3532 6.07425 19.7304 6.47765Z'
                fill='white'
              />
            </svg>
          </span>
          <span className={styles.passwordValidationText}>{text}</span>
        </div>
      ) : (
        <div className={styles.container}>
          <span className={styles.box}></span>
          <span className={styles.passwordValidationText}>{text}</span>
        </div>
      )}
    </>
  );
};

export default memo(passwordValidation);
