import { ChangeEvent, useEffect, useState } from 'react';
import TitleSettingsCard from './TitleCard';
import { CARD_CLASSNAMES } from '../SettingsBlock/SettingsBlock.constants';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks/redux';
import { tempSettings } from '../../../../redux/selectors/rootSelector';
import {
  changeTakeProfitMaxDistance,
  changeTakeProfitMinDistance,
  toggleTakeProfitDistanceUsePercent,
} from '../../../../redux/slices/tempSettingsSlice';
import InputSettings from '../InputSettings/InputSettings';
import { checkIsFloat, checkValidTakeProfit } from './input.utils';

function TakeProfit() {
  const { t } = useTranslation('botspage');
  const dispatch = useAppDispatch();
  const {
    strategyParameters: {
      trailingTp: { minDistance, maxDistance, usePercent },
    },
  } = useAppSelector(tempSettings);
  const { validation } = useAppSelector((state) => state.validateParams);

  const [validationStatuses, setValidationStatuses] = useState(
    checkValidTakeProfit(minDistance, maxDistance, validation.trailingTp),
  );

  useEffect(() => {
    setValidationStatuses(checkValidTakeProfit(minDistance, maxDistance, validation.trailingTp));
  }, [validation]);

  const onToggleTakeProfitDistanceUsePercent = () => {
    dispatch(toggleTakeProfitDistanceUsePercent());
  };

  const handleChangeMinDistance = (e: ChangeEvent<HTMLInputElement>) => {
    checkIsFloat(e.target.value) && dispatch(changeTakeProfitMinDistance(e.target.value));
  };

  const handleChangeMaxDistance = (e: ChangeEvent<HTMLInputElement>) => {
    checkIsFloat(e.target.value) && dispatch(changeTakeProfitMaxDistance(e.target.value));
  };

  return (
    <div className={CARD_CLASSNAMES}>
      <TitleSettingsCard
        title={t('settings.takeProfit.title')}
        tooltip={t('tooltips.takeProfit.title')}
      />
      <InputSettings
        label={t('settings.takeProfit.minDistance')}
        placeholder='0'
        value={minDistance}
        usePercent={usePercent}
        onChange={handleChangeMinDistance}
        setUsePercent={onToggleTakeProfitDistanceUsePercent}
        tooltip={t('tooltips.takeProfit.minDistance')}
        hasError={!validationStatuses.isMinDistanceValid}
        errorMessage='Must be bigger than 0'
      />
      <InputSettings
        label={t('settings.takeProfit.maxDistance')}
        placeholder='0'
        value={maxDistance}
        usePercent={usePercent}
        onChange={handleChangeMaxDistance}
        setUsePercent={onToggleTakeProfitDistanceUsePercent}
        tooltip={t('tooltips.takeProfit.maxDistance')}
        hasError={!validationStatuses.isMaxDistanceValid}
        errorMessage='Must be bigger than 0 and "Min distance"'
      />
    </div>
  );
}

export default TakeProfit;
