import React, { useState } from 'react';
import styles from './EnterPasswordModal.module.css';
import CloseButton from '../../CloseButton/CloseButton';
import { Input } from '../../CCLabsUI';
import { ESizing } from '../../CCLabsUI/Input/Input';
import ForgotPasswordModal from '../../ForgotPasswordModal';
import { useTranslation } from 'react-i18next';

interface IConfirmEmailChange {
  actionClose: () => void;
  actionSubmit: (password: string) => void;
}

const ConfirmEmailChange = ({ actionClose, actionSubmit }: IConfirmEmailChange) => {
  const { t } = useTranslation('profilePage');

  const [currentPassword, setCurrentPassword] = useState('');
  const [isForgotPasswordModalVisible, setIsForgotPasswordModalVisible] = useState(false);

  const onChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentPassword(e.target.value);
  };

  const onSubmit = () => {
    actionSubmit(currentPassword);
  };

  const onForgotPasswordClick = () => {
    setIsForgotPasswordModalVisible(true);
  };

  if (isForgotPasswordModalVisible) {
    return (
      <div className={styles.modalContainer}>
        <div className={styles.forgotPasswordContainer}>
          <ForgotPasswordModal
            isInModalContainer
            actionClose={() => setIsForgotPasswordModalVisible(false)}
          />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.modalContainer}>
      <div className={styles.modal}>
        <CloseButton onClick={actionClose} className={styles.closeButton} />
        <div className={styles.textBlock}>
          <h3>{t('passwordEnter.title')}</h3>
        </div>
        <Input
          isPassword
          id='password'
          sizing={ESizing.large}
          label={t('passwordEnter.label')}
          placeholder={t('passwordEnter.placeholder')}
          value={currentPassword}
          onChange={onChangePassword}
        />

        <div className={styles.buttonsBlock}>
          <button onClick={onSubmit} className={styles.confirmBtn} disabled={!currentPassword}>
            {t('nextBtn')}
          </button>
          <a onClick={onForgotPasswordClick} className={styles.forgotPassword} href='#'>
            {t('passwordEnter.forgotPassword')}
          </a>
        </div>
      </div>
    </div>
  );
};

export default ConfirmEmailChange;
