import { createPortal } from 'react-dom';
import CloseButton from '../../../CloseButton/CloseButton';
import styles from './SureModalWindow.module.css';

interface IBanModal {
  onClose: () => void;
  onBan: () => void;
}

const root: unknown = document.getElementById('root');

export default function ProSettingsModal({ onClose, onBan }: IBanModal) {
  return createPortal(
    <div className={styles.container}>
      <div className={styles.modalContainer}>
        <button className={styles.closeBtn}>
          <CloseButton onClick={onClose} />
        </button>
        <h2 className={styles.title}>Are you sure?</h2>
        <div className={styles.btns}>
          <button className={styles.yesBtn} onClick={onBan}>
            Да
          </button>
          <button className={styles.noBtn} onClick={onClose}>
            Нет
          </button>
        </div>
      </div>
    </div>,
    root as HTMLElement,
  );
}
