import { EChanel } from '../../NotificationsPage/NotificationList/Notification.types';
import { Checkbox } from '../../../CCLabsUI';
import onSelectItems from './choose.utils';

import styles from './Choose.module.css';
import { IChooseItems } from './Choose.types';

export default function ChooseChannel({ items, setItems }: IChooseItems<EChanel>) {
  const CHANNELS = Object.values(EChanel);

  return (
    <div className={styles.container}>
      <h4>Choose a channel</h4>
      <div className={styles.groups}>
        {CHANNELS.map((chanel) => (
          <Checkbox
            className={styles.checkbox}
            label={chanel}
            key={chanel}
            onClick={() => onSelectItems(items, setItems, chanel)}
          />
        ))}
      </div>
    </div>
  );
}
