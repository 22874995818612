import { AreaChart, Area, XAxis, YAxis, ReferenceLine, Tooltip, TooltipProps } from 'recharts';
import styles from './PNLChart.module.css';
import { IRechartsData } from '../APRCharts/APRCharts';

const data: IRechartsData[] = [
  {
    name: 'A',
    value: 500,
  },
  {
    name: 'B',
    value: 1100,
  },
  {
    name: 'C',
    value: 500,
  },
  {
    name: 'D',
    value: 1750,
  },
  {
    name: 'E',
    value: -650,
  },
  {
    name: 'F',
    value: 500,
  },
];

const CustomTooltip = ({ active, payload }: TooltipProps<string, number>) => {
  if (active && payload && payload.length) {
    return (
      <div className={styles.tooltipContainer}>
        <div className={styles.label}>09 Aug 2022</div>
        <div className={styles.charData}>-11,669.78%</div>
      </div>
    );
  }
  return null;
};

const PNLCharts = () => {
  return (
    <>
      <AreaChart width={240} height={36} data={data}>
        <XAxis dataKey='name' hide />
        <YAxis hide />
        <Tooltip
          content={<CustomTooltip />}
          wrapperStyle={{ outline: 'none' }}
          position={{ x: -5, y: -95 }}
          cursor={false}
        />
        <ReferenceLine y={0} stroke='#90ed6b' />
        <ReferenceLine
          stroke='#90ed6b'
          segment={[
            { x: 'D', y: 0 },
            { x: 'D', y: 1750 },
          ]}
        />
        <Area type='monotone' dataKey='value' stroke='#90ed6b' fill='#90ed6bb1' />
      </AreaChart>
    </>
  );
};

export default PNLCharts;
