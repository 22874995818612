// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BotsList_botsList__0t\\+CB {
  display: flex;
  flex-wrap: wrap;
  row-gap: 5px;
  column-gap: 20px;
  width: 1360px;
}

@media screen and (max-width: 1400px) {
  .BotsList_botsList__0t\\+CB {
    height: max-content;
    width: 920px;
  }
}

@media screen and (max-width: 950px) {
  .BotsList_botsList__0t\\+CB {
    height: max-content;
    width: 440px;
  }
}

@media (max-width: 768px) {
  .BotsList_botsList__0t\\+CB {
    height: auto;
    width: auto;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/BotsStatisticPage/BotsListStatistic/BotsList/BotsList.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;EACf,YAAY;EACZ,gBAAgB;EAChB,aAAa;AACf;;AAEA;EACE;IACE,mBAAmB;IACnB,YAAY;EACd;AACF;;AAEA;EACE;IACE,mBAAmB;IACnB,YAAY;EACd;AACF;;AAEA;EACE;IACE,YAAY;IACZ,WAAW;EACb;AACF","sourcesContent":[".botsList {\n  display: flex;\n  flex-wrap: wrap;\n  row-gap: 5px;\n  column-gap: 20px;\n  width: 1360px;\n}\n\n@media screen and (max-width: 1400px) {\n  .botsList {\n    height: max-content;\n    width: 920px;\n  }\n}\n\n@media screen and (max-width: 950px) {\n  .botsList {\n    height: max-content;\n    width: 440px;\n  }\n}\n\n@media (max-width: 768px) {\n  .botsList {\n    height: auto;\n    width: auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"botsList": `BotsList_botsList__0t+CB`
};
export default ___CSS_LOADER_EXPORT___;
