// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../assets/img/RoadmapMobile.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../../assets/img/RoadmapMobileEnglish.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Roadmap_container__R7Q5C {
  margin-left: auto;
  margin-right: auto;
  max-width: var(--wrapper-width);
  box-sizing: border-box;
  padding-bottom: 50px;
}

.Roadmap_container__R7Q5C h2 {
  margin-top: 5px;
  margin-left: calc(var(--main-content-padding) + 5px);
  margin-bottom: 0px;
  padding-top: 140px;
  color: white;
}

.Roadmap_container__R7Q5C img {
  display: block;
  margin-top: 49px;
  margin-right: auto;
  margin-left: 111px;
}

@media (max-width: 900px) {
  .Roadmap_container__R7Q5C {
    padding-bottom: 5px;
    position: relative;
    max-width: 500px;
  }

  .Roadmap_container__R7Q5C h2 {
    margin: auto;
    margin-top: 1px;
    padding-top: 76px;
    font-size: 24px;
    font-weight: 400;
    text-align: center;
  }

  .Roadmap_img_wrapper__SX25z {
    margin: auto;
    margin-top: 46px;
    width: 94%;
    overflow-x: visible;
  }

  .Roadmap_container__R7Q5C .Roadmap_img_wrapper__SX25z img {
    margin: auto;
    content: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    width: 327px;
    min-width: 327px;
    height: auto;
  }

  .Roadmap_enImg__opt2J {
    content: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/MainPage/Roadmap/Roadmap.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,+BAA+B;EAC/B,sBAAsB;EACtB,oBAAoB;AACtB;;AAEA;EACE,eAAe;EACf,oDAAoD;EACpD,kBAAkB;EAClB,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE;IACE,mBAAmB;IACnB,kBAAkB;IAClB,gBAAgB;EAClB;;EAEA;IACE,YAAY;IACZ,eAAe;IACf,iBAAiB;IACjB,eAAe;IACf,gBAAgB;IAChB,kBAAkB;EACpB;;EAEA;IACE,YAAY;IACZ,gBAAgB;IAChB,UAAU;IACV,mBAAmB;EACrB;;EAEA;IACE,YAAY;IACZ,gDAAqD;IACrD,YAAY;IACZ,gBAAgB;IAChB,YAAY;EACd;;EAEA;IACE,2DAAuE;EACzE;AACF","sourcesContent":[".container {\n  margin-left: auto;\n  margin-right: auto;\n  max-width: var(--wrapper-width);\n  box-sizing: border-box;\n  padding-bottom: 50px;\n}\n\n.container h2 {\n  margin-top: 5px;\n  margin-left: calc(var(--main-content-padding) + 5px);\n  margin-bottom: 0px;\n  padding-top: 140px;\n  color: white;\n}\n\n.container img {\n  display: block;\n  margin-top: 49px;\n  margin-right: auto;\n  margin-left: 111px;\n}\n\n@media (max-width: 900px) {\n  .container {\n    padding-bottom: 5px;\n    position: relative;\n    max-width: 500px;\n  }\n\n  .container h2 {\n    margin: auto;\n    margin-top: 1px;\n    padding-top: 76px;\n    font-size: 24px;\n    font-weight: 400;\n    text-align: center;\n  }\n\n  .img_wrapper {\n    margin: auto;\n    margin-top: 46px;\n    width: 94%;\n    overflow-x: visible;\n  }\n\n  .container .img_wrapper img {\n    margin: auto;\n    content: url('../../../assets/img/RoadmapMobile.svg');\n    width: 327px;\n    min-width: 327px;\n    height: auto;\n  }\n\n  .enImg {\n    content: url('../../../assets/img/RoadmapMobileEnglish.svg') !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Roadmap_container__R7Q5C`,
	"img_wrapper": `Roadmap_img_wrapper__SX25z`,
	"enImg": `Roadmap_enImg__opt2J`
};
export default ___CSS_LOADER_EXPORT___;
