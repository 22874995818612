import cn from 'classnames';
import styles from './AverageTable.module.css';

import { selectAdminStats } from '../../../../../redux/selectors/rootSelector';
import { useAppSelector } from '../../../../../redux/hooks/redux';
import { prepareAverageValues } from '../utils';

export default function AverageValuesTable() {
  const formatOption: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  };

  const adminPanel = useAppSelector(selectAdminStats).adminPanelInfo;
  const { averageValues } = adminPanel;
  const preparedAverageValues = prepareAverageValues(averageValues);

  return (
    <div className={styles.table}>
      <div className={cn(styles.group, styles.firstGroup)} />
      <div className={cn(styles.group, styles.secondGroup)} />
      <div className={styles.header}>
        <div className={styles.cell}>Date</div>
        <div className={styles.columnGroup}>
          <div>
            <div className={styles.columnsTitle}>Entry order quantity</div>
          </div>
          <div className={styles.modeBox}>
            <div className={styles.cell}>All</div>
            <div className={styles.cell}>Pro</div>
            <div className={styles.cell}>Auto</div>
          </div>
        </div>
        <div className={styles.columnGroup}>
          <div>
            <div className={styles.columnsTitle}>Entry order distance</div>
          </div>
          <div className={styles.modeBox}>
            <div className={styles.cell}>All</div>
            <div className={styles.cell}>Pro</div>
            <div className={styles.cell}>Auto</div>
          </div>
        </div>
        <div className={styles.columnGroup}>
          <div>
            <div className={styles.columnsTitle}>Trigger distance</div>
          </div>
          <div className={styles.modeBox}>
            <div className={styles.cell}>All</div>
            <div className={styles.cell}>Pro</div>
            <div className={styles.cell}>Auto</div>
          </div>
        </div>
        <div className={styles.columnGroup}>
          <div>
            <div className={styles.columnsTitle}>Trade profit distance</div>
          </div>
          <div className={styles.modeBox}>
            <div className={styles.cell}>All</div>
            <div className={styles.cell}>Pro</div>
            <div className={styles.cell}>Auto</div>
          </div>
        </div>
      </div>
      <div className={styles.tableData}>
        {preparedAverageValues.map(
          ({ id, date, inOrderQty, inOrderDistance, triggerDistance, tradeProfit }) => (
            <div className={styles.row} key={id}>
              <div className={styles.cell}>
                {new Intl.DateTimeFormat('ru', formatOption).format(date)}
              </div>
              <div className={styles.modeBox}>
                <div className={styles.cell}>{inOrderQty.all}</div>
                <div className={styles.cell}>{inOrderQty.pro}</div>
                <div className={styles.cell}>{inOrderQty.auto}</div>
              </div>
              <div className={styles.modeBox}>
                <div className={styles.cell}>{inOrderDistance.all}</div>
                <div className={styles.cell}>{inOrderDistance.pro}</div>
                <div className={styles.cell}>{inOrderDistance.auto}</div>
              </div>
              <div className={styles.modeBox}>
                <div className={styles.cell}>{triggerDistance.all}</div>
                <div className={styles.cell}>{triggerDistance.pro}</div>
                <div className={styles.cell}>{triggerDistance.auto}</div>
              </div>
              <div className={styles.modeBox}>
                <div className={styles.cell}>{tradeProfit.all}</div>
                <div className={styles.cell}>{tradeProfit.pro}</div>
                <div className={styles.cell}>{tradeProfit.auto}</div>
              </div>
            </div>
          ),
        )}
      </div>
    </div>
  );
}
