// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MyBotsList_list__ULK98 {
  margin-top: 20px;
}

.MyBotsList_header__-qkfU {
  display: flex;
  color: white;
  font-family: PTSans;
  font-size: 15px;
  font-weight: 400;
  line-height: 17px;
  margin-bottom: 25px;
}

.MyBotsList_header__-qkfU > * {
  transition: width 300ms ease-in-out;
}

.MyBotsList_strategy__lfyvp {
  width: 220px;
}

.MyBotsList_short__ybvBa .MyBotsList_strategy__lfyvp {
  width: 110px;
}

.MyBotsList_exchange__ndaJW {
  width: 155px;
}

.MyBotsList_short__ybvBa .MyBotsList_exchange__ndaJW {
  width: 80px;
}

.MyBotsList_pair__EF6mI {
  width: 150px;
}

.MyBotsList_short__ybvBa .MyBotsList_pair__EF6mI {
  width: 110px;
}

.MyBotsList_pnl__0KLWQ {
  width: 270px;
}

.MyBotsList_short__ybvBa .MyBotsList_pnl__0KLWQ {
  width: 175px;
}

@media screen and (max-width: 950px) {
  .MyBotsList_header__-qkfU {
    display: none;
  }
}

@media (max-width: 768px) {
  .MyBotsList_header__-qkfU {
    display: none;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/BotsPage/MyBotsList/MyBotsList.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,mBAAmB;EACnB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,mCAAmC;AACrC;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE;IACE,aAAa;EACf;AACF;;AAEA;EACE;IACE,aAAa;EACf;AACF","sourcesContent":[".list {\n  margin-top: 20px;\n}\n\n.header {\n  display: flex;\n  color: white;\n  font-family: PTSans;\n  font-size: 15px;\n  font-weight: 400;\n  line-height: 17px;\n  margin-bottom: 25px;\n}\n\n.header > * {\n  transition: width 300ms ease-in-out;\n}\n\n.strategy {\n  width: 220px;\n}\n\n.short .strategy {\n  width: 110px;\n}\n\n.exchange {\n  width: 155px;\n}\n\n.short .exchange {\n  width: 80px;\n}\n\n.pair {\n  width: 150px;\n}\n\n.short .pair {\n  width: 110px;\n}\n\n.pnl {\n  width: 270px;\n}\n\n.short .pnl {\n  width: 175px;\n}\n\n@media screen and (max-width: 950px) {\n  .header {\n    display: none;\n  }\n}\n\n@media (max-width: 768px) {\n  .header {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": `MyBotsList_list__ULK98`,
	"header": `MyBotsList_header__-qkfU`,
	"strategy": `MyBotsList_strategy__lfyvp`,
	"short": `MyBotsList_short__ybvBa`,
	"exchange": `MyBotsList_exchange__ndaJW`,
	"pair": `MyBotsList_pair__EF6mI`,
	"pnl": `MyBotsList_pnl__0KLWQ`
};
export default ___CSS_LOADER_EXPORT___;
