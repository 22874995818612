import { TFunction } from 'i18next';
import BotFeatureImg1 from '../../../assets/img/BotFeature1.svg';
import BotFeatureImg2 from '../../../assets/img/BotFeature2.svg';
import BotFeatureImg3 from '../../../assets/img/BotFeature3.svg';
import BotFeatureImg4 from '../../../assets/img/BotFeature4.svg';

export const getFeaturesContent = (t: TFunction<'translation', undefined>) => [
  {
    id: 1,
    title: t('features.title1'),
    description: t('features.description1'),
    popup: {
      title: t('popups.title1'),
      titleMobile: t('popups.title1'),
      paragraphs: [
        t('popups.description1.p1'),
        t('popups.description1.p2'),
      ],
      paragraphsMobile: [
        t('popups.descriptionMobile1.p1'),
        t('popups.descriptionMobile1.p2'),
      ],
    },
    imgSrc: BotFeatureImg1,
  },
  {
    id: 2,
    title: t('features.title2'),
    description: t('features.description2'),
    popup: {
      title: t('popups.title2'),
      titleMobile: t('popups.title2'),
      paragraphs: [
        t('popups.description2.p1'),
      ],
      paragraphsMobile: [
        t('popups.descriptionMobile2.p1'),
      ],
    },
    imgSrc: BotFeatureImg2,
  },
  {
    id: 3,
    title: t('features.title3'),
    description: t('features.description3'),
    popup: {
      title: t('popups.title3'),
      titleMobile: t('popups.title3Mobile'),
      paragraphs: [
        t('popups.description3.p1'),
        t('popups.description3.p2'),
        t('popups.description3.p3'),
      ],
      paragraphsMobile: [
        t('popups.descriptionMobile3.p1'),
        t('popups.descriptionMobile3.p2'),
      ],
    },
    imgSrc: BotFeatureImg3,
  },
  {
    id: 4,
    title: t('features.title4'),
    description: t('features.description4'),
    popup: {
      title: t('popups.title4'),
      titleMobile: t('popups.title4'),
      paragraphs: [
        t('popups.description4.p1'),
        t('popups.description4.p2'),
      ],
      paragraphsMobile: [
        t('popups.descriptionMobile4.p1'),
        t('popups.descriptionMobile4.p2'),
      ],
    },
    imgSrc: BotFeatureImg4,
  },
];
