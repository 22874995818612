import React from 'react';

import styles from './SecuritySettings.module.css';
import AuthBlock from './AuthBlock';
import PasswordBlock from './PasswordBlock';

const SettingsSecurity: React.FC = () => {
  return (
    <div className={styles.container}>
      <div className={styles.main}>
        <PasswordBlock />
        <AuthBlock />
      </div>
    </div>
  );
};

export default SettingsSecurity;
