import BotsPage from '../BotsPage/BotsPage';
import { ITab } from '../Navigation/Navigation';
import { useTranslation } from 'react-i18next';

import styles from './Page.module.css';
function MyBots() {
  const { t } = useTranslation('tabs');

  const tabs: ITab[] = [
    { id: 1, name: t('bots.mybots'), link: '/bots/mybots' },
    { id: 2, name: t('bots.launchBot'), link: '/bots/launchbot' },
  ];
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <BotsPage navigationTabs={tabs} navigationTitle={t('bots.title')} />
      </div>
    </div>
  );
}

export default (MyBots);
