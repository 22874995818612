// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BlockingOverlay_container__gwoPI {
  position: absolute;
  background-color: rgba(48, 45, 63, 0.75);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 10px;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.BlockingOverlay_container__gwoPI h2 {
  font-size: 24px;
  color: white;
}
`, "",{"version":3,"sources":["webpack://./src/components/BlockingOverlay/BlockingOverlay.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,wCAAwC;EACxC,MAAM;EACN,SAAS;EACT,OAAO;EACP,QAAQ;EACR,mBAAmB;EACnB,aAAa;EACb,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,eAAe;EACf,YAAY;AACd","sourcesContent":[".container {\n  position: absolute;\n  background-color: rgba(48, 45, 63, 0.75);\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  border-radius: 10px;\n  z-index: 1000;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.container h2 {\n  font-size: 24px;\n  color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `BlockingOverlay_container__gwoPI`
};
export default ___CSS_LOADER_EXPORT___;
