import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks/redux';
import { tempSettings } from '../../../redux/selectors/rootSelector';
import { useCreateFlowMutation, useLaunchBotMutation } from '../../../redux/services/botService';
import { clearTempData } from '../../../redux/slices/tempSettingsSlice';
import { EStrategyType, IFlowParameters } from '../../../redux/types';
import { formatDeepCamelObjKeysToSnakeCase } from '../../../utils/formatCamelToSnakeCase';
import Advantages from '../../BotsPage/BotSettings/Blocks/Advantages';
import SettingsBlock from '../../BotsPage/BotSettings/SettingsBlock/SettingsBlock';
import BotSetupHeader from '../BotSetupHeader/BotSetupHeader';
import ConfirmLaunchModal from '../ConfirmLaunchModal/ConfirmLaunchModal';
import styles from './ProSettingsModal.module.css';
import { createPortal } from 'react-dom';
import { useCheckStrategyParameters } from './ProSettingsModal.utils';
import { useNavigate } from 'react-router-dom';
import ButtonsSettings from '../../BotsPage/BotSettings/Blocks/ButtonsSettings';
import { formatDeepSnakeObjKeysToCamelCase } from '../../../utils/formatSnakeToCamelCase';
import useValidateFlowParameters from '../../../hooks/requests/useValidateFlowParameters';

interface IProSettingsModal {
  onClose: () => void;
}

interface IResponse {
  status?: {
    code: string;
    message: string;
  };
  flowProperties?: {
    flowId: string;
  };
}

const root: unknown = document.getElementById('root');

function ProSettingsModal({ onClose }: IProSettingsModal) {
  const [isConfirmWindowOpened, setIsConfirmWindowOpened] = useState(false);
  const [createdFlowId, setCreatedFlowId] = useState<string>();
  const flowParameters = useAppSelector(tempSettings);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [createFlow] = useCreateFlowMutation();
  const [launchBot] = useLaunchBotMutation();
  const [requestValidateParams] = useValidateFlowParameters();
  const { t } = useTranslation('launchBotPage');

  const isFeatureDisabled = flowParameters.flowContext.strategyType === EStrategyType.spot;

  const isValidParameters = useCheckStrategyParameters();

  useEffect(() => {
    dispatch(clearTempData());
  }, []);

  const onOpenConfirmLaunchWindow = () => {
    setIsConfirmWindowOpened(true);
  };

  const handleSaveSettings = async () => {
    const formattedFlowParameters = formatDeepCamelObjKeysToSnakeCase(
      flowParameters,
    ) as IFlowParameters;
    const isFlowParamsValid = await requestValidateParams(formattedFlowParameters);
    if (isFlowParamsValid) {
      await createFlow(formattedFlowParameters)
        .unwrap()
        .then((response) => {
          if (response.status?.code !== 'ok') {
            return alert(response.status?.message);
          } else {
            alert('Success!');
            const formattedResponse = formatDeepSnakeObjKeysToCamelCase(response) as IResponse;
            setCreatedFlowId(formattedResponse?.flowProperties?.flowId);
          }
        })
        .catch((error) => {
          console.log(error);
          alert('Something went wrong');
        });
    }
  };

  const handleConfirmLaunch = async () => {
    await launchBot(createdFlowId)
      .unwrap()
      .then((response) => {
        if (response.status?.code !== 'ok') {
          alert(response.status?.message);
        }
        if (response.status?.code === 'ok') {
          navigate('/bots/mybots');
          alert('Success!');
        }
      })
      .catch((error) => alert(error))
      .finally(() => {
        setIsConfirmWindowOpened(false);
        onClose();
      });
  };

  const handleCloseConfirmWindow = () => {
    setIsConfirmWindowOpened(false);
  };

  return createPortal(
    <>
      <div className={styles.container}>
        <div className={styles.modalContainer}>
          <h2 className={styles.title}>{t('setup')}</h2>
          <BotSetupHeader />
          <Advantages isHedgeGuardDisabled={isFeatureDisabled} />
          <SettingsBlock isMarginModeDisabled={isFeatureDisabled} />
          <ButtonsSettings
            onDiscard={onClose}
            onSave={handleSaveSettings}
            onLaunch={onOpenConfirmLaunchWindow}
            isLaunchBlocked={!createdFlowId}
            isCreateBlocked={!isValidParameters}
          />
        </div>
      </div>
      {isConfirmWindowOpened && (
        <ConfirmLaunchModal onClose={handleCloseConfirmWindow} onLaunch={handleConfirmLaunch} />
      )}
    </>,
    root as HTMLElement,
  );
}

export default memo(ProSettingsModal);
