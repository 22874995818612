export enum ELanguages {
  ru = 'RU',
  en = 'EN',
}

export interface IPopupProps {
  popupTitle: string;
  popupContent: string[];
  setIsPopupOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface IExchanges {
  id: number;
  icon?: React.ReactElement;
  name?: string;
  href?: string;
  isDisabled: boolean;
  isAvailable: boolean;
  hasConnect?: boolean;
  isSpotConnected?: boolean;
  isFuturesConnected?: boolean;
  areKeysExpired?: boolean;
  keysExpirationDate?: string;
  hasError?: boolean;
  defaultApiOpen: string;
  defaultApiSecret: string;
}

export interface IErrorResponse {
  status: number;
}
