// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PNLChart_tooltipContainer__SDjgA {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 116.959px;
  height: 59.84px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.05);
}
.PNLChart_label__DfT9d {
  font-family: PTSans;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: #afafaf;
}
.PNLChart_charData__rjv\\+g {
  display: flex;
  width: 92px;
  height: 12px;
  padding: 5px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  color: rgb(255, 78, 78);
}
`, "",{"version":3,"sources":["webpack://./src/components/BotsStatisticPage/PNLChart/PNLChart.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;EAChB,eAAe;EACf,kBAAkB;EAClB,qCAAqC;AACvC;AACA;EACE,mBAAmB;EACnB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,cAAc;AAChB;AACA;EACE,aAAa;EACb,WAAW;EACX,YAAY;EACZ,YAAY;EACZ,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;EAClB,uBAAuB;AACzB","sourcesContent":[".tooltipContainer {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  width: 116.959px;\n  height: 59.84px;\n  border-radius: 4px;\n  background: rgba(255, 255, 255, 0.05);\n}\n.label {\n  font-family: PTSans;\n  font-size: 13px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 24px;\n  color: #afafaf;\n}\n.charData {\n  display: flex;\n  width: 92px;\n  height: 12px;\n  padding: 5px;\n  justify-content: center;\n  align-items: center;\n  border-radius: 5px;\n  color: rgb(255, 78, 78);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltipContainer": `PNLChart_tooltipContainer__SDjgA`,
	"label": `PNLChart_label__DfT9d`,
	"charData": `PNLChart_charData__rjv+g`
};
export default ___CSS_LOADER_EXPORT___;
