// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StepChooseUser_container__oF8SB {
  padding-bottom: 20px;
}

.StepChooseUser_list__Q3zxL {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

.StepChooseUser_list__Q3zxL::-webkit-scrollbar {
  width: 0;
}

.StepChooseUser_list__Q3zxL::-webkit-scrollbar-thumb {
  background-color: var(--secondary-text);
}
.StepChooseUser_list__Q3zxL::-webkit-scrollbar-thumb:hover,
.StepChooseUser_list__Q3zxL::-webkit-scrollbar-thumb:active {
  background-color: var(--purple-background);
}

.StepChooseUser_container__oF8SB h4 {
  margin-top: 41px;
  margin-bottom: 0px;
  font-weight: 400;
}

.StepChooseUser_group_name__V6z\\+q {
  margin-top: 18px;
  margin-left: 12px;
  width: max-content;
  font-weight: 400;
  color: rgb(255, 255, 255);
}

.StepChooseUser_step_title__8oPUM {
  margin-top: 46px;
  margin-bottom: 3px;
  font-family: 'Jost';
  font-weight: 400;
  font-size: 24px;
}

.StepChooseUser_selected__gZMUU {
  opacity: 0.4;
}

.StepChooseUser_error__jXRyw {
  margin-top: 20px;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/components/AdminPage/CreateGroup/StepChooseUsers/StepChooseUser.module.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;AACtB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,QAAQ;AACV;;AAEA;EACE,uCAAuC;AACzC;AACA;;EAEE,0CAA0C;AAC5C;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;EAClB,gBAAgB;EAChB,yBAAyB;AAC3B;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,mBAAmB;EACnB,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;AACpB","sourcesContent":[".container {\n  padding-bottom: 20px;\n}\n\n.list {\n  display: flex;\n  flex-direction: column;\n  overflow-y: scroll;\n}\n\n.list::-webkit-scrollbar {\n  width: 0;\n}\n\n.list::-webkit-scrollbar-thumb {\n  background-color: var(--secondary-text);\n}\n.list::-webkit-scrollbar-thumb:hover,\n.list::-webkit-scrollbar-thumb:active {\n  background-color: var(--purple-background);\n}\n\n.container h4 {\n  margin-top: 41px;\n  margin-bottom: 0px;\n  font-weight: 400;\n}\n\n.group_name {\n  margin-top: 18px;\n  margin-left: 12px;\n  width: max-content;\n  font-weight: 400;\n  color: rgb(255, 255, 255);\n}\n\n.step_title {\n  margin-top: 46px;\n  margin-bottom: 3px;\n  font-family: 'Jost';\n  font-weight: 400;\n  font-size: 24px;\n}\n\n.selected {\n  opacity: 0.4;\n}\n\n.error {\n  margin-top: 20px;\n  text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `StepChooseUser_container__oF8SB`,
	"list": `StepChooseUser_list__Q3zxL`,
	"group_name": `StepChooseUser_group_name__V6z+q`,
	"step_title": `StepChooseUser_step_title__8oPUM`,
	"selected": `StepChooseUser_selected__gZMUU`,
	"error": `StepChooseUser_error__jXRyw`
};
export default ___CSS_LOADER_EXPORT___;
