import { useAppSelector } from '../../../redux/hooks/redux';
import { tempSettings } from '../../../redux/selectors/rootSelector';

export function useCheckStrategyParameters(): boolean {
  const {
    flowContext: { exchange, instrument, market, strategyType },
    flowName,
    strategyParameters,
  } = useAppSelector(tempSettings);
  const {
    multiplierInOrderQuantity,
    multiplierInOrderDistance,
    trailingBuyDistance,
    inOrderDistance,
    trailingTrigger,
    tradingRange,
    trailingTp,
    equityStop,
    leverage,
    maxTriggerCount,
    triggerDistance,
    inOrderQuantity,
    globalParameters: {
      emaBandsSettings: { atrMultiplierUp, atrMultiplierDown },
    },
    useReverse: isUseReverse,
  } = strategyParameters;

  const isContextEmpty = [flowName, exchange, instrument, market, strategyType].some(
    (item) => item === undefined,
  );

  if (isContextEmpty) {
    return false;
  }

  if (!inOrderQuantity.value) {
    return false;
  }

  if (!inOrderQuantity.value) {
    return false;
  }

  if (
    multiplierInOrderQuantity &&
    !(multiplierInOrderQuantity.ratio && multiplierInOrderQuantity.maxQuantity)
  ) {
    return false;
  }

  if (
    multiplierInOrderDistance &&
    !(multiplierInOrderDistance.ratio && multiplierInOrderDistance.maxDistance)
  ) {
    return false;
  }

  if (inOrderDistance.startFrom === 0 || !inOrderDistance.value) {
    return false;
  }

  if (trailingBuyDistance === 0) {
    return false;
  }

  if (leverage === 0) {
    return false;
  }

  if (!triggerDistance.distance) {
    return false;
  }

  if (!maxTriggerCount) {
    return false;
  }

  if (trailingTrigger && !(trailingTrigger.profitPercent && trailingTrigger.startDistance)) {
    return false;
  }

  if (tradingRange && !(tradingRange.lowerBound && tradingRange.upperBound)) {
    return false;
  }

  if (!(trailingTp.minDistance && trailingTp.maxDistance)) {
    return false;
  }

  if (equityStop && !equityStop.minEquity) {
    return false;
  }

  if (isUseReverse && !(atrMultiplierUp && atrMultiplierDown)) {
    return false;
  }

  return true;
}
