interface IResponseSummaryItem {
  date: string;
  allRunningBots: {
    all: string;
    pro: string;
    proPercent: string;
    auto: string;
    autoPercent: string;
  };
  allTradingVolume: {
    all: string;
    pro: string;
    proPercent: string;
    auto: string;
    autoPercent: string;
  };
  allRunningEquity: number;
  totalRealizedPnl: number;
  totalRealizedPnlPercent: number;
};

interface IResponseTradingVolumeItem {
  date: string;
  totalVolume: number;
  averageByBot: {
    all: string;
    pro: string;
    proPercent: string;
    auto: string;
    autoPercent: string;
  };
  averageByUser: {
    all: string;
    pro: string;
    proPercent: string;
    auto: string;
    autoPercent: string;
  };
};

interface IResponseUsersDataItem {
  date: string;
  allBotsCopied: {
    launched: string;
    launchedPercent: string;
    created: string;
    createdPercent: string;
  };
  allBotsCreated: {
    all: string;
    pro: string;
    proPercent: string;
    auto: string;
    autoPercent: string;
  };
  allBotsLaunched: {
    all: string;
    pro: string;
    proPercent: string;
    auto: string;
    autoPercent: string;
  };
  usersRegistered: number;
};

interface IResponseBotsDataItem {
  date: string;
  allBotsCreated: {
    all: string;
    pro: string;
    proPercent: string;
    auto: string;
    autoPercent: string;
  };
  runningLess24h: number;
  runningMore24h: number;
  runningMore7d: number;
  runningBots: number;
}

interface IResponseEquityDataItem {
  date: string;
  averageByBot: number;
  averageByUser: number;
  runningEquity: number;
};

interface IResponsePnlDataItem {
  date: string;
  averageByBot: {
    all: string;
    pro: string;
    proPercent: string;
    auto: string;
    autoPercent: string;
  };
  averageByUser: {
    all: string;
    pro: string;
    proPercent: string;
    auto: string;
    autoPercent: string;
  };
  dailyPnl: number;
  totalPnl: number;
}

interface IResponseAverageValuesItem {
  date: string;
  tradeProfit: {
    all: string;
    pro: string;
    proPercent: string;
    auto: string;
    autoPercent: string;
  };
}

type TResponseSummary = IResponseSummaryItem[];
type TResponseTradingVolume = IResponseTradingVolumeItem[];
type TResponseUsersData = IResponseUsersDataItem[];
type TResponseBotsData = IResponseBotsDataItem[];
type TResponseEquityData = IResponseEquityDataItem[];
type TResponsePnlData = IResponsePnlDataItem[];
type TResponseAverageValues = IResponseAverageValuesItem[];

export const prepareSummary = (
  responseSummary: TResponseSummary,
  responseTradingVolume: TResponseTradingVolume,
) => responseSummary.map(({
  date,
  allRunningBots,
  allTradingVolume,
  allRunningEquity,
  totalRealizedPnl,
  totalRealizedPnlPercent,
}, i: number) => ({
  id: date,
  date: new Date(date),
  volume: responseTradingVolume[i].totalVolume,
  averageBots: {
    all: allRunningBots.all,
    pro: `${allRunningBots.pro}/${allRunningBots.proPercent}%`,
    auto: `${allRunningBots.auto}/${allRunningBots.autoPercent}%`,
  },
  averageUsers: {
    all: allTradingVolume.all,
    pro: `${allTradingVolume.pro}/${allTradingVolume.proPercent}%`,
    auto: `${allTradingVolume.auto}/${allTradingVolume.autoPercent}%`,
  },
  equity: allRunningEquity,
  pnl: `${totalRealizedPnl}/${totalRealizedPnlPercent}%`,
}));

export const prepareUsersData = (responseUsersData: TResponseUsersData) => responseUsersData.map(({
  date,
  allBotsCopied,
  allBotsCreated,
  allBotsLaunched,
  usersRegistered,
}) => ({
  id: date,
  date: new Date(date),
  registered: usersRegistered,
  botsLaunched: {
    all: allBotsLaunched.all,
    auto: `${allBotsLaunched.auto}/${allBotsLaunched.autoPercent}%`,
    pro: `${allBotsLaunched.pro}/${allBotsLaunched.proPercent}%`,
  },
  botsCreated: {
    all: allBotsCreated.all,
    auto: `${allBotsCreated.auto}/${allBotsCreated.autoPercent}%`,
    pro: `${allBotsCreated.pro}/${allBotsCreated.proPercent}%`,
  },
  botsCopied: {
    launched: `${allBotsCopied.launched}/${allBotsCopied.launchedPercent}%`,
    created: `${allBotsCopied.created}/${allBotsCopied.createdPercent}%`,
  },
}));

export const prepareBotsData = (responseBotsData: TResponseBotsData) => responseBotsData.map(({
  date,
  allBotsCreated,
  runningLess24h,
  runningMore24h,
  runningMore7d,
  runningBots,
}) => ({
  id: date,
  date: new Date(date),
  bots: runningBots,
  botsCreated: {
    all: allBotsCreated.all,
    auto: `${allBotsCreated.auto}/${allBotsCreated.autoPercent}%`,
    pro: `${allBotsCreated.pro}/${allBotsCreated.proPercent}%`,
  },
  botsLaunched: {
    launched: '?',
    created: '?',
  },
  runtime: {
    lessDay: runningLess24h,
    moreDay: runningMore24h,
    lessWeek: runningMore7d,
  },
}));

export const prepareTradingVolume = (responseTradingVolume: TResponseTradingVolume) => responseTradingVolume.map(({
  date,
  totalVolume,
  averageByBot,
  averageByUser,
}) => ({
  id: date,
  date: new Date(date),
  volume: totalVolume,
  averageBots: {
    all: averageByBot.all,
    auto: `${averageByBot.auto}/${averageByBot.autoPercent}%`,
    pro: `${averageByBot.pro}/${averageByBot.proPercent}%`,
  },
  averageUsers: {
    all: averageByUser.all,
    auto: `${averageByUser.auto}/${averageByUser.autoPercent}%`,
    pro: `${averageByUser.pro}/${averageByUser.proPercent}%`,
  },
}));

export const prepareEquityData = (responseEquityData: TResponseEquityData) => responseEquityData.map(({
  date,
  averageByBot,
  averageByUser,
  runningEquity,
}) => ({
  id: date,
  date: new Date(date),
  equity: runningEquity,
  averageUsers: averageByUser,
  averageBots: averageByBot,
  equityAllocation: '?',
}));

export const preparePnlData = (responsePnlData: TResponsePnlData) => responsePnlData.map(({
  date,
  averageByBot,
  averageByUser,
  dailyPnl,
  totalPnl,
}) => ({
  id: date,
  date: new Date(date),
  totalPnl,
  dailyPnl,
  averageBots: {
    all: averageByBot.all,
    auto: `${averageByBot.auto}/${averageByBot.autoPercent}%`,
    pro: `${averageByBot.pro}/${averageByBot.proPercent}%`,
  },
  averageUsers: {
    all: averageByUser.all,
    auto: `${averageByUser.auto}/${averageByUser.autoPercent}%`,
    pro: `${averageByUser.pro}/${averageByUser.proPercent}%`,
  },
}));


export const prepareAverageValues = (responseAverageValues: TResponseAverageValues) => responseAverageValues.map(({
  date,
  tradeProfit,
}) => ({
  id: date,
  date: new Date(date),
  inOrderDistance: {
    all: '?',
    pro: '?',
    auto: '?',
  },
  inOrderQty: {
    all: '?',
    pro: '?',
    auto: '?',
  },
  triggerDistance: {
    all: '?',
    pro: '?',
    auto: '?',
  },
  tradeProfit: {
    all: tradeProfit.all,
    auto: `${tradeProfit.auto}/${tradeProfit.autoPercent}%`,
    pro: `${tradeProfit.pro}/${tradeProfit.proPercent}%`,
  },
}));

interface IResponseSettingsUsage {
  autofilling?: number;
  autofillingPercent?: number;
  backtesting?: number;
  backtestingPercent?: number;
  trendAutoreverse?: number;
  trendAutoreversePercent?: number;
  autohedge?: number;
  autohedgePercent?: number;
  marginMode?: number;
  marginModePercent?: number;
  quantityMultiplier?: number;
  quantityMultiplierPercent?: number;
  trailingBuy?: number;
  trailingBuyPercent?: number;
  distanceMultiplier?: number;
  distanceMultiplierPercent?: number;
  tradingRange?: number;
  tradingRangePercent?: number;
  trailingTrigger?: number;
  trailingTriggerPercent?: number;
  startCreate?: number;
  startCreatePercent?: number;
  entirePositionTp?: number;
  entirePositionTpPercent?: number;
  equityStop?: number;
  equityStopPercent?: number;
  rangeStopLoss?: number;
  rangeStopLossPercent?: number;
}

export const prepareSettingsUsage = (responseSettingsUsage: IResponseSettingsUsage) => {
  const advantagesData = [
    {
      title: 'autofilling',
      field: 'autofilling',
      value: `${responseSettingsUsage.autofilling}/${responseSettingsUsage.autofillingPercent}%`,
    },
    {
      title: 'backtesting',
      field: 'backtesting',
      value: `${responseSettingsUsage.backtesting}/${responseSettingsUsage.backtestingPercent}%`,
    },
    {
      title: 'trendflipper',
      field: 'trendflipper',
      value: `${responseSettingsUsage.trendAutoreverse}/${responseSettingsUsage.trendAutoreversePercent}%`,
    },
    {
      title: 'hedge guard',
      field: 'hedge guard',
      value: `${responseSettingsUsage.autohedge}/${responseSettingsUsage.autohedgePercent}%`,
    },
  ];

  const marginMode = {
    title: 'margin mode',
    fields: [
      { field: 'margin mode', value: `${responseSettingsUsage.marginMode}/${responseSettingsUsage.marginModePercent}%` },
      { field: 'leverage', value: `${responseSettingsUsage.autohedge}/${responseSettingsUsage.autohedgePercent}%` },
    ],
  };

  const entryOrders = {
    title: 'entry orders',
    priceLevel: { field: 'price level', value: '?' },
    groups: [
      [
        { field: 'entry order quantity', value: '?' },
        { field: 'quantity multiplier', value: `${responseSettingsUsage.quantityMultiplier}/${responseSettingsUsage.quantityMultiplierPercent}%` },
        { field: 'quantity multiplier ratio', value: '?' },
        { field: 'max entry order quantity', value: '?' },
      ],
      [
        { field: 'grid step', value: '?' },
        { field: 'grid step multiplier', value: '?' },
        { field: 'grid step ratio', value: '?' },
        { field: 'max grid step', value: '?' },
      ],
      [
        { field: 'trailing buy', value: `${responseSettingsUsage.trailingBuy}/${responseSettingsUsage.trailingBuyPercent}%` },
        { field: 'distance', value: `${responseSettingsUsage.distanceMultiplier}/${responseSettingsUsage.distanceMultiplierPercent}%` },
      ],
      [
        { field: 'trading range', value: `${responseSettingsUsage.tradingRange}/${responseSettingsUsage.tradingRangePercent}%` },
        { field: 'trading range to', value: '?' },
        { field: 'trading range from', value: '?' },
      ],
    ],
  };

  const triggers = {
    title: 'triggers',
    fields: [
      { field: 'to change', value: '?' },
      { field: 'trigger distance', value: '?' },
      { field: 'max trigger count', value: '?' },
    ],
    group: [
      { field: 'trailing trigger', value: `${responseSettingsUsage.trailingTrigger}/${responseSettingsUsage.trailingTriggerPercent}%` },
      { field: 'profit', value: '?' },
      { field: 'start distance', value: `${responseSettingsUsage.startCreate}/${responseSettingsUsage.startCreatePercent}%` },
    ],
  };

  const takeProfit = {
    title: 'take profit',
    fields: [
      { field: 'tp distance', value: '?' },
      { field: 'trailing distance', value: '?' },
    ],
    group: [
      { field: 'entire position', value: `${responseSettingsUsage.entirePositionTp}/${responseSettingsUsage.entirePositionTpPercent}%` },
      { field: 'min trigger count', value: '?' },
      { field: 'condition', value: '?' },
      { field: 'distance', value: '?' },
    ],
  };

  const stopLoss = {
    title: 'stop loss',
    fields: [
      { field: 'equity stop', value: `${responseSettingsUsage.equityStop}/${responseSettingsUsage.equityStopPercent}%` },
      { field: 'MIN equity', value: `${responseSettingsUsage.rangeStopLoss}/${responseSettingsUsage.rangeStopLossPercent}%` },
    ],
  };

  return {
    advantagesData,
    marginMode,
    entryOrders,
    triggers,
    takeProfit,
    stopLoss,
  };
};
