// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ExchangesSettings_container__cqxdb,
.ExchangesSettings_wrapper__WB8Ub {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ExchangesSettings_wrapper__WB8Ub {
  width: max-content;
}

.ExchangesSettings_cards__9AsNw {
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
}

.ExchangesSettings_title__cRlwT {
  display: flex;
  align-self: flex-start;
  margin: 100px 0 32px 0;
  color: white;
  font-weight: normal;
  font-size: 24px;
  line-height: 22px;
}

@media screen and (max-width: 1380px) {
  .ExchangesSettings_container__cqxdb {
    margin-left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 100px;
  }

  .ExchangesSettings_title__cRlwT {
    align-self: flex-start;
  }

  .ExchangesSettings_cards__9AsNw {
    justify-content: center;
    width: 668px;
  }
}

@media (max-width: 768px) {
  .ExchangesSettings_container__cqxdb {
    width: 100vw;
    padding: 0 16px;
    padding-bottom: 32px;
    box-sizing: border-box;
  }
  .ExchangesSettings_cards__9AsNw {
    flex-direction: column;
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/ExchangesPage/ExchangesSettings.module.css"],"names":[],"mappings":"AAAA;;EAEE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,SAAS;EACT,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,sBAAsB;EACtB,YAAY;EACZ,mBAAmB;EACnB,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE;IACE,cAAc;IACd,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,qBAAqB;EACvB;;EAEA;IACE,sBAAsB;EACxB;;EAEA;IACE,uBAAuB;IACvB,YAAY;EACd;AACF;;AAEA;EACE;IACE,YAAY;IACZ,eAAe;IACf,oBAAoB;IACpB,sBAAsB;EACxB;EACA;IACE,sBAAsB;IACtB,WAAW;EACb;AACF","sourcesContent":[".container,\n.wrapper {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.wrapper {\n  width: max-content;\n}\n\n.cards {\n  display: flex;\n  gap: 24px;\n  flex-wrap: wrap;\n}\n\n.title {\n  display: flex;\n  align-self: flex-start;\n  margin: 100px 0 32px 0;\n  color: white;\n  font-weight: normal;\n  font-size: 24px;\n  line-height: 22px;\n}\n\n@media screen and (max-width: 1380px) {\n  .container {\n    margin-left: 0;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    padding-bottom: 100px;\n  }\n\n  .title {\n    align-self: flex-start;\n  }\n\n  .cards {\n    justify-content: center;\n    width: 668px;\n  }\n}\n\n@media (max-width: 768px) {\n  .container {\n    width: 100vw;\n    padding: 0 16px;\n    padding-bottom: 32px;\n    box-sizing: border-box;\n  }\n  .cards {\n    flex-direction: column;\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ExchangesSettings_container__cqxdb`,
	"wrapper": `ExchangesSettings_wrapper__WB8Ub`,
	"cards": `ExchangesSettings_cards__9AsNw`,
	"title": `ExchangesSettings_title__cRlwT`
};
export default ___CSS_LOADER_EXPORT___;
