import { createSlice } from '@reduxjs/toolkit';
import { initialValidationStatus } from './constants';

const initialState = {
  validation: initialValidationStatus,
};

const validateParamsSlice = createSlice({
  name: 'validateParams',
  initialState,
  reducers: {
    setValidationStatus: (state, action) => {
      state.validation = action.payload;
    },
  },
});

export const { setValidationStatus } = validateParamsSlice.actions;
export default validateParamsSlice.reducer;
