import LeadersListItem from './LeadersListItem';
import { BOTS_LEADERBOARD } from './bots';
import styles from './List.module.css';
import BoardListItem from './BoardListItem';
import { IBoardListItem } from './ListLeaderboard.types';
import { useTranslation } from 'react-i18next';
import AvailableOnDesktopOverlay from '../../../BlockingOverlay/BlockingOverlay';
import { memo } from 'react';

function ListLeaderboard() {
  const LEADERS_PLACES: number = 3;
  const botsLeaders: IBoardListItem[] = BOTS_LEADERBOARD.filter(
    (bot) => bot.place <= LEADERS_PLACES,
  );
  const botsList: IBoardListItem[] = BOTS_LEADERBOARD.filter((bot) => bot.place > LEADERS_PLACES);
  const { t } = useTranslation('launchBotPage');
  return (
    <div className={styles.leadersList}>
      <div className={styles.leaders}>
        {botsLeaders.map((bot) => (
          <LeadersListItem key={bot.id} bot={bot} />
        ))}
      </div>
      <div className={styles.list}>
        <div className={styles.blockingOverlay}>
          <AvailableOnDesktopOverlay />
        </div>
        <div className={styles.listHeader}>
          <span className={styles.nameStrategy}>{t('nameStrategy')}</span>
          <span className={styles.exchangeMarket}>
            {t('exchange')}/ <br />
            {t('market')}
          </span>
          <span className={styles.riskPair}>{t('pairRisk')}</span>
          <span className={styles.pnlRuntime}>
            PNL/ <br />
            {t('runtime')}
          </span>
          <span className={styles.actions}>{t('actions')}</span>
        </div>
        {botsList.map((bot) => (
          <BoardListItem key={bot.id} bot={bot} />
        ))}
      </div>
    </div>
  );
}

export default memo(ListLeaderboard);
