import { useCallback, useState, useRef, useEffect } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

export const useElementSize = <T extends HTMLElement = HTMLDivElement>() => {
  const [size, setSize] = useState<{ width: number; height: number }>();
  const ref = useRef<T>();

  const refFunction = useCallback(
    (element: T) => {
      if (element) {
        ref.current = element;
        const { width, height } = element.getBoundingClientRect();
        setSize({ width: Math.round(width), height: Math.round(height) });
      } else {
        setSize({ width: 0, height: 0 });
      }
    },
    [setSize],
  );

  useEffect(() => {
    const currentTarget = ref.current;
    if (!currentTarget) {
      return;
    }
    const observer: ResizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => {
      const entry = entries.find((it) => it.target === currentTarget);
      if (entry) {
        const { width, height } = entry.contentRect;
        setSize({ width: Math.round(width), height: Math.round(height) });
      }
    });
    observer.observe(currentTarget);
    return () => observer.disconnect();
  }, [setSize]);

  return [size, refFunction] as const;
};
