import { useRef, memo, useState, useEffect } from 'react';
import cn from 'classnames';
import InputSettings from '../InputSettings/InputSettings';
import styles from '../BotSettings.module.css';
import SettingsCardTitle from './TitleCard';
import TabsSettingsCard from './TabsSettingsCard';
import { ITab } from '../../../StatisticPage/Tabs/Tabs';
import { CARD_CLASSNAMES } from '../SettingsBlock/SettingsBlock.constants';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks/redux';
import { tempSettings } from '../../../../redux/selectors/rootSelector';
import { changeLeverage, changeMarginMode } from '../../../../redux/slices/tempSettingsSlice';
import { checkIsInteger } from './input.utils';
const steps: string[] = ['1x', '2x', '3x', '5x', '10x', '15x', '30x', '50x'];

interface IMarginModeProps {
  isDisabled: boolean;
}

function MarginMode({ isDisabled }: IMarginModeProps) {
  const { t } = useTranslation('botspage');
  const ref = useRef<HTMLInputElement | null>(null);
  const [range, setRange] = useState(0);
  const {
    strategyParameters: { marginMode, leverage },
  } = useAppSelector(tempSettings);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isDisabled) {
      dispatch(changeLeverage(null));
      dispatch(changeMarginMode(null));
    } else {
      dispatch(changeLeverage(0));
      dispatch(changeMarginMode('Isolated'));
    }
  }, [isDisabled]);
  const marginTabs: ITab[] = [
    { value: 'Cross', render: t('settings.marginMode.tabs.cross') },
    { value: 'Isolated', render: t('settings.marginMode.tabs.isolated') },
  ];

  const onChangeRange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const index: number = Number(e.target.value);
    setRange(index);
    if (ref.current) {
      const ALL_PERCENTS: number = 100;
      const STEP_PERCENTS: number = 7;
      const percent: number = Math.round(ALL_PERCENTS / STEP_PERCENTS) * index;
      ref.current.style.setProperty(
        'background',
        `linear-gradient(to right, #17ABA3 0%, #17ABA3 ${percent}%, #17ABA380 ${percent}%, #17ABA380 100%)`,
      );
    }
  };

  return (
    <div className={cn(CARD_CLASSNAMES, { [styles.bluredCard]: isDisabled })}>
      <SettingsCardTitle
        title={t('settings.marginMode.title')}
        tooltip={t('tooltips.marginMode.title')}
      />
      <TabsSettingsCard
        tabs={marginTabs}
        activeTab={marginMode}
        onSetActiveTab={(tab) => dispatch(changeMarginMode(tab.value as 'Cross' | 'Isolated'))}
      />
      <InputSettings
        label={t('settings.marginMode.leverage')}
        isPercentVisible={false}
        value={leverage}
        usePercent={false}
        onChange={(e) =>
          checkIsInteger(e.target.value) && dispatch(changeLeverage(Number(e.target.value)))
        }
        isDisabled={isDisabled}
        hasTooltip={false}
      />
      <input
        type='range'
        value={range}
        min='0'
        max='7'
        step='1'
        onChange={onChangeRange}
        ref={ref}
        disabled={isDisabled}
      />
      <div className={styles.numbers}>
        {steps.map((item) => {
          return (
            <span className={styles.number} key={item}>
              {item}
            </span>
          );
        })}
      </div>
    </div>
  );
}

export default memo(MarginMode);
