import React from 'react';
import styles from './NoActiveBots.module.css';
import { useNavigate } from 'react-router-dom';

const NoActiveBots = () => {
  const navigate = useNavigate();

  const navigateLaunchBot = () => {
    navigate('/bots/launchbot');
  };

  return (
    <div className={styles.container}>
      <h2 className={styles.noActiveBotText}>No active bots</h2>
      <button className={styles.createBotBtn} onClick={navigateLaunchBot}>
        Create a first bot
      </button>
    </div>
  );
};

export default NoActiveBots;
