import { EExchangeType } from './redux/types';

export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const TELEGRAM_SUPPORT_LINK = 'https://t.me/delonix_support';

export const emptyFn = () => {};

export const EXCHANGES_BY_RESPONSE = {
  EXCHANGE_BYBIT: EExchangeType.bybit,
  EXCHANGE_BINANCE: EExchangeType.binance,
  EXCHANGE_BITGET: EExchangeType.bitget,
  EXCHANGE_OKX: EExchangeType.okx,
};
