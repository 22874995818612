import styles from './EquityAllocation.module.css';

interface ILogos {
  name: string;
  logo: string;
}

interface CustomTooltipProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any;
  logos?: Array<ILogos>;
}

function CustomTooltip({ payload, logos }: CustomTooltipProps) {
  const [item] = payload;
  return (
    <div className={styles.tooltipContainer}>
      <div className={styles.tooltipLogo}>
        {!!logos && (
          <img
            alt={item?.name}
            key={`${item?.name} logo`}
            className={styles.tooltipLogo}
            src={logos?.find((el) => el.name === item?.name)?.logo}
          />
        )}

        <span className={styles.tooltipValues}>{item?.name}</span>
      </div>

      <div>
        <span className={styles.tooltipKeys}>Balance: </span>
        <span className={styles.tooltipValues}>{item?.value}</span>
      </div>
      <div>
        <span className={styles.tooltipKeys}>Value: </span>
        <span className={styles.tooltipValues}>$ {item?.value}</span>
      </div>
      <div>
        <span className={styles.tooltipKeys}>Ratio: </span>
        <span className={styles.tooltipValues}>{item?.value}%</span>
      </div>
    </div>
  );
}

export default CustomTooltip;
