import React from 'react';
import cn from 'classnames';
import styles from './JoinBetaTesting.module.css';
import JoinInput from '../../JoinInput/JoinInput';
import { useTranslation } from 'react-i18next';


const JoinBetaTesting: React.FC = () => {
  const { t, i18n } = useTranslation('joinBetaTesting');
  const isRuLanguage = i18n.language === 'ru';

  const inputStyles = cn(
    styles.controls,
    { [styles.controls_ru]: isRuLanguage },
  );

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <h2>
          {t('title.part1')} <br />
          {t('title.part2')} <br />
          {t('title.part3')}
        </h2>
        <div className={inputStyles}>
          <JoinInput />
        </div>
      </div>
    </div>
  );
};

export default JoinBetaTesting;
