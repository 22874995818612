// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StepCreateFunctionality_container__bjSqN {
}

.StepCreateFunctionality_container__bjSqN .StepCreateFunctionality_group_name__Y4D-3 {
  display: block;
  margin-top: 41px;
  width: 528px;
}

.StepCreateFunctionality_header__bVuLi {
  display: flex;
  justify-content: space-between;
}

.StepCreateFunctionality_header__bVuLi h4 {
  margin-bottom: 14px;
  font-size: 24px;
  font-family: 'Jost';
  font-weight: 400;
}

.StepCreateFunctionality_controls__sVl7R button {
  margin-top: 36px;
  margin-left: 30px;
  font-size: 18px;
  font-weight: 400;
  font-family: 'PTSans';
  color: var(--secondary-text);
  -webkit-text-decoration: 1px underline dashed;
          text-decoration: 1px underline dashed;
  text-decoration-color: rgba(62%, 59%, 98%, 0.7);
}

.StepCreateFunctionality_features__zniye {
  margin-right: auto;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  height: 456px;
  width: max-content;
}

.StepCreateFunctionality_features__zniye label {
  margin-bottom: 33px;
  width: 225px;
}
`, "",{"version":3,"sources":["webpack://./src/components/AdminPage/CreateGroup/StepCreateFunctionality/StepCreateFunctionality.module.css"],"names":[],"mappings":"AAAA;AACA;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,mBAAmB;EACnB,eAAe;EACf,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,eAAe;EACf,gBAAgB;EAChB,qBAAqB;EACrB,4BAA4B;EAC5B,6CAAqC;UAArC,qCAAqC;EACrC,+CAA+C;AACjD;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,eAAe;EACf,uBAAuB;EACvB,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;EACnB,YAAY;AACd","sourcesContent":[".container {\n}\n\n.container .group_name {\n  display: block;\n  margin-top: 41px;\n  width: 528px;\n}\n\n.header {\n  display: flex;\n  justify-content: space-between;\n}\n\n.header h4 {\n  margin-bottom: 14px;\n  font-size: 24px;\n  font-family: 'Jost';\n  font-weight: 400;\n}\n\n.controls button {\n  margin-top: 36px;\n  margin-left: 30px;\n  font-size: 18px;\n  font-weight: 400;\n  font-family: 'PTSans';\n  color: var(--secondary-text);\n  text-decoration: 1px underline dashed;\n  text-decoration-color: rgba(62%, 59%, 98%, 0.7);\n}\n\n.features {\n  margin-right: auto;\n  display: flex;\n  flex-direction: column;\n  flex-wrap: wrap;\n  align-items: flex-start;\n  height: 456px;\n  width: max-content;\n}\n\n.features label {\n  margin-bottom: 33px;\n  width: 225px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `StepCreateFunctionality_container__bjSqN`,
	"group_name": `StepCreateFunctionality_group_name__Y4D-3`,
	"header": `StepCreateFunctionality_header__bVuLi`,
	"controls": `StepCreateFunctionality_controls__sVl7R`,
	"features": `StepCreateFunctionality_features__zniye`
};
export default ___CSS_LOADER_EXPORT___;
