import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './JoinClosedBetaTest.module.css';
import JoinInput from '../../JoinInput/JoinInput';

export default function JoinClosedBetaTest() {
  const { t } = useTranslation('howToConnect');
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <h4>{t('joinBetaTestHeader')}</h4>
        <p>{t('subtitle1')}</p>
        <div className={styles.inputWrapper}>
          <JoinInput />
        </div>
        <p>{t('subtitle2')}</p>
      </div>
      <div className={styles.register}>
        <p>{t('subtitle3')}</p>
        <div className={styles.registerLink}>
          <p>{t('register1')}</p>
          <a href='https://www.bybit.com/en/sign-up?affiliate_id=52998&group_id=119697&group_type=1&ref_code=DELONIX'>
            {t('register2')}
          </a>
        </div>
      </div>
    </div>
  );
}
