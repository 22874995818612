import styles from './SecuritySettings.module.css';
import IconWithTooltip from '../../IconWithTooltip/IconWithTooltip';

interface IInfoBlock {
  text: string;
}

export default function InfoBlock({ text }: IInfoBlock) {
  return (
    <div className={styles.infoBlockMain}>
      <IconWithTooltip
        id='tooltip'
        content='security settings'
        color='#fff'
        height={24}
        width={24}
      />
      <div className={styles.infoBlockText}>{text}</div>
    </div>
  );
}
