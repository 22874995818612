// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Filter_container__oNJM4 {
  display: flex;
  position: relative;
}

.Filter_compactFilter__2nrxm {
  display: flex;
  gap: 4px;
  align-items: center;
}

.Filter_compactFilter__2nrxm span {
  font-weight: normal;
  color: white;
}
.Filter_compactFilter__2nrxm button {
  padding: 0;
  display: flex;
  align-items: center;
}

.Filter_opened__tIvge {
  transform: rotate(180deg);
}

.Filter_dropdowns__0i1kR {
  position: relative;
  display: flex;
  gap: 8px;
  z-index: 4;
  align-items: center;
}

.Filter_disabledDropdown__GIGEl {
  opacity: 0.5;
}

.Filter_compactDropdowns__kuvsW {
  flex-direction: column;
  position: absolute;
  top: 30px;
  background-color: rgb(20, 18, 32);
  z-index: 500;
  border-radius: 10px;
}

.Filter_compactDropdowns__kuvsW > div,
.Filter_disabledDropdown__GIGEl .Filter_compactDropdowns__kuvsW > div {
  width: 130px;
}

.Filter_riskWrapper__i\\+yG0 {
  position: relative;
  opacity: 0.3;
}

@media (max-width: 768px) {
  .Filter_container__oNJM4 {
    display: none;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/FilterUserBots/Filter.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,QAAQ;EACR,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,YAAY;AACd;AACA;EACE,UAAU;EACV,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,QAAQ;EACR,UAAU;EACV,mBAAmB;AACrB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,sBAAsB;EACtB,kBAAkB;EAClB,SAAS;EACT,iCAAiC;EACjC,YAAY;EACZ,mBAAmB;AACrB;;AAEA;;EAEE,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE;IACE,aAAa;EACf;AACF","sourcesContent":[".container {\n  display: flex;\n  position: relative;\n}\n\n.compactFilter {\n  display: flex;\n  gap: 4px;\n  align-items: center;\n}\n\n.compactFilter span {\n  font-weight: normal;\n  color: white;\n}\n.compactFilter button {\n  padding: 0;\n  display: flex;\n  align-items: center;\n}\n\n.opened {\n  transform: rotate(180deg);\n}\n\n.dropdowns {\n  position: relative;\n  display: flex;\n  gap: 8px;\n  z-index: 4;\n  align-items: center;\n}\n\n.disabledDropdown {\n  opacity: 0.5;\n}\n\n.compactDropdowns {\n  flex-direction: column;\n  position: absolute;\n  top: 30px;\n  background-color: rgb(20, 18, 32);\n  z-index: 500;\n  border-radius: 10px;\n}\n\n.compactDropdowns > div,\n.disabledDropdown .compactDropdowns > div {\n  width: 130px;\n}\n\n.riskWrapper {\n  position: relative;\n  opacity: 0.3;\n}\n\n@media (max-width: 768px) {\n  .container {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Filter_container__oNJM4`,
	"compactFilter": `Filter_compactFilter__2nrxm`,
	"opened": `Filter_opened__tIvge`,
	"dropdowns": `Filter_dropdowns__0i1kR`,
	"disabledDropdown": `Filter_disabledDropdown__GIGEl`,
	"compactDropdowns": `Filter_compactDropdowns__kuvsW`,
	"riskWrapper": `Filter_riskWrapper__i+yG0`
};
export default ___CSS_LOADER_EXPORT___;
