import { memo, useEffect, useState } from 'react';
import TitleSettingsCard from './TitleCard';
import SwitchBlock from './SwitchBlock';
import InputSettings from '../InputSettings/InputSettings';
import { CARD_CLASSNAMES, INNER_CARD_CLASSES } from '../SettingsBlock/SettingsBlock.constants';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks/redux';
import { tempSettings } from '../../../../redux/selectors/rootSelector';
import {
  changeMinEquity,
  toggleMinEquityUsePercent,
  toggleEquityStop,
} from '../../../../redux/slices/tempSettingsSlice';
import { checkEquityStop, checkIsFloat } from './input.utils';

function StopLoss() {
  const { t } = useTranslation('botspage');
  const dispatch = useAppDispatch();
  const {
    strategyParameters: { equityStop },
  } = useAppSelector(tempSettings);
  const { validation } = useAppSelector((state) => state.validateParams);
  const [isEquityStopValid, setIsEquityStopValid] = useState(
    checkEquityStop(equityStop, validation.equityStop),
  );

  useEffect(() => {
    setIsEquityStopValid(checkEquityStop(equityStop, validation.equityStop));
  }, [validation]);

  const minEquityTooltip = `${t('tooltips.stopLoss.minEquity.p1')} \n${t(
    'tooltips.stopLoss.minEquity.p2',
  )} \n${t('tooltips.stopLoss.minEquity.p3')}`;

  const onToggleSwitchBlock = () => {
    dispatch(toggleEquityStop());
  };

  const errorValidationMessage = equityStop?.usePercent
    ? 'Must be bigger than 0 and less than 100'
    : 'Must be bigger than 0';

  return (
    <div className={CARD_CLASSNAMES}>
      <TitleSettingsCard title={t('settings.stopLoss.title')} hasTooltip={false} />
      <div className={INNER_CARD_CLASSES}>
        <SwitchBlock
          title={t('settings.stopLoss.equityStop')}
          isSwitched={equityStop !== null}
          action={onToggleSwitchBlock}
          tooltip={t('tooltips.stopLoss.equityStop')}
        />
        <InputSettings
          label={t('settings.stopLoss.minEquity')}
          placeholder='0'
          value={equityStop?.minEquity}
          onChange={(e) =>
            checkIsFloat(e.target.value) && dispatch(changeMinEquity(e.target.value))
          }
          usePercent={equityStop?.usePercent}
          setUsePercent={() => dispatch(toggleMinEquityUsePercent())}
          tooltip={minEquityTooltip}
          readonly={equityStop === null}
          hasError={!isEquityStopValid}
          errorMessage={errorValidationMessage}
        />
      </div>
    </div>
  );
}

export default memo(StopLoss);
