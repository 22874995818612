import { useFinishSessionMutation } from '../redux/services/signUpService';
import { useNavigate } from 'react-router-dom';

function useLogout(): () => Promise<void> {
  const [finishSession] = useFinishSessionMutation();
  const navigate = useNavigate();
  const sessionId = sessionStorage.getItem('session_id');
  const token = sessionStorage.getItem('token');

  const doFinishSession = async () => {
    try {
      await finishSession({ sessionId, token }).unwrap();
    } catch (error) {
      console.log(error);
    } finally {
      localStorage.removeItem('token');
      localStorage.removeItem('userId');
      sessionStorage.removeItem('session_id');
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('credentials');
      navigate('/');
    }
  };
  return doFinishSession;
}

export default useLogout;
