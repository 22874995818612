import React from 'react';
import HowToConnectPresentation from './HowToConnectPresentation/HowToConnectPresentation';
import JoinClosedBetaTest from './JoinClosedBetaTest/JoinClosedBetaTest';
import HowToStart from './HowToStart/HowToStart';
import HowToConnectBybit from './HowToConnectBybit/HowToConnectBybit';
import Footer from '../MainPage/Footer/Footer';

import styles from './HowToConnectPage.module.css';

const HowToConnectPage = () => {
  return (
    <div className={styles.container}>
      <HowToConnectPresentation/>
      <a href='#' className='up' />
      <div className={styles.content}>
        <JoinClosedBetaTest />
        <HowToStart />
        <HowToConnectBybit />
      </div>
      <Footer />
    </div>
  );
};

export default HowToConnectPage;
