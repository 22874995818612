import { useState } from 'react';
import VerificationCodeModal from '../VerificationCodeModal/VerificationCodeModal';
import QrCodeModal from '../QrCodeModal/QrCodeModal';
import EnableGoogleAuth from '../../EnableGoogleAuthModal/EnableGoogleAuthModal';
import { useGenerateSecretMutation } from '../../../redux/services/settingSecurityService';
import { useCurrentUserProfileQuery } from '../../../redux/services/settingProfileService';

interface IModalWindowsProps {
  switchGoogle: () => void;
  setIsGoogleOn: () => void;
  openCodeModal: boolean;
}

export default function ModalWindows({
  switchGoogle,
  openCodeModal,
  setIsGoogleOn,
}: IModalWindowsProps) {
  const { data: userProfile } = useCurrentUserProfileQuery();
  const [isGoogle, setIsGoogle] = useState(false);
  const [isCorrectCode] = useState(true);
  const [isOpenQrModal, setIsOpenQrModal] = useState(false);
  const [isOpenCodeModal, setIsOpenCodeModal] = useState(false);
  const [isOpenEnableAuthModal, setIsOpenEnableAuthModal] = useState(false);
  const [isOpenResetSecurityModal, setIsOpenResetSecurityModal] = useState(false);
  const [isOpenSecurityCheckModal, setIsOpenSecurityCheckModal] = useState(false);
  const settingId: string = userProfile?.setting?.settingId ?? '';
  const [generateSecret] = useGenerateSecretMutation();
  const [dataCode, setDataCode] = useState({ qrCode: '', secretCode: '' });

  const onSubmitCode = async () => {
    try {
      if (isCorrectCode) {
        setIsOpenCodeModal(!isOpenCodeModal);
        setIsOpenQrModal(true);
        const data = await generateSecret(settingId).unwrap();
        setDataCode(data);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const closeQrModal = () => {
    setIsOpenQrModal(!isOpenQrModal);
  };
  const closeEnableAuthModal = () => {
    setIsOpenEnableAuthModal(!isOpenEnableAuthModal);
    if (!isGoogle) {
      setIsGoogle(false);
    }
  };
  const openResetSecurityModal = () => {
    setIsOpenEnableAuthModal(!isOpenEnableAuthModal);
    setIsOpenResetSecurityModal(!isOpenResetSecurityModal);
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const closeResetSecurityModal = () => {
    setIsOpenResetSecurityModal(!isOpenResetSecurityModal);
  };
  const openOpenSecurityCheckModal = () => {
    setIsOpenSecurityCheckModal(true);
    setIsOpenResetSecurityModal(!isOpenResetSecurityModal);
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const closeSecurityCheckModal = () => {
    setIsOpenSecurityCheckModal(!isOpenSecurityCheckModal);
  };

  return (
    <>
      {openCodeModal && (
        <VerificationCodeModal
          actionClose={switchGoogle}
          action={() => null}
          isOpen={isGoogle}
          isCorrectCode={isCorrectCode}
          actionSubmit={onSubmitCode}
        />
      )}
      {isOpenQrModal && (
        <QrCodeModal
          actionClose={closeQrModal}
          qrImage={dataCode.qrCode}
          secretCode={dataCode.secretCode}
          isOpen={isOpenQrModal}
          actionSwitchGoogle={() => setIsGoogleOn()}
        />
      )}
      {isOpenEnableAuthModal && (
        <EnableGoogleAuth
          actionClose={closeEnableAuthModal}
          secretCode={dataCode.secretCode}
          actionOpenResetModal={openResetSecurityModal}
          actionOpenSecurityCheckModal={openOpenSecurityCheckModal}
          isOpen={isOpenQrModal}
          actionSwitchGoogle={() => setIsGoogle(false)}
        />
      )}
    </>
  );
}
