import { EMarketType } from '../../../redux/types';
import { renderMarketOption } from '../../LaunchBotPage/BotSetupHeader/utils';
import styles from './BotsCard.module.css';

interface IMarketBot {
  market: EMarketType;
}

export default function MarketBot({ market }: IMarketBot) {
  const marketStyle = market === EMarketType.spot ? styles.Spot : styles.Futures;

  return <div className={marketStyle}>{renderMarketOption(market)}</div>;
}
