import { TFunction } from 'i18next';

export const getBybitSetupSteps = (t: TFunction<'translation', undefined>) => [
  {
    text: t('howToConnectBybitDescription1'),
  },
  {
    text: t('howToConnectBybitDescription2'),
    imageName: 'api.png',
  },
  {
    text: t('howToConnectBybitDescription3'),
    imageName: 'createKey.png',
  },
  {
    text: t('howToConnectBybitDescription4'),
    imageName: 'apiKeyType.png',
    isSmallImage: true,
  },
  {
    text: t('howToConnectBybitDescription5'),
    imageName: 'apiKeySettings.png',
  },
  {
    text: t('howToConnectBybitDescription6'),
    imageName: 'botOptions.png',
  },
  {
    text: t('howToConnectBybitDescription7'),
    imageName: 'transferFromAccount.png',
  },
  {
    text: t('howToConnectBybitDescription8'),
    imageName: 'securityCheck.png',
    isSmallImage: true,
  },
  {
    text: t('howToConnectBybitDescription9'),
    imageName: 'keyAdded.png',
    isSmallImage: true,
  },
  {
    text: t('howToConnectBybitDescription10'),
    imageName: 'delonixTrade.png',
  },
  {
    text: t('howToConnectBybitDescription11'),
  },
  {
    text: t('howToConnectBybitDescription12'),
  },
  {
    text: t('howToConnectBybitDescription13'),
  },
  {
    text: t('howToConnectBybitDescription14'),
    imageName: 'apiKeySubmit.png',
    isSmallImage: true,
  },
  {
    text: t('howToConnectBybitDescription15'),
  },
  {
    text: t('howToConnectBybitDescription16'),
  },
];
