import { EStrategy, IFlowContext, IFlowParameters, IStrategyParameters } from '../types';

export const initialStrategyParameters: IStrategyParameters = {
  useReverse: false,
  useAutoHedge: false,
  inOrderQuantity: {
    value: 0,
    usePercent: false,
  },
  multiplierInOrderQuantity: null,
  inOrderDistance: {
    value: 0,
    usePercent: false,
    startFrom: null,
  },
  multiplierInOrderDistance: null,
  trailingBuyDistance: null,
  tradingRange: {
    lowerBound: '0.000000001',
    upperBound: 1000000,
    usePercent: false,
  },
  marginMode: 'Isolated',
  leverage: 0,
  triggerDistance: {
    choice: 'New',
    distance: 0,
    usePercent: true,
  },
  maxTriggerCount: 0,
  trailingTrigger: null,
  trailingTp: {
    minDistance: 0,
    maxDistance: 0,
    usePercent: false,
  },
  equityStop: null,
  useCounterDistance: false,
  useBeastMode: false,
  entireTp: {
    minTriggerCount: 12,
    choice: 'PricePercent',
    condition: 'Exceeding',
    value: 4.37,
  },
  rangeStop: {
    choice: 'CloseAndStop',
    condition: 'DistanceFromRangeInPercent',
    value: 80.0,
  },
  globalParameters: {
    lowRiskProfit: {
      firstTimeFrame: '4h',
      secondTimeFrame: '1d',
    },
    midRiskProfit: {
      firstTimeFrame: '30m',
      secondTimeFrame: '1h',
    },
    filter: {
      timeFrame: '15m',
      hmaFastestPeriod: 10,
      hmaFastPeriod: 70,
      hmaSlowPeriod: 200,
    },
    renkoForTrend: null,
    renkoForFilter: null,
    rsiPeriod: 14,
    atrPeriod: 21,
    unitedDealType: 'ByLastElement',
    switchOffEntpForHedge: true,
    useAdvancedRsi: false,
    useMultipleRsi: false,
    multipleRsi: [
      {
        firstTimeFrame: '15m',
        secondTimeFrame: '2h',
      },
    ],
    maxActiveTriggerMultiplier: 3,
    isApplyMultiplierForTrailingBuy: true,
    usePauseInContrTrend: false,
    useEmaBands: true,
    emaBandsSettings: {
      pauseCounterdiapOrders: false,
      onStrategySignal: false,
      emaLength: 27,
      emaSource: 'OHLC4',
      atrLength: 21,
      atrMultiplierUp: 0,
      atrMultiplierDown: 0,
      params: {
        firstTimeFrame: '1m',
        secondTimeFrame: '1m',
      },
    },
    convertFixedToAtr: false,
    convertParams: {
      convertTf: '15m',
      convertAtrPeriod: 21,
      minAtrPeriod: 105,
      lookback: 120,
    },
    useAntiSqueeze: false,
    antiSqueezeParams: {
      antiSqueezeTf: '30m',
      antiSqueezeAtrLength: 20,
      antiSqueezeAtrUp: 3.2,
      antiSqueezeAtrDown: 3.1,
    },
  },
  applyAutoBalanceToTrigger: false,
  disableMinusPnlCheck: true,
};

export const initialFlowContext: IFlowContext = {
  exchange: undefined,
  instrument: undefined,
  market: undefined,
  strategy: EStrategy.gridTronik,
  strategyType: undefined,
};

export const initialFlowParameters: IFlowParameters = {
  flowContext: initialFlowContext,
  strategyParameters: initialStrategyParameters,
  flowName: '',
};

export const initialValidationStatus = {
  inOrderDistance: 'Ok',
  inOrderQuantity: 'Ok',
  triggerDistance: 'Ok',
  maxTriggerCount: 'Ok',
  tradingRange: 'Ok',
  trailingTp: 'Ok',
  useReverse: 'Ok',
  multiplierInOrderQuantity: 'Ok',
  multiplierInOrderDistance: 'Ok',
  trailingBuyDistance: 'Ok',
  useCounterDistance: 'Ok',
  useBeastMode: 'Ok',
  trailingTrigger: 'Ok',
  entireTp: 'Ok',
  rangeStop: 'Ok',
  equityStop: 'Ok',
  disableMinusPnlCheck: 'Ok',
  globalParameters: 'Ok',
  leverage: 'Ok',
  marginMode: 'Ok',
  useAutoHedge: 'Ok',
  applyAutoBalanceToTrigger: 'Ok',
};
