import React, { memo } from 'react';
import styles from './AlgorithmCard.module.css';
import { useTranslation } from 'react-i18next';
interface IAlgorithmCardShape {
  children: React.ReactNode;
}

function AlgorithmCardShape({ children }: IAlgorithmCardShape) {
  const { t } = useTranslation('launchBotPage');
  return (
    <div className={styles.shape}>
      <div className={styles.inner}>{children}</div>
      <div className={styles.soon}>{t('soon')}</div>
    </div>
  );
}

export default memo(AlgorithmCardShape);
