// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tooltip_tooltip__bOKT3 {
  padding: 8px !important;
  color: white !important;
  font-family: PTSans !important;
  font-size: 10px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  background-color: rgb(10, 8, 23) !important;
  white-space: pre-wrap !important;
  opacity: 1 !important;
  z-index: 1;
}

.tooltip_element__jWw2Y {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
`, "",{"version":3,"sources":["webpack://./src/components/IconWithTooltip/tooltip.module.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,uBAAuB;EACvB,8BAA8B;EAC9B,0BAA0B;EAC1B,6BAA6B;EAC7B,2BAA2B;EAC3B,8BAA8B;EAC9B,2CAA2C;EAC3C,gCAAgC;EAChC,qBAAqB;EACrB,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,UAAU;AACZ","sourcesContent":[".tooltip {\n  padding: 8px !important;\n  color: white !important;\n  font-family: PTSans !important;\n  font-size: 10px !important;\n  font-style: normal !important;\n  font-weight: 400 !important;\n  line-height: normal !important;\n  background-color: rgb(10, 8, 23) !important;\n  white-space: pre-wrap !important;\n  opacity: 1 !important;\n  z-index: 1;\n}\n\n.element {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltip": `tooltip_tooltip__bOKT3`,
	"element": `tooltip_element__jWw2Y`
};
export default ___CSS_LOADER_EXPORT___;
