import React, { useEffect } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useOnClickOutside = (ref: React.MutableRefObject<any>, handler: () => void) => {
  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      const target = 'touches' in event ? event.touches[0].target : event.target;

      if (!ref.current || ref.current.contains(target)) {
        return;
      }

      handler();
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
};
