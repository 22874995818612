import { useTranslation } from 'react-i18next';
import Footer from '../MainPage/Footer/Footer';
import cn from 'classnames';
import styles from './AboutUsPage.module.css';
import Team from './Team/Team';
import { useEffect } from 'react';
import { useAppSelector } from '../../redux/hooks/redux';

export default function AboutUsPage() {
  const { t } = useTranslation('aboutPage');
  const isMobileMenuOpen = useAppSelector((state) => state.layout.isMobileMenuOpen);
  const containerStyle = cn(styles.container, { [styles.blur]: isMobileMenuOpen });
  useEffect(() => {
    window.scrollTo({
      top: 1,
      behavior: 'smooth',
    });
  }, []);
  return (
    <>
      <div className={containerStyle}>
        <div className={styles.background} />
        <div className={styles.content}>
          <h2>{t('title')}</h2>
          <div className={styles.about}>
            <h3>{t('subtitle')}</h3>
            <p>{t('about.p1')}</p>
            <p>{t('about.p2')}</p>
            <br />
            <p>{t('about.p3')}</p>
          </div>
          <Team />
        </div>
      </div>
      <Footer />
    </>
  );
}
