/* eslint-disable camelcase */
export const botsMock = [
  {
    'flow_id': 1,
    'mode': 'MODE_PRO',
    'strategy': 'STRATEGY_GRID_TRONIK',
    'flow_name': 'First Bot',
    'strategy_type': 'STRATEGY_TYPE_LONG',
    'exchange_type': 'EXCHANGE_BYBIT',
    'market_type': 'MARKET_USDT_PERPETUAL',
    'instrument': 'BTCUSDT',
    'pnl': 11.2,
    'uptime': '2D 12H 5M:0S',
    'flow_status': 'FLOW_STATUS_STOPPED_NEW',
    'use_reverse': false,
  },
  {
    'flow_id': 2,
    'mode': 'MODE_PRO',
    'strategy': 'STRATEGY_GRID_TRONIK',
    'flow_name': 'Second Bot',
    'strategy_type': 'STRATEGY_TYPE_SHORT',
    'exchange_type': 'EXCHANGE_BYBIT',
    'market_type': 'MARKET_INVERSE_PERPETUAL',
    'instrument': 'BTCUSDT',
    'pnl': 11669.78,
    'uptime': '2D 12H 5M:0S',
    'flow_status': 'FLOW_STATUS_PAUSED_REQUEST',
    'use_reverse': false,
  },
  {
    'flow_id': 3,
    'mode': 'MODE_PRO',
    'strategy': 'STRATEGY_GRID_TRONIK',
    'flow_name': 'Third Bot',
    'strategy_type': 'STRATEGY_TYPE_LONG',
    'exchange_type': 'EXCHANGE_BYBIT',
    'market_type': 'MARKET_INVERSE_PERPETUAL',
    'instrument': 'MATICUSDT',
    'pnl': 11889.78,
    'uptime': '3D 4H 23M:0S',
    'flow_status': 'FLOW_STATUS_RUNNING_PRO',
    'use_reverse': true,
    'has_subbots': true,
  },
  {
    'flow_id': 5,
    'mode': 'MODE_PRO',
    'strategy': 'STRATEGY_GRID_TRONIK',
    'flow_name': 'Fifth Bot',
    'strategy_type': 'STRATEGY_TYPE_LONG',
    'exchange_type': 'EXCHANGE_BYBIT',
    'market_type': 'MARKET_INVERSE_PERPETUAL',
    'instrument': 'ADAUSDT',
    'pnl': -23.9,
    'uptime': '1D 18H 40M:10S',
    'flow_status': 'FLOW_STATUS_PAUSED_REQUEST',
    'use_reverse': false,
  },
  {
    'flow_id': 10,
    'mode': 'MODE_PRO',
    'strategy': 'STRATEGY_GRID_TRONIK',
    'flow_name': 'Tenth Bot',
    'strategy_type': 'STRATEGY_TYPE_SPOT',
    'exchange_type': 'EXCHANGE_BYBIT',
    'market_type': 'MARKET_SPOT',
    'instrument': 'MATICUSDT',
    'pnl': -15.3,
    'uptime': '1D 12H 50M:20S',
    'flow_status': 'FLOW_STATUS_STOPPED_REQUEST',
    'use_reverse': true,
  },
  {
    'flow_id': 11,
    'mode': 'MODE_PRO',
    'strategy': 'STRATEGY_GRID_TRONIK',
    'flow_name': 'Eleventh Bot',
    'strategy_type': 'STRATEGY_TYPE_SPOT',
    'exchange_type': 'EXCHANGE_BYBIT',
    'market_type': 'MARKET_USDT_PERPETUAL',
    'instrument': 'SOLUSDT',
    'pnl': 32.7,
    'uptime': '2D 8H 5M:45S',
    'flow_status': 'FLOW_STATUS_RUNNING_PRO',
    'has_subbots': true,
    'use_reverse': true,
  },
  {
    'flow_id': 16,
    'mode': 'MODE_PRO',
    'strategy': 'STRATEGY_GRID_TRONIK',
    'flow_name': 'Sixteenth Bot',
    'strategy_type': 'STRATEGY_TYPE_SPOT',
    'exchange_type': 'EXCHANGE_BYBIT',
    'market_type': 'MARKET_SPOT',
    'instrument': 'LUNAUSDT',
    'pnl': -9.8,
    'uptime': '1D 15H 5M:25S',
    'flow_status': 'FLOW_STATUS_STOPPED_EMERGENCY',
    'use_reverse': false,
  },
  {
    'flow_id': 19,
    'mode': 'MODE_PRO',
    'strategy': 'STRATEGY_GRID_TRONIK',
    'flow_name': 'Nineteenth Bot',
    'strategy_type': 'STRATEGY_TYPE_SHORT',
    'exchange_type': 'EXCHANGE_BYBIT',
    'market_type': 'MARKET_USDT_PERPETUAL',
    'instrument': 'AAVEUSDT',
    'pnl': -18.5,
    'uptime': '2D 4H 45M:15S',
    'flow_status': 'FLOW_STATUS_STOPPED_NEW',
    'use_reverse': false,
  },

  {
    'flow_id': 101,
    'mode': 'MODE_PRO',
    'strategy': 'STRATEGY_GRID_TRONIK',
    'flow_name': 'Twenty Bot',
    'strategy_type': 'STRATEGY_TYPE_LONG',
    'exchange_type': 'EXCHANGE_BYBIT',
    'market_type': 'MARKET_USDT_PERPETUAL',
    'instrument': 'BTCUSDT',
    'pnl': 11.2,
    'uptime': '2D 12H 5M:0S',
    'flow_status': 'FLOW_STATUS_STOPPED_NEW',
    'use_reverse': false,
    'has_subbots': true,
  },
  {
    'flow_id': 102,
    'mode': 'MODE_PRO',
    'strategy': 'STRATEGY_GRID_TRONIK',
    'flow_name': 'Twenty One Bot',
    'strategy_type': 'STRATEGY_TYPE_SHORT',
    'exchange_type': 'EXCHANGE_BYBIT',
    'market_type': 'MARKET_INVERSE_PERPETUAL',
    'instrument': 'BTCUSDT',
    'pnl': 11669.78,
    'uptime': '2D 12H 5M:0S',
    'flow_status': 'FLOW_STATUS_PAUSED_REQUEST',
    'use_reverse': false,
  },
  {
    'flow_id': 103,
    'mode': 'MODE_PRO',
    'strategy': 'STRATEGY_GRID_TRONIK',
    'flow_name': 'Twenty Two Bot',
    'strategy_type': 'STRATEGY_TYPE_LONG',
    'exchange_type': 'EXCHANGE_BYBIT',
    'market_type': 'MARKET_INVERSE_PERPETUAL',
    'instrument': 'ETHUSDT',
    'pnl': -5.7,
    'uptime': '1D 6H 30M:15S',
    'flow_status': 'FLOW_STATUS_RUNNING_PRO',
    'has_subbots': true,
    'use_reverse': true,
  },
  {
    'flow_id': 105,
    'mode': 'MODE_PRO',
    'strategy': 'STRATEGY_GRID_TRONIK',
    'flow_name': 'Twenty Third Bot',
    'strategy_type': 'STRATEGY_TYPE_LONG',
    'exchange_type': 'EXCHANGE_BYBIT',
    'market_type': 'MARKET_INVERSE_PERPETUAL',
    'instrument': 'ADAUSDT',
    'pnl': -23.9,
    'uptime': '1D 18H 40M:10S',
    'flow_status': 'FLOW_STATUS_PAUSED_REQUEST',
    'use_reverse': false,
  },
  {
    'flow_id': 110,
    'mode': 'MODE_PRO',
    'strategy': 'STRATEGY_GRID_TRONIK',
    'flow_name': 'Twenty Fourth Bot',
    'strategy_type': 'STRATEGY_TYPE_SPOT',
    'exchange_type': 'EXCHANGE_BYBIT',
    'market_type': 'MARKET_INVERSE_PERPETUAL',
    'instrument': 'MATICUSDT',
    'pnl': -15.3,
    'uptime': '1D 12H 50M:20S',
    'flow_status': 'FLOW_STATUS_STOPPED_REQUEST',
    'use_reverse': true,
  },
  {
    'flow_id': 111,
    'mode': 'MODE_PRO',
    'strategy': 'STRATEGY_GRID_TRONIK',
    'flow_name': 'Twenty Fifth Bot',
    'strategy_type': 'STRATEGY_TYPE_SPOT',
    'exchange_type': 'EXCHANGE_BYBIT',
    'market_type': 'MARKET_USDT_PERPETUAL',
    'instrument': 'SOLUSDT',
    'pnl': 32.7,
    'uptime': '2D 8H 5M:45S',
    'flow_status': 'FLOW_STATUS_RUNNING_PRO',
    'has_subbots': true,
    'use_reverse': true,
  },
  {
    'flow_id': 116,
    'mode': 'MODE_PRO',
    'strategy': 'STRATEGY_GRID_TRONIK',
    'flow_name': 'Twenty Sixth Bot',
    'strategy_type': 'STRATEGY_TYPE_SPOT',
    'exchange_type': 'EXCHANGE_BYBIT',
    'market_type': 'MARKET_SPOT',
    'instrument': 'LUNAUSDT',
    'pnl': -9.8,
    'uptime': '1D 15H 5M:25S',
    'flow_status': 'FLOW_STATUS_STOPPED_EMERGENCY',
    'use_reverse': false,
  },
  {
    'flow_id': 119,
    'mode': 'MODE_PRO',
    'strategy': 'STRATEGY_GRID_TRONIK',
    'flow_name': 'Twenty Seventh Bot',
    'strategy_type': 'STRATEGY_TYPE_SHORT',
    'exchange_type': 'EXCHANGE_BYBIT',
    'market_type': 'MARKET_USDT_PERPETUAL',
    'instrument': 'AAVEUSDT',
    'pnl': -18.5,
    'uptime': '2D 4H 45M:15S',
    'flow_status': 'FLOW_STATUS_STOPPED_NEW',
    'use_reverse': false,
  },
];

export const subBotsMock = {
  '3': [
    {
      flow_id: 3,
      mode: 'MODE_PRO',
      strategy: 'STRATEGY_GRID_TRONIK',
      flow_name: 'Shooter grid ethir 1',
      strategy_type: 'STRATEGY_TYPE_LONG',
      exchange_type: 'EXCHANGE_BYBIT',
      market_type: 'MARKET_INVERSE_PERPETUAL',
      instrument: 'MATICUSDT',
      pnl: 11889.78,
      uptime: '3D 4H 23M:0S',
      flow_status: 'FLOW_STATUS_STOPPED_REGULAR',
      use_reverse: false,
      has_subbots: false,
    },
    {
      flow_id: 3,
      mode: 'MODE_PRO',
      strategy: 'STRATEGY_GRID_TRONIK',
      flow_name: 'Shooter grid ethir 2',
      strategy_type: 'STRATEGY_TYPE_SHORT',
      exchange_type: 'EXCHANGE_BYBIT',
      market_type: 'MARKET_INVERSE_PERPETUAL',
      instrument: 'MATICUSDT',
      pnl: 11889.78,
      uptime: '1D 6H 30M:15S',
      flow_status: 'FLOW_STATUS_CRUSHED',
      use_reverse: false,
      has_subbots: false,
    },
    {
      flow_id: 3,
      mode: 'MODE_PRO',
      strategy: 'STRATEGY_GRID_TRONIK',
      flow_name: 'Shooter grid ethir 3',
      strategy_type: 'STRATEGY_TYPE_SHORT',
      exchange_type: 'EXCHANGE_BYBIT',
      market_type: 'MARKET_INVERSE_PERPETUAL',
      instrument: 'MATICUSDT',
      pnl: 11889.78,
      uptime: '1D 6H 30M:15S',
      flow_status: 'FLOW_STATUS_STOPPED_REGULAR',
      use_reverse: false,
      has_subbots: false,
    },
  ],
  '11': [
    {
      flow_id: 11,
      mode: 'MODE_PRO',
      strategy: 'STRATEGY_GRID_TRONIK',
      flow_name: 'Shooter grid ethir 1',
      strategy_type: 'STRATEGY_TYPE_LONG',
      exchange_type: 'EXCHANGE_BYBIT',
      market_type: 'MARKET_INVERSE_PERPETUAL',
      instrument: 'MATICUSDT',
      pnl: 11889.78,
      uptime: '2D 8H 5M:45S',
      flow_status: 'FLOW_STATUS_STOPPED_REGULAR',
      use_reverse: false,
      has_subbots: false,
    },
    {
      flow_id: 11,
      mode: 'MODE_PRO',
      strategy: 'STRATEGY_GRID_TRONIK',
      flow_name: 'Shooter grid ethir 2',
      strategy_type: 'STRATEGY_TYPE_SHORT',
      exchange_type: 'EXCHANGE_BYBIT',
      market_type: 'MARKET_INVERSE_PERPETUAL',
      instrument: 'MATICUSDT',
      pnl: 11889.78,
      uptime: '1D 6H 30M:15S',
      flow_status: 'FLOW_STATUS_CRUSHED',
      use_reverse: false,
      has_subbots: false,
    },
    {
      flow_id: 11,
      mode: 'MODE_PRO',
      strategy: 'STRATEGY_GRID_TRONIK',
      flow_name: 'Shooter grid ethir 3',
      strategy_type: 'STRATEGY_TYPE_SHORT',
      exchange_type: 'EXCHANGE_BYBIT',
      market_type: 'MARKET_INVERSE_PERPETUAL',
      instrument: 'MATICUSDT',
      pnl: 11889.78,
      uptime: '1D 6H 30M:15S',
      flow_status: 'FLOW_STATUS_STOPPED_REGULAR',
      use_reverse: false,
      has_subbots: false,
    },
  ],
  '103': [
    {
      flow_id: 103,
      mode: 'MODE_PRO',
      strategy: 'STRATEGY_GRID_TRONIK',
      flow_name: 'Shooter grid ethir 1',
      strategy_type: 'STRATEGY_TYPE_LONG',
      exchange_type: 'EXCHANGE_BYBIT',
      market_type: 'MARKET_INVERSE_PERPETUAL',
      instrument: 'MATICUSDT',
      pnl: 11889.78,
      uptime: '1D 6H 30M:15S',
      flow_status: 'FLOW_STATUS_STOPPED_REGULAR',
      use_reverse: false,
      has_subbots: false,
    },
    {
      flow_id: 103,
      mode: 'MODE_PRO',
      strategy: 'STRATEGY_GRID_TRONIK',
      flow_name: 'Shooter grid ethir 2',
      strategy_type: 'STRATEGY_TYPE_SHORT',
      exchange_type: 'EXCHANGE_BYBIT',
      market_type: 'MARKET_INVERSE_PERPETUAL',
      instrument: 'MATICUSDT',
      pnl: 11889.78,
      uptime: '1D 6H 30M:15S',
      flow_status: 'FLOW_STATUS_CRUSHED',
      use_reverse: false,
      has_subbots: false,
    },
    {
      flow_id: 103,
      mode: 'MODE_PRO',
      strategy: 'STRATEGY_GRID_TRONIK',
      flow_name: 'Shooter grid ethir 3',
      strategy_type: 'STRATEGY_TYPE_SHORT',
      exchange_type: 'EXCHANGE_BYBIT',
      market_type: 'MARKET_INVERSE_PERPETUAL',
      instrument: 'MATICUSDT',
      pnl: 11889.78,
      uptime: '1D 6H 30M:15S',
      flow_status: 'FLOW_STATUS_STOPPED_REGULAR',
      use_reverse: false,
      has_subbots: false,
    },
  ],
  '111': [
    {
      flow_id: 111,
      mode: 'MODE_PRO',
      strategy: 'STRATEGY_GRID_TRONIK',
      flow_name: 'Shooter grid ethir 1',
      strategy_type: 'STRATEGY_TYPE_LONG',
      exchange_type: 'EXCHANGE_BYBIT',
      market_type: 'MARKET_INVERSE_PERPETUAL',
      instrument: 'MATICUSDT',
      pnl: 11889.78,
      uptime: '2D 8H 5M:45S',
      flow_status: 'FLOW_STATUS_STOPPED_REGULAR',
      use_reverse: false,
      has_subbots: false,
    },
    {
      flow_id: 111,
      mode: 'MODE_PRO',
      strategy: 'STRATEGY_GRID_TRONIK',
      flow_name: 'Shooter grid ethir 2',
      strategy_type: 'STRATEGY_TYPE_SHORT',
      exchange_type: 'EXCHANGE_BYBIT',
      market_type: 'MARKET_INVERSE_PERPETUAL',
      instrument: 'MATICUSDT',
      pnl: 11889.78,
      uptime: '1D 6H 30M:15S',
      flow_status: 'FLOW_STATUS_CRUSHED',
      use_reverse: false,
      has_subbots: false,
    },
    {
      flow_id: 111,
      mode: 'MODE_PRO',
      strategy: 'STRATEGY_GRID_TRONIK',
      flow_name: 'Shooter grid ethir 3',
      strategy_type: 'STRATEGY_TYPE_SHORT',
      exchange_type: 'EXCHANGE_BYBIT',
      market_type: 'MARKET_INVERSE_PERPETUAL',
      instrument: 'MATICUSDT',
      pnl: 11889.78,
      uptime: '1D 6H 30M:15S',
      flow_status: 'FLOW_STATUS_STOPPED_REGULAR',
      use_reverse: false,
      has_subbots: false,
    },
  ],
};
