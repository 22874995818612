import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { BASE_URL } from '../../constants';

export const botApi = createApi({
  reducerPath: 'botApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers) => {
      headers.set('accept', 'application/json');
      headers.set('Content-Type', 'application/json');
    },
  }),
  endpoints: (build) => ({
    createFlow: build.mutation({
      query: (payload) => ({
        url: `v1/sessions/${sessionStorage.getItem('session_id')}/flows`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
        body: payload,
      }),
    }),

    getUserBots: build.mutation({
      query: () => ({
        url: `/v1/sessions/${sessionStorage.getItem('session_id')}/flows/all`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      }),
    }),

    getBotParameters: build.mutation({
      query: (botId) => ({
        url: `/v1/sessions/${sessionStorage.getItem('session_id')}/flows/${botId}/parameters`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      }),
    }),
    getBotOrders: build.mutation({
      query: (flowId) => ({
        url: `/v1/sessions/${sessionStorage.getItem('session_id')}/bots/${flowId}/statistics`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      }),
    }),
    updateFlowParams: build.mutation({
      query: (payload) => ({
        url: `/v1/sessions/${sessionStorage.getItem('session_id')}/flows/${
          payload.flowId
        }/strategy-parameters`,
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
        body: payload.parameters,
      }),
    }),
    validateParams: build.mutation({
      query: (payload) => ({
        url: `/v1/sessions/${sessionStorage.getItem('session_id')}/strategy-parameters/validate`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
        body: payload,
      }),
    }),
    launchBot: build.mutation({
      query: (payload) => ({
        url: `/v1/sessions/${sessionStorage.getItem('session_id')}/flows/${payload}/run`,
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      }),
    }),
  }),
});

export const {
  useCreateFlowMutation,
  useGetUserBotsMutation,
  useGetBotParametersMutation,
  useGetBotOrdersMutation,
  useUpdateFlowParamsMutation,
  useValidateParamsMutation,
  useLaunchBotMutation,
} = botApi;
