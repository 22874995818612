import React from 'react';

import styles from './HowToConnectBybit.module.css';
import { Trans, useTranslation } from 'react-i18next';
import LocalizedImage from '../LocalizedImage/LocalizedImage';
import { getBybitSetupSteps } from './HowToConnectBybit.constants';
import cn from 'classnames';

export default function HowToConnectBybit() {
  const { t } = useTranslation('howToConnectBybit');

  return (
    <div className={styles.container}>
      <h2>{t('howToConnectBybit')}</h2>
      <div className={styles.subtitle}>
        <p className={styles.inlineParagraph}>{t('subtitle1')}</p>
        <p className={styles.inlineParagraph}>{t('subtitle2')}</p>
        <p>{t('subtitle3')}</p>
        <p>{t('subtitle4')}</p>
      </div>
      <div className={styles.content}>
        <ol>
          {getBybitSetupSteps(t).map((item, index) => (
            <li key={index}>
              <p>
                <Trans
                  i18nKey={item.text}
                  components={{ highlighted: <strong className={styles.highlighted}></strong> }}
                />
              </p>
              {item.imageName && (
                <LocalizedImage
                  className={cn(styles.image, { [styles.centerImage]: item.isSmallImage })}
                  imageName={item.imageName}
                />
              )}
            </li>
          ))}
        </ol>
        <p>{t('howToConnectBybitDescriptionEnd')}</p>
      </div>
    </div>
  );
}
