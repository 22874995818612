import { memo, useState } from 'react';
import styles from '../BotSettings.module.css';
import MarginMode from '../Blocks/MarginMode';
import Triggers from '../Blocks/Triggers';
import InOrders from '../Blocks/InOrders';
import TakeProfit from '../Blocks/TakeProfit';
import StopLoss from '../Blocks/StopLoss';
import BalanceInfoBlock from '../Blocks/BalanceInfoBlock';
import SwitchBlock from '../Blocks/SwitchBlock';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks/redux';
import { tempSettings } from '../../../../redux/selectors/rootSelector';
import { toggleVolatilityAdjustment } from '../../../../redux/slices/tempSettingsSlice';

interface ISettingsBlock {
  isMarginModeDisabled?: boolean;
}

function SettingsBlock({ isMarginModeDisabled = false }: ISettingsBlock) {
  const { t } = useTranslation('botspage');
  const {
    strategyParameters: {
      globalParameters: { convertFixedToAtr },
    },
  } = useAppSelector(tempSettings);
  const dispatch = useAppDispatch();
  const [isAllowCopySettings, setIsAllowCopySettings] = useState(false);
  const onSwitchSettingsBeCopied = () => setIsAllowCopySettings((state) => !state);

  return (
    <div className={styles.settingsContainer}>
      <div className={styles.settingsHeader}>
        <h3 className={styles.subtitle}>{t('settings.title')}</h3>
        <SwitchBlock
          title={t('settings.allowCopy')}
          action={onSwitchSettingsBeCopied}
          isSwitched={isAllowCopySettings}
          hasTooltip={false}
        />
      </div>
      <div className={styles.settingsCards}>
        <div>
          <div className={styles.settingsCards}>
            <InOrders />
            <div className={styles.column}>
              <MarginMode isDisabled={isMarginModeDisabled} />
              <Triggers />
              <TakeProfit />
              <StopLoss />
            </div>
          </div>
          <div className={styles.cardWrapper}>
            <SwitchBlock
              title={t('volatility')}
              subtitle={t('adjustment')}
              isSwitched={convertFixedToAtr}
              action={() => dispatch(toggleVolatilityAdjustment())}
              tooltip={t('tooltips.volatility')}
            />
          </div>
          <BalanceInfoBlock />
        </div>
      </div>
    </div>
  );
}

export default memo(SettingsBlock);
