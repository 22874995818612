// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Header_header__DkO-I {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 45px 20px 15px;
}

.Header_title__NPsKI {
  margin: 0;
  color: white;
  font-family: PTSans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
.Header_switchTab__g9u6n {
  display: flex;
  padding: 4px 16px;
  border: 1px solid white;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 13px;
  line-height: 24px;
  font-weight: 700;
}

.Header_switchTab__g9u6n:nth-child(1) {
  border-radius: 10px 0px 0px 10px;
  border-right: none;
}

.Header_switchTab__g9u6n:nth-child(2) {
  border-radius: 0px 10px 10px 0px;
}

.Header_activeTab__1Fk2D {
  background-color: white;
  color: black;
}

.Header_switchBox__yGDoi {
  display: flex;
}

@media (max-width: 768px) {
  .Header_header__DkO-I {
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    margin: 20px 16px;
  }

  .Header_switchBox__yGDoi {
    width: 100%;
  }

  .Header_switchTab__g9u6n {
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/LaunchBotPage/Leaderboard/HeaderLeaderboard/Header.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,2BAA2B;AAC7B;;AAEA;EACE,SAAS;EACT,YAAY;EACZ,mBAAmB;EACnB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;AACnB;AACA;EACE,aAAa;EACb,iBAAiB;EACjB,uBAAuB;EACvB,uBAAuB;EACvB,mBAAmB;EACnB,WAAW;EACX,eAAe;EACf,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,gCAAgC;EAChC,kBAAkB;AACpB;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,uBAAuB;EACvB,YAAY;AACd;;AAEA;EACE,aAAa;AACf;;AAEA;EACE;IACE,sBAAsB;IACtB,uBAAuB;IACvB,SAAS;IACT,iBAAiB;EACnB;;EAEA;IACE,WAAW;EACb;;EAEA;IACE,WAAW;EACb;AACF","sourcesContent":[".header {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin: 30px 45px 20px 15px;\n}\n\n.title {\n  margin: 0;\n  color: white;\n  font-family: PTSans;\n  font-size: 18px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 22px;\n}\n.switchTab {\n  display: flex;\n  padding: 4px 16px;\n  border: 1px solid white;\n  justify-content: center;\n  align-items: center;\n  color: #fff;\n  font-size: 13px;\n  line-height: 24px;\n  font-weight: 700;\n}\n\n.switchTab:nth-child(1) {\n  border-radius: 10px 0px 0px 10px;\n  border-right: none;\n}\n\n.switchTab:nth-child(2) {\n  border-radius: 0px 10px 10px 0px;\n}\n\n.activeTab {\n  background-color: white;\n  color: black;\n}\n\n.switchBox {\n  display: flex;\n}\n\n@media (max-width: 768px) {\n  .header {\n    flex-direction: column;\n    align-items: flex-start;\n    gap: 24px;\n    margin: 20px 16px;\n  }\n\n  .switchBox {\n    width: 100%;\n  }\n\n  .switchTab {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `Header_header__DkO-I`,
	"title": `Header_title__NPsKI`,
	"switchTab": `Header_switchTab__g9u6n`,
	"activeTab": `Header_activeTab__1Fk2D`,
	"switchBox": `Header_switchBox__yGDoi`
};
export default ___CSS_LOADER_EXPORT___;
