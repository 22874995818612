import { IBot } from '../../../redux/types';

const SECONDS_IN_MINUTE = 60;
const SECONDS_IN_HOUR = 3600;
const SECONDS_IN_DAY = 86400;

export const getUptimeSeconds = (bot: IBot) => {
  if (!bot.uptime) {
    return 0;
  }

  const [daysPart, hoursPart, restPart] = bot.uptime.split(' ');
  const [minPart, secPart] = restPart.split(':');

  const days = +(daysPart.replace('D', ''));
  const hours = +(hoursPart.replace('H', ''));
  const min = +(minPart.replace('M', ''));
  const sec = +(secPart.replace('S', ''));

  return sec + (min * SECONDS_IN_MINUTE) + (hours * SECONDS_IN_HOUR) + (days * SECONDS_IN_DAY);
};
