import cn from 'classnames';
import IconWithTooltip from '../../../IconWithTooltip/IconWithTooltip';
import { ESizing } from '../../../CCLabsUI/Input/Input';
import styles from '../BotSettings.module.css';
import { memo } from 'react';

interface ICardTitle {
  title: string;
  size?: ESizing;
  tooltip?: string;
  hasTooltip?: boolean;
}
function TitleCard({ title, size = ESizing.medium, tooltip, hasTooltip = true }: ICardTitle) {
  const titleClassNames: string = cn(
    [styles.cardTitle],
    { [styles.titleLarge]: size === ESizing.large },
    { [styles.titleMedium]: size === ESizing.medium },
  );
  return (
    <div className={titleClassNames}>
      {title}{' '}
      {hasTooltip && (
        <IconWithTooltip content={tooltip ? tooltip : title} color='#fff' tooltipWidth={164} />
      )}
    </div>
  );
}
export default memo(TitleCard);
