import { useTranslation } from 'react-i18next';
import Dropdown from '../../../Filter/Dropdown/Dropdown';
import styles from '../BotSettings.module.css';
import InputSettings from '../InputSettings/InputSettings';
import { TIMEFRAME_VALUES } from '../SettingsBlock/SettingsBlock.constants';
import { SetStateAction, memo, useEffect, useState } from 'react';
import InfoIcon from '../../../../assets/General/InfoIcon';
import { ELanguages } from '../../../../types';
import { checkATRMultipliers, checkIsFloat } from './input.utils';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks/redux';
import {
  changeAtrMultiplierLower,
  changeAtrMultiplierUpper,
  setFirstTimeFrame,
  setSecondTimeFrame,
} from '../../../../redux/slices/tempSettingsSlice';
import { tempSettings } from '../../../../redux/selectors/rootSelector';

const TrendFlipperSettings = () => {
  const { t, i18n } = useTranslation('botspage');
  const dispatch = useAppDispatch();
  const {
    strategyParameters: {
      globalParameters: {
        emaBandsSettings: {
          atrMultiplierUp,
          atrMultiplierDown,
          params: { firstTimeFrame, secondTimeFrame },
        },
      },
    },
  } = useAppSelector(tempSettings);

  const { validation } = useAppSelector((state) => state.validateParams);
  const [validationStatuses, setValidationStatuses] = useState(
    checkATRMultipliers(atrMultiplierUp, atrMultiplierDown, validation.globalParameters),
  );

  useEffect(() => {
    setValidationStatuses(
      checkATRMultipliers(atrMultiplierUp, atrMultiplierDown, validation.globalParameters),
    );
  }, [validation]);

  const currentLanguage = i18n.language as keyof typeof ELanguages;
  const timeframes = TIMEFRAME_VALUES[currentLanguage] ?? TIMEFRAME_VALUES.en;
  const timeframesValuesLocalized = Object.values(timeframes);

  const onChangeAtrMultiplierUpper = (e: React.ChangeEvent<HTMLInputElement>) => {
    checkIsFloat(e.target.value) && dispatch(changeAtrMultiplierUpper(e.target.value));
  };

  const onChangeAtrMultiplierLower = (e: React.ChangeEvent<HTMLInputElement>) => {
    checkIsFloat(e.target.value) && dispatch(changeAtrMultiplierLower(e.target.value));
  };

  const renderTimeframeOptions = (tf: string) => {
    return <p className={styles.timeframe}>{tf}</p>;
  };

  const onChangeFirstTimeFrame = (option: SetStateAction<string | undefined>) => {
    const timeframe = Object.keys(timeframes).find(
      (key) => timeframes[key as keyof typeof timeframes] === option,
    );
    dispatch(setFirstTimeFrame(timeframe));
  };

  const onChangeSecondTimeFrame = (option: SetStateAction<string | undefined>) => {
    const timeframe = Object.keys(timeframes).find(
      (key) => timeframes[key as keyof typeof timeframes] === option,
    );
    dispatch(setSecondTimeFrame(timeframe));
  };

  return (
    <div className={styles.trendFlipperSettingsBlock}>
      <div className={styles.trendFlipperSettings}>
        <div className={styles.inputSettings}>
          <InputSettings
            placeholder='0'
            label={t('atrMultiplierUpper')}
            isPercentVisible={false}
            value={atrMultiplierUp}
            onChange={onChangeAtrMultiplierUpper}
            hasTooltip={false}
            hasError={!validationStatuses.isUpperATRValid}
            errorMessage='Must be bigger than 0'
          />
        </div>
        <div className={styles.dropdownWrapper}>
          <label>Timeframe 1</label>
          <Dropdown
            className={styles.dropdown}
            labelClassName={styles.dropdownLabel}
            label={t('strategy')}
            value={firstTimeFrame}
            setValue={onChangeFirstTimeFrame}
            options={timeframesValuesLocalized}
            renderOption={renderTimeframeOptions}
            hasSingleSelection
          />
        </div>
      </div>
      <div className={styles.trendFlipperSettings}>
        <div className={styles.inputSettings}>
          <InputSettings
            placeholder='0'
            label={t('atrMultiplierLower')}
            isPercentVisible={false}
            value={atrMultiplierDown}
            onChange={onChangeAtrMultiplierLower}
            hasTooltip={false}
            hasError={!validationStatuses.isLowerATRValid}
            errorMessage='Must be bigger than 0'
          />
        </div>
        <div className={styles.dropdownWrapper}>
          <label>Timeframe 2</label>
          <Dropdown
            className={styles.dropdown}
            labelClassName={styles.dropdownLabel}
            label={t('strategy')}
            value={secondTimeFrame}
            setValue={onChangeSecondTimeFrame}
            options={timeframesValuesLocalized}
            renderOption={renderTimeframeOptions}
            hasSingleSelection
          />
        </div>
      </div>
      <div className={styles.tradingViewLink}>
        <InfoIcon color='white' height={16} width={16} />
        <a
          href='https://www.tradingview.com/script/x2ui0eYl-Classic-KIT'
          target='_blank'
          rel='noreferrer'
        >
          View and select TrendFlipper parameters using our indicator on TradingView
        </a>
      </div>
    </div>
  );
};

export default memo(TrendFlipperSettings);
