import React from 'react';
import styles from './SecurityCheckWarningModal.module.css';
import CloseButton from '../../CloseButton/CloseButton';
import { useTranslation } from 'react-i18next';

interface ISecurityCheckWarningModal {
  actionClose: () => void;
  actionSubmit: () => Promise<void>;
  currentEmail: string;
}

const SecurityCheckWarningModal = ({ actionClose, actionSubmit, currentEmail }: ISecurityCheckWarningModal) => {
  const { t } = useTranslation('profilePage');

  return (
    <div className={styles.modalContainer}>
      <div className={styles.modal}>
        <CloseButton className={styles.closeButton} onClick={actionClose} />
        <div className={styles.textBlock}>
          <h3>{t('securityCheck.title')}</h3>
          <p>{t('securityCheck.sendConfirmCode.description', { email: currentEmail })}</p>
        </div>
        <div>
          <button onClick={actionSubmit} className={styles.confirmBtn}>{t('securityCheck.sendConfirmCode.btn')}</button>
        </div>
      </div>
    </div>
  );
};

export default SecurityCheckWarningModal;
