import cn from 'classnames';
import styles from './SummaryTable.module.css';

import { useAppSelector } from '../../../../../redux/hooks/redux';
import { selectAdminStats } from '../../../../../redux/selectors/rootSelector';
import { prepareSummary } from '../utils';

export default function SummaryTable() {
  const formatOption: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  };

  const adminPanel = useAppSelector(selectAdminStats).adminPanelInfo;
  const { summary, tradingVolume } = adminPanel;
  const preparedSummary = prepareSummary(summary, tradingVolume);

  return (
    <div className={styles.table}>
      <div className={cn(styles.group, styles.firstGroup)} />
      <div className={cn(styles.group, styles.secondGroup)} />
      <div className={styles.header}>
        <div className={styles.cell}>Date</div>
        <div className={styles.cell}>Total volume</div>
        <div className={styles.columnGroup}>
          <div>
            <div className={styles.columnsTitle}>Average by user</div>
          </div>
          <div className={styles.modeBox}>
            <div className={styles.cell}>All</div>
            <div className={styles.cell}>Pro</div>
            <div className={styles.cell}>Auto</div>
          </div>
        </div>
        <div className={styles.columnGroup}>
          <div>
            <div className={styles.columnsTitle}>Average by bot</div>
          </div>
          <div className={styles.modeBox}>
            <div className={styles.cell}>All</div>
            <div className={styles.cell}>Pro</div>
            <div className={styles.cell}>Auto</div>
          </div>
        </div>
        <div className={styles.long}>All running equity</div>
        <div className={styles.long}>Total realized PNL</div>
      </div>
      <div className={styles.tableData}>
        {preparedSummary.map(({ id, date, volume, averageUsers, averageBots, equity, pnl }) => (
          <div className={styles.row} key={id}>
            <div className={styles.cell}>
              {new Intl.DateTimeFormat('ru', formatOption).format(date)}
            </div>
            <div className={styles.cell}>{volume}</div>
            <div className={styles.modeBox}>
              <div className={styles.cell}>{averageUsers.all}</div>
              <div className={styles.cell}>{averageUsers.pro}</div>
              <div className={styles.cell}>{averageUsers.auto}</div>
            </div>
            <div className={styles.modeBox}>
              <div className={styles.cell}>{averageBots.all}</div>
              <div className={styles.cell}>{averageBots.pro}</div>
              <div className={styles.cell}>{averageBots.auto}</div>
            </div>
            <div className={cn(styles.long)}>{equity}</div>
            <div className={cn(styles.long)}>{pnl}</div>
          </div>
        ))}
      </div>
    </div>
  );
}
