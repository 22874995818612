import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  exchangeItems: [],
};


const exchangesSlice = createSlice({
  name: 'exchanges',
  initialState,
  reducers: {
    setExchangesItems(state, action) {
      state.exchangeItems = action.payload;
    },
  },
});

export const { setExchangesItems } = exchangesSlice.actions;

export default exchangesSlice.reducer;
