import { memo } from 'react';
import cn from 'classnames';
import IconWithTooltip from '../../../IconWithTooltip/IconWithTooltip';
import SwitchToggle from '../../../SwitchToggle/SwitchToggle';
import TitleSettingsCard from './TitleCard';
import styles from '../BotSettings.module.css';
import { CARD_CLASSNAMES } from '../SettingsBlock/SettingsBlock.constants';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks/redux';
import {
  toggleTrendFlipper,
  toggleHedgeGuard,
  toggleAntiSqueeze,
} from '../../../../redux/slices/tempSettingsSlice';
import { tempSettings } from '../../../../redux/selectors/rootSelector';
import TrendFlipperSettings from './TrendFlipperSettings';

interface IAdvantagesProps {
  isHedgeGuardDisabled?: boolean;
}

function Advantages({ isHedgeGuardDisabled = false }: IAdvantagesProps) {
  const { t } = useTranslation('botspage');
  const {
    strategyParameters: {
      useReverse,
      useAutoHedge,
      globalParameters: { useAntiSqueeze },
    },
  } = useAppSelector(tempSettings);
  const dispatch = useAppDispatch();

  const onSwitchTrendFlipper = () => {
    dispatch(toggleTrendFlipper());
    if (useAutoHedge) {
      dispatch(toggleHedgeGuard());
    }
  };

  const onSwitchHedgeGuard = () => {
    if (!isHedgeGuardDisabled && !useReverse) {
      dispatch(toggleHedgeGuard());
    }
  };

  const tooltipTrendFlipper = `${t('tooltips.trendFlipper.p1')} \n${t('tooltips.trendFlipper.p2')}`;
  const tooltipHedgeGuard = `${t('tooltips.hedgeGuard.p1')} \n${t('tooltips.hedgeGuard.p2')}`;

  const ADVANTAGE_CARD_CLASSES = cn(CARD_CLASSNAMES, styles.advantageCard);
  const SEPERATE_ADVANTAGE_CARD_CLASSES = cn(CARD_CLASSNAMES, styles.advantageCardSeperate);
  return (
    <div>
      <div className={cn([styles.advantagesWrapper], [styles.advantages])}>
        <div className={styles.cardHeader}>
          <h3 className={styles.subtitle}>{t('settings.advantages')}</h3>
          <div className={styles.assistant}>
            <SwitchToggle /> {t('settings.assistant')}
          </div>
        </div>
        <div className={styles.advantagesCards}>
          <div className={styles.advantageColumn}>
            <div className={ADVANTAGE_CARD_CLASSES}>
              <div className={styles.bluredCard}>
                <TitleSettingsCard title={t('settings.autofilling')} tooltip={t('tooltips.autofilling')}/>
                <div className={styles.risk}>
                  <span>
                    {t('settings.risk')}* <IconWithTooltip color='#afafaf' />
                  </span>
                  <div className={styles.riskIcons}>
                    <div className={cn([styles.icon], [styles.low])}>Low</div>
                    <div className={cn([styles.icon], [styles.medium])}>Medium</div>
                  </div>
                </div>
                <button className={styles.headerBtn}>{t('settings.applyBtn')}</button>
              </div>
              <div className={cn([styles.soonText], [styles.title])}>{t('settings.soon')}</div>
            </div>
            <div className={ADVANTAGE_CARD_CLASSES}>
              <div className={cn(styles.switchBox, styles.advantagesSwitchBox)}>
                <TitleSettingsCard title='TrendFlipper' tooltip={tooltipTrendFlipper} />
                <SwitchToggle isSwitched={useReverse} action={onSwitchTrendFlipper} />
              </div>
            </div>
          </div>
          <div className={styles.advantageColumn}>
            <div className={ADVANTAGE_CARD_CLASSES}>
              <div className={styles.bluredCard}>
                <TitleSettingsCard title={t('settings.backtesting')} tooltip={t('tooltips.backtesting')}/>
                <div className={styles.risk}>
                  <span>
                    {t('settings.risk')}* <IconWithTooltip color='#afafaf' />
                  </span>
                  <div className={styles.riskIcons}>
                    <div className={cn([styles.icon], [styles.low])}>Low</div>
                    <div className={cn([styles.icon], [styles.medium])}>Medium</div>
                  </div>
                </div>
                <button className={styles.headerBtn}>{t('settings.launchBtn')}</button>
              </div>

              <div className={cn([styles.soonText], [styles.title])}>{t('settings.soon')}</div>
            </div>
          </div>
          {useReverse && <TrendFlipperSettings />}
        </div>
      </div>
      <div className={styles.seperateToggles}>
        <div className={SEPERATE_ADVANTAGE_CARD_CLASSES}>
          <div className={cn(styles.switchBox, styles.advantagesSwitchBox)}>
            <TitleSettingsCard title='SqueezeArmor' tooltip={t('tooltips.squeezeArmor')} />
            <SwitchToggle
              isSwitched={useAntiSqueeze}
              action={() => dispatch(toggleAntiSqueeze())}
            />
          </div>
        </div>

        <div className={SEPERATE_ADVANTAGE_CARD_CLASSES}>
          <div className={cn(styles.switchBox, styles.advantagesSwitchBox)}>
            <TitleSettingsCard title='HedgeGuard Mode' tooltip={tooltipHedgeGuard} />
            <SwitchToggle isSwitched={useAutoHedge} action={onSwitchHedgeGuard} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(Advantages);
