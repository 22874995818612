// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NotificationItem_notification__7gg3h {
  display: flex;
  padding: 23px;
  width: 1640px;
  gap: 10px;
  background-color: var(--black-background-card);
  border: 1px solid var(--border-white-02);
  border-radius: 5px;
  font-family: PTSans;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.NotificationItem_title__f4f8\\+ {
  width: 280px;
}

.NotificationItem_message__p8bAc {
  width: 570px;
}

.NotificationItem_channels__WCYiT {
  width: 320px;
}

.NotificationItem_date__Xv6iZ {
  width: 210px;
}

.NotificationItem_opened__aT9kN {
  width: 132px;
}

.NotificationItem_read__a3svu {
  width: 81px;
}
`, "",{"version":3,"sources":["webpack://./src/components/AdminPage/NotificationsPage/NotificationItem/NotificationItem.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;EACb,aAAa;EACb,SAAS;EACT,8CAA8C;EAC9C,wCAAwC;EACxC,kBAAkB;EAClB,mBAAmB;EACnB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,WAAW;AACb","sourcesContent":[".notification {\n  display: flex;\n  padding: 23px;\n  width: 1640px;\n  gap: 10px;\n  background-color: var(--black-background-card);\n  border: 1px solid var(--border-white-02);\n  border-radius: 5px;\n  font-family: PTSans;\n  font-size: 16px;\n  font-weight: 400;\n  line-height: 24px;\n}\n\n.title {\n  width: 280px;\n}\n\n.message {\n  width: 570px;\n}\n\n.channels {\n  width: 320px;\n}\n\n.date {\n  width: 210px;\n}\n\n.opened {\n  width: 132px;\n}\n\n.read {\n  width: 81px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notification": `NotificationItem_notification__7gg3h`,
	"title": `NotificationItem_title__f4f8+`,
	"message": `NotificationItem_message__p8bAc`,
	"channels": `NotificationItem_channels__WCYiT`,
	"date": `NotificationItem_date__Xv6iZ`,
	"opened": `NotificationItem_opened__aT9kN`,
	"read": `NotificationItem_read__a3svu`
};
export default ___CSS_LOADER_EXPORT___;
