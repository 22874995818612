import { formatDeepSnakeObjKeysToCamelCase } from '../../utils/formatSnakeToCamelCase';
import { useAppDispatch } from '../../redux/hooks/redux';
import { setStatistics } from '../../redux/slices/statisticSlice';
import { IFlowStatistics } from '../../redux/types';
import { useRef } from 'react';

export default function useGetBotsStatistics() {
  const dispatch = useAppDispatch();
  const HOST = process.env.REACT_APP_WSS_HOST;
  const connectionOpened = useRef(false);
  const socket = new WebSocket(
    `${HOST}/ws/v1/sessions/${sessionStorage.getItem('wsId')}/flows/statistics`,
  );
  const getPnlStatistics = () => {
    socket.addEventListener('open', () => {
      console.log('WS connection is opened');
      connectionOpened.current = true;
    });

    socket.addEventListener('message', (event) => {
      const response = JSON.parse(event.data);
      const formattedResponse = formatDeepSnakeObjKeysToCamelCase(response.flows) as {
        flows: IFlowStatistics[];
      };

      dispatch(setStatistics(formattedResponse));
    });

    socket.addEventListener('close', () => console.log('WS connection is closed'));
  };

  const closeWsConnection = () => {
    if (connectionOpened.current) {
      socket.close();
      connectionOpened.current = false;
    }
  };

  return { getPnlStatistics, closeWsConnection };
}
