// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TotalRealizedPNL_container__iV0yW {
  box-sizing: border-box;
  width: 405px;
  max-width: 485px;
  height: 295px;
  padding: 20px;
}

.TotalRealizedPNL_header__Jj\\+eo {
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
}

.TotalRealizedPNL_headerText__jGNpq {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 13px;
}

.TotalRealizedPNL_header__Jj\\+eo span {
  border-bottom: 1px dotted var(--border-white-02);
  color: white;
  font-size: 13px;
  line-height: 17px;
}

.TotalRealizedPNL_cardContent__fNety {
  position: relative;
  margin-top: 25px;
  margin-left: -25px;
}

.TotalRealizedPNL_chartText__GT3s4 {
  font-size: 13px;
  font-weight: 400;
}

.TotalRealizedPNL_noData__UpwCV {
  position: absolute;
  margin: 0;
  left: 50%;
  top: 50%;
  transform: translate(-50%, 50%);
  font-size: 17px;
  color: white;
}

@media (max-width: 768px) {
  .TotalRealizedPNL_container__iV0yW {
    width: auto;
    max-width: none;
  }
  .TotalRealizedPNL_chartText__GT3s4 {
    width: 287px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/StatisticPage/TotalRealizedPNL/TotalRealizedPNL.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,YAAY;EACZ,gBAAgB;EAChB,aAAa;EACb,aAAa;AACf;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,QAAQ;EACR,eAAe;AACjB;;AAEA;EACE,gDAAgD;EAChD,YAAY;EACZ,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,SAAS;EACT,QAAQ;EACR,+BAA+B;EAC/B,eAAe;EACf,YAAY;AACd;;AAEA;EACE;IACE,WAAW;IACX,eAAe;EACjB;EACA;IACE,YAAY;EACd;AACF","sourcesContent":[".container {\n  box-sizing: border-box;\n  width: 405px;\n  max-width: 485px;\n  height: 295px;\n  padding: 20px;\n}\n\n.header {\n  display: flex;\n  margin-bottom: 20px;\n  justify-content: space-between;\n  align-items: center;\n  font-weight: 400;\n}\n\n.headerText {\n  display: flex;\n  align-items: center;\n  gap: 8px;\n  font-size: 13px;\n}\n\n.header span {\n  border-bottom: 1px dotted var(--border-white-02);\n  color: white;\n  font-size: 13px;\n  line-height: 17px;\n}\n\n.cardContent {\n  position: relative;\n  margin-top: 25px;\n  margin-left: -25px;\n}\n\n.chartText {\n  font-size: 13px;\n  font-weight: 400;\n}\n\n.noData {\n  position: absolute;\n  margin: 0;\n  left: 50%;\n  top: 50%;\n  transform: translate(-50%, 50%);\n  font-size: 17px;\n  color: white;\n}\n\n@media (max-width: 768px) {\n  .container {\n    width: auto;\n    max-width: none;\n  }\n  .chartText {\n    width: 287px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `TotalRealizedPNL_container__iV0yW`,
	"header": `TotalRealizedPNL_header__Jj+eo`,
	"headerText": `TotalRealizedPNL_headerText__jGNpq`,
	"cardContent": `TotalRealizedPNL_cardContent__fNety`,
	"chartText": `TotalRealizedPNL_chartText__GT3s4`,
	"noData": `TotalRealizedPNL_noData__UpwCV`
};
export default ___CSS_LOADER_EXPORT___;
