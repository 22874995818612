import { Dispatch, SetStateAction } from 'react';

import { Input } from '../../../CCLabsUI';
import { ESizing } from '../../../CCLabsUI/Input/Input';
import CalendarComponent from '../Calendar/CalendarComponent';
import CalendarIcon from '../../../../assets/calendar-icon.svg';
import styles from '../StatusUsersBar/StatusUserBar.module.css';

interface IDateFilter {
  dateFrom: string;
  dateTo: string;
  isShowCalendar: boolean;
  setIsShowCalendar: Dispatch<SetStateAction<boolean>>;
  dateValue: [Date, Date] | undefined;
  setDateValue: Dispatch<SetStateAction<[Date, Date] | undefined>>;
}

export default function DateFilter({
  dateFrom,
  dateTo,
  setIsShowCalendar,
  dateValue,
  setDateValue,
  isShowCalendar,
}: IDateFilter) {
  return (
    <div className={styles.dateInputs}>
      <div className={styles.inputField}>
        <Input
          label=''
          sizing={ESizing.medium}
          placeholder='From'
          value={dateFrom}
          onFocus={() => setIsShowCalendar(true)}
          readOnly={true}
        />
        <button className={styles.calendarBtn} onClick={() => setIsShowCalendar((state) => !state)}>
          <img src={CalendarIcon} />
        </button>
      </div>
      <div className={styles.inputField}>
        <Input
          label=''
          sizing={ESizing.medium}
          value={dateTo}
          placeholder='To'
          onFocus={() => setIsShowCalendar(true)}
          readOnly={true}
        />
        <button className={styles.calendarBtn} onClick={() => setIsShowCalendar((state) => !state)}>
          <img src={CalendarIcon} />
        </button>
      </div>
      {isShowCalendar && (
        <CalendarComponent
          value={dateValue}
          onChange={setDateValue}
          setShowCalendar={setIsShowCalendar}
        />
      )}
    </div>
  );
}
