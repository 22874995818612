/* eslint-disable react/jsx-key */
import React from 'react';

import styles from './HowToStart.module.css';
import { Trans, useTranslation } from 'react-i18next';
import LocalizedImage from '../LocalizedImage/LocalizedImage';
import { getHowToStartSteps } from './HowToStart.constants';

export default function HowToStart() {
  const { t, i18n } = useTranslation('howToStart');

  const locale = i18n.language;
  const formattedLocale = locale === 'ru' ? 'ru-RU' : locale;


  return (
    <div className={styles.container}>
      <h2>{t('howToStart')}</h2>
      <div className={styles.content}>
        <ol>
          {getHowToStartSteps().map((item, index) => (
            <li key={index}>
              <p>
                <Trans t={t} components={[
                  <a className={styles.textLink} href='https://partner.bybit.com/b/delonix' target='_blank' rel="noreferrer"/>,
                  <a className={styles.textLink} href={`https://www.bybit.com/${formattedLocale}/help-center/article/How-to-Transfer-Your-Identity-to-Another-Account`} target='_blank' rel="noreferrer"/>,
                ]}>
                  {t(item.text)}
                </Trans>
              </p>
              {item.imageName && <LocalizedImage imageName={item.imageName} />}
            </li>
          ))}
        </ol>
      </div>
    </div>
  );
}
