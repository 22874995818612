import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BASE_URL } from '../../constants';

export const whiteListApi = createApi({
  reducerPath: 'whiteListApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
  }),
  endpoints: (build) => ({
    addUserToWhiteList: build.mutation({
      query: (payload) => ({
        url: `/v1/sessions/${payload.sessionId}/whitelist`,
        method: 'POST',
        body: payload.body,
        headers: {
          Authorization: `Bearer ${payload.token}`,
        },
      }),
    }),
  }),
});

export const {
  useAddUserToWhiteListMutation,
} = whiteListApi;
