import TitleCard from '../../BotsPage/BotSettings/Blocks/TitleCard';
import LineOfCard from './LineOfCard';
import styles from './Cards.module.css';
import { useTranslation } from 'react-i18next';

export default function Triggers() {
  const { t } = useTranslation('botsStats');

  return (
    <div className={styles.container}>
      <TitleCard title={t('triggers')} />
      <LineOfCard title={t('firstTrigger')} value='Value' />
      <LineOfCard title={t('lastTrigger')} value='Value' />
      <LineOfCard title={t('triggerCount')} value='Value' />
      <LineOfCard title={t('nextOrderQty')} value='Value' />
    </div>
  );
}
