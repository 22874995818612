/* eslint-disable @typescript-eslint/naming-convention */
import { useState } from 'react';
import { useAppDispatch } from '../../redux/hooks/redux';
import { useGetUserBotsMutation } from '../../redux/services/botService';
import { setBots } from '../../redux/slices/botsSlice';
import { EClientErrors, IBot } from '../../redux/types';
import { formatDeepSnakeObjKeysToCamelCase } from '../../utils/formatSnakeToCamelCase';
import { v4 } from 'uuid';
import { useNavigateToSingIn } from '../../utils/navigateToSingIn';

const useRequestUserBots = () => {
  const [getBots] = useGetUserBotsMutation();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();
  const navigateToSingIn = useNavigateToSingIn();
  const requestUserBots = async () => {
    try {
      setIsLoading(true);
      const response = await getBots(null).unwrap();
      if (response.status.code === 'uuid.not_found.session') {
        return navigateToSingIn(EClientErrors.unauthorized);
      }
      const formattedBots = formatDeepSnakeObjKeysToCamelCase(response.flows) as IBot[];
      setIsLoading(false);
      dispatch(setBots(formattedBots.map((bot: IBot) => ({ ...bot, uuid: v4() }))));
    } catch (err) {
      setIsLoading(false);
      console.log('request bots error: ', err);
      if (typeof err === 'object' && err !== null && 'status' in err) {
        navigateToSingIn(err.status as EClientErrors);
      }
    }
  };
  return { requestUserBots, isLoading };
};

export default useRequestUserBots;
