import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import 'react-tooltip/dist/react-tooltip.css';

import './i18n';
import { store } from './redux/store/store';
import SecuritySettings from './components/Pages/SecuritySettings';
import ExchangesSettings from './components/ExchangesPage/ExchangesSettings';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';
import { ITab, Navigation } from './components/Navigation/Navigation';
import './App.css';
import MainPage from './components/MainPage/MainPage';
import SignIn from './components/SignIn/SignIn';
import Header from './components/Header/Header';
import SignUp from './components/SignUp/SignUp';
import UsersList from './components/AdminPage/UsersListPage/UsersList/UsersList';
import CreateNotification from './components/AdminPage/NewNotificationPage/CreateNotification/CreateNotification';
import NotificationList from './components/AdminPage/NotificationsPage/NotificationList/NotificationList';
import GroupList from './components/AdminPage/GroupeListPage/GroupList/GroupList';
import CreateGroup from './components/AdminPage/CreateGroup/CreateGroup';
import AdminStatisticPage from './components/AdminPage/AdminStatisticsPage/AdminStatisticPage';
import SummaryTable from './components/AdminPage/AdminStatisticsPage/StatisticTables/Summary/SummaryTable';
import TradingVolumeTable from './components/AdminPage/AdminStatisticsPage/StatisticTables/TradingVolume/TradingVolumeTable';
import UsersTable from './components/AdminPage/AdminStatisticsPage/StatisticTables/Users/UsersTable';
import BotsTable from './components/AdminPage/AdminStatisticsPage/StatisticTables/Bots/BotsTable';
import EquityTable from './components/AdminPage/AdminStatisticsPage/StatisticTables/Equity/EqutyTable';
import AboutUsPage from './components/AboutUsPage/AboutUsPage';
import AverageValuesTable from './components/AdminPage/AdminStatisticsPage/StatisticTables/Average/AverageTable';
import PNLTable from './components/AdminPage/AdminStatisticsPage/StatisticTables/PNL/PNLTable';
import SettingsUsage from './components/AdminPage/AdminStatisticsPage/StatisticTables/SettingssUsage/SettingsUsage';
import HowToConnectPage from './components/HowToConnectPage/HowToConnectPage';
import MyBots from './components/Pages/MyBots';
import CreateBot from './components/Pages/CreateBot';
import TotalProfit from './components/Pages/TotalProfit';
import BotsStatistics from './components/Pages/BotStatistic';
import ProfileSettings from './components/Pages/ProfileSettings';
// todo: replace all paths to enum EPaths

const App: React.FC = () => {
  const adminTabs: ITab[] = [
    { id: 1, name: 'stats', link: '/admin/stats' },
    { id: 2, name: 'userlist', link: '/admin/userlist' },
    { id: 3, name: 'grouplist', link: '/admin/grouplist' },
    { id: 4, name: 'notifications', link: '/admin/notifications' },
  ];
  return (

    <Provider store={store}>
      <Suspense fallback={<div> </div>}>
        <BrowserRouter>
          <Header />
          <Routes>
            <Route path='/' element={<MainPage />} />
            <Route path='/aboutUs' element={<AboutUsPage />} />
            <Route path='/log-in' element={<SignIn />} />
            <Route path='/sign-up' element={<SignUp />} />
            <Route path='/how-to-connect' element={<HowToConnectPage />} />
          </Routes>
          <Routes>
            <Route element={<ProtectedRoute />}>
              <Route
                path='/admin/*'
                element={<Navigation title='Admin panel' tabs={adminTabs} />}
              />
              <Route path='/admin/grouplist/create' element={<CreateGroup />} />
            </Route>
          </Routes>
          <Routes>
            <Route path='/admin/stats/*' element={<AdminStatisticPage />} />
          </Routes>
          <Routes>
            <Route element={<ProtectedRoute />}>
              <Route path='/bots/mybots' element={<MyBots />} />
              <Route path='/bots/launchbot' element={<CreateBot />} />
              <Route path='/statistic/totalProfit' element={<TotalProfit />} />
              <Route path='/statistic/bot' element={<BotsStatistics />} />
              <Route path='/settings/security' element={<SecuritySettings />} />
              <Route path='/settings/profile' element={<ProfileSettings />} />
              <Route path='/exchanges' element={<ExchangesSettings />} />
              <Route path='/admin/userlist' element={<UsersList />} />
              <Route path='/admin/notifications/create' element={<CreateNotification />} />
              <Route path='/admin/notifications' element={<NotificationList />} />
              <Route path='/admin/grouplist' element={<GroupList />} />
              <Route path='/admin/stats/summary' element={<SummaryTable />} />
              <Route path='/admin/stats/trading' element={<TradingVolumeTable />} />
              <Route path='/admin/stats/users' element={<UsersTable />} />
              <Route path='/admin/stats/bot' element={<BotsTable />} />
              <Route path='/admin/stats/equity' element={<EquityTable />} />
              <Route path='/admin/stats/pnl' element={<PNLTable />} />
              <Route path='/admin/stats/average' element={<AverageValuesTable />} />
              <Route path='/admin/stats/setting' element={<SettingsUsage />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </Suspense>
    </Provider>
  );
};
export default App;
