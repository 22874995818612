/* eslint-disable @typescript-eslint/no-magic-numbers */
import React from 'react';
import { createPortal } from 'react-dom';

import cn from 'classnames';

import styles from './EquityAllocationModal.module.css';
import CloseButton from '../../CloseButton/CloseButton';
import busdLogo from '../../../assets/busd_logo.svg';
import usdtLogo from '../../../assets/usdt_logo.svg';
import bnbLogo from '../../../assets/bnb_logo.svg';
import ethLogo from '../../../assets/eth_logo.svg';
import btcLogo from '../../../assets/btc_logo.svg';
import AvailableOnDesktopOverlay from '../../BlockingOverlay/BlockingOverlay';

const data = [
  { name: 'BUSD', value: 50 },
  { name: 'USDT', value: 15 },
  { name: 'BNB', value: 25 },
  { name: 'ETH', value: 5 },
  { name: 'BTC', value: 3 },
  { name: 'Others', value: 2 },
  { name: 'BUSD', value: 50 },
  { name: 'USDT', value: 15 },
  { name: 'BNB', value: 25 },
  { name: 'ETH', value: 5 },
  { name: 'BTC', value: 3 },
  { name: 'Others', value: 2 },
];

const logos = [
  { name: 'BUSD', logo: busdLogo },
  { name: 'USDT', logo: usdtLogo },
  { name: 'BNB', logo: bnbLogo },
  { name: 'ETH', logo: ethLogo },
  { name: 'BTC', logo: btcLogo },
  { name: 'Others', logo: btcLogo },
];

interface EquityAllocationModalProps {
  isOpen?: boolean;
  isCorrectCode?: boolean;
  inputValue?: string;
  actionClose: () => void;
  action: () => void;
  actionSubmit: () => void;
}

const root: HTMLElement | null = document.getElementById('root');

const EquityAllocationModal: React.FC<EquityAllocationModalProps> = (props) => {
  return createPortal(
    <div className={styles.modalContainer}>
      <div className={styles.modal}>
        <div className={styles.blockingOverlay}>
          <AvailableOnDesktopOverlay />
        </div>
        <div className={styles.modalHeader}>
          <div className={styles.headerText}>Equity Allocation</div>
          <span className={styles.closeButton}>
            <CloseButton onClick={props.actionClose} />
          </span>
        </div>
        <div className={styles.headersTable}>
          <div className={styles.columns}>
            <div className={cn([styles.coinColumn, styles.coinHeader])}>Coin</div>
            <div className={styles.quantityColumn}>Quantity</div>
          </div>
          <div className={styles.columns}>
            <div className={styles.valueColumn}>Values</div>
            <div className={styles.ratioValueColumn}>Ratio</div>
          </div>
        </div>
        <div className={styles.valuesTable}>
          {data.map((item, index) => {
            return (
              <div
                key={index + item.value}
                className={cn([styles.coinRow], {
                  [styles.oddLine]: index % 2 !== 0,
                })}
              >
                <div className={styles.columns}>
                  <div className={styles.coinColumn}>
                    <img
                      alt={item.name}
                      key={`${item.name} logo`}
                      className={cn([styles.valuesLogo, styles.currencyLogo])}
                      src={logos?.find((el) => el.name === item.name)?.logo}
                    />
                    <span className={styles.currencyShortcut}>{item.name}</span>
                  </div>
                  <div className={styles.quantityColumn}> {item.value * 100000}</div>
                </div>
                <div className={styles.columns}>
                  <div className={styles.valueColumn}>{`$ ${item.value * 123456}`}</div>
                  <div className={styles.ratioColumn}>
                    <span> {`${item.value}%`}</span>
                    <div className={styles.progressLineContainer}>
                      <div
                        style={{ flexBasis: `${item.value}%` }}
                        className={styles.progressLineFilled}
                      />
                      <div
                        style={{
                          flexBasis: `${100 - item.value}%`,
                        }}
                        className={styles.progressLineEmpty}
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>,
    root as HTMLElement,
  );
};

export default EquityAllocationModal;
