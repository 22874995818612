import { memo, useState } from 'react';
import AlgorithmCard from './AlgorithmCard/AlgorithmCard';
import AlgorithmCardShape from './AlgorithmCard/AlgorithmCardShape';
import styles from './LaunchBot.module.css';
import Leaderboard from './Leaderboard/Leaderboard';
import ProSettingsModal from './ProBotSettings/ProSettingsModal';
import { EType } from '../BotsPage/BotIcon/BotIcon';
import { useTranslation } from 'react-i18next';
import { EStrategyType } from '../../redux/types';
import ConnectExchangesApiModal from './ConnectExchangesApiModal/ConnectExchangesApiModal';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../redux/hooks/redux';
import { IExchanges } from '../../types';

export interface IAlgorithm {
  id: number;
  algorithm: EType;
  strategies: EStrategyType[];
  market?: string;
  description: string;
  apr: string;
  launched: number;
  isEnabled: boolean;
}

function LaunchBotPage() {
  const { t } = useTranslation('launchBotPage');
  const navigate = useNavigate();

  const exchangeParameters =
    useAppSelector((state) => state.exchanges.exchangeItems).find(({ name }) => name === 'ByBit') ||
    {};
  const { access } = useAppSelector((state) => state.profile);
  const { isFuturesConnected, isSpotConnected } = exchangeParameters as unknown as IExchanges;
  const isExchangeConnected = isFuturesConnected || isSpotConnected;
  const isDemo = access === 'ACCESS_DEMO';
  const [isConnectApiVisible, setIsConnectApiVisible] = useState(false);
  const [isSettingsOpened, setIsSettingsOpened] = useState(false);

  const ALGORITHMS: IAlgorithm[] = [
    {
      id: 1,
      algorithm: EType.ultraGrid,
      strategies: [EStrategyType.long, EStrategyType.short],
      description: t('grid'),
      apr: '5,048,77%',
      launched: 6125319,
      isEnabled: true,
    },
    {
      id: 2,
      algorithm: EType.fibo,
      strategies: [EStrategyType.long, EStrategyType.short],
      description: t('fibo'),
      apr: '5,048,77%',
      launched: 6125319,
      isEnabled: false,
    },
    {
      id: 3,
      algorithm: EType.shooter,
      strategies: [EStrategyType.long, EStrategyType.short],
      description: t('shooter'),
      apr: '5,048,77%',
      launched: 6125319,
      isEnabled: false,
    },

    {
      id: 4,
      algorithm: EType.hybrid,
      strategies: [EStrategyType.long, EStrategyType.short],
      description: t('hybrid'),
      apr: '5,048,77%',
      launched: 6125319,
      isEnabled: false,
    },
  ];

  const onCloseSettings = () => {
    setIsSettingsOpened(false);
  };

  const onOpenSettings = () => {
    if (isExchangeConnected || isDemo) {
      setIsSettingsOpened(true);
      return;
    }
    setIsConnectApiVisible(true);
  };

  const onCloseConnectApiModal = () => {
    setIsConnectApiVisible(false);
  };

  const onNavigateExchanges = () => {
    navigate('/exchanges');
  };

  if (isSettingsOpened) {
    return <ProSettingsModal onClose={onCloseSettings} />;
  }
  return (
    <div className={styles.container}>
      <div className={styles.algorithms}>
        {ALGORITHMS.map((algorithm) =>
          algorithm.isEnabled ? (
            <AlgorithmCard
              algorithm={algorithm}
              key={algorithm.id}
              onOpenSettings={onOpenSettings}
            />
          ) : (
            <AlgorithmCardShape key={algorithm.id}>
              <AlgorithmCard algorithm={algorithm} onOpenSettings={onOpenSettings} />
            </AlgorithmCardShape>
          ),
        )}
      </div>
      <Leaderboard isDisabled />
      {isConnectApiVisible && (
        <ConnectExchangesApiModal
          closeModal={onCloseConnectApiModal}
          navigateToConnectApi={onNavigateExchanges}
        />
      )}
    </div>
  );
}

export default memo(LaunchBotPage);
