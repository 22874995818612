// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ItemGroupList_container__i\\+54g {
  display: flex;
  gap: 25px;
  padding: 18px 0;
  width: max-content;
  color: #fff;
  font-size: 16px;
  font-weight: normal;
  line-height: 22px;
}
.ItemGroupList_isBanned__OKP3Y {
  background-color: rgba(247, 166, 0, 0.3);
}

.ItemGroupList_botsTypeBox__o\\+6s8 {
  display: flex;
  width: 200px;
  justify-content: center;
  gap: 67px;
}

.ItemGroupList_date__DOxMa {
  width: 160px;
}

.ItemGroupList_uid__pddG\\+ {
  width: 110px;
}

.ItemGroupList_group__AbMjL {
  width: 200px;
}

.ItemGroupList_functionality__bVFmg {
  width: 500px;
}

.ItemGroupList_users__bQNz8 {
  width: 200px;
}

.ItemGroupList_trading__4-0Hm {
  width: 190px;
}

.ItemGroupList_pnl__B8RNO {
  width: 197px;
}

.ItemGroupList_equity__YaxCk {
  width: 210px;
}
`, "",{"version":3,"sources":["webpack://./src/components/AdminPage/GroupeListPage/ItemGroupList/ItemGroupList.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,SAAS;EACT,eAAe;EACf,kBAAkB;EAClB,WAAW;EACX,eAAe;EACf,mBAAmB;EACnB,iBAAiB;AACnB;AACA;EACE,wCAAwC;AAC1C;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,uBAAuB;EACvB,SAAS;AACX;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;AACd","sourcesContent":[".container {\n  display: flex;\n  gap: 25px;\n  padding: 18px 0;\n  width: max-content;\n  color: #fff;\n  font-size: 16px;\n  font-weight: normal;\n  line-height: 22px;\n}\n.isBanned {\n  background-color: rgba(247, 166, 0, 0.3);\n}\n\n.botsTypeBox {\n  display: flex;\n  width: 200px;\n  justify-content: center;\n  gap: 67px;\n}\n\n.date {\n  width: 160px;\n}\n\n.uid {\n  width: 110px;\n}\n\n.group {\n  width: 200px;\n}\n\n.functionality {\n  width: 500px;\n}\n\n.users {\n  width: 200px;\n}\n\n.trading {\n  width: 190px;\n}\n\n.pnl {\n  width: 197px;\n}\n\n.equity {\n  width: 210px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ItemGroupList_container__i+54g`,
	"isBanned": `ItemGroupList_isBanned__OKP3Y`,
	"botsTypeBox": `ItemGroupList_botsTypeBox__o+6s8`,
	"date": `ItemGroupList_date__DOxMa`,
	"uid": `ItemGroupList_uid__pddG+`,
	"group": `ItemGroupList_group__AbMjL`,
	"functionality": `ItemGroupList_functionality__bVFmg`,
	"users": `ItemGroupList_users__bQNz8`,
	"trading": `ItemGroupList_trading__4-0Hm`,
	"pnl": `ItemGroupList_pnl__B8RNO`,
	"equity": `ItemGroupList_equity__YaxCk`
};
export default ___CSS_LOADER_EXPORT___;
