import {
  IEquityStop,
  IInOrderDistance,
  IInOrderQuantity,
  IMultiplierInOrderDistance,
  IMultiplierInOrderQuantity,
  ITrailingTrigger,
  IValidateParams,
} from '../../../../redux/types';

export const checkIsFloat = (value: string) => {
  if (value === '' || /^[0-9]+([.,][0-9]*)?$/.test(value)) {
    return true;
  }
  return false;
};

export const checkIsMoreOrEqualOne = (value: string) => {
  if (value === '' || /^(?!0$)[0-9]+([.,][0-9]*)?$/.test(value)) {
    return true;
  }
  return false;
};

export const checkIsInteger = (value: string) => {
  if (value === '' || /^[0-9]+$/.test(value)) {
    return true;
  }
  return false;
};

export const checkIsValid = (validateResponse: string) => {
  return validateResponse === 'Ok';
};

export const checkValidInOrdersValues = (
  inOrderQuantity: IInOrderQuantity,
  multiplierInOrderQuantity: IMultiplierInOrderQuantity | null,
  inOrderDistance: IInOrderDistance,
  multiplierInOrderDistance: IMultiplierInOrderDistance | null,
  trailingBuyDistance: number | null,
  validation: IValidateParams,
) => {
  const {
    inOrderDistance: validationEntryOrderQty,
    inOrderQuantity: validationGridStep,
    multiplierInOrderQuantity: validationQtyMultiplier,
    multiplierInOrderDistance: validationGridStepMultiplier,
    trailingBuyDistance: validTrailingBuyDistance,
  } = validation;

  const isEntryOrderQuantityValid =
    checkIsValid(validationEntryOrderQty) || inOrderQuantity.value >= 1;

  const isGridStepValid = checkIsValid(validationGridStep) || inOrderDistance.value > 0;

  const isPriceLevelValid = checkIsValid(validationGridStep) || inOrderDistance.startFrom !== 0;

  const isQtyMultiplierRatioValid =
    checkIsValid(validationQtyMultiplier) ||
    (multiplierInOrderQuantity !== null ? multiplierInOrderQuantity?.ratio >= 1 : true);
  const isMaxEntryOrderQtyValid =
    checkIsValid(validationQtyMultiplier) ||
    (multiplierInOrderQuantity !== null ? multiplierInOrderQuantity?.maxQuantity > 0 : true);

  const isGridStepMultiplierRatioValid =
    checkIsValid(validationGridStepMultiplier) ||
    (multiplierInOrderDistance !== null ? multiplierInOrderDistance?.ratio >= 1 : true);

  const isGridStepMultiplierMaxGridStepValid =
    checkIsValid(validationGridStepMultiplier) ||
    (multiplierInOrderDistance !== null ? multiplierInOrderDistance?.maxDistance > 0 : true);

  const isTrailingBuyValid = checkIsValid(validTrailingBuyDistance) || trailingBuyDistance !== 0;

  return {
    isPriceLevelValid,
    isEntryOrderQuantityValid,
    isGridStepValid,
    isQtyMultiplierRatioValid,
    isMaxEntryOrderQtyValid,
    isGridStepMultiplierRatioValid,
    isGridStepMultiplierMaxGridStepValid,
    isTrailingBuyValid,
  };
};

export const checkValidTriggersValues = (
  triggerDistance: number,
  maxTriggerCount: number,
  trailingTrigger: ITrailingTrigger | null,
  validation: IValidateParams,
) => {
  const {
    triggerDistance: validationTriggerDistance,
    maxTriggerCount: validationMaxTriggerCount,
    trailingTrigger: validationTrailingTrigger,
  } = validation;

  const isTriggerDistanceValid = checkIsValid(validationTriggerDistance) || triggerDistance > 0;

  const isMaxTriggerCountValid = checkIsValid(validationMaxTriggerCount) || maxTriggerCount >= 1;

  const isTrailingTriggerProfitValid =
    checkIsValid(validationTrailingTrigger) ||
    (trailingTrigger && trailingTrigger?.profitPercent !== 0);

  const isTrailingTriggerDistanceValid =
    checkIsValid(validationTrailingTrigger) ||
    (trailingTrigger && trailingTrigger?.startDistance !== 0);

  return {
    isTriggerDistanceValid,
    isMaxTriggerCountValid,
    isTrailingTriggerProfitValid,
    isTrailingTriggerDistanceValid,
  };
};

export const checkValidTakeProfit = (
  minDistance: number,
  maxDistance: number,
  validationStatus: string,
) => {
  const isMinDistanceValid = checkIsValid(validationStatus) || minDistance > 0;

  const isMaxDistanceValid =
    checkIsValid(validationStatus) || (maxDistance > 0 && maxDistance > minDistance);

  return {
    isMinDistanceValid,
    isMaxDistanceValid,
  };
};

export const checkEquityStop = (equityStop: IEquityStop | null, validationStatus: string) => {
  const MAX_PERCENT = 100;
  return (
    checkIsValid(validationStatus) ||
    (equityStop &&
      equityStop.minEquity > 0 &&
      equityStop?.usePercent &&
      equityStop.minEquity < MAX_PERCENT)
  );
};

export const checkATRMultipliers = (
  upperATR: number,
  lowerATR: number,
  validationStatus: string,
) => {
  const isUpperATRValid = upperATR > 0 || checkIsValid(validationStatus);
  const isLowerATRValid = lowerATR > 0 || checkIsValid(validationStatus);

  return { isUpperATRValid, isLowerATRValid };
};
