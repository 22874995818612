import { EExchange } from '../../../LaunchBotPage/Leaderboard/ListLeaderboard/ListLeaderboard.types';

export interface IUser {
  dateTime: Date;
  uid: string;
  username: string;
  userStatus?: string;
  email: string;
  group: EGroup | EGroup[];
  exchanges: EExchange[];
  tradingVolume: number;
  realizedPnl: number;
  runningEquity: number;
  runningBots: IBotsType;
  botsCreated: IBotsType;
  botsCopied: IBotsType;
  totalProfit: string;
  botHistory: string;
  isActive: boolean;
  usersQuantity?: number;
}

export interface IBotsType {
  auto: number;
  pro: number;
}

export enum EGroup {
  user = 'user',
  admin = 'admin',
  sudo = 'sudo',
  whitelist = 'whitelist',
}
