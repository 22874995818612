import { NavLink, useLocation } from 'react-router-dom';
import styles from './Navigation.module.css';
import cn from 'classnames';

export interface ITab {
  id: number;
  name: string;
  link: string;
}

interface NavigationProps {
  title?: string;
  tabs: ITab[];
}

export const Navigation = ({ title, tabs }: NavigationProps) => {
  const { pathname } = useLocation();
  const getTabStyle = (path: string) =>
    cn([styles.tab], {
      [styles.tabActive]: pathname.includes(path),
    });

  return (
    <div className={cn(styles.container, { [styles.adminMargin]: pathname.includes('admin') })}>
      <div className={styles.menu}>{title && <div className={styles.title}>{title}</div>}</div>
      <div className={styles.buttonsBlock}>
        {tabs.map((tab) => (
          <NavLink key={tab.id} to={tab.link} className={getTabStyle(tab.link)}>
            {tab.name}
          </NavLink>
        ))}
      </div>
    </div>
  );
};
