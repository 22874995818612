import { Dispatch, SetStateAction } from 'react';
import HeaderButton from '../../UsersListPage/UsersList/HeaderButton';
import { IGroup } from '../group.types';

import styles from './HeaderGroupList.module.css';

interface IHeaderList {
  setGroups: Dispatch<SetStateAction<IGroup[]>>;
}

export default function HeaderGroupList({ setGroups }: IHeaderList) {
  return (
    <div className={styles.listHeader}>
      <HeaderButton
        className={styles.date}
        field='dateTime'
        title='Date/Time'
        setGroup={setGroups}
      />
      <div className={styles.uid}>UID</div>
      <div className={styles.group}>Groupname</div>
      <div className={styles.functionality}>Functionality</div>
      <HeaderButton
        field='usersQuantity'
        title='Quantity of users'
        className={styles.users}
        setGroup={setGroups}
      />
      <HeaderButton
        field='realizedPnl'
        title='Trading volume'
        className={styles.trading}
        setGroup={setGroups}
      />
      <HeaderButton
        field='realizedPnl'
        setGroup={setGroups}
        title='Realized PNL'
        className={styles.pnl}
      />
      <HeaderButton
        field='runningEquity'
        setGroup={setGroups}
        title='Running equity'
        className={styles.equity}
      />
      <div className={styles.runningBotsBox}>
        <HeaderButton
          field='runningBots'
          setGroup={setGroups}
          title='Running Bots'
          className={styles.runningBotsBox}
        />
        <div className={styles.botsTypeBox}>
          <div className={styles.botsType}>Pro</div>
          <div className={styles.botsType}>Auto</div>
        </div>
      </div>
      <div className={styles.runningBotsBox}>
        <HeaderButton
          field='botsCreated'
          setGroup={setGroups}
          title='Bots created'
          className={styles.runningBotsBox}
        />
        <div className={styles.botsTypeBox}>
          <div className={styles.botsType}>Pro</div>
          <div className={styles.botsType}>Auto</div>
        </div>
      </div>
      <div className={styles.runningBotsBox}>
        <HeaderButton
          field='botsCopied'
          setGroup={setGroups}
          title='Bots copied'
          className={styles.runningBotsBox}
        />
        <div className={styles.botsTypeBox}>
          <div className={styles.botsType}>Pro</div>
          <div className={styles.botsType}>Auto</div>
        </div>
      </div>
      <div className={styles.stopBtn} />
    </div>
  );
}
