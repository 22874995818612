// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.react-calendar__month-view__weekdays {
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 400;
}

.react-calendar__month-view__days__day {
  color: #fff;
}

.react-calendar__navigation__next-button {
  display: flex;
  transform: rotate(270deg);
}

.react-calendar__navigation__prev-button {
  display: flex;
  transform: rotate(90deg);
}

.react-calendar__navigation {
  display: flex;
  justify-content: space-between;
}

.react-calendar__navigation__prev2-button {
  display: none;
}

.react-calendar__navigation__next2-button {
  display: none;
}

.react-calendar__navigation__label {
  font-family: Jost;
  color: var(--secondary-text);
  font-size: 24px;
  font-weight: 400;
}

.react-calendar__tile--active {
  background-color: var(--purple-background);
  cursor: pointer;
}

.react-calendar__tile--active:first-child {
  border-radius: 5px 0px 0px 5px;
}

.react-calendar__tile--rangeEnd {
  border-radius: 0px 5px 5px 0px;
}
`, "",{"version":3,"sources":["webpack://./src/components/AdminPage/UsersListPage/Calendar/CalendarComponents.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,yBAAyB;EACzB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,aAAa;EACb,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,wBAAwB;AAC1B;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,iBAAiB;EACjB,4BAA4B;EAC5B,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,0CAA0C;EAC1C,eAAe;AACjB;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,8BAA8B;AAChC","sourcesContent":[".react-calendar__month-view__weekdays {\n  display: flex;\n  justify-content: center;\n  text-transform: uppercase;\n  font-size: 14px;\n  font-weight: 400;\n}\n\n.react-calendar__month-view__days__day {\n  color: #fff;\n}\n\n.react-calendar__navigation__next-button {\n  display: flex;\n  transform: rotate(270deg);\n}\n\n.react-calendar__navigation__prev-button {\n  display: flex;\n  transform: rotate(90deg);\n}\n\n.react-calendar__navigation {\n  display: flex;\n  justify-content: space-between;\n}\n\n.react-calendar__navigation__prev2-button {\n  display: none;\n}\n\n.react-calendar__navigation__next2-button {\n  display: none;\n}\n\n.react-calendar__navigation__label {\n  font-family: Jost;\n  color: var(--secondary-text);\n  font-size: 24px;\n  font-weight: 400;\n}\n\n.react-calendar__tile--active {\n  background-color: var(--purple-background);\n  cursor: pointer;\n}\n\n.react-calendar__tile--active:first-child {\n  border-radius: 5px 0px 0px 5px;\n}\n\n.react-calendar__tile--rangeEnd {\n  border-radius: 0px 5px 5px 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
