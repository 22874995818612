// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GroupList_container__Hc90U {
  margin: 0 5%;
}

.GroupList_list__vrEhd {
  overflow-y: scroll;
}
.GroupList_list__vrEhd::-webkit-scrollbar {
  width: 0;
}

.GroupList_list__vrEhd::-webkit-scrollbar-thumb {
  background-color: var(--secondary-text);
}
.GroupList_list__vrEhd::-webkit-scrollbar-thumb:hover,
.GroupList_list__vrEhd::-webkit-scrollbar-thumb:active {
  background-color: var(--purple-background);
}
`, "",{"version":3,"sources":["webpack://./src/components/AdminPage/GroupeListPage/GroupList/GroupList.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,kBAAkB;AACpB;AACA;EACE,QAAQ;AACV;;AAEA;EACE,uCAAuC;AACzC;AACA;;EAEE,0CAA0C;AAC5C","sourcesContent":[".container {\n  margin: 0 5%;\n}\n\n.list {\n  overflow-y: scroll;\n}\n.list::-webkit-scrollbar {\n  width: 0;\n}\n\n.list::-webkit-scrollbar-thumb {\n  background-color: var(--secondary-text);\n}\n.list::-webkit-scrollbar-thumb:hover,\n.list::-webkit-scrollbar-thumb:active {\n  background-color: var(--purple-background);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `GroupList_container__Hc90U`,
	"list": `GroupList_list__vrEhd`
};
export default ___CSS_LOADER_EXPORT___;
