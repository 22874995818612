import React from 'react';
import styles from './AboutUs.module.css';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useAppSelector } from '../../../redux/hooks/redux';

const AboutUs: React.FC = () => {
  const { t, i18n } = useTranslation('aboutUs');
  const isMobileMenuOpen = useAppSelector(state => state.layout.isMobileMenuOpen);
  const isEnglish = i18n.language === 'en';

  const descriptionStyle = cn(styles.description, { [styles.en]: isEnglish });
  const containerStyle = cn(styles.container, { [styles.blur]: isMobileMenuOpen });

  return (
    <div id='about_us' className={containerStyle}>
      <h2>{t('title')}</h2>
      <p className={styles.description}>{t('description1')}</p>
      <p className={styles.description}>{t('description2')}</p>
      <p className={descriptionStyle}>{t('description3')}</p>
      <p className={styles.description}>{t('description4')}</p>
      <div className={styles.tablet}></div>
    </div>
  );
};

export default AboutUs;
