import React, { useEffect, useState } from 'react';
import styles from './GridBot.module.css';
import { getFeaturesContent } from './constants';
import Popup from '../Popup/Popup';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { useLocation } from 'react-router-dom';

const GridBot: React.FC = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popupTitle, setPopupTitle] = useState('');
  const [popupContent, setPopupContent] = useState(['']);
  const { t, i18n } = useTranslation('gridBot');
  const isEnglish = i18n.language === 'en';
  const isMobile = useMediaQuery('(max-width: 1100px)');

  const cardStyle = cn(styles.card, { [styles.en]: isEnglish });
  const location = useLocation();

  useEffect(() => {
    if (location.hash.includes('grid_bot')) {
      window.scrollTo({
        top: document.getElementById('grid_bot')?.offsetTop,
        behavior: 'smooth',
      });
    }
  }, [location]);
  return (
    <div id='grid_bot' className={styles.container}>
      <h2>{t('title')}</h2>
      <h3 className={styles.subtitle}>{t('subtitle')}</h3>

      <div className={styles.cards}>
        {getFeaturesContent(t).map(({ id, title, description, popup, imgSrc }) => {
          const onFeatureBtnClick = () => {
            const popupTitleContent = isMobile ? popup.titleMobile : popup.title;
            const popupContentBySize = isMobile ? popup.paragraphsMobile : popup.paragraphs;
            setPopupTitle(popupTitleContent);
            setPopupContent(popupContentBySize);
            setIsPopupOpen(true);
          };

          return (
            <div key={id} className={cardStyle}>
              <img src={imgSrc} />
              <div className={styles.card_content}>
                <h3>{title}</h3>
                <p>{description}</p>
                <button onClick={onFeatureBtnClick}>{t('featureButton')}</button>
              </div>
            </div>
          );
        })}
      </div>

      {isPopupOpen && (
        <Popup
          popupContent={popupContent}
          popupTitle={popupTitle}
          setIsPopupOpen={setIsPopupOpen}
        />
      )}
    </div>
  );
};

export default GridBot;
