/* eslint-disable camelcase */
import { Dispatch, SetStateAction, useState } from 'react';
import {
  useLoginMutation,
  useGetSessionIdMutation,
  useConfirmOperationMutation,
  useCancelAnyOperationsMutation,
  useFinishAllSessionsMutation,
} from '../../redux/services/signUpService';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { formatDeepSnakeObjKeysToCamelCase } from '../../utils/formatSnakeToCamelCase';

enum EErrors {
  id = 'uuid.invalid.user',
  password = 'password.wrong',
  exists = 'user.session.exists',
  awaiting = 'operation.awaiting',
}

function useSignIn(
  userId: string,
  password: string,
): [() => Promise<void>, string, boolean, Dispatch<SetStateAction<boolean>>, boolean] {
  const { t } = useTranslation('signIn');

  const navigate = useNavigate();
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [login] = useLoginMutation();
  const [sessionId] = useGetSessionIdMutation();
  const [confirmOperation] = useConfirmOperationMutation();
  const [cancelAnyOperations] = useCancelAnyOperationsMutation();
  const [finishSession] = useFinishAllSessionsMutation();
  const credentials = { userId, body: { password, code: '' } };

  const getWsSessionId = (userId: string, password: string) => {
    const HOST = process.env.REACT_APP_WSS_HOST;
    const socket = new WebSocket(`${HOST}/ws/v1/login`);

    socket.addEventListener('open', () => {
      socket.send(JSON.stringify({ user_id: userId, password }));
    });

    socket.addEventListener('message', (event) => {
      const formattedResponse = formatDeepSnakeObjKeysToCamelCase(JSON.parse(event.data)) as {
        sessionId: string;
      };
      sessionStorage.setItem('wsId', formattedResponse.sessionId);
      socket.close();
    });

    socket.addEventListener('error', (error) => {
      console.error('WebSocket connecting error: ', error);
    });
  };

  const getToken = async () => {
    try {
      const {
        token: { access_token },
        status: { code },
      } = await login(credentials).unwrap();

      if (Object.values(EErrors).includes(code)) {
        setIsError(true);
        const isIdOrPassword = code === EErrors.id || code === EErrors.password;
        const errorMessage = isIdOrPassword ? t('idPasswordError') : 'Error';

        return setErrorMessage(errorMessage);
      }

      sessionStorage.setItem('token', access_token);

      return access_token;
    } catch (error) {
      setIsError(true);
      return setErrorMessage('Something went wrong');
    }
  };

  const confirmOperations = async (operation_id: string, access_token: string) => {
    try {
      const {
        status: { code },
      } = await confirmOperation({ userId, operation_id, access_token, credentials }).unwrap();
      return code;
    } catch (error) {
      console.log(error);
      setIsError(true);
      return setErrorMessage('Something went wrong');
    }
  };

  const getSessionId = async (access_token: string) => {
    try {
      const {
        session_id,
        operation_id,
        status: { code },
      } = await sessionId({ userId, access_token }).unwrap();

      if (code === 'ok') {
        sessionStorage.setItem('session_id', session_id);
        const code = await confirmOperations(operation_id, access_token);
        if (code === 'ok') {
          navigate('/bots/mybots');
        }
      }
      return code;
    } catch (error) {
      setIsError(true);
      return setErrorMessage('Something went wrong');
    }
  };

  const onSignIn = async () => {
    setIsLoading(true);
    const access_token = await getToken();
    if (!access_token || isError) {
      setIsLoading(false);
      return;
    }

    const code = await getSessionId(access_token);
    localStorage.setItem('userId', userId);
    if (Object.values(EErrors).includes(code)) {
      if (code === EErrors.exists) {
        const { operation_id } = await finishSession({ userId, access_token }).unwrap();
        await confirmOperations(operation_id, access_token);
      }
      if (code === EErrors.awaiting) {
        await cancelAnyOperations({ userId, access_token, credentials });
      }
      await getSessionId(access_token);
    }
    getWsSessionId(userId, password);
    setIsLoading(false);
  };

  return [onSignIn, errorMessage, isError, setIsError, isLoading];
}

export default useSignIn;
