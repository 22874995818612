// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CreateNotification_container__hfrnz {
  display: flex;
  margin: 0 5%;
  gap: 40px;
}

.CreateNotification_container__hfrnz hr {
  margin: 0;
  border-color: var(--border-white-02);
}

.CreateNotification_topic__HFOaq input {
  background: transparent;
  width: 530px;
}

.CreateNotification_container__hfrnz h3 {
  margin: 0;
}

.CreateNotification_textField__zPwMc {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.CreateNotification_buttons__W5c2T {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.CreateNotification_send__8hM7\\+ {
  display: inline-flex;
  width: max-content;
  height: 44px;
  padding: 16px 40px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: var(--text-colored);
  color: #000;
  font-weight: bold;
  font-family: PTSans;
  font-size: 16px;
}

.CreateNotification_buttons__W5c2T button:disabled {
  opacity: 0.5;
}
`, "",{"version":3,"sources":["webpack://./src/components/AdminPage/NewNotificationPage/CreateNotification/CreateNotification.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;EACZ,SAAS;AACX;;AAEA;EACE,SAAS;EACT,oCAAoC;AACtC;;AAEA;EACE,uBAAuB;EACvB,YAAY;AACd;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,oBAAoB;EACpB,kBAAkB;EAClB,YAAY;EACZ,kBAAkB;EAClB,uBAAuB;EACvB,mBAAmB;EACnB,mBAAmB;EACnB,+BAA+B;EAC/B,WAAW;EACX,iBAAiB;EACjB,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,YAAY;AACd","sourcesContent":[".container {\n  display: flex;\n  margin: 0 5%;\n  gap: 40px;\n}\n\n.container hr {\n  margin: 0;\n  border-color: var(--border-white-02);\n}\n\n.topic input {\n  background: transparent;\n  width: 530px;\n}\n\n.container h3 {\n  margin: 0;\n}\n\n.textField {\n  display: flex;\n  flex-direction: column;\n  gap: 30px;\n}\n\n.buttons {\n  display: flex;\n  flex-direction: column;\n  gap: 40px;\n}\n\n.send {\n  display: inline-flex;\n  width: max-content;\n  height: 44px;\n  padding: 16px 40px;\n  justify-content: center;\n  align-items: center;\n  border-radius: 10px;\n  background: var(--text-colored);\n  color: #000;\n  font-weight: bold;\n  font-family: PTSans;\n  font-size: 16px;\n}\n\n.buttons button:disabled {\n  opacity: 0.5;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `CreateNotification_container__hfrnz`,
	"topic": `CreateNotification_topic__HFOaq`,
	"textField": `CreateNotification_textField__zPwMc`,
	"buttons": `CreateNotification_buttons__W5c2T`,
	"send": `CreateNotification_send__8hM7+`
};
export default ___CSS_LOADER_EXPORT___;
