import React from 'react';
import styles from './Presentation.module.css';
import { useTranslation } from 'react-i18next';

const Presentation: React.FC = () => {
  const { t } = useTranslation('presentation');

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <h1 className={styles.title}>{t('NewTradingLevel')}</h1>
        <div className={styles.subtitle}>{t('strategyReverse')}</div>
        <div className={styles.close_testing}>{t('betaTesting')}</div>
        <div className={styles.promo}>
          <h2>{t('earn')}</h2>
          <p>{t('cryptoBots')}</p>
        </div>
      </div>
    </div>
  );
};

export default Presentation;
