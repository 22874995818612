import React from 'react';
import styles from './GroupIcon.module.css';
import { EGroup } from '../UsersList/UserList.types';
import cn from 'classnames';

interface IGroupIcon {
  group: EGroup;
}

export default function GroupIcon({ group }: IGroupIcon) {
  return (
    <div
      className={cn(
        [styles.icon],
        { [styles.admin]: group === EGroup.admin },
        { [styles.sudo]: group === EGroup.sudo },
        { [styles.whitelist]: group === EGroup.whitelist },
      )}
    >
      {group}
    </div>
  );
}
