import { Dispatch, SetStateAction, useState } from 'react';
import { Checkbox } from '../../../CCLabsUI';
import { ECheckboxVariants } from '../../../CCLabsUI/Checkbox/Checkbox';
import { IUser } from '../../UsersListPage/UsersList/UserList.types';
import { IFeature } from '../StepCreateFunctionality/StepCreateFunctionality.types';
import styles from './StepGroupOverview.module.css';
import PopupUsersList from '../PopupUsersList/PopupUsersList';

interface IStepGroupOverviewProps {
  groupName: string;
  features: IFeature[];
  userList: IUser[];
  selectedUsers: IUser[];
  setFeatures: Dispatch<SetStateAction<IFeature[]>>;
  setUserList: Dispatch<SetStateAction<IUser[]>>;
  setSelectedUsers: Dispatch<SetStateAction<IUser[]>>;
};

const StepGroupOverview = ({
  groupName,
  features,
  selectedUsers,
  setSelectedUsers,
  setFeatures,
  setUserList,
}: IStepGroupOverviewProps) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const selectedFeatures = features.filter(({ checked }) => checked);

  const onItemChange = (name: string, checked: boolean) => () => {
    setFeatures((prevFeatures) => {
      const currentFeatureIndex = prevFeatures.findIndex(feature => feature.name === name);
      prevFeatures[currentFeatureIndex].checked = !checked;
      return [...prevFeatures];
    });
  };

  const onDiscardClick = () => {
    const newFeatures = features.map(({ name }) => ({ name, checked: false }));
    setFeatures(newFeatures);
  };

  const onGroupMembersClick = () => {
    setIsPopupOpen(true);
  };

  return (
    <div className={styles.container}>
      <div>
        <h4>Group name</h4>
        <div className={styles.group_name}>{groupName}</div>
      </div>

      <div className={styles.controls}>
        <button>Select all</button>
        <button onClick={onDiscardClick}>Discard</button>
        <button onClick={onGroupMembersClick}>+<span>Group members</span></button>
      </div>

      <div className={styles.functionality}>
        <h4>Functionality</h4>
        <div className={styles.features}>
          {selectedFeatures.map(({ name, checked }) => (
            <Checkbox
              label={name}
              name={name}
              key={name}
              checked={checked}
              variant={ECheckboxVariants.primary}
              onChange={onItemChange(name, checked)}
              className={styles.features_item}
            />
          ))}
        </div>
      </div>
      {isPopupOpen && (
        <PopupUsersList
          setIsPopupOpen={setIsPopupOpen}
          selectedUsers={selectedUsers}
          setSelectedUsers={setSelectedUsers}
          setUserList={setUserList}
        />
      )}
    </div>
  );
};

export default StepGroupOverview;
