import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  isOpenCreateBot: false,
  isOpenSelectUsersModal: false,
  isOpenRoles: false,
  isOpenAddMembers: false,
  isOpenOrdersModal: false,
  isOpenShareModal: false,
};

export const modalWindowSlice = createSlice({
  name: 'modalWindow',
  initialState,
  reducers: {
    modalCreateBot(state, action) {
      state.isOpenCreateBot = !action.payload;
    },
    modalSelectUsers(state, action) {
      state.isOpenSelectUsersModal = !action.payload;
    },
    modalRoles(state, action) {
      state.isOpenRoles = !action.payload;
    },
    modalAddMembers(state, action) {
      state.isOpenAddMembers = !action.payload;
    },
    modalOrders(state, action) {
      state.isOpenOrdersModal = !action.payload;
    },
    modalShare(state, action) {
      state.isOpenShareModal = !action.payload;
    },
  },
});

export const {
  modalCreateBot,
  modalSelectUsers,
  modalRoles,
  modalAddMembers,
  modalOrders,
  modalShare,
} = modalWindowSlice.actions;
export default modalWindowSlice.reducer;
