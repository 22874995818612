// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StatisticNavigation_container__AAKc\\+ {
  display: flex;
  gap: 30px;
}

.StatisticNavigation_container__AAKc\\+ a {
  text-decoration: none;
  color: white;
  font-weight: normal;
  line-height: 24px;
}

.StatisticNavigation_active__Z1DSU {
  border-bottom: 2px solid var(--text-colored);
}
`, "",{"version":3,"sources":["webpack://./src/components/AdminPage/AdminStatisticsPage/StatisticNavigation/StatisticNavigation.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,qBAAqB;EACrB,YAAY;EACZ,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,4CAA4C;AAC9C","sourcesContent":[".container {\n  display: flex;\n  gap: 30px;\n}\n\n.container a {\n  text-decoration: none;\n  color: white;\n  font-weight: normal;\n  line-height: 24px;\n}\n\n.active {\n  border-bottom: 2px solid var(--text-colored);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `StatisticNavigation_container__AAKc+`,
	"active": `StatisticNavigation_active__Z1DSU`
};
export default ___CSS_LOADER_EXPORT___;
