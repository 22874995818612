// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PasswordValidation_passwordValidationContainer__Duhmp {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 80px;
  column-gap: 10px;
}

@media (max-width: 768px) {
  .PasswordValidation_passwordValidationContainer__Duhmp {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    height: auto;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/PasswordValidation/PasswordValidation.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,eAAe;EACf,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE;IACE,aAAa;IACb,sBAAsB;IACtB,iBAAiB;IACjB,YAAY;EACd;AACF","sourcesContent":[".passwordValidationContainer {\n  display: flex;\n  flex-direction: column;\n  flex-wrap: wrap;\n  height: 80px;\n  column-gap: 10px;\n}\n\n@media (max-width: 768px) {\n  .passwordValidationContainer {\n    display: flex;\n    flex-direction: column;\n    flex-wrap: nowrap;\n    height: auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"passwordValidationContainer": `PasswordValidation_passwordValidationContainer__Duhmp`
};
export default ___CSS_LOADER_EXPORT___;
