import React from 'react';
import { Input } from '../../../CCLabsUI';
import { ESizing } from '../../../CCLabsUI/Input/Input';
import SettingsIcon from '../../../../assets/settings-icon.svg';
import SearchIcon from '../../../../assets/search-icon.svg';
import styles from '../StatusUsersBar/StatusUserBar.module.css';

interface ISearchBar {
  searchValue: string;
  onSetSearchValue: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSearch: () => void;
  onSettingsOpen: () => void;
  buttonTitle?: string;
  onCreateNew?: () => void;
  isButtonVisible?: boolean;
}

export default function SearchBar({
  searchValue,
  onSetSearchValue,
  onSearch,
  onSettingsOpen,
  buttonTitle,
  onCreateNew,
  isButtonVisible = true,
}: ISearchBar) {
  return (
    <div className={styles.search}>
      {isButtonVisible &&
        <button className={styles.createGroupBtn} onClick={onCreateNew}>
          {buttonTitle}
        </button>
      }
      <div className={styles.searchField}>
        <Input
          label=''
          sizing={ESizing.medium}
          placeholder='Поиск'
          value={searchValue}
          onChange={(e) => onSetSearchValue(e)}
        />
        <button className={styles.searchBtn} onClick={onSearch}>
          <img src={SearchIcon} />
        </button>
        <button className={styles.settingsBtn} onClick={onSettingsOpen}>
          <img src={SettingsIcon} />
        </button>
      </div>
    </div>
  );
}
