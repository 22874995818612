import { AreaChart, Area, XAxis, YAxis, ReferenceLine, Tooltip, TooltipProps } from 'recharts';
import styles from './APRCharts.module.css';

export interface IRechartsData {
  name: string;
  value: number;
}

const data: IRechartsData[] = [
  {
    name: 'A',
    value: 500,
  },
  {
    name: 'B',
    value: 1100,
  },
  {
    name: 'C',
    value: 500,
  },
  {
    name: 'D',
    value: 1750,
  },
  {
    name: 'E',
    value: -650,
  },
  {
    name: 'F',
    value: 500,
  },
];

const CustomTooltip = ({ active, payload }: TooltipProps<string, number>) => {
  if (active && payload && payload.length) {
    return (
      <div className={styles.tooltipContainer}>
        <div className={styles.label}>09 Aug 2022</div>
        <div className={styles.charData}>-11,669.78%</div>
      </div>
    );
  }
  return null;
};

const APRCharts = () => {
  return (
    <>
      <AreaChart width={240} height={36} data={data}>
        <XAxis dataKey='name' hide />
        <YAxis hide />
        <Tooltip
          content={<CustomTooltip />}
          wrapperStyle={{ outline: 'none' }}
          cursor={false}
          position={{ x: 90, y: -170 }}
        />
        <ReferenceLine y={0} stroke='#db3b21' />
        <ReferenceLine
          segment={[
            { x: 'D', y: 0 },
            { x: 'D', y: 1750 },
          ]}
          stroke='#db3b21'
        />
        <Area type='monotone' dataKey='value' stroke='#db3b21' fill='#db3a2195' />
      </AreaChart>
    </>
  );
};

export default APRCharts;
