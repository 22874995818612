import { memo, useEffect, useState } from 'react';
import TabsSettingsCard from './TabsSettingsCard';
import InputSettings from '../InputSettings/InputSettings';
import styles from '../BotSettings.module.css';
import SwitchBlock from './SwitchBlock';
import TitleSettingsCard from './TitleCard';
import IconWithTooltip from '../../../IconWithTooltip/IconWithTooltip';
import { ITab } from '../../../StatisticPage/Tabs/Tabs';
import { CARD_CLASSNAMES, INNER_CARD_CLASSES } from '../SettingsBlock/SettingsBlock.constants';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks/redux';
import { tempSettings } from '../../../../redux/selectors/rootSelector';
import {
  changeEntryOrderMode,
  changeEntryOrderQty,
  changeGridStep,
  changeGridStepRatio,
  toggleGridStepUsePercent,
  changeMaxGridStep,
  changePriceLevel,
  changeQtyEntryOrder,
  changeQtyMultiplierRatio,
  changeTailingDistance,
  changeTradingRangeFrom,
  changeTradingRangeTo,
  toggleGridStepMultiplier,
  toggleQtyMultiplier,
  toggleTradingRange,
  toggleTrailingBuy,
} from '../../../../redux/slices/tempSettingsSlice';
import { checkIsFloat, checkIsMoreOrEqualOne, checkValidInOrdersValues } from './input.utils';

function InOrders() {
  const {
    strategyParameters: {
      inOrderQuantity,
      multiplierInOrderQuantity,
      inOrderDistance,
      multiplierInOrderDistance,
      trailingBuyDistance,
    },
  } = useAppSelector(tempSettings);

  const { validation } = useAppSelector((state) => state.validateParams);

  const { t } = useTranslation('botspage');
  const dispatch = useAppDispatch();

  const tabs: ITab[] = [
    { value: 'fromLaunch', render: t('settings.entryOrders.tabs.fromLaunch') },
    { value: 'fromPrice', render: t('settings.entryOrders.tabs.fromPrice') },
  ];

  const trailingDistanceTooltip = `${t('tooltips.entryOrders.trailingDistance.p1')} \n${t(
    'tooltips.entryOrders.trailingDistance.p2',
  )}`;

  const handleChangeGridStepMode = () => {
    dispatch(toggleGridStepUsePercent());
  };

  const [validationStatuses, setValidationStatuses] = useState(
    checkValidInOrdersValues(
      inOrderQuantity,
      multiplierInOrderQuantity,
      inOrderDistance,
      multiplierInOrderDistance,
      trailingBuyDistance,
      validation,
    ),
  );

  useEffect(() => {
    setValidationStatuses(
      checkValidInOrdersValues(
        inOrderQuantity,
        multiplierInOrderQuantity,
        inOrderDistance,
        multiplierInOrderDistance,
        trailingBuyDistance,
        validation,
      ),
    );
  }, [validation]);

  return (
    <div className={CARD_CLASSNAMES}>
      <TitleSettingsCard title={t('settings.entryOrders.title')} hasTooltip={false} />
      <div className={styles.cardSubTitle}>
        {t('settings.entryOrders.subtitle')}{' '}
        <IconWithTooltip
          color='#afafaf'
          content={t('tooltips.entryOrders.subtitle')}
          height={15}
          tooltipWidth={164}
        />
      </div>
      <TabsSettingsCard
        tabs={tabs}
        activeTab={inOrderDistance.startFrom !== null ? 'fromPrice' : 'fromLaunch'}
        onSetActiveTab={({ value }) => {
          dispatch(changeEntryOrderMode(value));
        }}
      />
      {inOrderDistance.startFrom !== null && (
        <InputSettings
          label={t('settings.entryOrders.priceLevel')}
          value={inOrderDistance.startFrom}
          onChange={(e) =>
            checkIsFloat(e.target.value) && dispatch(changePriceLevel(e.target.value))
          }
          isPercentVisible={false}
          tooltip={t('tooltips.entryOrders.priceLevel')}
          usePercent={false}
          hasError={!validationStatuses.isPriceLevelValid}
          errorMessage='Must be bigger than 0'
        />
      )}
      <div className={INNER_CARD_CLASSES}>
        <TitleSettingsCard
          title={t('settings.entryOrders.qtyEntryOrder')}
          tooltip={t('tooltips.entryOrders.qtyEntryOrders')}
        />
        <InputSettings
          value={inOrderQuantity.value}
          onChange={(e) =>
            checkIsFloat(e.target.value) && dispatch(changeQtyEntryOrder(e.target.value))
          }
          isPercentVisible={false}
          usePercent={false}
          hasError={!validationStatuses.isEntryOrderQuantityValid}
          errorMessage='Must be bigger than 0'
        />
        <SwitchBlock
          title={t('settings.entryOrders.qtyMultiplier')}
          isSwitched={multiplierInOrderQuantity !== null}
          action={() => dispatch(toggleQtyMultiplier())}
          tooltip={t('tooltips.entryOrders.qtyMultiplier')}
        />
        <InputSettings
          name='ratio'
          label={t('settings.entryOrders.ratio')}
          value={multiplierInOrderQuantity?.ratio}
          onChange={(e) =>
            checkIsMoreOrEqualOne(e.target.value) &&
            dispatch(changeQtyMultiplierRatio(e.target.value))
          }
          usePercent={false}
          isPercentVisible={false}
          tooltip={t('tooltips.entryOrders.ratioMultiplier')}
          readonly={!multiplierInOrderQuantity}
          hasError={!validationStatuses.isQtyMultiplierRatioValid}
          errorMessage='Must be bigger than 1.0'
        />
        <InputSettings
          name='maxQuantity'
          label={t('settings.entryOrders.maxEntryOrder')}
          value={multiplierInOrderQuantity?.maxQuantity}
          onChange={(e) =>
            checkIsFloat(e.target.value) && dispatch(changeEntryOrderQty(e.target.value))
          }
          usePercent={false}
          isPercentVisible={false}
          tooltip={t('tooltips.entryOrders.maxEntryOrder')}
          readonly={multiplierInOrderQuantity === null}
          hasError={!validationStatuses.isMaxEntryOrderQtyValid}
          errorMessage='Must be bigger than 0'
        />
      </div>
      <div className={INNER_CARD_CLASSES}>
        <TitleSettingsCard
          title={t('settings.entryOrders.gridStep')}
          tooltip={t('tooltips.entryOrders.gridStep')}
        />
        <InputSettings
          value={inOrderDistance.value}
          usePercent={inOrderDistance.usePercent}
          onChange={(e) => checkIsFloat(e.target.value) && dispatch(changeGridStep(e.target.value))}
          setUsePercent={handleChangeGridStepMode}
          hasError={!validationStatuses.isGridStepValid}
          errorMessage='Must be bigger than 0'
        />
        <SwitchBlock
          title={t('settings.entryOrders.gridStepMultiplier')}
          isSwitched={multiplierInOrderDistance !== null}
          action={() => dispatch(toggleGridStepMultiplier())}
          tooltip={t('tooltips.entryOrders.gridStepMultiplier')}
        />
        <InputSettings
          label={t('settings.entryOrders.ratio')}
          value={multiplierInOrderDistance?.ratio}
          onChange={(e) =>
            checkIsMoreOrEqualOne(e.target.value) &&
            dispatch(changeGridStepRatio(Number(e.target.value)))
          }
          isNumbersVisible={false}
          usePercent={true}
          hasTooltip={false}
          readonly={multiplierInOrderDistance === null}
          hasError={!validationStatuses.isGridStepMultiplierRatioValid}
          errorMessage='Must be bigger than 1.0'
        />
        <InputSettings
          label={t('settings.entryOrders.maxGridStep')}
          value={multiplierInOrderDistance?.maxDistance}
          usePercent={false}
          onChange={(e) =>
            checkIsFloat(e.target.value) && dispatch(changeMaxGridStep(e.target.value))
          }
          isPercentVisible={false}
          tooltip={t('tooltips.entryOrders.maxGridStep')}
          readonly={multiplierInOrderDistance === null}
          hasError={!validationStatuses.isGridStepMultiplierMaxGridStepValid}
          errorMessage='Must be bigger than 0'
        />
      </div>
      <div className={INNER_CARD_CLASSES}>
        <SwitchBlock
          title={t('settings.entryOrders.trailingBuy')}
          isSwitched={trailingBuyDistance !== null}
          action={() => dispatch(toggleTrailingBuy())}
          tooltip={t('tooltips.entryOrders.trailingBuy')}
        />
        <InputSettings
          label={t('settings.entryOrders.trailingDistance')}
          value={trailingBuyDistance}
          onChange={(e) =>
            checkIsFloat(e.target.value) && dispatch(changeTailingDistance(e.target.value))
          }
          tooltip={trailingDistanceTooltip}
          usePercent={false}
          isPercentVisible={false}
          readonly={trailingBuyDistance === null}
          hasError={!validationStatuses.isTrailingBuyValid}
          errorMessage='Must be bigger than 0'
        />
      </div>
      <div className={INNER_CARD_CLASSES}>
        <SwitchBlock
          title={t('settings.entryOrders.tradingRange')}
          isSwitched={false}
          action={() => dispatch(toggleTradingRange())}
          tooltip={t('tooltips.entryOrders.tradingRange')}
        />
        <InputSettings
          value={null}
          usePercent={false}
          isPercentVisible={false}
          onChange={(e) =>
            checkIsFloat(e.target.value) && dispatch(changeTradingRangeTo(e.target.value))
          }
          isDisabled={true}
          placeholder={t('settings.entryOrders.rangeHolderTo')}
        />
        <InputSettings
          value={null}
          onChange={(e) =>
            checkIsFloat(e.target.value) && dispatch(changeTradingRangeFrom(e.target.value))
          }
          isDisabled={true}
          placeholder={t('settings.entryOrders.rangeHolderFrom')}
          isPercentVisible={false}
        />
        <div className={styles.soonOverlay}>
          <div className={styles.soonText}>{t('settings.soon')}</div>
        </div>
      </div>
    </div>
  );
}

export default memo(InOrders);
