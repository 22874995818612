import { useAppDispatch } from '../../redux/hooks/redux';
import { useValidateParamsMutation } from '../../redux/services/botService';
import { initialValidationStatus } from '../../redux/slices/constants';
import { setValidationStatus } from '../../redux/slices/validateeFlowParamsSlice';
import { IFlowParameters, IValidateParams } from '../../redux/types';
import { formatDeepSnakeObjKeysToCamelCase } from '../../utils/formatSnakeToCamelCase';

export default function useValidateFlowParameters() {
  const [validateParams] = useValidateParamsMutation();
  const dispatch = useAppDispatch();
  const checkValidateResponse = (validateResponse: IValidateParams) => {
    const values = Object.values(validateResponse);
    return values.every((value) => value === 'Ok');
  };

  const requestValidateParams = async (flowParameters: IFlowParameters) => {
    dispatch(setValidationStatus(initialValidationStatus));
    try {
      const { strategy_parameters_validation: validationResponse } = await validateParams(
        flowParameters,
      ).unwrap();
      const formattedResponse = formatDeepSnakeObjKeysToCamelCase(
        validationResponse,
      ) as IValidateParams;
      dispatch(setValidationStatus(formattedResponse));
      return checkValidateResponse(formattedResponse);
    } catch (error) {
      console.log(error);
    }
  };
  return [requestValidateParams];
}
