import { TFunctionality } from './../types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IFunc {
  id: number;
  label: TFunctionality | string;
  checked: boolean;
}

interface IInitialFilterState {
  functionality: IFunc[];
  usersChecked: string[];
  mockDataBasicSettings: IFunc[];
  mockDataInOrders: IFunc[];
  mockDataTriggers: IFunc[];
  mockDataTakeProfit: IFunc[];
  mockDataStopLoss: IFunc[];
  mockDataFeatures: IFunc[];
}

export const initialState: IInitialFilterState = {
  functionality: [
    { id: 1, label: 'findAllUsers', checked: true },
    { id: 2, label: 'findOneUser', checked: false },
    { id: 3, label: 'updateUser', checked: true },
    { id: 4, label: 'userBotsStat', checked: true },
    { id: 5, label: 'twoFAOn', checked: true },
    { id: 6, label: 'twoFAOff', checked: true },
    { id: 7, label: 'saveKeys', checked: false },
    { id: 8, label: 'findUserSettings', checked: true },
    { id: 9, label: 'updateUserSettings', checked: true },
    { id: 10, label: 'createBot', checked: true },
    { id: 11, label: 'findUserBots', checked: true },
    { id: 12, label: 'leaderboardView', checked: false },
    { id: 13, label: 'updateBotSettings', checked: false },
    { id: 14, label: 'algorithmsView', checked: false },
    { id: 15, label: 'whiteListAdd', checked: true },
    { id: 16, label: 'whiteListRemove', checked: true },
    { id: 17, label: 'whiteListView', checked: true },
    { id: 18, label: 'whiteListUpdate', checked: false },
    { id: 19, label: 'getGroups', checked: false },
  ],
  usersChecked: [],
  mockDataBasicSettings: [
    { id: 20, label: 'Copy Settings', checked: false },
    { id: 21, label: 'Margin Mode', checked: true },
  ],
  mockDataInOrders: [
    { id: 22, label: 'Start create', checked: true },
    { id: 23, label: 'Order quantity', checked: true },
    { id: 24, label: 'Quantity multiplier', checked: false },
    { id: 25, label: 'Order distance', checked: true },
    { id: 26, label: 'Distance multiplier', checked: true },
    { id: 27, label: 'Trailing buy', checked: true },
    { id: 28, label: 'Trading range', checked: true },
    { id: 29, label: 'Counter Distance 2x', checked: false },
    { id: 30, label: 'Beast mode', checked: false },
  ],
  mockDataTriggers: [
    { id: 31, label: 'To change - All active/New', checked: true },
    { id: 32, label: 'Trigger distance', checked: true },
    { id: 33, label: 'Max triggers count', checked: true },
    { id: 34, label: 'Trailing triggers', checked: false },
  ],
  mockDataTakeProfit: [
    { id: 35, label: 'TP distance', checked: true },
    { id: 36, label: 'TP trailing distance', checked: false },
    { id: 37, label: 'Entire position TP', checked: true },
  ],
  mockDataStopLoss: [
    { id: 38, label: 'Range stop', checked: true },
    { id: 39, label: 'Equity stop ', checked: true },
    { id: 40, label: 'Min equity', checked: false },
  ],
  mockDataFeatures: [
    { id: 41, label: 'TREND AUTOVERSE', checked: true },
    { id: 42, label: 'AUTOHEDGE', checked: true },
    { id: 43, label: 'AUTOFILLING', checked: true },
    { id: 44, label: 'BACKTESTING', checked: false },
  ],
};

const functionalitySlice = createSlice({
  name: 'functionality',
  initialState,
  reducers: {
    changeStatus(state, action: PayloadAction<IFunc>) {
      state.functionality = state.functionality.map((func) =>
        func.id === action.payload.id ? { ...func, checked: !action.payload.checked } : func,
      );
    },
    selectAll(state) {
      state.functionality = state.functionality.map((func) => {
        return { ...func, checked: true };
      });
      state.mockDataBasicSettings = state.mockDataBasicSettings.map((func) => {
        return { ...func, checked: true };
      });
      state.mockDataInOrders = state.mockDataInOrders.map((func) => {
        return { ...func, checked: true };
      });
      state.mockDataTriggers = state.mockDataTriggers.map((func) => {
        return { ...func, checked: true };
      });
      state.mockDataTakeProfit = state.mockDataTakeProfit.map((func) => {
        return { ...func, checked: true };
      });
      state.mockDataStopLoss = state.mockDataStopLoss.map((func) => {
        return { ...func, checked: true };
      });
      state.mockDataFeatures = state.mockDataFeatures.map((func) => {
        return { ...func, checked: true };
      });
    },
    discard(state) {
      state.functionality = state.functionality.map((func) => {
        return { ...func, checked: false };
      });
      state.mockDataBasicSettings = state.mockDataBasicSettings.map((func) => {
        return { ...func, checked: false };
      });
      state.mockDataInOrders = state.mockDataInOrders.map((func) => {
        return { ...func, checked: false };
      });
      state.mockDataTriggers = state.mockDataTriggers.map((func) => {
        return { ...func, checked: false };
      });
      state.mockDataTakeProfit = state.mockDataTakeProfit.map((func) => {
        return { ...func, checked: false };
      });
      state.mockDataStopLoss = state.mockDataStopLoss.map((func) => {
        return { ...func, checked: false };
      });
      state.mockDataFeatures = state.mockDataFeatures.map((func) => {
        return { ...func, checked: false };
      });
    },
    selectUser(state, action: PayloadAction<string>) {
      if (state.usersChecked.includes(action.payload)) {
        state.usersChecked = state.usersChecked.filter((user) => user !== action.payload);
        return;
      }
      state.usersChecked = [...state.usersChecked, action.payload];
    },
    selectAllUsers(state, action: PayloadAction<string[]>) {
      state.usersChecked = action.payload;
    },
    discardUsers(state) {
      state.usersChecked = [];
    },
    changeStatusMockData(state, action: PayloadAction<IFunc>) {
      state.mockDataBasicSettings = state.mockDataBasicSettings.map((func) =>
        func.id === action.payload.id ? { ...func, checked: !action.payload.checked } : func,
      );
      state.mockDataInOrders = state.mockDataInOrders.map((func) =>
        func.id === action.payload.id ? { ...func, checked: !action.payload.checked } : func,
      );
      state.mockDataTriggers = state.mockDataTriggers.map((func) =>
        func.id === action.payload.id ? { ...func, checked: !action.payload.checked } : func,
      );
      state.mockDataTakeProfit = state.mockDataTakeProfit.map((func) =>
        func.id === action.payload.id ? { ...func, checked: !action.payload.checked } : func,
      );
      state.mockDataStopLoss = state.mockDataStopLoss.map((func) =>
        func.id === action.payload.id ? { ...func, checked: !action.payload.checked } : func,
      );
      state.mockDataFeatures = state.mockDataFeatures.map((func) =>
        func.id === action.payload.id ? { ...func, checked: !action.payload.checked } : func,
      );
    },
  },
});

export const {
  changeStatus,
  selectAll,
  discard,
  selectUser,
  selectAllUsers,
  discardUsers,
  changeStatusMockData,
} = functionalitySlice.actions;

export default functionalitySlice.reducer;
