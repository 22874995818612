import React from 'react';

import styles from './NotificationItem.module.css';
import { INotificationProps } from './Notification.types';

export default function NotificationItem({
  notification: { channels, date, message, opened, read, title },
}: INotificationProps) {
  const formatOption: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  };

  const MESSAGE_MAX_LENGTH = 36;
  const formattedDate = new Intl.DateTimeFormat('ru', formatOption).format(date);
  const formattedMessage =
    message.length > MESSAGE_MAX_LENGTH
      ? `${message.slice(0, MESSAGE_MAX_LENGTH - 1)} ...`
      : message;
  return (
    <div className={styles.notification}>
      <div className={styles.title}>{title}</div>
      <div className={styles.message}>{formattedMessage}</div>
      <div className={styles.channels}>{channels.join(', ')}</div>
      <div className={styles.date}>{formattedDate}</div>
      <div className={styles.opened}>{opened}</div>
      <div className={styles.read}>{read}</div>
    </div>
  );
}
