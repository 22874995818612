import React from 'react';
import styles from './OrderHistory.module.css';
import { useTranslation } from 'react-i18next';
export default function HeaderOrdersList() {
  const { t } = useTranslation('ordersModal');
  return (
    <div className={styles.listHeader}>
      <div className={styles.column}>
        {t('entryOrder')}
        <p>{t('time')}</p>
      </div>
      <div className={styles.column}>
        {t('entryOrder')}
        <p>{t('quantity')}</p>
      </div>
      <div className={styles.column}>
        {t('entryOrder')}
        <p>{t('price')}</p>
      </div>
      <div className={styles.column}>
        {t('trigger')}
        <p>{t('time')}</p>
      </div>
      <div className={styles.column}>
        {t('trigger')}
        <p>{t('quantity')}</p>
      </div>
      <div className={styles.column}>
        <span>{t('elapsedTime')}</span>
      </div>
      <div className={styles.column}>
        {t('ttp')}
        <p>{t('quantity')}</p>
      </div>
      <div className={styles.column}>
        {t('ttp')}
        <p>{t('price')}</p>
      </div>
      <div className={styles.column}>{t('profit')}</div>
    </div>
  );
}
