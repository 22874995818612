import { createPortal } from 'react-dom';
import CloseButton from '../../../CloseButton/CloseButton';
import styles from './RolesModalWindow.module.css';
import TitleCard from '../../../BotsPage/BotSettings/Blocks/TitleCard';
import { Checkbox } from '../../../CCLabsUI';
import { ECheckboxVariants } from '../../../CCLabsUI/Checkbox/Checkbox';

interface IRolesModal {
  onClose: () => void;
}

const root: unknown = document.getElementById('root');

export default function RolesModalWindow({ onClose }: IRolesModal) {
  return createPortal(
    <div className={styles.container}>
      <div className={styles.modalContainer}>
        <button className={styles.closeBtn}>
          <CloseButton onClick={onClose} />
        </button>
        <h2 className={styles.title}>Roles</h2>
        <div className={styles.cards}>
          <div className={styles.card}>
            <TitleCard title='Delete' />
            <p>The text about what rights are given to users here</p>
            <div className={styles.checkboxes}>
              <div>
                <Checkbox label='Sudo' variant={ECheckboxVariants.secondary} />
                <Checkbox label='Admin' variant={ECheckboxVariants.secondary} />
              </div>
              <div>
                <Checkbox label='Sudo' variant={ECheckboxVariants.secondary} />
                <Checkbox label='Admin' variant={ECheckboxVariants.secondary} />
              </div>
            </div>
          </div>
          <div className={styles.card}>
            <TitleCard title='Block' />
            <p>The text about what rights are given to users here</p>
            <div className={styles.checkboxes}>
              <div>
                <Checkbox label='Sudo' variant={ECheckboxVariants.secondary} />
                <Checkbox label='Admin' variant={ECheckboxVariants.secondary} />
              </div>
              <div>
                <Checkbox label='Sudo' variant={ECheckboxVariants.secondary} />
                <Checkbox label='Admin' variant={ECheckboxVariants.secondary} />
              </div>
            </div>
          </div>
        </div>
        <button className={styles.saveBtn}>Сохранить</button>
      </div>
    </div>,
    root as HTMLElement,
  );
}
