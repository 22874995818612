import React, { useRef, useState } from 'react';
import styles from './Header.module.css';
import AuthorizedHeader from './AuthorizedHeader/AuthorizedHeader';
import UnauthorizedHeader from './UnauthorizedHeader/UnauthorizedHeader';
import cn from 'classnames';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/hooks/redux';
import { changeIsMobileMenuOpen } from '../../redux/slices/layoutSlice';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';

const Header: React.FC = () => {
  const dispatch = useAppDispatch();
  const hasToken: boolean = !!sessionStorage.getItem('token');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const hiddenStyle: { [key in string]: boolean } = { [styles.hidden]: !isMenuOpen };
  const headerStyle = cn(styles.container);

  const location = useLocation();
  const currentPath = location.pathname;
  const isPrivatePath = currentPath === '/bots/mybots';
  const isAuthorizedHeader = hasToken || isPrivatePath;

  const { isMobileMenuOpen } = useAppSelector((state) => state.layout);
  const onMenuBtnClick = () => {
    setIsMenuOpen(!isMenuOpen);
    dispatch(changeIsMobileMenuOpen(!isMenuOpen));
  };

  const frame = useRef(null);

  useOnClickOutside(frame, () => {
    setIsMenuOpen(false);
    if (isMobileMenuOpen) {
      dispatch(changeIsMobileMenuOpen(false));
    }
  });

  return (
    <div className={styles.vertical_indent}>
      <div className={headerStyle}>
        <div ref={frame} className={cn(styles.wrapper, { [styles.header_open]: isMenuOpen })}>
          {isAuthorizedHeader ? (
            <AuthorizedHeader hidden={hiddenStyle} setIsMenuOpen={setIsMenuOpen} />
          ) : (
            <UnauthorizedHeader
              isMenuOpen={isMenuOpen}
              setIsMenuOpen={setIsMenuOpen}
              hidden={hiddenStyle}
            />
          )}

          <button
            onClick={onMenuBtnClick}
            className={cn(styles.menu, { [styles.menu_open]: isMenuOpen })}
          >
            menu
          </button>
        </div>
      </div>
    </div>
  );
};

export default Header;
