import { memo } from 'react';
import cn from 'classnames';
import NumbersIcon from '../../../../assets/General/NumbersIcon';
import PercentIcon from '../../../../assets/General/PercentIcon';
import Tabs, { ITab } from '../../../StatisticPage/Tabs/Tabs';

import styles from './InputSettings.module.css';
import IconWithTooltip from '../../../IconWithTooltip/IconWithTooltip';

export interface IInputSettingsProps {
  label?: string;
  value: number | null | undefined;
  isDisabled?: boolean;
  placeholder?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isNumbersVisible?: boolean;
  isPercentVisible?: boolean;
  isRow?: boolean;
  readonly?: boolean;
  usePercent?: boolean;
  setUsePercent?: () => void;
  tooltip?: string;
  name?: string;
  hasTooltip?: boolean;
  onBlur?: () => void;
  hasError?: boolean;
  errorMessage?: string;
}

const InputSettings = ({
  label,
  value,
  onChange,
  placeholder = '0',
  isDisabled = false,
  isNumbersVisible = true,
  isPercentVisible = true,
  readonly = false,
  usePercent,
  setUsePercent,
  tooltip,
  name,
  hasTooltip = true,
  onBlur,
  hasError,
  errorMessage,
}: IInputSettingsProps) => {
  const data: ITab[] = [];

  const onSetMode = () => {
    if (!isDisabled) {
      setUsePercent && setUsePercent();
    }
  };

  if (isPercentVisible) {
    data.push({
      value: '%',
      render: <PercentIcon width={11} height={12} color='#000' />,
    });
  }

  if (isNumbersVisible) {
    data.push({
      value: 'numbers',
      render: <NumbersIcon width={9} height={12} color='#000' />,
    });
  }

  const inputValue = value || '';

  const isSingleTab = !isNumbersVisible || !isPercentVisible;

  return (
    <div className={styles.inputContainer}>
      {label && (
        <label htmlFor={label} className={styles.label}>
          {label}{' '}
          {hasTooltip && <IconWithTooltip color='#afafaf' content={tooltip} tooltipWidth={164} />}
        </label>
      )}
      <div className={styles.inputWrapper}>
        <input
          className={cn(styles.inputField, {
            [styles.singleTab]: isSingleTab,
            [styles.error]: hasError,
          })}
          placeholder={`${placeholder}`}
          disabled={isDisabled}
          value={inputValue}
          onChange={onChange}
          readOnly={readonly}
          name={name}
          onBlur={onBlur}
        />
        <div className={styles.tabsBox}>
          <Tabs
            onSetActiveTab={onSetMode}
            activeTab={usePercent ? '%' : 'numbers'}
            readOnly={readonly}
            tabs={data}
            className={styles.tabs}
            tabClassName={styles.tab}
            activeTabClassName={styles.activeTab}
          />
        </div>
      </div>
      {hasError && <p>{errorMessage}</p>}
    </div>
  );
};

export default memo(InputSettings);
