import React, { useState } from 'react';
import styles from './JoinInput.module.css';
import { Input } from '../CCLabsUI';
import { ESizing } from '../CCLabsUI/Input/Input';
import onEmailValidate from '../../utils/onEmailValidate';
import { useAddEmailMutation } from '../../redux/services/waitListService';
import { useTranslation } from 'react-i18next';
import InfoBlock from '../SettingsPage/SecuritySettings/InfoBlock';

interface IResponseError {
  status: number | string;
  data: { detail: string };
}

enum EResponseCodes {
  alreadyExist = 'email.list.exists',
  notFound = 'email.not_found',
}

const JoinInput: React.FC = () => {
  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isEmailInWaitlist, setIsEmailInWaitlist] = useState(false);
  const [errorMessage, setErrorMessage] = useState('Error');
  const [addEmail] = useAddEmailMutation();
  const { t } = useTranslation('joinInput');

  const onInput = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(target.value);
  };

  const onFocus = () => {
    setIsEmailValid(true);
  };

  const onJoinClick = async () => {
    const isCurrentValueValid = onEmailValidate(email);
    setIsEmailInWaitlist(false);
    setIsEmailValid(isCurrentValueValid);

    if (isCurrentValueValid) {
      try {
        const { status: { code, message } } = await addEmail({ email }).unwrap();
        let isCurrentValueValid = true;

        const validateResponse = (responseCode: EResponseCodes) => {
          if (code === responseCode) {
            isCurrentValueValid = false;
            setIsEmailInWaitlist(true);
          }
        };

        validateResponse(EResponseCodes.alreadyExist);
        validateResponse(EResponseCodes.notFound);

        if (isCurrentValueValid) {
          alert(message); // todo: delete after this design is created
        }

      } catch (error) {
        const { data, status } = error as IResponseError;
        setIsEmailValid(false);
        setErrorMessage(data?.detail || status.toString());
      }
    } else {
      setErrorMessage('invalid email');
    }
  };

  return (
    <>
      <div className={styles.join}>
        <Input
          className={styles.input}
          label=''
          placeholder='Enter your email'
          sizing={ESizing.medium}
          isValid={isEmailValid}
          errorMessage={errorMessage}
          onInput={onInput}
          onFocus={onFocus}
        />
        <button onClick={onJoinClick}>{t('button')}</button>
      </div>
      {isEmailInWaitlist && (
        <div className={styles.infoBlockWrapper}>
          <InfoBlock text={t('waitlistNotification')} />
        </div>
      )}
    </>
  );
};

export default JoinInput;
