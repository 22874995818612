import { IOrderStatistics } from '../../../redux/types';

export const INITIAL_BOTS: IOrderStatistics[] = [
  {
    inOrderTime: '2023-02-21T13:09:10.000Z',
    inOrderQuantity: 2,
    inOrderPrice: '1,987766',
    trailingTpTime: '2023-02-21T13:09:10.000Z',
    trailingTpQuantity: 18,
    elapsedTime: 1696989000000,
    stopQuantity: 87,
    stopPrice: '1,987766 USDT',
    profit: '1,987766 USDT',
  },
  {
    inOrderTime: '2023-02-21T13:09:10.000Z',
    inOrderQuantity: 2,
    inOrderPrice: '2,987766 USDT',
    trailingTpTime: '2023-02-21T13:09:10.000Z',
    trailingTpQuantity: 18,
    elapsedTime: 1696989000000,
    stopQuantity: 87,
    stopPrice: '1,777766 USDT',
    profit: '1,987766 USDT',
  },
  {
    inOrderTime: '2023-02-21T13:09:10.000Z',
    inOrderQuantity: 2,
    inOrderPrice: '1,987766 USDT',
    trailingTpTime: '2023-02-21T13:09:10.000Z',
    trailingTpQuantity: 18,
    elapsedTime: 1696989000000,
    stopQuantity: 87,
    stopPrice: '1,987766 USDT',
    profit: '1,987766 USDT',
  },
  {
    inOrderTime: '2023-02-21T13:09:10.000Z',
    inOrderQuantity: 2,
    inOrderPrice: '1,987766 USDT',
    trailingTpTime: '2023-02-21T13:09:10.000Z',
    trailingTpQuantity: 18,
    elapsedTime: 1696989000000,
    stopQuantity: 87,
    stopPrice: '1,987766 USDT',
    profit: '1,987766 USDT',
  },
  {
    inOrderTime: '2023-02-21T13:09:10.000Z',
    inOrderQuantity: 2,
    inOrderPrice: '1,987766 USDT',
    trailingTpTime: '2023-02-21T13:09:10.000Z',
    trailingTpQuantity: 18,
    elapsedTime: 1696989000000,
    stopQuantity: 87,
    stopPrice: '1,987766 USDT',
    profit: '1,987766 USDT',
  },
  {
    inOrderTime: '2023-02-21T13:09:10.000Z',
    inOrderQuantity: 2,
    inOrderPrice: '1,987766 USDT',
    trailingTpTime: '2023-02-21T13:09:10.000Z',
    trailingTpQuantity: 18,
    elapsedTime: 1696989000000,
    stopQuantity: 87,
    stopPrice: '1,987766 USDT',
    profit: '1,987766 USDT',
  },
  {
    inOrderTime: '2023-02-21T13:09:10.000Z',
    inOrderQuantity: 2,
    inOrderPrice: '1,987766 USDT',
    trailingTpTime: '2023-02-21T13:09:10.000Z',
    trailingTpQuantity: 18,
    elapsedTime: 1696989000000,
    stopQuantity: 87,
    stopPrice: '1,987766 USDT',
    profit: '1,987766 USDT',
  },
  {
    inOrderTime: '2023-02-21T13:09:10.000Z',
    inOrderQuantity: 2,
    inOrderPrice: '1,987766 USDT',
    trailingTpTime: '2023-02-21T13:09:10.000Z',
    trailingTpQuantity: 18,
    elapsedTime: 1696989000000,
    stopQuantity: 87,
    stopPrice: '1,987766 USDT',
    profit: '1,987766 USDT',
  },
  {
    inOrderTime: '2023-02-21T13:09:10.000Z',
    inOrderQuantity: 2,
    inOrderPrice: '1,987766 USDT',
    trailingTpTime: '2023-02-21T13:09:10.000Z',
    trailingTpQuantity: 18,
    elapsedTime: 1696989000000,
    stopQuantity: 87,
    stopPrice: '1,987766 USDT',
    profit: '1,987766 USDT',
  },
  {
    inOrderTime: '2023-02-21T13:09:10.000Z',
    inOrderQuantity: 2,
    inOrderPrice: '1,987766 USDT',
    trailingTpTime: '2023-02-21T13:09:10.000Z',
    trailingTpQuantity: 18,
    elapsedTime: 1696989000000,
    stopQuantity: 87,
    stopPrice: '1,987766 USDT',
    profit: '1,987766 USDT',
  },

  {
    inOrderTime: '2023-02-21T13:09:10.000Z',
    inOrderQuantity: 2,
    inOrderPrice: '1,987766 USDT',
    trailingTpTime: '2023-02-21T13:09:10.000Z',
    trailingTpQuantity: 18,
    elapsedTime: 1696989000000,
    stopQuantity: 87,
    stopPrice: '1,987766 USDT',
    profit: '1,987766 USDT',
  },
  {
    inOrderTime: '2023-02-21T13:09:10.000Z',
    inOrderQuantity: 2,
    inOrderPrice: '2,987766 USDT',
    trailingTpTime: '2023-02-21T13:09:10.000Z',
    trailingTpQuantity: 18,
    elapsedTime: 1696989000000,
    stopQuantity: 87,
    stopPrice: '1,777766 USDT',
    profit: '1,987766 USDT',
  },
  {
    inOrderTime: '2023-02-21T13:09:10.000Z',
    inOrderQuantity: 2,
    inOrderPrice: '2,987766 USDT',
    trailingTpTime: '2023-02-21T13:09:10.000Z',
    trailingTpQuantity: 18,
    elapsedTime: 1696989000000,
    stopQuantity: 87,
    stopPrice: '1,087766 USDT',
    profit: '4,987766 USDT',
  },
  {
    inOrderTime: '2023-02-21T13:09:10.000Z',
    inOrderQuantity: 2,
    inOrderPrice: '1,987766 USDT',
    trailingTpTime: '2023-02-21T13:09:10.000Z',
    trailingTpQuantity: 18,
    elapsedTime: 1696989000000,
    stopQuantity: 87,
    stopPrice: '1,987766 USDT',
    profit: '1,987766 USDT',
  },
  {
    inOrderTime: '2023-02-21T13:09:10.000Z',
    inOrderQuantity: 2,
    inOrderPrice: '2,987766 USDT',
    trailingTpTime: '2023-02-21T13:09:10.000Z',
    trailingTpQuantity: 18,
    elapsedTime: 1696989000000,
    stopQuantity: 86,
    stopPrice: '1,087766 USDT',
    profit: '4,987766 USDT',
  },
  {
    inOrderTime: '2023-02-21T13:09:10.000Z',
    inOrderQuantity: 4,
    inOrderPrice: '1,987766 USDT',
    trailingTpTime: '2023-02-21T13:09:10.000Z',
    trailingTpQuantity: 16,
    elapsedTime: 1696989000000,
    stopQuantity: 90,
    stopPrice: '1,987766 USDT',
    profit: '1,987766 USDT',
  },
  {
    inOrderTime: '2023-02-21T13:09:10.000Z',
    inOrderQuantity: 2,
    inOrderPrice: '2,987766 USDT',
    trailingTpTime: '2023-02-21T13:09:10.000Z',
    trailingTpQuantity: 16,
    elapsedTime: 1696989000000,
    stopQuantity: 87,
    stopPrice: '1,087766 USDT',
    profit: '4,987766 USDT',
  },
  {
    inOrderTime: '2023-02-21T13:09:10.000Z',
    inOrderQuantity: 2,
    inOrderPrice: '1,987766 USDT',
    trailingTpTime: '2023-02-21T13:09:10.000Z',
    trailingTpQuantity: 14,
    elapsedTime: 1696989000000,
    stopQuantity: 97,
    stopPrice: '1,987766 USDT',
    profit: '1,987766 USDT',
  },
  {
    inOrderTime: '2023-02-21T13:09:10.000Z',
    inOrderQuantity: 2,
    inOrderPrice: '1,987766 USDT',
    trailingTpTime: '2023-02-21T13:09:10.000Z',
    trailingTpQuantity: 18,
    elapsedTime: 1696989000000,
    stopQuantity: 87,
    stopPrice: '1,987766 USDT',
    profit: '1,987766 USDT',
  },
  {
    inOrderTime: '2023-02-21T13:09:10.000Z',
    inOrderQuantity: 2,
    inOrderPrice: '1,237766 USDT',
    trailingTpTime: '2023-02-21T13:09:10.000Z',
    trailingTpQuantity: 18,
    elapsedTime: 1696989000000,
    stopQuantity: 92,
    stopPrice: '1,057766 USDT',
    profit: '5,987766 USDT',
  },
  {
    inOrderTime: '2023-02-21T13:09:10.000Z',
    inOrderQuantity: 2,
    inOrderPrice: '1,987766 USDT',
    trailingTpTime: '2023-02-21T13:09:10.000Z',
    trailingTpQuantity: 18,
    elapsedTime: 1696989000000,
    stopQuantity: 87,
    stopPrice: '1,987766 USDT',
    profit: '1,987766 USDT',
  },
  {
    inOrderTime: '2023-02-21T13:09:10.000Z',
    inOrderQuantity: 2,
    inOrderPrice: '2,987766 USDT',
    trailingTpTime: '2023-02-21T13:09:10.000Z',
    trailingTpQuantity: 18,
    elapsedTime: 1696989000000,
    stopQuantity: 87,
    stopPrice: '1,777766 USDT',
    profit: '1,987766 USDT',
  },
  {
    inOrderTime: '2023-02-21T13:09:10.000Z',
    inOrderQuantity: 2,
    inOrderPrice: '1,987766 USDT',
    trailingTpTime: '2023-02-21T13:09:10.000Z',
    trailingTpQuantity: 18,
    elapsedTime: 1696989000000,
    stopQuantity: 87,
    stopPrice: '1,987766 USDT',
    profit: '1,987766 USDT',
  },
  {
    inOrderTime: '2023-02-21T13:09:10.000Z',
    inOrderQuantity: 2,
    inOrderPrice: '1,987766 USDT',
    trailingTpTime: '2023-02-21T13:09:10.000Z',
    trailingTpQuantity: 18,
    elapsedTime: 1696989000000,
    stopQuantity: 87,
    stopPrice: '1,987766 USDT',
    profit: '1,987766 USDT',
  },
  {
    inOrderTime: '2023-02-21T13:09:10.000Z',
    inOrderQuantity: 2,
    inOrderPrice: '1,987766 USDT',
    trailingTpTime: '2023-02-21T13:09:10.000Z',
    trailingTpQuantity: 18,
    elapsedTime: 1696989000000,
    stopQuantity: 87,
    stopPrice: '1,987766 USDT',
    profit: '1,987766 USDT',
  },
  {
    inOrderTime: '2023-02-21T13:09:10.000Z',
    inOrderQuantity: 2,
    inOrderPrice: '1,987766 USDT',
    trailingTpTime: '2023-02-21T13:09:10.000Z',
    trailingTpQuantity: 18,
    elapsedTime: 1696989000000,
    stopQuantity: 87,
    stopPrice: '1,987766 USDT',
    profit: '1,987766 USDT',
  },
  {
    inOrderTime: '2023-02-21T13:09:10.000Z',
    inOrderQuantity: 2,
    inOrderPrice: '1,987766 USDT',
    trailingTpTime: '2023-02-21T13:09:10.000Z',
    trailingTpQuantity: 18,
    elapsedTime: 1696989000000,
    stopQuantity: 87,
    stopPrice: '1,987766 USDT',
    profit: '1,987766 USDT',
  },
  {
    inOrderTime: '2023-02-21T13:09:10.000Z',
    inOrderQuantity: 2,
    inOrderPrice: '1,987766 USDT',
    trailingTpTime: '2023-02-21T13:09:10.000Z',
    trailingTpQuantity: 18,
    elapsedTime: 1696989000000,
    stopQuantity: 87,
    stopPrice: '1,987766 USDT',
    profit: '1,987766 USDT',
  },
  {
    inOrderTime: '2023-02-21T13:09:10.000Z',
    inOrderQuantity: 2,
    inOrderPrice: '1,987766 USDT',
    trailingTpTime: '2023-02-21T13:09:10.000Z',
    trailingTpQuantity: 18,
    elapsedTime: 1696989000000,
    stopQuantity: 87,
    stopPrice: '1,987766 USDT',
    profit: '1,987766 USDT',
  },
  {
    inOrderTime: '2023-02-21T13:09:10.000Z',
    inOrderQuantity: 2,
    inOrderPrice: '1,987766 USDT',
    trailingTpTime: '2023-02-21T13:09:10.000Z',
    trailingTpQuantity: 18,
    elapsedTime: 1696989000000,
    stopQuantity: 87,
    stopPrice: '1,987766 USDT',
    profit: '1,987766 USDT',
  },

  {
    inOrderTime: '2023-02-21T13:09:10.000Z',
    inOrderQuantity: 2,
    inOrderPrice: '1,987766 USDT',
    trailingTpTime: '2023-02-21T13:09:10.000Z',
    trailingTpQuantity: 18,
    elapsedTime: 1696989000000,
    stopQuantity: 87,
    stopPrice: '1,987766 USDT',
    profit: '1,987766 USDT',
  },
  {
    inOrderTime: '2023-02-21T13:09:10.000Z',
    inOrderQuantity: 2,
    inOrderPrice: '2,987766 USDT',
    trailingTpTime: '2023-02-21T13:09:10.000Z',
    trailingTpQuantity: 18,
    elapsedTime: 1696989000000,
    stopQuantity: 87,
    stopPrice: '1,777766 USDT',
    profit: '1,987766 USDT',
  },
  {
    inOrderTime: '2023-02-21T13:09:10.000Z',
    inOrderQuantity: 2,
    inOrderPrice: '2,987766 USDT',
    trailingTpTime: '2023-02-21T13:09:10.000Z',
    trailingTpQuantity: 18,
    elapsedTime: 1696989000000,
    stopQuantity: 87,
    stopPrice: '1,087766 USDT',
    profit: '4,987766 USDT',
  },
  {
    inOrderTime: '2023-02-21T13:09:10.000Z',
    inOrderQuantity: 2,
    inOrderPrice: '1,987766 USDT',
    trailingTpTime: '2023-02-21T13:09:10.000Z',
    trailingTpQuantity: 18,
    elapsedTime: 1696989000000,
    stopQuantity: 87,
    stopPrice: '1,987766 USDT',
    profit: '1,987766 USDT',
  },
  {
    inOrderTime: '2023-02-21T13:09:10.000Z',
    inOrderQuantity: 2,
    inOrderPrice: '2,987766 USDT',
    trailingTpTime: '2023-02-21T13:09:10.000Z',
    trailingTpQuantity: 18,
    elapsedTime: 1696989000000,
    stopQuantity: 86,
    stopPrice: '1,087766 USDT',
    profit: '4,987766 USDT',
  },
  {
    inOrderTime: '2023-02-21T13:09:10.000Z',
    inOrderQuantity: 4,
    inOrderPrice: '1,987766 USDT',
    trailingTpTime: '2023-02-21T13:09:10.000Z',
    trailingTpQuantity: 16,
    elapsedTime: 1696989000000,
    stopQuantity: 90,
    stopPrice: '1,987766 USDT',
    profit: '1,987766 USDT',
  },
  {
    inOrderTime: '2023-02-21T13:09:10.000Z',
    inOrderQuantity: 2,
    inOrderPrice: '2,987766 USDT',
    trailingTpTime: '2023-02-21T13:09:10.000Z',
    trailingTpQuantity: 16,
    elapsedTime: 1696989000000,
    stopQuantity: 87,
    stopPrice: '1,087766 USDT',
    profit: '4,987766 USDT',
  },
  {
    inOrderTime: '2023-02-21T13:09:10.000Z',
    inOrderQuantity: 2,
    inOrderPrice: '1,987766 USDT',
    trailingTpTime: '2023-02-21T13:09:10.000Z',
    trailingTpQuantity: 14,
    elapsedTime: 1696989000000,
    stopQuantity: 97,
    stopPrice: '1,987766 USDT',
    profit: '1,987766 USDT',
  },
  {
    inOrderTime: '2023-02-21T13:09:10.000Z',
    inOrderQuantity: 2,
    inOrderPrice: '1,987766 USDT',
    trailingTpTime: '2023-02-21T13:09:10.000Z',
    trailingTpQuantity: 18,
    elapsedTime: 1696989000000,
    stopQuantity: 87,
    stopPrice: '1,987766 USDT',
    profit: '1,987766 USDT',
  },
  {
    inOrderTime: '2023-02-21T13:09:10.000Z',
    inOrderQuantity: 2,
    inOrderPrice: '1,237766 USDT',
    trailingTpTime: '2023-02-21T13:09:10.000Z',
    trailingTpQuantity: 18,
    elapsedTime: 1696989000000,
    stopQuantity: 92,
    stopPrice: '1,057766 USDT',
    profit: '5,987766 USDT',
  },
  {
    inOrderTime: '2023-02-21T13:09:10.000Z',
    inOrderQuantity: 2,
    inOrderPrice: '1,987766 USDT',
    trailingTpTime: '2023-02-21T13:09:10.000Z',
    trailingTpQuantity: 18,
    elapsedTime: 1696989000000,
    stopQuantity: 87,
    stopPrice: '1,987766 USDT',
    profit: '1,987766 USDT',
  },
  {
    inOrderTime: '2023-02-21T13:09:10.000Z',
    inOrderQuantity: 2,
    inOrderPrice: '1,987766 USDT',
    trailingTpTime: '2023-02-21T13:09:10.000Z',
    trailingTpQuantity: 18,
    elapsedTime: 1696989000000,
    stopQuantity: 87,
    stopPrice: '1,987766 USDT',
    profit: '1,987766 USDT',
  },
  {
    inOrderTime: '2023-02-21T13:09:10.000Z',
    inOrderQuantity: 2,
    inOrderPrice: '1,987766 USDT',
    trailingTpTime: '2023-02-21T13:09:10.000Z',
    trailingTpQuantity: 18,
    elapsedTime: 1696989000000,
    stopQuantity: 87,
    stopPrice: '1,987766 USDT',
    profit: '1,987766 USDT',
  },

  {
    inOrderTime: '2023-02-21T13:09:10.000Z',
    inOrderQuantity: 2,
    inOrderPrice: '1,987766 USDT',
    trailingTpTime: '2023-02-21T13:09:10.000Z',
    trailingTpQuantity: 18,
    elapsedTime: 1696989000000,
    stopQuantity: 87,
    stopPrice: '1,987766 USDT',
    profit: '1,987766 USDT',
  },
  {
    inOrderTime: '2023-02-21T13:09:10.000Z',
    inOrderQuantity: 2,
    inOrderPrice: '2,987766 USDT',
    trailingTpTime: '2023-02-21T13:09:10.000Z',
    trailingTpQuantity: 18,
    elapsedTime: 1696989000000,
    stopQuantity: 87,
    stopPrice: '1,777766 USDT',
    profit: '1,987766 USDT',
  },
  {
    inOrderTime: '2023-02-21T13:09:10.000Z',
    inOrderQuantity: 2,
    inOrderPrice: '2,987766 USDT',
    trailingTpTime: '2023-02-21T13:09:10.000Z',
    trailingTpQuantity: 18,
    elapsedTime: 1696989000000,
    stopQuantity: 87,
    stopPrice: '1,087766 USDT',
    profit: '4,987766 USDT',
  },
  {
    inOrderTime: '2023-02-21T13:09:10.000Z',
    inOrderQuantity: 2,
    inOrderPrice: '1,987766 USDT',
    trailingTpTime: '2023-02-21T13:09:10.000Z',
    trailingTpQuantity: 18,
    elapsedTime: 1696989000000,
    stopQuantity: 87,
    stopPrice: '1,987766 USDT',
    profit: '1,987766 USDT',
  },
  {
    inOrderTime: '2023-02-21T13:09:10.000Z',
    inOrderQuantity: 2,
    inOrderPrice: '2,987766 USDT',
    trailingTpTime: '2023-02-21T13:09:10.000Z',
    trailingTpQuantity: 18,
    elapsedTime: 1696989000000,
    stopQuantity: 86,
    stopPrice: '1,087766 USDT',
    profit: '4,987766 USDT',
  },
  {
    inOrderTime: '2023-02-21T13:09:10.000Z',
    inOrderQuantity: 4,
    inOrderPrice: '1,987766 USDT',
    trailingTpTime: '2023-02-21T13:09:10.000Z',
    trailingTpQuantity: 16,
    elapsedTime: 1696989000000,
    stopQuantity: 90,
    stopPrice: '1,987766 USDT',
    profit: '1,987766 USDT',
  },
  {
    inOrderTime: '2023-02-21T13:09:10.000Z',
    inOrderQuantity: 2,
    inOrderPrice: '2,987766 USDT',
    trailingTpTime: '2023-02-21T13:09:10.000Z',
    trailingTpQuantity: 16,
    elapsedTime: 1696989000000,
    stopQuantity: 87,
    stopPrice: '1,087766 USDT',
    profit: '4,987766 USDT',
  },
  {
    inOrderTime: '2023-02-21T13:09:10.000Z',
    inOrderQuantity: 2,
    inOrderPrice: '1,987766 USDT',
    trailingTpTime: '2023-02-21T13:09:10.000Z',
    trailingTpQuantity: 14,
    elapsedTime: 1696989000000,
    stopQuantity: 97,
    stopPrice: '1,987766 USDT',
    profit: '1,987766 USDT',
  },
  {
    inOrderTime: '2023-02-21T13:09:10.000Z',
    inOrderQuantity: 2,
    inOrderPrice: '1,987766 USDT',
    trailingTpTime: '2023-02-21T13:09:10.000Z',
    trailingTpQuantity: 18,
    elapsedTime: 1696989000000,
    stopQuantity: 87,
    stopPrice: '1,987766 USDT',
    profit: '1,987766 USDT',
  },
  {
    inOrderTime: '2023-02-21T13:09:10.000Z',
    inOrderQuantity: 2,
    inOrderPrice: '1,237766 USDT',
    trailingTpTime: '2023-02-21T13:09:10.000Z',
    trailingTpQuantity: 18,
    elapsedTime: 1696989000000,
    stopQuantity: 92,
    stopPrice: '1,057766 USDT',
    profit: '5,987766 USDT',
  },
  {
    inOrderTime: '2023-02-21T13:09:10.000Z',
    inOrderQuantity: 2,
    inOrderPrice: '1,987766 USDT',
    trailingTpTime: '2023-02-21T13:09:10.000Z',
    trailingTpQuantity: 18,
    elapsedTime: 1696989000000,
    stopQuantity: 87,
    stopPrice: '1,987766 USDT',
    profit: '1,987766 USDT',
  },
  {
    inOrderTime: '2023-02-21T13:09:10.000Z',
    inOrderQuantity: 2,
    inOrderPrice: '2,987766 USDT',
    trailingTpTime: '2023-02-21T13:09:10.000Z',
    trailingTpQuantity: 18,
    elapsedTime: 1696989000000,
    stopQuantity: 87,
    stopPrice: '1,777766 USDT',
    profit: '1,987766 USDT',
  },
  {
    inOrderTime: '2023-02-21T13:09:10.000Z',
    inOrderQuantity: 2,
    inOrderPrice: '1,987766 USDT',
    trailingTpTime: '2023-02-21T13:09:10.000Z',
    trailingTpQuantity: 18,
    elapsedTime: 1696989000000,
    stopQuantity: 87,
    stopPrice: '1,987766 USDT',
    profit: '1,987766 USDT',
  },
  {
    inOrderTime: '2023-02-21T13:09:10.000Z',
    inOrderQuantity: 2,
    inOrderPrice: '1,987766 USDT',
    trailingTpTime: '2023-02-21T13:09:10.000Z',
    trailingTpQuantity: 18,
    elapsedTime: 1696989000000,
    stopQuantity: 87,
    stopPrice: '1,987766 USDT',
    profit: '1,987766 USDT',
  },
  {
    inOrderTime: '2023-02-21T13:09:10.000Z',
    inOrderQuantity: 2,
    inOrderPrice: '1,987766 USDT',
    trailingTpTime: '2023-02-21T13:09:10.000Z',
    trailingTpQuantity: 18,
    elapsedTime: 1696989000000,
    stopQuantity: 87,
    stopPrice: '1,987766 USDT',
    profit: '1,987766 USDT',
  },
  {
    inOrderTime: '2023-02-21T13:09:10.000Z',
    inOrderQuantity: 2,
    inOrderPrice: '1,987766 USDT',
    trailingTpTime: '2023-02-21T13:09:10.000Z',
    trailingTpQuantity: 18,
    elapsedTime: 1696989000000,
    stopQuantity: 87,
    stopPrice: '1,987766 USDT',
    profit: '1,987766 USDT',
  },
  {
    inOrderTime: '2023-02-21T13:09:10.000Z',
    inOrderQuantity: 2,
    inOrderPrice: '1,987766 USDT',
    trailingTpTime: '2023-02-21T13:09:10.000Z',
    trailingTpQuantity: 18,
    elapsedTime: 1696989000000,
    stopQuantity: 87,
    stopPrice: '1,987766 USDT',
    profit: '1,987766 USDT',
  },
  {
    inOrderTime: '2023-02-21T13:09:10.000Z',
    inOrderQuantity: 2,
    inOrderPrice: '1,987766 USDT',
    trailingTpTime: '2023-02-21T13:09:10.000Z',
    trailingTpQuantity: 18,
    elapsedTime: 1696989000000,
    stopQuantity: 87,
    stopPrice: '1,987766 USDT',
    profit: '1,987766 USDT',
  },
  {
    inOrderTime: '2023-02-21T13:09:10.000Z',
    inOrderQuantity: 2,
    inOrderPrice: '1,987766 USDT',
    trailingTpTime: '2023-02-21T13:09:10.000Z',
    trailingTpQuantity: 18,
    elapsedTime: 1696989000000,
    stopQuantity: 87,
    stopPrice: '1,987766 USDT',
    profit: '1,987766 USDT',
  },
  {
    inOrderTime: '2023-02-21T13:09:10.000Z',
    inOrderQuantity: 2,
    inOrderPrice: '1,987766 USDT',
    trailingTpTime: '2023-02-21T13:09:10.000Z',
    trailingTpQuantity: 18,
    elapsedTime: 1696989000000,
    stopQuantity: 87,
    stopPrice: '1,987766 USDT',
    profit: '1,987766 USDT',
  },

  {
    inOrderTime: '2023-02-21T13:09:10.000Z',
    inOrderQuantity: 2,
    inOrderPrice: '1,987766 USDT',
    trailingTpTime: '2023-02-21T13:09:10.000Z',
    trailingTpQuantity: 18,
    elapsedTime: 1696989000000,
    stopQuantity: 87,
    stopPrice: '1,987766 USDT',
    profit: '1,987766 USDT',
  },
  {
    inOrderTime: '2023-02-21T13:09:10.000Z',
    inOrderQuantity: 2,
    inOrderPrice: '2,987766 USDT',
    trailingTpTime: '2023-02-21T13:09:10.000Z',
    trailingTpQuantity: 18,
    elapsedTime: 1696989000000,
    stopQuantity: 87,
    stopPrice: '1,777766 USDT',
    profit: '1,987766 USDT',
  },
  {
    inOrderTime: '2023-02-21T13:09:10.000Z',
    inOrderQuantity: 2,
    inOrderPrice: '2,987766 USDT',
    trailingTpTime: '2023-02-21T13:09:10.000Z',
    trailingTpQuantity: 18,
    elapsedTime: 1696989000000,
    stopQuantity: 87,
    stopPrice: '1,087766 USDT',
    profit: '4,987766 USDT',
  },
  {
    inOrderTime: '2023-02-21T13:09:10.000Z',
    inOrderQuantity: 2,
    inOrderPrice: '1,987766 USDT',
    trailingTpTime: '2023-02-21T13:09:10.000Z',
    trailingTpQuantity: 18,
    elapsedTime: 1696989000000,
    stopQuantity: 87,
    stopPrice: '1,987766 USDT',
    profit: '1,987766 USDT',
  },
  {
    inOrderTime: '2023-02-21T13:09:10.000Z',
    inOrderQuantity: 2,
    inOrderPrice: '2,987766 USDT',
    trailingTpTime: '2023-02-21T13:09:10.000Z',
    trailingTpQuantity: 18,
    elapsedTime: 1696989000000,
    stopQuantity: 86,
    stopPrice: '1,087766 USDT',
    profit: '4,987766 USDT',
  },
  {
    inOrderTime: '2023-02-21T13:09:10.000Z',
    inOrderQuantity: 4,
    inOrderPrice: '1,987766 USDT',
    trailingTpTime: '2023-02-21T13:09:10.000Z',
    trailingTpQuantity: 16,
    elapsedTime: 1696989000000,
    stopQuantity: 90,
    stopPrice: '1,987766 USDT',
    profit: '1,987766 USDT',
  },
  {
    inOrderTime: '2023-02-21T13:09:10.000Z',
    inOrderQuantity: 2,
    inOrderPrice: '2,987766 USDT',
    trailingTpTime: '2023-02-21T13:09:10.000Z',
    trailingTpQuantity: 16,
    elapsedTime: 1696989000000,
    stopQuantity: 87,
    stopPrice: '1,087766 USDT',
    profit: '4,987766 USDT',
  },
  {
    inOrderTime: '2023-02-21T13:09:10.000Z',
    inOrderQuantity: 2,
    inOrderPrice: '1,987766 USDT',
    trailingTpTime: '2023-02-21T13:09:10.000Z',
    trailingTpQuantity: 14,
    elapsedTime: 1696989000000,
    stopQuantity: 97,
    stopPrice: '1,987766 USDT',
    profit: '1,987766 USDT',
  },
  {
    inOrderTime: '2023-02-21T13:09:10.000Z',
    inOrderQuantity: 2,
    inOrderPrice: '1,987766 USDT',
    trailingTpTime: '2023-02-21T13:09:10.000Z',
    trailingTpQuantity: 18,
    elapsedTime: 1696989000000,
    stopQuantity: 87,
    stopPrice: '1,987766 USDT',
    profit: '1,987766 USDT',
  },
  {
    inOrderTime: '2023-02-21T13:09:10.000Z',
    inOrderQuantity: 2,
    inOrderPrice: '1,237766 USDT',
    trailingTpTime: '2023-02-21T13:09:10.000Z',
    trailingTpQuantity: 18,
    elapsedTime: 1696989000000,
    stopQuantity: 92,
    stopPrice: '1,057766 USDT',
    profit: '5,987766 USDT',
  },
];
