// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SwitchToggle_toggleButtonContainerOff__eUhR0 {
  cursor: pointer;
  width: 32px;
  height: 18px;
  border-radius: 25px;
  background-color: var(--text-colored);
  border: 1px solid var(--text-colored);
  display: flex;
}
.SwitchToggle_toggle__LpwSE {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: #ffffff;
  transition: transform 300ms;
}
.SwitchToggle_toggleOn__ldLNy {
  margin: 1.5px;
}
.SwitchToggle_toggleButtonContainerOn__9RMyh {
  cursor: pointer;
  width: 32px;
  height: 18px;
  border-radius: 25px;
  background-color: transparent;
  border: 1px solid var(--text-colored);
  display: flex;
}
.SwitchToggle_toggleOff__hz0dI {
  transform: translateX(17px);
  margin-top: 1.5px;
}
`, "",{"version":3,"sources":["webpack://./src/components/SwitchToggle/SwitchToggle.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,qCAAqC;EACrC,qCAAqC;EACrC,aAAa;AACf;AACA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,yBAAyB;EACzB,2BAA2B;AAC7B;AACA;EACE,aAAa;AACf;AACA;EACE,eAAe;EACf,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,6BAA6B;EAC7B,qCAAqC;EACrC,aAAa;AACf;AACA;EACE,2BAA2B;EAC3B,iBAAiB;AACnB","sourcesContent":[".toggleButtonContainerOff {\n  cursor: pointer;\n  width: 32px;\n  height: 18px;\n  border-radius: 25px;\n  background-color: var(--text-colored);\n  border: 1px solid var(--text-colored);\n  display: flex;\n}\n.toggle {\n  width: 14px;\n  height: 14px;\n  border-radius: 50%;\n  background-color: #ffffff;\n  transition: transform 300ms;\n}\n.toggleOn {\n  margin: 1.5px;\n}\n.toggleButtonContainerOn {\n  cursor: pointer;\n  width: 32px;\n  height: 18px;\n  border-radius: 25px;\n  background-color: transparent;\n  border: 1px solid var(--text-colored);\n  display: flex;\n}\n.toggleOff {\n  transform: translateX(17px);\n  margin-top: 1.5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toggleButtonContainerOff": `SwitchToggle_toggleButtonContainerOff__eUhR0`,
	"toggle": `SwitchToggle_toggle__LpwSE`,
	"toggleOn": `SwitchToggle_toggleOn__ldLNy`,
	"toggleButtonContainerOn": `SwitchToggle_toggleButtonContainerOn__9RMyh`,
	"toggleOff": `SwitchToggle_toggleOff__hz0dI`
};
export default ___CSS_LOADER_EXPORT___;
