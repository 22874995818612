function getHiddenEmail(email: string) {
  const emailRegEx: RegExp = /^\S+@[a-z.]+\.[a-z]{2,}$/;
  if (!emailRegEx.test(email)) {
    return '';
  }
  const [visiblePart, domain] = email.split('@');
  const VISIBLY_CHARS = 2;

  const hiddenPart =
    visiblePart.slice(0, VISIBLY_CHARS) + '*'.repeat(visiblePart.length - VISIBLY_CHARS);
  return `${hiddenPart}@${domain}`;
}

export default getHiddenEmail;
