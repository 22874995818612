export interface INotification {
  id: number;
  title: string;
  message: string;
  channels: EChanel[];
  date: Date;
  opened: number;
  read: number;
}

export enum EChanel {
  telegram = 'Telegram',
  email = 'Email',
  app = 'App-notifications',
}
