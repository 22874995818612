import { ReactElement, memo } from 'react';
import cn from 'classnames';
import BotIcon from '../../../BotsPage/BotIcon/BotIcon';
import styles from './List.module.css';
import StrategyBot from '../../../BotsPage/BotsCard/StrategyBot';
import RuntimeBot from '../../../BotsPage/BotsCard/RuntimeBot';
import RiskBot, { ESizing } from '../../../BotsPage/BotsCard/RiskBot';
import PnlValue from '../../../BotsPage/BotsCard/PnlValue';
import PersonIcon from '../../../../assets/person.svg';
import { Exchanges } from './ExchangesIcons';
import MarketBot from '../../../BotsPage/BotsCard/MarketBot';
import { IBoardListItem } from './ListLeaderboard.types';
import { useTranslation } from 'react-i18next';
import { formatPlace } from '../../../../utils/formatBotPlaceNumber';

interface ILeaderboardList {
  bot: IBoardListItem;
}

function BoardListItem({
  bot: {
    strategies,
    botType,
    count,
    exchange,
    name,
    pair,
    place,
    pnl,
    risk,
    runtime,
    id,
    isTrendFlipper,
    market,
  },
}: ILeaderboardList) {
  const exchangeIcon: ReactElement = Exchanges[exchange];
  const isEven: boolean = id % 2 === 0;
  const { t } = useTranslation('launchBotPage');
  return (
    <div className={cn([styles.listItem], { [styles.listItemEven]: isEven })}>
      <span className={styles.placeValue}>{formatPlace(place)}</span>
      <BotIcon icon={botType} width={28} height={28} />
      <div className={styles.strategy}>
        <div className={styles.name}>{name}</div>
        <StrategyBot strategy={strategies} trendFlipper={isTrendFlipper} />
      </div>
      <div className={styles.exchange}>
        <div className={styles.exchangeIcon}>{exchangeIcon}</div>
        <MarketBot market={market} />
      </div>
      <div className={styles.pairRisk}>
        {pair}
        <RiskBot enableIcon={false} risk={risk} />
      </div>
      <div className={styles.aprPnl}>
        <div className={styles.aprPnlValue}>
          PNL <PnlValue value={pnl} size={ESizing.small} />
        </div>
        <RuntimeBot runtime={runtime} />
      </div>
      <div className={styles.copy}>
        <button>{t('copyBtn')}</button>
        <div className={styles.count}>
          <img src={PersonIcon} /> {count}
        </div>
      </div>
    </div>
  );
}

export default memo(BoardListItem);
