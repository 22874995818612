import React, { useEffect, useState, useRef } from 'react';
import styles from './VerificationCodeModal.module.css';

import CloseButton from '../../CloseButton/CloseButton';
import ValidateIcon from '../../../assets/invalidinput-icon.svg';
import { useTranslation } from 'react-i18next';
import { useCurrentUserEmailQuery } from '../../../redux/services/settingProfileService';

interface IVerificationCodeModal {
  isOpen?: boolean;
  isCorrectCode?: boolean;
  inputValue?: string;
  actionClose: () => void;
  action: () => void;
  actionSubmit: () => void;
}

enum EModalSteps {
  stepEmail = 'stepEmail',
  stepCode = 'stepCode',
}

const VerificationCodeModal: React.FC<IVerificationCodeModal> = (props) => {
  const token = sessionStorage.getItem('token');
  const sessionId = sessionStorage.getItem('session_id');

  const { t } = useTranslation('profilePage');
  const [modalStep, setModalStep] = useState(EModalSteps.stepEmail);
  const isEmailStep = modalStep === EModalSteps.stepEmail;
  const { data } = useCurrentUserEmailQuery({ sessionId, token });
  const userEmail = data?.email ?? '';

  const stepContent = isEmailStep
    ? t('securityCheck.sendConfirmCode.description', { email: userEmail })
    : t('securityCheck.confirmCode');

  const [inputValue, setInputValue] = useState([
    { id: 1, name: '1', value: '' },
    { id: 2, name: '2', value: '' },
    { id: 3, name: '3', value: '' },
    { id: 4, name: '4', value: '' },
    { id: 5, name: '5', value: '' },
    { id: 6, name: '6', value: '' },
  ]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const refInput = useRef<any>(null);
  const [isError, setIsError] = useState(false);
  const [code, setCode] = useState('');
  const [isSendCode, setIsSendCode] = useState(true);
  const [isResendCode, setIsResendCode] = useState(false);

  const timeout: number = 2000;
  useEffect(() => {
    const codeFromInput = inputValue.map((item) => item.value).join('');
    if (codeFromInput) {
      setCode(codeFromInput);
    }
  }, [inputValue]);
  useEffect(() => {
    if (isError) {
      setTimeout(() => setIsError(false), timeout);
    }
  }, [isError]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onSendCode = () => {
    setIsSendCode(!isSendCode);
    setIsResendCode(true);
  };
  const onResendCode = () => {
    setIsResendCode(true);
  };
  const clearInputs = () => {
    setInputValue(inputValue.map((v) => ({ ...v, value: '' })));
  };
  const onClose = () => {
    clearInputs();
    props.actionClose();
  };
  const onSubmit = () => {
    if (isEmailStep) {
      setModalStep(EModalSteps.stepCode);
      return;
    }

    if (code !== '123456') {
      setIsError(true);
    } else {
      props.actionSubmit();
      props.actionClose();
      clearInputs();
    }
  };
  return (
    <div className={styles.modalContainer}>
      <div className={styles.modal}>
        <div className={styles.modalMain}>
          <div className={styles.closeButton}>
            <CloseButton onClick={onClose} />
          </div>
          <div className={styles.modalHeader}>
            <div className={styles.headerText}>{t('securityCheck.title')}</div>
          </div>
          <div className={styles.title}>{stepContent}</div>

          {!isEmailStep && (
            <div className={styles.codeContainer}>
              {inputValue.map((item, i) => {
                return (
                  <input
                    key={item.id}
                    className={styles.codeBorder}
                    name={`${item.id}`}
                    type={'text'}
                    value={item.value}
                    ref={refInput}
                    onFocus={(event) => event.target.select()}
                    onChange={(event) => {
                      if (event.target.value.length > 1) {
                        return;
                      }
                      setInputValue((prev) => {
                        const next = [...prev];
                        next[i].value = event.target.value;
                        return next;
                      });
                      const nextInput = refInput.current.parentNode.childNodes[i + 1];
                      if (nextInput && !inputValue[i].value) {
                        nextInput.focus();
                      }
                    }}
                  />
                );
              })}
            </div>
          )}

          <div className={styles.validateInputBlock}>
            {isError && (
              <div className={styles.validateInput}>
                <img src={ValidateIcon} />
                <div className={styles.validateInputText}>Incorrect code</div>
              </div>
            )}
            {isResendCode && <button onClick={onResendCode}>Resend verification code</button>}
          </div>

          <button onClick={onSubmit} className={styles.submitButton}>
            {t('securityCheck.confirmBtn')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default VerificationCodeModal;
