import React from 'react';
import IconWithTooltip from '../../IconWithTooltip/IconWithTooltip';
import cn from 'classnames';

import styles from './Cards.module.css';

interface ILineCardProps {
  title: string;
  value: string | React.ReactNode;
  isColumn?: boolean;
  positive?: boolean;
  shortTitle?: boolean;
  isValueSwitcher?: boolean;
}

export default function LineOfCard({
  title,
  value,
  isColumn = false,
  positive = false,
  shortTitle = false,
  isValueSwitcher = false,
}: ILineCardProps) {
  return (
    <div className={cn([styles.lineCard], { [styles.lineColumn]: isColumn })}>
      <div className={cn([styles.titleRow], { [styles.shortTitle]: shortTitle })}>
        {title} <IconWithTooltip color='#afafaf' />
      </div>
      <div
        className={cn(
          [styles.lineValue],
          { [styles.positiveValue]: positive },
          { [styles.lineValueLong]: shortTitle },
          { [styles.valueSwitcher]: isValueSwitcher },
        )}
      >
        {value}
      </div>
    </div>
  );
}
