import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import styles from './SecuritySettings.module.css';
import PasswordUpdate from '../PasswordUpdate/PasswordUpdate';

export default function PasswordBlock() {
  const { t } = useTranslation('profilePage');
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);

  const updateModalVisibilityHandler = () => setIsUpdateModalOpen((state) => !state);
  const handleCloseModal = () => setIsUpdateModalOpen(false);
  return (
    <>
      <div className={styles.passwordBlock}>
        <h3 className={styles.mainTitle}>{t('password')}</h3>
        <button className={styles.updatePasswordBtn} onClick={updateModalVisibilityHandler}>
          {t('updatePasswordBtn')}
        </button>
      </div>
      {isUpdateModalOpen && <PasswordUpdate onClose={handleCloseModal} />}
    </>
  );
}
