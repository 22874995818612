import React, { Dispatch, SetStateAction, useRef, useState } from 'react';
import styles from './PopupUsersList.module.css';
import { useOnClickOutside } from '../../../../hooks/useOnClickOutside';
import HeaderList from '../../UsersListPage/UsersList/HeaderList';
import UserListItem from '../../UsersListPage/UserListItem/UserListItem';
import { IUser } from '../../UsersListPage/UsersList/UserList.types';
import SearchBar from '../../UsersListPage/SearchBar/SearchBar';
import { Checkbox } from '../../../CCLabsUI';
import { ECheckboxVariants } from '../../../CCLabsUI/Checkbox/Checkbox';

interface IPopupUsersListProps {
  selectedUsers: IUser[];
  setUserList: Dispatch<SetStateAction<IUser[]>>;
  setSelectedUsers: Dispatch<SetStateAction<IUser[]>>;
  setIsPopupOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

// todo: refactor duplicated logic with StepChooseUsers.tsx after UX feedback
const PopupUsersList: React.FC<IPopupUsersListProps> = ({
  setIsPopupOpen,
  setUserList,
  selectedUsers,
  setSelectedUsers,
}) => {
  const [searchValue, setSearchValue] = useState('');
  const onPopupClose = () => setIsPopupOpen(false);
  const frame = useRef(null);

  useOnClickOutside(frame, () => {
    setIsPopupOpen(false);
  });

  const isUserSelected = (user: IUser) => selectedUsers.some(({ uid }) => uid === user.uid);

  const onUserClick = (user: IUser) => () => {
    const newSelectedUsers = isUserSelected(user)
      ? selectedUsers.filter(({ uid }) => uid !== user.uid)
      : [...selectedUsers, user];

    setSelectedUsers(newSelectedUsers);
  };

  const onSearch = () => {
    console.log('not implemented yet');
  };

  const onSettingsOpen = () => {
    console.log('not implemented yet');
  };


  const onSetSearchValue = (e: React.ChangeEvent<HTMLInputElement>) =>
    setSearchValue(e.target.value.toLowerCase());

  const onUsersDiscard = () => {
    setSelectedUsers([]);
  };

  return (
    <div className={styles.container}>
      <div className={styles.frame} ref={frame}>

        <div className={styles.controls}>
          <SearchBar
            searchValue={searchValue}
            onSearch={onSearch}
            onSetSearchValue={onSetSearchValue}
            onSettingsOpen={onSettingsOpen}
            isButtonVisible={false}
          />
          <button>Choose all</button>
          <button onClick={onUsersDiscard}>Delete all</button>
        </div>

        <div className={styles.wrapper_list}>
          <div className={styles.list}>
            <HeaderList setUsers={setUserList} />
            {selectedUsers.map((user) => (
              <div className={styles.wrapper_user} key={user.uid} onClick={onUserClick(user)}>
                <Checkbox
                  label=''
                  variant={ECheckboxVariants.secondary}
                  className={styles.user_checkbox}
                  checked
                />
                <UserListItem user={user} />
              </div>
            ))}
          </div>
          <button className={styles.list_save} onClick={onPopupClose}>Save</button>
        </div>

        <button className={styles.frame_close} onClick={onPopupClose}>Close</button>
      </div>
    </div>
  );
};

export default PopupUsersList;
