// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LaunchBot_container__7AW8u {
  display: flex;
  padding-bottom: 200px;
  gap: 24px;
  justify-content: center;
  position: relative;
}

.LaunchBot_title__j0fXa {
  position: absolute;
  left: 0;
  top: -80px;
  margin: 0;
  color: white;
  font-family: PTSans;
  font-size: 24px;
  font-weight: 400;
  text-transform: capitalize;
}

.LaunchBot_algorithms__zf2PH {
  display: flex;
  flex-wrap: wrap;
  width: 605px;
  height: max-content;
  gap: 20px;
  letter-spacing: 0;
}

@media screen and (max-width: 1370px) {
  .LaunchBot_algorithms__zf2PH {
    width: 300px;
    gap: 15px;
  }
}

@media screen and (max-width: 1100px) {
  .LaunchBot_container__7AW8u {
    flex-direction: column;
    align-items: center;
  }
  .LaunchBot_algorithms__zf2PH {
    width: 615px;
  }
}

@media (max-width: 768px) {
  .LaunchBot_container__7AW8u {
    align-items: flex-start;
  }
  .LaunchBot_algorithms__zf2PH {
    width: calc(100vw - 32px);
    justify-content: center;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/LaunchBotPage/LaunchBot.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,qBAAqB;EACrB,SAAS;EACT,uBAAuB;EACvB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,OAAO;EACP,UAAU;EACV,SAAS;EACT,YAAY;EACZ,mBAAmB;EACnB,eAAe;EACf,gBAAgB;EAChB,0BAA0B;AAC5B;;AAEA;EACE,aAAa;EACb,eAAe;EACf,YAAY;EACZ,mBAAmB;EACnB,SAAS;EACT,iBAAiB;AACnB;;AAEA;EACE;IACE,YAAY;IACZ,SAAS;EACX;AACF;;AAEA;EACE;IACE,sBAAsB;IACtB,mBAAmB;EACrB;EACA;IACE,YAAY;EACd;AACF;;AAEA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;IACzB,uBAAuB;EACzB;AACF","sourcesContent":[".container {\n  display: flex;\n  padding-bottom: 200px;\n  gap: 24px;\n  justify-content: center;\n  position: relative;\n}\n\n.title {\n  position: absolute;\n  left: 0;\n  top: -80px;\n  margin: 0;\n  color: white;\n  font-family: PTSans;\n  font-size: 24px;\n  font-weight: 400;\n  text-transform: capitalize;\n}\n\n.algorithms {\n  display: flex;\n  flex-wrap: wrap;\n  width: 605px;\n  height: max-content;\n  gap: 20px;\n  letter-spacing: 0;\n}\n\n@media screen and (max-width: 1370px) {\n  .algorithms {\n    width: 300px;\n    gap: 15px;\n  }\n}\n\n@media screen and (max-width: 1100px) {\n  .container {\n    flex-direction: column;\n    align-items: center;\n  }\n  .algorithms {\n    width: 615px;\n  }\n}\n\n@media (max-width: 768px) {\n  .container {\n    align-items: flex-start;\n  }\n  .algorithms {\n    width: calc(100vw - 32px);\n    justify-content: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `LaunchBot_container__7AW8u`,
	"title": `LaunchBot_title__j0fXa`,
	"algorithms": `LaunchBot_algorithms__zf2PH`
};
export default ___CSS_LOADER_EXPORT___;
