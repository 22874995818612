import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import PasswordValidation from '../SettingsPage/PasswordUpdate/PasswordValidation';
import styles from './PasswordValidation.module.css';

interface IPasswordValidateProps {
  validationFields: { [key: string]: boolean };
}
function PasswordValidationBlock({ validationFields }: IPasswordValidateProps) {
  const { t } = useTranslation('passwordUpdate');
  return (
    <div className={styles.passwordValidationContainer}>
      <PasswordValidation text={t('lowercase')} isMatch={validationFields.lowercasePass} />
      <PasswordValidation text={t('specialChar')} isMatch={validationFields.symPass} />
      <PasswordValidation text={t('uppercase')} isMatch={validationFields.uppercasePass} />
      <PasswordValidation text={t('16char')} isMatch={validationFields.lengthPass} />
      <PasswordValidation text={t('number')} isMatch={validationFields.digitPass} />
    </div>
  );
}

export default memo(PasswordValidationBlock);
