import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import styles from './StatisticNavigation.module.css';

export default function StatisticNavigation() {
  const location = useLocation();
  const [activePath, setActivePath] = useState('');

  useEffect(() => {
    setActivePath(location.pathname);
  }, [location.pathname]);

  const getActiveClass = (path: string) => {
    return activePath.includes(path) ? styles.active : '';
  };

  return (
    <div className={styles.container}>
      <Link to='/admin/stats/summary' className={getActiveClass('summary')}>
        Summary data
      </Link>
      <Link to='/admin/stats/users' className={getActiveClass('users')}>
        Users data
      </Link>
      <Link to='/admin/stats/bot' className={getActiveClass('bot')}>
        Bots data
      </Link>
      <Link to='/admin/stats/trading' className={getActiveClass('trading')}>
        Trading volume
      </Link>
      <Link to='/admin/stats/equity' className={getActiveClass('equity')}>
        Equity data
      </Link>
      <Link to='/admin/stats/pnl' className={getActiveClass('pnl')}>
        PNL data
      </Link>
      <Link to='/admin/stats/average' className={getActiveClass('average')}>
        Average values
      </Link>
      <Link to='/admin/stats/setting' className={getActiveClass('setting')}>
        Settings usage
      </Link>
    </div>
  );
}
