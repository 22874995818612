import InfoIcon from '../../assets/General/InfoIcon';
import { Tooltip } from 'react-tooltip';

import styles from './tooltip.module.css';
import { ReactNode, memo } from 'react';

interface CustomTooltipProps {
  id?: string;
  content?: string;
  color?: string;
  width?: number;
  height?: number;
  tooltipWidth?: number | string;
  element?: string | ReactNode;
}

function IconWithTooltip({
  id,
  content,
  color = '#afafaf',
  width,
  height,
  tooltipWidth = 'maxContent',
  element,
}: CustomTooltipProps) {
  const MAX_RADIX = 36;
  const UNUSED_PREFIX_LENGTH = 3;
  const idUniq: string =
    id?.replaceAll(' ', '-') +
    (Math.random() + 1).toString(MAX_RADIX).substring(UNUSED_PREFIX_LENGTH);
  return (
    <>
      <div id={idUniq} className={styles.element}>
        {element || <InfoIcon color={color} height={height} width={width} />}
      </div>
      <Tooltip
        anchorSelect={`#${idUniq}`}
        content={content}
        className={styles.tooltip}
        style={{ width: tooltipWidth }}
      />
    </>
  );
}

export default memo(IconWithTooltip);
