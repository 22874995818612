import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BASE_URL } from '../../constants';

export const exchangesApi = createApi({
  reducerPath: 'exchangesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
  }),
  endpoints: (build) => ({
    getExchangesLabels: build.mutation({
      query: (payload) => ({
        url: `/v1/sessions/${payload.sessionId}/exchanges`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${payload.token}`,
        },
      }),
    }),
    getExchangesMarkets: build.mutation({
      query: (payload) => ({
        url: `/v1/sessions/${payload.sessionId}/exchanges/${payload.exchangeLabel}/markets`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${payload.token}`,
        },
      }),
    }),
    getExchangesPairs: build.mutation({
      query: (payload) => ({
        url: `/v1/sessions/${payload.sessionId}/exchanges/` +
          `${payload.exchangeLabel}/markets/${payload.exchangeMarket}/instruments`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${payload.token}`,
        },
      }),
    }),
  }),
});

export const {
  useGetExchangesLabelsMutation,
  useGetExchangesMarketsMutation,
  useGetExchangesPairsMutation,
} = exchangesApi;
