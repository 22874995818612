import cn from 'classnames';
import styles from './UsersTable.module.css';

import { useAppSelector } from '../../../../../redux/hooks/redux';
import { selectAdminStats } from '../../../../../redux/selectors/rootSelector';
import { prepareUsersData } from '../utils';

export default function UsersTable() {
  const formatOption: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  };

  const { usersData } = useAppSelector(selectAdminStats).adminPanelInfo;
  const preparedUsersData = prepareUsersData(usersData);

  return (
    <div className={styles.table}>
      <div className={cn(styles.group, styles.firstGroup)} />
      <div className={cn(styles.group, styles.secondGroup)} />
      <div className={styles.header}>
        <div className={styles.cell}>Date</div>
        <div className={styles.usersCell}>Users registered</div>
        <div className={styles.columnGroup}>
          <div>
            <div className={styles.columnsTitle}>All bots created</div>
          </div>
          <div className={styles.modeBox}>
            <div className={styles.cell}>All</div>
            <div className={styles.cell}>Pro</div>
            <div className={styles.cell}>Auto</div>
          </div>
        </div>
        <div className={styles.columnGroup}>
          <div>
            <div className={styles.columnsTitle}>All bots launched</div>
          </div>
          <div className={styles.modeBox}>
            <div className={styles.cell}>All</div>
            <div className={styles.cell}>Pro</div>
            <div className={styles.cell}>Auto</div>
          </div>
        </div>
        <div className={styles.columnGroup}>
          <div>
            <div className={styles.columnsTitle}>All bots copied</div>
          </div>
          <div className={styles.modeBox}>
            <div className={styles.cell}>Launched</div>
            <div className={styles.cell}>Created</div>
          </div>
        </div>
      </div>
      <div className={styles.tableData}>
        {preparedUsersData.map(({ id, date, registered, botsCreated, botsLaunched, botsCopied }) => (
          <div className={styles.row} key={id}>
            <div className={styles.cell}>
              {new Intl.DateTimeFormat('ru', formatOption).format(date)}
            </div>
            <div className={styles.cell}>{registered}</div>
            <div className={styles.modeBox}>
              <div className={styles.cell}>{botsCreated.all}</div>
              <div className={styles.cell}>{botsCreated.pro}</div>
              <div className={styles.cell}>{botsCreated.auto}</div>
            </div>
            <div className={styles.modeBox}>
              <div className={styles.cell}>{botsLaunched.all}</div>
              <div className={styles.cell}>{botsLaunched.pro}</div>
              <div className={styles.cell}>{botsLaunched.auto}</div>
            </div>
            <div className={styles.modeBox}>
              <div className={styles.cell}>{botsCopied.launched}</div>
              <div className={styles.cell}>{botsCopied.created}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
