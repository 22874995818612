// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  background-attachment: fixed;
  margin: 0;
  padding: 0;
  box-sizing: content-box;
  height: 100vh;
  font-family: 'Vipnagorgialla';
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.02em;
}

ul {
  padding: 0;
  margin: 0;
}

@media screen and (max-width: 440px) {
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;EAC5B,SAAS;EACT,UAAU;EACV,uBAAuB;EACvB,aAAa;EACb,6BAA6B;EAC7B,kBAAkB;EAClB,gBAAgB;EAChB,sBAAsB;AACxB;;AAEA;EACE,UAAU;EACV,SAAS;AACX;;AAEA;AACA","sourcesContent":["body {\n  background-attachment: fixed;\n  margin: 0;\n  padding: 0;\n  box-sizing: content-box;\n  height: 100vh;\n  font-family: 'Vipnagorgialla';\n  font-style: normal;\n  font-weight: 600;\n  letter-spacing: 0.02em;\n}\n\nul {\n  padding: 0;\n  margin: 0;\n}\n\n@media screen and (max-width: 440px) {\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
