// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/public/images/UI/ArrowDownMenu.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LanguageMenu_container__dVWkM {
  margin-left: 2.3%;
  margin-right: 2%;
  position: relative;
  display: flex;
}

.LanguageMenu_control__PZB0c {
  position: relative;
  font-size: 14px;
  font-weight: 700;
  text-decoration: underline;
}

.LanguageMenu_control__PZB0c::before {
  position: absolute;
  right: -13px;
  top: 17px;
  content: '';
  width: 16px;
  height: 10px;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-position: center;
}

.LanguageMenu_menu__2c6hE {
  position: absolute;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 45px;
  text-align: center;
  top: 41px;
  color: rgb(175, 175, 175);
  background-color: rgb(27, 26, 39);
  border: 1px solid var(--border-white-02);
  border-radius: 5px;
  z-index: 3;
}

.LanguageMenu_menu__2c6hE button {
  margin-left: 3px;
}

.LanguageMenu_menu__2c6hE button:hover {
  color: rgb(255, 255, 255);
}
`, "",{"version":3,"sources":["webpack://./src/components/Header/LanguageMenu/LanguageMenu.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,0BAA0B;AAC5B;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,SAAS;EACT,WAAW;EACX,WAAW;EACX,YAAY;EACZ,yDAA4D;EAC5D,4BAA4B;EAC5B,2BAA2B;AAC7B;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,WAAW;EACX,kBAAkB;EAClB,SAAS;EACT,yBAAyB;EACzB,iCAAiC;EACjC,wCAAwC;EACxC,kBAAkB;EAClB,UAAU;AACZ;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".container {\n  margin-left: 2.3%;\n  margin-right: 2%;\n  position: relative;\n  display: flex;\n}\n\n.control {\n  position: relative;\n  font-size: 14px;\n  font-weight: 700;\n  text-decoration: underline;\n}\n\n.control::before {\n  position: absolute;\n  right: -13px;\n  top: 17px;\n  content: '';\n  width: 16px;\n  height: 10px;\n  background-image: url('/public/images/UI/ArrowDownMenu.png');\n  background-repeat: no-repeat;\n  background-position: center;\n}\n\n.menu {\n  position: absolute;\n  padding-top: 5px;\n  padding-bottom: 5px;\n  width: 45px;\n  text-align: center;\n  top: 41px;\n  color: rgb(175, 175, 175);\n  background-color: rgb(27, 26, 39);\n  border: 1px solid var(--border-white-02);\n  border-radius: 5px;\n  z-index: 3;\n}\n\n.menu button {\n  margin-left: 3px;\n}\n\n.menu button:hover {\n  color: rgb(255, 255, 255);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `LanguageMenu_container__dVWkM`,
	"control": `LanguageMenu_control__PZB0c`,
	"menu": `LanguageMenu_menu__2c6hE`
};
export default ___CSS_LOADER_EXPORT___;
