/* eslint-disable @typescript-eslint/indent */
/* eslint-disable @typescript-eslint/typedef */
import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { IDisableGoogleAuth, IGenerateSecret, IGoodleAuth } from '../types';
import baseQueryWithTokenRemove from './common/baseQueryWithTokenRemove';

export const settingSecurityApi = createApi({
  reducerPath: 'settingSecurityApi',
  baseQuery: baseQueryWithTokenRemove,
  endpoints: (build) => ({
    generateSecret: build.mutation<IGenerateSecret, string>({
      query: (settingId) => {
        return {
          url: `settings/generate-secret/${settingId}`,
          method: 'POST',
        };
      },
    }),
    sendCode: build.mutation<
    IGoodleAuth,
    {
      userId: string;
      twoFactorAuthenticationCode: string;
    }
    >({
      query: ({ userId, twoFactorAuthenticationCode }) => {
        return {
          url: 'auth/google-auth',
          method: 'POST',
          body: {
            userId,
            twoFactorAuthenticationCode,
          },
        };
      },
    }),
    disableGoogleAuth: build.mutation<
    IDisableGoogleAuth,
    {
      userId: string;
      password: string;
      key: string;
    }
    >({
      query: ({ userId, password, key }) => {
        return {
          url: `settings/remove-auth/${userId}`,
          method: 'POST',
          body: {
            password,
            key,
          },
        };
      },
    }),
  }),
});

export const {
  useGenerateSecretMutation,
  useSendCodeMutation,
  useDisableGoogleAuthMutation,
} = settingSecurityApi;
