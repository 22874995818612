import { useAppSelector } from '../../redux/hooks/redux';
import { selectBots } from '../../redux/selectors/rootSelector';

export default function useSubscribeToBots() {
  const { bots } = useAppSelector(selectBots);
  const HOST = process.env.REACT_APP_WSS_HOST;

  const subscribeToBots = () => {
    if (!bots) {
      return;
    }
    const flowIds = bots.map((bot) => bot.flowId);

    const socket = new WebSocket(
      `${HOST}/ws/v1/sessions/${sessionStorage.getItem('wsId')}/flows/subscriptions`,
    );

    socket.addEventListener('open', () => {
      console.log('WebSocket connection is opened');
      socket.send(JSON.stringify({ subscribe: flowIds, unsubscribe: [] }));
    });

    socket.addEventListener('message', (event) => {
      const response = JSON.parse(event.data);
      console.log(response);
    });

    socket.addEventListener('close', () => console.log('WebSocket connection is closed'));
    socket.addEventListener('error', (error) =>
      console.log('WebSocket subscribe to bots error: ', error),
    );
  };

  return subscribeToBots;
}
