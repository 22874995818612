// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Filter_container__L5eml {
  display: flex;
  position: relative;
}

.Filter_compactFilter__wTiZ0 {
  display: flex;
  gap: 4px;
  align-items: center;
}

.Filter_compactFilter__wTiZ0 span {
  font-weight: normal;
  color: white;
}
.Filter_compactFilter__wTiZ0 button {
  padding: 0;
  display: flex;
  align-items: center;
}

.Filter_opened__QM4tR {
  transform: rotate(180deg);
}

.Filter_dropdowns__F4Qo7 {
  position: relative;
  display: flex;
  gap: 8px;
  z-index: 2;
  align-items: center;
}

.Filter_compactDropdowns__AZqE3 {
  flex-direction: column;
  position: absolute;
  top: 30px;
  background-color: rgb(20, 18, 32);
  z-index: 500;
  border-radius: 10px;
}

@media (max-width: 768px) {
  .Filter_container__L5eml {
    width: 100%;
  }
  .Filter_dropdowns__F4Qo7 {
    flex-direction: row;
    flex-wrap: wrap;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Filter/Filter.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,QAAQ;EACR,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,YAAY;AACd;AACA;EACE,UAAU;EACV,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,QAAQ;EACR,UAAU;EACV,mBAAmB;AACrB;;AAEA;EACE,sBAAsB;EACtB,kBAAkB;EAClB,SAAS;EACT,iCAAiC;EACjC,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE;IACE,WAAW;EACb;EACA;IACE,mBAAmB;IACnB,eAAe;EACjB;AACF","sourcesContent":[".container {\n  display: flex;\n  position: relative;\n}\n\n.compactFilter {\n  display: flex;\n  gap: 4px;\n  align-items: center;\n}\n\n.compactFilter span {\n  font-weight: normal;\n  color: white;\n}\n.compactFilter button {\n  padding: 0;\n  display: flex;\n  align-items: center;\n}\n\n.opened {\n  transform: rotate(180deg);\n}\n\n.dropdowns {\n  position: relative;\n  display: flex;\n  gap: 8px;\n  z-index: 2;\n  align-items: center;\n}\n\n.compactDropdowns {\n  flex-direction: column;\n  position: absolute;\n  top: 30px;\n  background-color: rgb(20, 18, 32);\n  z-index: 500;\n  border-radius: 10px;\n}\n\n@media (max-width: 768px) {\n  .container {\n    width: 100%;\n  }\n  .dropdowns {\n    flex-direction: row;\n    flex-wrap: wrap;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Filter_container__L5eml`,
	"compactFilter": `Filter_compactFilter__wTiZ0`,
	"opened": `Filter_opened__QM4tR`,
	"dropdowns": `Filter_dropdowns__F4Qo7`,
	"compactDropdowns": `Filter_compactDropdowns__AZqE3`
};
export default ___CSS_LOADER_EXPORT___;
