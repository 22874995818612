import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import DateFilter from '../../UsersListPage/DateFilter/DateFilter';
import SearchBar from '../../UsersListPage/SearchBar/SearchBar';
import { IGroup } from '../group.types';
import styles from './StatusGroupBar.module.css';
import { useNavigate } from 'react-router-dom';

interface IStatusGroupBar {
  setAllGroups: () => void;
  dateValue: [Date, Date] | undefined;
  setDateValue: Dispatch<SetStateAction<[Date, Date] | undefined>>;
  groups: IGroup[];
  setGroups: Dispatch<SetStateAction<IGroup[] | []>>;
  onSettingsOpen: () => void;
  setCurrentPage: Dispatch<SetStateAction<number>>;
}

export default function StatusGroupBar({
  setAllGroups,
  dateValue,
  setDateValue,
  groups,
  setGroups,
  onSettingsOpen,
  setCurrentPage,
}: IStatusGroupBar) {
  const navigate = useNavigate();
  const [isShowCalendar, setIsShowCalendar] = useState(false);
  const [dateFrom, setDateFrom] = useState<string>('');
  const [dateTo, setDateTo] = useState<string>('');
  const [searchValue, setSearchValue] = useState('');

  const formatOption: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
  };

  useEffect(() => {
    if (dateValue) {
      setDateFrom(new Intl.DateTimeFormat('ru', formatOption).format(dateValue[0]));
      setDateTo(new Intl.DateTimeFormat('ru', formatOption).format(dateValue[1]));
    } else {
      setDateFrom('');
      setDateTo('');
    }
  }, [dateValue]);

  const onSetSearchValue = (e: React.ChangeEvent<HTMLInputElement>) =>
    setSearchValue(e.target.value.toLowerCase());

  const onSearch = () => {
    const searchResult = groups.filter(
      (group) =>
        group.id.toString().includes(searchValue) ||
        group.functionality.toLowerCase().includes(searchValue) ||
        group.group.toLowerCase().includes(searchValue),
    );
    setGroups(searchResult);
    setCurrentPage(1);
  };

  const onNewGroupCreate = () => {
    navigate('/admin/grouplist/create');
  };

  return (
    <div className={styles.container}>
      <div className={styles.filters}>
        <div className={styles.dateBox}>
          <h4>Grouplist</h4>
          <DateFilter
            dateFrom={dateFrom}
            dateTo={dateTo}
            dateValue={dateValue}
            setDateValue={setDateValue}
            setIsShowCalendar={setIsShowCalendar}
            isShowCalendar={isShowCalendar}
          />
          <button
            className={styles.resetBtn}
            onClick={() => {
              setAllGroups();
              setDateValue(undefined);
              setSearchValue('');
            }}
          >
            Reset filter
          </button>
        </div>
      </div>
      <SearchBar
        searchValue={searchValue}
        onSearch={onSearch}
        onSetSearchValue={onSetSearchValue}
        onSettingsOpen={onSettingsOpen}
        buttonTitle='Create new group'
        onCreateNew={onNewGroupCreate}
      />
    </div>
  );
}
