import { EStatus } from './BotsCard/ActivityBotIcon';

export const prepareStatus = (status: string) => {
  const statusesByResponse = {
    FLOW_STATUS_RUNNING_AUTOCONFIG: EStatus.active,
    FLOW_STATUS_RUNNING_PRO: EStatus.active,
    FLOW_STATUS_RUNNING_AUTO: EStatus.active,

    FLOW_STATUS_PAUSED_REQUEST: EStatus.paused,

    FLOW_STATUS_STOPPED_NEW: EStatus.new,

    FLOW_STATUS_STOPPING: EStatus.stopped,
    FLOW_STATUS_STOPPED_REQUEST: EStatus.stopped,
    FLOW_STATUS_STOPPED_REGULAR: EStatus.stopped,

    FLOW_STATUS_STOPPED_EMERGENCY: EStatus.error,
    FLOW_STATUS_CRUSHED: EStatus.error,
    FLOW_STATUS_PAUSED_EMERGENCY: EStatus.error,

    FLOW_STATUS_REMOVED: EStatus.removed,
  };

  return statusesByResponse[status as keyof typeof statusesByResponse] || EStatus.error;
};
