import cn from 'classnames';
import { EGroup } from '../../UsersListPage/UsersList/UserList.types';
import onSelectItems from './choose.utils';
import styles from './Choose.module.css';
import { IChooseItems } from './Choose.types';

export default function ChooseGroup({ items, setItems }: IChooseItems<EGroup>) {
  const getStyles = (group: EGroup) =>
    cn([styles.group], { [styles.selectedGroup]: items.includes(group) });

  const GROUPS = Object.values(EGroup);

  return (
    <div className={styles.container}>
      <h4>Choose a group</h4>
      <div className={styles.groups}>
        {GROUPS.map((group) => (
          <button
            onClick={() => onSelectItems(items, setItems, group)}
            className={getStyles(group)}
            key={group}
          >
            {group}
          </button>
        ))}
      </div>
    </div>
  );
}
