// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HowToConnectPage_container__di172 {
  margin-left: auto;
  margin-right: auto;
}

.HowToConnectPage_content__akeA- {
  padding: 120px 100px;
  margin-left: auto;
  margin-right: auto;
  max-width: var(--wrapper-width);
}

@media (max-width: 1200px) {
  :root {
    --wrapper-width: 100%;
  }
}

@media (max-width: 768px) {
  .HowToConnectPage_content__akeA- {
    padding: 120px 32px;
  }
}

@media (max-width: 480px) {
  .HowToConnectPage_content__akeA- {
    padding: 120px 16px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/HowToConnectPage/HowToConnectPage.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,oBAAoB;EACpB,iBAAiB;EACjB,kBAAkB;EAClB,+BAA+B;AACjC;;AAEA;EACE;IACE,qBAAqB;EACvB;AACF;;AAEA;EACE;IACE,mBAAmB;EACrB;AACF;;AAEA;EACE;IACE,mBAAmB;EACrB;AACF","sourcesContent":[".container {\n  margin-left: auto;\n  margin-right: auto;\n}\n\n.content {\n  padding: 120px 100px;\n  margin-left: auto;\n  margin-right: auto;\n  max-width: var(--wrapper-width);\n}\n\n@media (max-width: 1200px) {\n  :root {\n    --wrapper-width: 100%;\n  }\n}\n\n@media (max-width: 768px) {\n  .content {\n    padding: 120px 32px;\n  }\n}\n\n@media (max-width: 480px) {\n  .content {\n    padding: 120px 16px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `HowToConnectPage_container__di172`,
	"content": `HowToConnectPage_content__akeA-`
};
export default ___CSS_LOADER_EXPORT___;
