/* eslint-disable react/jsx-key */
import { ChangeEvent, useState, FC } from 'react';
import DoneIcon from '../../../assets/General/DoneIcon';
import styles from './ExchangesCard.module.css';
import { IExchanges } from '../../../types';
import cn from 'classnames';
import { Input } from '../../CCLabsUI';
import { ESizing } from '../../CCLabsUI/Input/Input';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
export interface IExchangesCardProps {
  item: IExchanges;
  addApi: (apiOpen: string, apiSecret: string, id: number) => void;
  deleteApi: (id: number) => void;
  currentLoadingId: number | null;
  isLoadingConnection?: boolean;
}

const ExchangesCard: FC<IExchangesCardProps> = ({
  item,
  addApi,
  deleteApi,
  currentLoadingId,
  isLoadingConnection,
}) => {
  const [apiOpen, setApiOpen] = useState<string>(item.defaultApiOpen);
  const [apiSecret, setApiSecret] = useState<string>(item.defaultApiSecret);
  const { t } = useTranslation('exhangesPage');

  const handleOnClick = (id: number) => {
    if (item.hasConnect) {
      deleteApi(id);
      return;
    }
    addApi(apiOpen, apiSecret, id);
  };

  const buttonText = item.hasConnect ? t('disconnect') : t('connect');
  const isLoading = currentLoadingId === item.id;
  const buttonContent = isLoading ? (
    <ClipLoader size={20} loading={isLoading} color='#fff' />
  ) : (
    buttonText
  );

  const onApiOpenChange = (e: ChangeEvent<HTMLInputElement>) =>
    !item.hasConnect && setApiOpen(e.target.value);

  const onApiSecretChange = (e: ChangeEvent<HTMLInputElement>) => setApiSecret(e.target.value);

  const inputStyles = cn({ [styles.inputConnected]: item.hasConnect });
  const buttonStyles = cn(styles.button, { [styles.buttonConnected]: item.hasConnect });

  const connectionSpotContent = item.isSpotConnected ? t('spotConnect') : t('spotNotConnect');
  const connectionFuturesContent = item.isFuturesConnected
    ? t('futuresConnect')
    : t('futuresNotConnect');

  const connectionSpotStyles = cn(styles.connectionSpot, {
    [styles.connectionSuccess]: item.isSpotConnected,
  });
  const connectionFuturesStyles = cn(styles.connectionSpot, {
    [styles.connectionSuccess]: item.isFuturesConnected,
  });
  const keyExpiresStyle = cn(styles.keyExpires, {
    [styles.keyExpiresSuccess]: !item.areKeysExpired,
  });

  const isBlurredCard = item.isDisabled || isLoadingConnection;
  const cardClasses = cn(styles.card, {
    [styles.cardDisabled]: isBlurredCard,
  });

  return (
    <div className={styles.container}>
      <div className={cardClasses}>
        <div className={styles.headerCard}>
          <div className={styles.icon}>{item.icon}</div>
          <div className={item.hasConnect && !item.isDisabled ? styles.done : styles.undone}>
            <DoneIcon
              data-testid='done-icon-element'
              style={item.hasConnect && !item.isDisabled ? {} : { display: 'none' }}
              color='#black'
              width={20}
              height={15}
            />
          </div>
        </div>
        <div className={styles.content}>
          {item.hasConnect ? (
            <p>
              <span className={connectionSpotStyles}>{connectionSpotContent}</span>
              <br />
              <span className={connectionFuturesStyles}>{connectionFuturesContent}</span>
              <br />
              <span className={keyExpiresStyle}>
                {t('expires')} {item.keysExpirationDate}
              </span>
            </p>
          ) : (
            <div className={styles.connectionBlock}>
              <p className={styles.text}>
                <Trans t={t} i18nKey='description' components={[<Link to='/how-to-connect' />]} />
              </p>
              <div className={styles.inputs}>
                <Input
                  id={`key${item.id}`}
                  sizing={ESizing.large}
                  label={t('apiKey')}
                  placeholder={t('apiKeyPlaceholder')}
                  value={apiOpen}
                  onChange={onApiOpenChange}
                  className={inputStyles}
                />
                <div>
                  <Input
                    id={`private-key${item.id}`}
                    sizing={ESizing.large}
                    label={t('privateKey')}
                    placeholder={t('privateKeyPlaceholder')}
                    isPassword
                    value={apiSecret}
                    onChange={onApiSecretChange}
                    className={inputStyles}
                  />
                  <p>{t('copyAndSave')}</p>
                </div>
              </div>
            </div>
          )}
          {isLoadingConnection && item.isAvailable && (
            <ClipLoader
              size={50}
              loading={isLoadingConnection}
              color='#fff'
              className={styles.loader}
            />
          )}
        </div>

        {item.hasError && <div>something went wrong, try later</div>}

        <button
          className={buttonStyles}
          onClick={() => handleOnClick(item.id)}
          disabled={item.isDisabled}
        >
          {buttonContent}
        </button>
        {!item.hasConnect && (
          <div className={styles.textRegister}>
            <p>{t('noAcc', { exchange: item.name })}?</p>
            <a
              className={styles.textRegisterLink}
              href={item.isDisabled ? undefined : item.href}
              target='_blank'
              rel='noreferrer'
            >
              {t('register')}
            </a>
          </div>
        )}
      </div>
      {!item.isAvailable ? <p className={styles.soonText}>{t('soon')}</p> : null}
    </div>
  );
};

export default ExchangesCard;
