import TitleCard from '../../BotsPage/BotSettings/Blocks/TitleCard';
import PnlValue from '../../BotsPage/BotsCard/PnlValue';
import LineOfCard from './LineOfCard';
import styles from './Cards.module.css';
import { ESizing } from '../../BotsPage/BotsCard/RiskBot';
import { useTranslation } from 'react-i18next';

export default function Profits() {
  const { t } = useTranslation('botsStats');
  return (
    <div className={styles.container}>
      <TitleCard title={t('profits')} />
      <LineOfCard title={t('realizedPnl')} value='+36,879.97' positive={true} />
      <LineOfCard title={t('tradeProfit')} value='+36,879.97' positive={true} />
      <LineOfCard title='PNL %' value={<PnlValue value={11669.78} size={ESizing.small} />} />
      <LineOfCard title='APR %' value={<PnlValue value={11669.78} size={ESizing.small} />} />
    </div>
  );
}
