import cn from 'classnames';
import ActionsBlock from './ActionsBlock';
import PnlValue from './PnlValue';
import StrategyBot from './StrategyBot';
import RuntimeBot from './RuntimeBot';
import RiskBot, { ESizing } from './RiskBot';
import BotIcon from '../BotIcon/BotIcon';
import styles from './BotsCard.module.css';
import ActivityBotIcon, { EStatus } from './ActivityBotIcon';
import { useAppSelector } from '../../../redux/hooks/redux';
import { selectBot } from '../../../redux/selectors/rootSelector';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import MarketBot from './MarketBot';
import { EFlowStatus, IBot } from '../../../redux/types';
import { prepareStatus } from '../BotsPage.utils';
import IconWithTooltip from '../../IconWithTooltip/IconWithTooltip';
import { Exchanges } from '../../LaunchBotPage/Leaderboard/ListLeaderboard/ExchangesIcons';
import useTransformPair from '../../../hooks/useTransformPair';
export interface BotCardProps {
  bot: IBot;
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
  isInfoBlockOpened: boolean;
  isSubBotCard: boolean;
}
export default function BotCard({ bot, isInfoBlockOpened, onClick, isSubBotCard }: BotCardProps) {
  const {
    mode,
    pnl,
    uptime,
    instrument,
    strategy,
    strategyType,
    flowName,
    marketType,
    flowStatus,
    useReverse,
    uuid,
  }: IBot = bot;
  const selectedBot = useAppSelector(selectBot);
  const { t } = useTranslation('botspage');
  const transformPair = useTransformPair();
  const isBotDisable: boolean =
    prepareStatus(flowStatus) !== EStatus.active && prepareStatus(flowStatus) !== EStatus.new;

  const handleInfoOpen = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if ((e.target as HTMLElement).tagName !== 'BUTTON') {
        onClick(e);
      }
    },
    [onClick],
  );

  const isSelectedBot = isInfoBlockOpened && selectedBot?.uuid === uuid;

  const cardClassNames = cn(
    [styles.container],
    { [styles.nonactive]: isBotDisable },
    { [styles.selectedBot]: isSelectedBot },
    { [styles.subbot]: isSubBotCard },
  );

  const cardHeaderClassNames = cn(styles.contentHeader, {
    [styles.shortHeader]: isInfoBlockOpened,
  });

  const MAX_NAME_LENGTH = 20;
  const shouldCutName = flowName.length > MAX_NAME_LENGTH;
  const botName = shouldCutName ? `${flowName.slice(0, MAX_NAME_LENGTH - 1)}...` : flowName;
  const botMode = mode === 'NO' ? 'PRO' : t(`botCard.mode.${bot.mode.replace('MODE_', '')}`);
  return (
    <div className={styles.wrapper} onClick={handleInfoOpen}>
      <ActivityBotIcon bot={bot} />
      <div className={cardClassNames}>
        <div className={cardHeaderClassNames}>
          <span className={styles.modeBox}>{botMode}</span>
          <div className={styles.botName}>
            <BotIcon icon={strategy} height={24} width={24} />
            <IconWithTooltip content={flowName} element={botName} />
          </div>
          <div className={styles.activityStatus} />
        </div>

        <div className={cn([styles.content], { [styles.short]: isInfoBlockOpened })}>
          <div className={styles.strategyInfoWrapper}>
            <div className={styles.strategyBox}>
              <StrategyBot
                strategy={strategyType}
                trendFlipper={isSubBotCard ? false : useReverse}
              />
            </div>
            <div className={styles.infoWrapper}>
              <div className={styles.info}>
                {Exchanges[bot.exchangeType]}
                <MarketBot market={marketType} />
                {transformPair(instrument)}
                <RiskBot />
                <div className={styles.pnl}>
                  PNL: <PnlValue value={pnl} char='USD' size={ESizing.small} />
                </div>
                <div className={styles.runtimeBox}>
                  {t('botCard.runtime.time')}: <RuntimeBot runtime={uptime} />
                </div>
              </div>
            </div>
          </div>
          {!isSubBotCard && (
            <div className={styles.actionsBlock}>
              {/* todo: update type when do ActionsBlock task */}
              <ActionsBlock
                flowStatus={flowStatus as EFlowStatus}
                isInfoBlockOpened={isInfoBlockOpened}
                bot={bot}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
