// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StatisticsCurrencyValue_container__8MtyU {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 12px 16px;
  gap: 10px;
  border-radius: 10px;
  border: 1px solid var(--border-white-02);
  font-weight: 400;

  font-family: PTSans;
}

.StatisticsCurrencyValue_currencyValue__83onu {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  line-height: normal;
}

.StatisticsCurrencyValue_currency__XdRaq {
  color: white;
  font-size: 16px;
}

.StatisticsCurrencyValue_description__znr9- {
  display: flex;
  gap: 8px;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
}

.StatisticsCurrencyValue_description__znr9- button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  border: none;
}

.StatisticsCurrencyValue_positive__HFRVQ {
  color: rgb(96, 207, 149);
}

.StatisticsCurrencyValue_negative__VVutp {
  color: rgb(255, 78, 78);
}
@media screen and (max-width: 1100px) {
  .StatisticsCurrencyValue_container__8MtyU {
    width: 200px;
  }
}

@media (max-width: 768px) {
  .StatisticsCurrencyValue_wrapper__YDGah {
    flex: 0 0 48%;
  }
  .StatisticsCurrencyValue_container__8MtyU {
    width: auto;
    padding: 12px 14px;
  }
  .StatisticsCurrencyValue_description__znr9- {
    font-size: 12px;
  }
  .StatisticsCurrencyValue_description__znr9- button {
    display: none;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/StatisticPage/CurrenciesCards/StatisticsCurrencyValue/StatisticsCurrencyValue.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,6BAA6B;EAC7B,kBAAkB;EAClB,SAAS;EACT,mBAAmB;EACnB,wCAAwC;EACxC,gBAAgB;;EAEhB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,QAAQ;EACR,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,QAAQ;EACR,mBAAmB;EACnB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,UAAU;EACV,YAAY;AACd;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,uBAAuB;AACzB;AACA;EACE;IACE,YAAY;EACd;AACF;;AAEA;EACE;IACE,aAAa;EACf;EACA;IACE,WAAW;IACX,kBAAkB;EACpB;EACA;IACE,eAAe;EACjB;EACA;IACE,aAAa;EACf;AACF","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  justify-content: space-evenly;\n  padding: 12px 16px;\n  gap: 10px;\n  border-radius: 10px;\n  border: 1px solid var(--border-white-02);\n  font-weight: 400;\n\n  font-family: PTSans;\n}\n\n.currencyValue {\n  display: flex;\n  align-items: center;\n  gap: 8px;\n  font-size: 14px;\n  line-height: normal;\n}\n\n.currency {\n  color: white;\n  font-size: 16px;\n}\n\n.description {\n  display: flex;\n  gap: 8px;\n  align-items: center;\n  font-size: 14px;\n  font-weight: 400;\n}\n\n.description button {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding: 0;\n  border: none;\n}\n\n.positive {\n  color: rgb(96, 207, 149);\n}\n\n.negative {\n  color: rgb(255, 78, 78);\n}\n@media screen and (max-width: 1100px) {\n  .container {\n    width: 200px;\n  }\n}\n\n@media (max-width: 768px) {\n  .wrapper {\n    flex: 0 0 48%;\n  }\n  .container {\n    width: auto;\n    padding: 12px 14px;\n  }\n  .description {\n    font-size: 12px;\n  }\n  .description button {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `StatisticsCurrencyValue_container__8MtyU`,
	"currencyValue": `StatisticsCurrencyValue_currencyValue__83onu`,
	"currency": `StatisticsCurrencyValue_currency__XdRaq`,
	"description": `StatisticsCurrencyValue_description__znr9-`,
	"positive": `StatisticsCurrencyValue_positive__HFRVQ`,
	"negative": `StatisticsCurrencyValue_negative__VVutp`,
	"wrapper": `StatisticsCurrencyValue_wrapper__YDGah`
};
export default ___CSS_LOADER_EXPORT___;
