import { IUser } from '../UsersList/UserList.types';
import { INotification } from '../../NotificationsPage/NotificationList/Notification.types';
import { IGroup } from '../../GroupeListPage/group.types';

export const getPaginationPages = (totalPages: number, currentPage: number) => {
  const MAX_PAGES_IN_BLOCK = 5;
  const MIDDLE_PAGES = 3;
  const CONSECUTIVE_PAGES = 4;

  const generatePageRange = (start: number, end: number) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    return Array.from({ length: end - start + 1 }, (_, i) => start + i);
  };

  if (totalPages <= MAX_PAGES_IN_BLOCK) {
    return generatePageRange(1, totalPages);
  }

  if (currentPage <= MIDDLE_PAGES) {
    const firstNumbers = generatePageRange(1, CONSECUTIVE_PAGES);
    return [...firstNumbers, '...', totalPages];
  }

  if (currentPage > totalPages - MIDDLE_PAGES) {
    const lastNumbers = generatePageRange(totalPages - MIDDLE_PAGES, totalPages);
    return [1, '...', ...lastNumbers];
  }

  return [1, '...', currentPage - 1, currentPage, currentPage + 1, '...', totalPages];
};

export const getArrayIndexes = (
  array: IUser[] | INotification[] | IGroup[],
  currentPage: number,
) => {
  const ITEMS_ON_PAGE: number = 10;
  const totalPages = Math.ceil(array.length / ITEMS_ON_PAGE);
  const indexOfLastUser = currentPage * ITEMS_ON_PAGE;
  const indexOfFirstUser = indexOfLastUser - ITEMS_ON_PAGE;
  return [totalPages, indexOfFirstUser, indexOfLastUser];
};
