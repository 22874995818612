import React, { useState, useEffect } from 'react';
import styles from './EnableGoogleAuthModal.module.css';
import CloseButton from '../CloseButton/CloseButton';
import ValidateIcon from '../../assets/invalidinput-icon.svg';
import { useDisableGoogleAuthMutation } from '../../redux/services/settingSecurityService';
import { useElementSize } from '../../hooks/useElementSize';
import { useCurrentUserProfileQuery } from '../../redux/services/settingProfileService';
interface IEnableGoogleAuthModal {
  isOpen?: boolean;
  isSendCode?: boolean;
  inputValue?: string;
  secretCode: string;
  children?: JSX.Element | JSX.Element[];
  actionClose: () => void;
  actionOpenSecurityCheckModal: () => void;
  actionOpenResetModal: () => void;
  actionSwitchGoogle: () => void;
}
const EnableGoogleAuthModal: React.FC<IEnableGoogleAuthModal> = (props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, ref] = useElementSize();
  const [passwordValue, setPasswordValue] = useState('');
  const [codeValue, setCodeValue] = useState('');
  const [sendData, { isLoading }] = useDisableGoogleAuthMutation();
  const [isCodeError, setIsCodeError] = useState(false);
  const { data: userProfile } = useCurrentUserProfileQuery();
  const userId = userProfile?.userId ?? '0';
  const isDisabledButtonStyle: boolean =
    passwordValue === '' || codeValue === '' || isLoading || isCodeError;

  const timeout: number = 2000;
  useEffect(() => {
    const timer: NodeJS.Timeout = setTimeout(() => setIsCodeError(false), timeout);

    () => {
      clearTimeout(timer);
    };
  }, [isCodeError]);
  const onConfirm = async () => {
    try {
      const dataSendForm = await sendData({
        userId: userId,
        password: passwordValue,
        key: codeValue,
      }).unwrap();
      if (dataSendForm.setting.googleAuth === null) {
        props.actionSwitchGoogle();
        props.actionClose();
      }
    } catch (error) {
      const { status } = error as { status: string}; // todo: add statuses to enum
      if (status === 'FETCH_ERROR') {
        setIsCodeError(true);
      }
    }
  };
  const onClickProblemsVerification = () => {
    props.actionOpenResetModal();
  };

  return (
    <div className={styles.modalContainer}>
      <div className={styles.modal} ref={ref}>
        <div className={styles.closeButton}>
          <CloseButton onClick={props.actionClose} />
        </div>
        <div className={styles.modalHeader}>
          <div className={styles.headerText}>Disable Google Authentication</div>
        </div>
        <div className={styles.textBlockRow}>
          <div className={styles.warningIcon}>!</div>
          <div className={styles.description}>
            You will not be able to withdraw funds within 24 hours after disabling Google
            authentication
          </div>
        </div>

        <form action='submit' className={styles.googleAuthForm}>
          <label htmlFor='password'>Password</label>
          <input
            className={styles.googleAuthenticatorInput}
            placeholder='Enter Password'
            type='password'
            id='password'
            value={passwordValue}
            onChange={(e) => setPasswordValue(e.target.value)}
          />

          <label htmlFor='authCode'>Google Authenticatorv</label>
          <input
            id='authCode'
            placeholder='Enter code 2FA Google authentication'
            className={styles.googleAuthenticatorInput}
            value={codeValue}
            onChange={(e) => setCodeValue(e.target.value)}
          />
          <div className={styles.validateInputBlock}>
            {isCodeError && (
              <div className={styles.validateInput}>
                <img src={ValidateIcon} />
                <div className={styles.validateInputText}>Something went wrong</div>
              </div>
            )}
          </div>
          <button
            type='submit'
            onClick={onConfirm}
            disabled={isDisabledButtonStyle}
            className={styles.confirmBtn}
          >
            Confirm
          </button>
        </form>

        <button className={styles.problemBtn} onClick={onClickProblemsVerification}>
          Problems with verification?
        </button>
      </div>
    </div>
  );
};

export default EnableGoogleAuthModal;
