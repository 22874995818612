import React from 'react';
import styles from './UnauthorizedHeader.module.css';

import { Link, useLocation, useNavigate } from 'react-router-dom';
import LogoImg from '../../../assets/img/Logo.svg';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import LanguageMenu from '../LanguageMenu/LanguageMenu';
import { useDispatch } from 'react-redux';
import { changeIsMobileMenuOpen } from '../../../redux/slices/layoutSlice';
import { TELEGRAM_SUPPORT_LINK } from '../../../constants';

interface IUnauthorizedHeaderProps {
  hidden: { [x: string]: boolean };
  isMenuOpen: boolean;
  setIsMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const UnauthorizedHeader: React.FC<IUnauthorizedHeaderProps> = ({
  hidden,
  isMenuOpen,
  setIsMenuOpen,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation('header');
  const isEnglish = i18n.language === 'en';
  const controlsStyle = cn(styles.controls, hidden, { [styles.controls_ru]: !isEnglish });
  const helpStyle = cn(styles.help, { [styles.hidden]: isMenuOpen });
  const { pathname } = useLocation();
  const navigateToTargetPage = () => () => {
    if (pathname !== '/') {
      navigate('/');
    }
    setIsMenuOpen(false);
    dispatch(changeIsMobileMenuOpen(false));
  };

  const navigateToAboutUsPage = () => {
    setIsMenuOpen(false);
    navigate('/aboutUs');
    dispatch(changeIsMobileMenuOpen(false));
  };

  const onClickLogo = () => {
    navigate('/');

    window.scrollTo(0, 0);
  };

  return (
    <div className={styles.container}>
      <div className={styles.logo}>
        <img src={LogoImg} onClick={onClickLogo} />
      </div>
      <div className={cn(styles.nav, hidden)}>
        <a onClick={navigateToTargetPage()} href='#grid_bot'>
          {t('navigation.product')}
        </a>
        <a onClick={navigateToAboutUsPage}>{t('navigation.aboutUs')}</a>
        <a onClick={navigateToTargetPage()} href='#how_it_works'>
          {t('navigation.howItWorks')}
        </a>
        <a onClick={navigateToTargetPage()} href='#roadmap'>
          {t('navigation.roadmap')}
        </a>
        <a onClick={navigateToTargetPage()} href='#ai'>
          {t('navigation.aI')}
        </a>
      </div>

      <a className={helpStyle} href={TELEGRAM_SUPPORT_LINK} target='_blank' rel='noreferrer'>
        {t('help')}
      </a>
      <div className={styles.container_language}>
        <LanguageMenu />
      </div>

      <div className={controlsStyle}>
        <span className={styles.addictive}>
          <span>{t('haveAccount')}</span>
          <Link to='log-in' className={styles.btn_sign_in}>
            {t('signIn')}
          </Link>
        </span>
        <Link to='' className={cn(styles.btn_registration, styles.link_disable)}>
          {t('registration')}
        </Link>
      </div>
    </div>
  );
};

export default UnauthorizedHeader;
