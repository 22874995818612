// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Tabs_statisticsTabs__4F\\+Gd {
  cursor: pointer;
  display: flex;
  width: 100%;
}
.Tabs_directionColumn__EYKsp {
  flex-direction: column;
}

.Tabs_tab__b6Fl7 {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-family: PTSans;
  font-weight: 400;
}
.Tabs_statisticsSingleTab__-58Xi {
  width: 100%;
  padding: 4px;
  border: 1px solid var(--border-white-02);
  font-size: 13px;
  line-height: 24px;
}

.Tabs_statisticsSingleTab__-58Xi:nth-child(1) {
  border-radius: 10px 0px 0px 10px;
  border-right: none;
}

.Tabs_statisticsSingleTab__-58Xi:last-child {
  border-radius: 0px 10px 10px 0px;
  border-left: none;
}

.Tabs_activeTab__QxpwY {
  background: white;
  color: black;
  font-weight: 700;
}
`, "",{"version":3,"sources":["webpack://./src/components/StatisticPage/Tabs/Tabs.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,aAAa;EACb,WAAW;AACb;AACA;EACE,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,WAAW;EACX,YAAY;EACZ,uBAAuB;EACvB,mBAAmB;EACnB,mBAAmB;EACnB,gBAAgB;AAClB;AACA;EACE,WAAW;EACX,YAAY;EACZ,wCAAwC;EACxC,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,gCAAgC;EAChC,kBAAkB;AACpB;;AAEA;EACE,gCAAgC;EAChC,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;EACjB,YAAY;EACZ,gBAAgB;AAClB","sourcesContent":[".statisticsTabs {\n  cursor: pointer;\n  display: flex;\n  width: 100%;\n}\n.directionColumn {\n  flex-direction: column;\n}\n\n.tab {\n  display: flex;\n  width: 100%;\n  height: 100%;\n  justify-content: center;\n  align-items: center;\n  font-family: PTSans;\n  font-weight: 400;\n}\n.statisticsSingleTab {\n  width: 100%;\n  padding: 4px;\n  border: 1px solid var(--border-white-02);\n  font-size: 13px;\n  line-height: 24px;\n}\n\n.statisticsSingleTab:nth-child(1) {\n  border-radius: 10px 0px 0px 10px;\n  border-right: none;\n}\n\n.statisticsSingleTab:last-child {\n  border-radius: 0px 10px 10px 0px;\n  border-left: none;\n}\n\n.activeTab {\n  background: white;\n  color: black;\n  font-weight: 700;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"statisticsTabs": `Tabs_statisticsTabs__4F+Gd`,
	"directionColumn": `Tabs_directionColumn__EYKsp`,
	"tab": `Tabs_tab__b6Fl7`,
	"statisticsSingleTab": `Tabs_statisticsSingleTab__-58Xi`,
	"activeTab": `Tabs_activeTab__QxpwY`
};
export default ___CSS_LOADER_EXPORT___;
