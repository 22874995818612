import { createPortal } from 'react-dom';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import styles from './BotSettingsModal.module.css';
import TitleCard from '../../BotsPage/BotSettings/Blocks/TitleCard';
import LineOfCard from '../StatisticsCards/LineOfCard';
import CloseButton from '../../CloseButton/CloseButton';
import { ESizing } from '../../CCLabsUI/Input/Input';
import { useTranslation } from 'react-i18next';
import BlockingOverlay from '../../BlockingOverlay/BlockingOverlay';

const root: unknown = document.getElementById('root');

interface ISettingsModalProps {
  onClose: () => void;
}

export default function BotSettingsModal({ onClose }: ISettingsModalProps) {
  const navigate: NavigateFunction = useNavigate();
  const onSettingsPage = () => navigate('/bots/mybots', { state: { openSettings: true } });
  const { t } = useTranslation('settingsModal');
  return createPortal(
    <div className={styles.container}>
      <div className={styles.modalContainer}>
        <h4 className={styles.title}>{t('title')}</h4>
        <button className={styles.closeBtn}>
          <CloseButton onClick={onClose} />
        </button>
        <div className={styles.cardsContainer}>
          <div>
            <div className={styles.card}>
              <BlockingOverlay title='Soon' />
              <TitleCard title={t('autofilling')} size={ESizing.large} />
              <LineOfCard title={t('autofilling')} value='Preset #' />
            </div>
            <div className={styles.card}>
              <BlockingOverlay title='Soon' />
              <TitleCard title={t('backtesting')} size={ESizing.large} />
              <LineOfCard title={t('backtesting')} value='Test #' />
            </div>
            <div className={styles.card}>
              <TitleCard title={t('trendFlipper')} size={ESizing.large} />
              <LineOfCard title={t('trendFlipper')} value='On' isValueSwitcher={true} />
            </div>
            <div className={styles.card}>
              <TitleCard title={t('squeezeArmor')} size={ESizing.large} />
              <LineOfCard title={t('squeezeArmor')} value='On' isValueSwitcher={true} />
            </div>
            <div className={styles.card}>
              <TitleCard title={t('hedgeMode')} size={ESizing.large} />
              <LineOfCard title={t('hedgeMode')} value='On' isValueSwitcher={true} />
            </div>
            <div className={styles.card}>
              <TitleCard title={t('marginMode')} size={ESizing.large} />
              <LineOfCard title={t('marginMode')} value='Isolated' isValueSwitcher={true} />
              <LineOfCard title='Leverage' value='10' isValueSwitcher={true} />
            </div>
            <div className={styles.card}>
              <TitleCard title={t('entryOrder')} size={ESizing.large} />
              <LineOfCard title={t('firstOrder')} value={'From launch'} isValueSwitcher={true} />
              <LineOfCard title={t('priceLevel')} value='12345' />
              <LineOfCard title={t('qtyEntryOrder')} value='12345' />
              <LineOfCard title={t('qtyMultiplier')} value='On' isValueSwitcher={true} />
              <LineOfCard title={t('ratio')} value='Value' />
              <LineOfCard title={t('maxEntryOrder')} value='Value' />
              <LineOfCard title={t('gridStep')} value='On' isValueSwitcher={true} />
              <LineOfCard title={t('gridStepMultiplierRatio')} value='Value' />
              <LineOfCard title={t('maxGridStep')} value='Value' />
              <LineOfCard title={t('trailingBuy')} value='Value' />
              <LineOfCard title={t('trailingDistance')} value='On' isValueSwitcher={true} />
              <LineOfCard title={t('volatility')} value='On' isValueSwitcher={true} />
            </div>
          </div>
          <div className={styles.columnContainer}>
            <div className={styles.card}>
              <TitleCard title={t('triggers')} size={ESizing.large} />
              <LineOfCard title={t('changeApplied')} value='New' isValueSwitcher={true} />
              <LineOfCard title={t('triggerDistance')} value='12345' />
              <LineOfCard title={t('maxTriggerCount')} value='12345' />
              <LineOfCard title={t('trailingTrigger')} value='On' isValueSwitcher={true} />
              <LineOfCard title={t('profitPercent')} value='20%' />
              <LineOfCard title={t('distanceFromPrice')} value='2' />
            </div>
            <div className={styles.card}>
              <TitleCard title={t('takeProfit')} size={ESizing.large} />
              <LineOfCard title={t('minDistance')} value='12-40' />
              <LineOfCard title={t('maxDistance')} value='12-40' />
            </div>
            <div className={styles.card}>
              <TitleCard title={t('stopLoss')} size={ESizing.large} />
              <LineOfCard
                title={t('equityStop')}
                value='On'
                isValueSwitcher={true}
                shortTitle={true}
              />
              <LineOfCard title={t('minEquity')} value='Value' shortTitle={true} />
            </div>
            <button className={styles.settingsBtn} onClick={onSettingsPage}>
              {t('editSettings')}
            </button>
          </div>
        </div>
      </div>
    </div>,
    root as HTMLElement,
  );
}
