import React, { useState, SetStateAction } from 'react';
import Advantages from './Blocks/Advantages';
import OrderHistory from '../OrdersHistory/OrderHistory';
import ShareCard from '../ShareCard/ShareCard';
import backArrow from '../../../assets/General/back-arrow.svg';
import listIcon from '../../../assets/listIcon.svg';
import shareIcon from '../../../assets/shareIcon.svg';
import styles from './BotSettings.module.css';
import SettingsBlock from './SettingsBlock/SettingsBlock';
import { useTranslation } from 'react-i18next';
import ConfirmLaunchModal from '../../LaunchBotPage/ConfirmLaunchModal/ConfirmLaunchModal';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks/redux';
import { closeBlock } from '../../../redux/slices/navigationSlice';
import { selectBot } from '../../../redux/selectors/rootSelector';
import { EFlowStatus, EMarketType } from '../../../redux/types';
import { prepareStatus } from '../BotsPage.utils';
import { EStatus } from '../BotsCard/ActivityBotIcon';
import {
  useLaunchBotMutation,
  useUpdateFlowParamsMutation,
  useValidateParamsMutation,
} from '../../../redux/services/botService';
import { formatDeepCamelObjKeysToSnakeCase } from '../../../utils/formatCamelToSnakeCase';
interface IBotSettingsProps {
  setIsSettingsOpen: React.Dispatch<SetStateAction<boolean>>;
}

export default function BotSettings({ setIsSettingsOpen }: IBotSettingsProps) {
  const { t } = useTranslation('botspage');
  const [isOrderModalOpen, setIsOrderModalOpen] = useState(false);
  const [isShareCardOpen, setIsShareCardOpen] = useState(false);
  const [isConfirmWindowOpened, setIsConfirmWindowOpened] = useState(false);
  const dispatch = useAppDispatch();
  const selectedBot = useAppSelector(selectBot);
  const flowId = selectedBot?.flowId;
  const botSettings = useAppSelector((state) => state.bots.selectedFlowParameters);
  const botTempSettings = useAppSelector((state) => state.tempSettings);

  const [updateFlowParams] = useUpdateFlowParamsMutation();
  const [validateParams] = useValidateParamsMutation();

  const handleCloseSettings = () => {
    dispatch(closeBlock());
    setIsSettingsOpen(false);
  };

  const onCloseModalWindow = () => {
    isOrderModalOpen ? setIsOrderModalOpen(false) : setIsShareCardOpen(false);
  };
  const handleConfirmWindowOpen = () => {
    setIsConfirmWindowOpened(true);
  };
  const handleCloseConfirmWindow = () => {
    setIsConfirmWindowOpened(false);
  };
  const isMarginModeDisabled = selectedBot?.marketType === EMarketType.spot;

  const onDiscard = () => {
    setIsSettingsOpen(false);
  };

  const handleSaveSettings = async () => {
    const parameters = formatDeepCamelObjKeysToSnakeCase(botTempSettings.strategyParameters);
    await validateParams(formatDeepCamelObjKeysToSnakeCase(botTempSettings))
      .unwrap()
      .then((response) => console.log(response));
    await updateFlowParams({ flowId, parameters })
      .unwrap()
      .then((response) => {
        if (response.status.code === 'ok') {
          alert('Success');
          setIsSettingsOpen(false);
        }
        if (response.status.code !== 'ok') {
          alert(response.status.message);
          setIsSettingsOpen(false);
        }
      });
  };
  const [launchBot] = useLaunchBotMutation();

  const onLaunchBot = async () => {
    await launchBot(flowId)
      .unwrap()
      .then((response) => {
        if (response.status.code === 'ok') {
          alert('Success!');
          setIsSettingsOpen(false);
        }
        if (response.status.code !== 'ok') {
          alert(response.status.message);
          setIsSettingsOpen(false);
        }
      })
      .catch((error) => console.log(error));
  };

  const settingsIsNotEdited = JSON.stringify(botSettings) === JSON.stringify(botTempSettings);

  const isHiddenButtons =
    selectedBot && prepareStatus(selectedBot?.flowStatus) === EStatus.active && settingsIsNotEdited;

  const botStatus: EStatus = selectedBot ? prepareStatus(selectedBot?.flowStatus) : EStatus.error;
  const launchButtonContent = {
    [EStatus.new]: t('settings.launchBotBtn'),
    [EStatus.paused]: t('settings.restartBtn'),
    [EStatus.error]: t('settings.restartBtn'),
    [EStatus.stopped]: t('settings.restartBtn'),
    [EStatus.active]: t('settings.updateBtn'),
    [EStatus.removed]: 'Removed',
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.header}>
          <button className={styles.backBtnMobile} onClick={handleCloseSettings}>
            <img src={backArrow} /> {t('settings.backBtn.mobile')}
          </button>
          <h2 className={styles.title}>{t('settings.title')}</h2>
          <div className={styles.description}>{t('settings.description')}</div>
          <div className={styles.rightSideBtns}>
            <button onClick={() => setIsOrderModalOpen(true)}>
              <img src={listIcon} /> <span>{t('settings.orders')}</span>
            </button>
            <button onClick={() => setIsShareCardOpen(true)} disabled={true}>
              <img src={shareIcon} />
              <span>{t('settings.share')}</span>
            </button>
          </div>
          <div className={styles.headerBtns}>
            <button className={styles.backBtn} onClick={handleCloseSettings}>
              <img src={backArrow} /> {t('settings.backBtn.desktop')}
            </button>
            <button className={styles.headerBtn}>{t('settings.statisticsBtn')}</button>
          </div>
        </div>
        <Advantages />
        <div className={styles.settings}>
          <SettingsBlock isMarginModeDisabled={isMarginModeDisabled} />
          {!isHiddenButtons && (
            <div className={styles.buttonsBlock}>
              <button className={styles.discardBtn} onClick={onDiscard}>
                Discard
              </button>
              <div className={styles.innerButtons}>
                {selectedBot?.flowStatus === EFlowStatus.new && !settingsIsNotEdited && (
                  <button className={styles.saveBtn} onClick={handleSaveSettings}>
                    {t('settings.saveBtn')}
                  </button>
                )}
                {!settingsIsNotEdited ||
                  (selectedBot?.flowStatus === EFlowStatus.new && (
                    <button className={styles.launchBtn} onClick={handleConfirmWindowOpen}>
                      {launchButtonContent[botStatus]}
                    </button>
                  ))}
              </div>
            </div>
          )}
        </div>
        {isOrderModalOpen && <OrderHistory onClose={onCloseModalWindow} />}
        {isShareCardOpen && <ShareCard onClose={onCloseModalWindow} />}
        {isConfirmWindowOpened && (
          <ConfirmLaunchModal onClose={handleCloseConfirmWindow} onLaunch={onLaunchBot} />
        )}
      </div>
    </div>
  );
}
