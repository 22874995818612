import styles from './MyBotsList.module.css';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

interface IListHeader {
  isInfoBlockOpened: boolean;
}

export default function ListHeader({ isInfoBlockOpened }: IListHeader) {
  const { t } = useTranslation('botspage');
  return (
    <div className={cn(styles.list, styles.header, { [styles.short]: isInfoBlockOpened })}>
      <div className={styles.strategy}>
        {isInfoBlockOpened
          ? t('listHeader.strategy').replace('TrendFlipper', 'TF')
          : t('listHeader.strategy')}
      </div>

      <div className={styles.exchange}>{t('listHeader.exchange')}</div>
      <div className={styles.pair}>{t('listHeader.pairRisk')}</div>
      <div className={styles.pnl}>{t('listHeader.pnlTime')}</div>
      <div className={styles.actions}>{t('listHeader.actions')}</div>
    </div>
  );
}
