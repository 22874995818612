import React, { useState } from 'react';
import { Input } from '../../CCLabsUI';
import { ESizing } from '../../CCLabsUI/Input/Input';
import useChangeEmail from '../../../hooks/requests/useChangeEmail';
import { useCurrentUserEmailQuery } from '../../../redux/services/settingProfileService';
import styles from './ChangeEmailModal.module.css';
import CloseButton from '../../CloseButton/CloseButton';
import SecurityCheckWarningModal from '../SecurityCheckWarningModal/SecurityCheckWarningModal';
import ConfirmEmailChangeModal from '../ConfirmCredsChangeModal/ConfirmCredsChangeModal';
import EnterPasswordModal from '../EnterPasswordModal/EnterPasswordModal';
import { useTranslation } from 'react-i18next';

interface IChangeEmailModal {
  actionClose: () => void;
  currentEmail: string;
}

export default function ChangeEmailModal({ actionClose, currentEmail }: IChangeEmailModal) {
  const sessionId = sessionStorage.getItem('session_id');
  const token = sessionStorage.getItem('token');
  const { t } = useTranslation('profilePage');

  const [emailError, setEmailError] = useState<string | undefined>();
  const [isEmailValid, setIsEmailValid] = useState<boolean>(true);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState('');

  const [operationId, setOperationId] = useState<string | null>(null);
  const [isPasswordModalVisible, setIsPasswordModalVisible] = useState(false);
  const [isSecurityCheckModalVisible, setIsSecurityCheckModalVisible] = useState(false);
  const [isChangeEmailModalVisible, setIsChangeEmailModalVisible] = useState(false);

  const { refetch } = useCurrentUserEmailQuery({ sessionId, token });
  const [changeEmail, submitVerificationCode] = useChangeEmail();

  const onChangeNewEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const onEmailValidate = () => {
    const emailRegEx: RegExp = /^\S+@[a-z.]+\.[a-z]{2,}$/;
    setIsEmailValid(emailRegEx.test(email));
  };

  const onPasswordSubmit = (userPassword: string) => {
    setIsPasswordModalVisible(false);
    setIsSecurityCheckModalVisible(true);
    setPassword(userPassword);
  };

  const onSubmitVerificationCode = async (code: string) => {
    if (operationId) {
      const res = await submitVerificationCode(code, operationId, password);
      await refetch();
      return res;
    }
    return null;
  };

  const onPressSendCode = async () => {
    const operationId = await changeEmail(email, password);
    setIsSecurityCheckModalVisible(false);
    if (operationId) {
      setOperationId(operationId);
      setIsChangeEmailModalVisible(true);
      setIsEmailValid(true);
    } else {
      setIsEmailValid(false);
      setEmailError('Error!');
    }
  };

  const onCloseSecurityCheck = () => {
    setIsSecurityCheckModalVisible(false);
  };

  const onCloseConfirmEmailChangeModal = () => {
    setIsChangeEmailModalVisible(false);
    actionClose();
  };

  const onPasswordModalVisible = () => setIsPasswordModalVisible(true);

  return (
    <>
      <div className={styles.modalContainer}>
        <div className={styles.modal}>
          <CloseButton className={styles.closeButton} onClick={actionClose} />
          <h3>{t('updateEmail')}</h3>
          <Input
            id='email'
            autoComplete='off'
            label={t('email')}
            errorMessage={emailError}
            sizing={ESizing.large}
            placeholder='user@example.com'
            value={email}
            onChange={onChangeNewEmail}
            onBlur={onEmailValidate}
            onFocus={() => setIsEmailValid(true)}
            isValid={isEmailValid}
            className={styles.input}
          />
          <button
            className={styles.confirmBtn}
            disabled={!isEmailValid && !email}
            onClick={onPasswordModalVisible}
          >
            {t('nextBtn')}
          </button>
        </div>
      </div>
      {isSecurityCheckModalVisible && (
        <SecurityCheckWarningModal
          currentEmail={currentEmail ?? ''}
          actionClose={onCloseSecurityCheck}
          actionSubmit={onPressSendCode}
        />
      )}
      {isChangeEmailModalVisible && (
        <ConfirmEmailChangeModal
          email={currentEmail}
          credType='email'
          actionClose={onCloseConfirmEmailChangeModal}
          actionSubmit={onSubmitVerificationCode}
          actionResend={onPressSendCode}
        />
      )}
      {!isPasswordModalVisible && (
        <EnterPasswordModal
          actionClose={actionClose}
          actionSubmit={onPasswordSubmit}
        />
      )}
    </>
  );
}
