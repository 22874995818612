/* eslint-disable max-len */
interface ITrashIcon {
  width?: number;
  height?: number;
  color?: string;
}

export default function TrashIcon({ width = 16, height = 16, color = '#fff' }: ITrashIcon) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 21 21'
      fill='none'
    >
      <g clipPath='url(#clip0_1158_5551)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M8.88761 2.65066C8.66602 2.65066 8.45351 2.73794 8.29682 2.8933C8.14014 3.04867 8.05211 3.25939 8.05211 3.4791V4.30754H13.0651V3.4791C13.0651 3.25939 12.9771 3.04867 12.8204 2.8933C12.6637 2.73794 12.4512 2.65066 12.2296 2.65066H8.88761ZM7.11525 1.72171C7.58531 1.25562 8.22285 0.993774 8.88761 0.993774H12.2296C12.8944 0.993774 13.5319 1.25562 14.002 1.72171C14.472 2.1878 14.7361 2.81995 14.7361 3.4791V4.30754H18.0781C18.5395 4.30754 18.9136 4.67845 18.9136 5.13599C18.9136 5.59352 18.5395 5.96443 18.0781 5.96443H17.2426V16.7342C17.2426 17.3933 16.9785 18.0255 16.5085 18.4916C16.0384 18.9577 15.4009 19.2195 14.7361 19.2195H6.38111C5.71635 19.2195 5.07881 18.9577 4.60875 18.4916C4.13869 18.0255 3.87461 17.3933 3.87461 16.7342V5.96443H3.03911C2.57768 5.96443 2.20361 5.59352 2.20361 5.13599C2.20361 4.67845 2.57768 4.30754 3.03911 4.30754H6.38111V3.4791C6.38111 2.81995 6.64519 2.1878 7.11525 1.72171ZM5.54561 5.96443V16.7342C5.54561 16.9539 5.63364 17.1646 5.79032 17.32C5.94701 17.4753 6.15952 17.5626 6.38111 17.5626H14.7361C14.9577 17.5626 15.1702 17.4753 15.3269 17.32C15.4836 17.1646 15.5716 16.9539 15.5716 16.7342V5.96443H5.54561ZM8.88761 8.44976C9.34904 8.44976 9.72311 8.82066 9.72311 9.2782V14.2489C9.72311 14.7064 9.34904 15.0773 8.88761 15.0773C8.42618 15.0773 8.05211 14.7064 8.05211 14.2489V9.2782C8.05211 8.82066 8.42618 8.44976 8.88761 8.44976ZM11.3941 9.2782C11.3941 8.82066 11.7682 8.44976 12.2296 8.44976C12.691 8.44976 13.0651 8.82066 13.0651 9.2782V14.2489C13.0651 14.7064 12.691 15.0773 12.2296 15.0773C11.7682 15.0773 11.3941 14.7064 11.3941 14.2489V9.2782Z'
          fill={color}
        />
      </g>
      <defs>
        <clipPath id='clip0_1158_5551'>
          <rect
            width='20.052'
            height='19.8826'
            fill='white'
            transform='translate(0.532715 0.165283)'
          />
        </clipPath>
      </defs>
    </svg>
  );
}
