/* eslint-disable @typescript-eslint/no-magic-numbers */
import { useState } from 'react';
import cn from 'classnames';
import { LineChart, Line, CartesianGrid, XAxis, YAxis } from 'recharts';

import styles from './TotalRealizedPNL.module.css';
import Tabs, { ITab } from '../Tabs/Tabs';

import IconWithTooltip from '../../IconWithTooltip/IconWithTooltip';
import { useTranslation } from 'react-i18next';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import {
  dataFor30days,
  dataFor7days,
  dataFor90days,
  ticksData,
} from '../StatisticsMain/StatisticsMain.utils';

const TotalRealizedPNL = (): JSX.Element => {
  const { width } = useWindowDimensions();
  const lineChartWidth = Math.min(width * 0.77, 385);

  const [activeTab, setActiveTab] = useState('7');
  const { t } = useTranslation('totalStatistics');
  const tabs: ITab[] = [
    { value: '7', render: `7 ${t('days')}` },
    { value: '30', render: `30 ${t('days')}` },
    { value: '90', render: `90 ${t('days')}` },
  ];

  const demoData: Record<string, { date: string | number; value?: number }[]> = {
    '7': dataFor7days,
    '30': dataFor30days,
    '90': dataFor90days,
  };

  const selectedData = demoData[activeTab];

  const selectedTicks = ticksData[activeTab];
  return (
    <div className={styles.container}>
      <div className={cn(styles.header)}>
        <div className={styles.headerText}>
          {t('totalPNL')}
          <IconWithTooltip id='tooltip' content='Total realized PNL' />
        </div>
        <span>{t('updatedHour')}</span>
      </div>
      <Tabs activeTab={activeTab} tabs={tabs} onSetActiveTab={(tab) => setActiveTab(tab.value)} />
      <div className={cn([styles.chartText, styles.cardContent])}>
        {selectedData.length ? (
          <LineChart width={lineChartWidth} height={160} data={selectedData}>
            <CartesianGrid vertical={false} stroke='#afafaf' />
            <XAxis
              dataKey={'date'}
              ticks={selectedTicks}
              axisLine={false}
              stroke='white'
              tickLine={false}
              tickMargin={15}
            />
            <YAxis
              tickLine={false}
              axisLine={false}
              stroke='#afafaf'
              domain={['dataMin', 'dataMax']}
            />
            <Line
              dot={
                activeTab === '90' ? false : { stroke: '#9E96FA', fill: '#9E96FA', strokeWidth: 0 }
              }
              activeDot={false}
              dataKey='value'
              stroke='#9E96FA'
              strokeWidth={activeTab === '90' ? 1 : 2}
            />
          </LineChart>
        ) : (
          <p className={styles.noData}>No Data yet</p>
        )}
      </div>
    </div>
  );
};

export default TotalRealizedPNL;
