// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/img/BurgerIcon.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../assets/img/XIcon.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Header_vertical_indent__uAX1x {
  padding-top: 0px;
}

.Header_container__vI36D {
  position: fixed;
  top: 0px;
  width: 100%;
  background-color: rgba(34, 31, 54, 0.2);
  -webkit-backdrop-filter: blur(7px);
          backdrop-filter: blur(7px);
  z-index: 105;
}

.Header_wrapper__r1\\+ms {
  margin-left: auto;
  margin-right: auto;
}

.Header_menu__bKlxq {
  display: block;
  position: absolute;
  right: 16px;
  top: 16px;
  width: 24px;
  height: 24px;
  font-size: 0px;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-position: center;
  z-index: 3;
}

.Header_menu_open__U8\\+vr {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}

.Header_menu__bKlxq {
  display: none;
}

@media (max-width: 1100px) {
  .Header_wrapper__r1\\+ms {
    position: relative;
    max-width: auto;
  }

  .Header_menu__bKlxq {
    display: block;
  }

  div.Header_hidden__h44Et,
  button.Header_hidden__h44Et {
    display: none;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Header/Header.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,QAAQ;EACR,WAAW;EACX,uCAAuC;EACvC,kCAA0B;UAA1B,0BAA0B;EAC1B,YAAY;AACd;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,WAAW;EACX,SAAS;EACT,WAAW;EACX,YAAY;EACZ,cAAc;EACd,yDAAwD;EACxD,4BAA4B;EAC5B,2BAA2B;EAC3B,UAAU;AACZ;;AAEA;EACE,yDAAmD;AACrD;;AAEA;EACE,aAAa;AACf;;AAEA;EACE;IACE,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,cAAc;EAChB;;EAEA;;IAEE,aAAa;EACf;AACF","sourcesContent":[".vertical_indent {\n  padding-top: 0px;\n}\n\n.container {\n  position: fixed;\n  top: 0px;\n  width: 100%;\n  background-color: rgba(34, 31, 54, 0.2);\n  backdrop-filter: blur(7px);\n  z-index: 105;\n}\n\n.wrapper {\n  margin-left: auto;\n  margin-right: auto;\n}\n\n.menu {\n  display: block;\n  position: absolute;\n  right: 16px;\n  top: 16px;\n  width: 24px;\n  height: 24px;\n  font-size: 0px;\n  background-image: url('../../assets/img/BurgerIcon.svg');\n  background-repeat: no-repeat;\n  background-position: center;\n  z-index: 3;\n}\n\n.menu_open {\n  background-image: url('../../assets/img/XIcon.svg');\n}\n\n.menu {\n  display: none;\n}\n\n@media (max-width: 1100px) {\n  .wrapper {\n    position: relative;\n    max-width: auto;\n  }\n\n  .menu {\n    display: block;\n  }\n\n  div.hidden,\n  button.hidden {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"vertical_indent": `Header_vertical_indent__uAX1x`,
	"container": `Header_container__vI36D`,
	"wrapper": `Header_wrapper__r1+ms`,
	"menu": `Header_menu__bKlxq`,
	"menu_open": `Header_menu_open__U8+vr`,
	"hidden": `Header_hidden__h44Et`
};
export default ___CSS_LOADER_EXPORT___;
