import cn from 'classnames';
import Filter from '../../Filter/Filter';
import HeaderLeaderboard from './HeaderLeaderboard/HeaderLeaderboard';
import ListLeaderboard from './ListLeaderboard/ListLeaderboard';
import styles from './Leaderboard.module.css';
import { ESizing } from '../../Filter/Dropdown/Dropdown';
import { useTranslation } from 'react-i18next';
import { memo } from 'react';

interface ILeaderboard {
  isDisabled?: boolean;
}

function Leaderboard({ isDisabled = false }: ILeaderboard) {
  const { t } = useTranslation('launchBotPage');

  return (
    <div className={styles.wrapper}>
      <div className={cn(styles.container, { [styles.disabled]: isDisabled })}>
        <HeaderLeaderboard />
        <div className={styles.filter}>
          <Filter size={ESizing.medium} calendar={false} />
        </div>
        <ListLeaderboard />
      </div>
      {isDisabled && <p className={styles.soon}>{t('soon')}</p>}
    </div>
  );
}

export default memo(Leaderboard);
