import React, { memo } from 'react';
import styles from './BlockingOverlay.module.css';
import { useTranslation } from 'react-i18next';

interface IBlockingOverlay {
  title?: string;
}

function BlockingOverlay({ title }: IBlockingOverlay) {
  const { t } = useTranslation('webVersionOverlay');
  return (
    <div className={styles.container}>
      <h2>{title ?? t('title')}</h2>
    </div>
  );
}

export default memo(BlockingOverlay);
