import React from 'react';
import cn from 'classnames';
import styles from './StatisticsCurrencyValue.module.css';
import IconWithTooltip from '../../../IconWithTooltip/IconWithTooltip';

interface StatisticsCurrencyValueProps {
  value: number;
  activeCurrency: string;
  description: string;
  isBluredAll: boolean;
  color?: string;
}

const pattern: RegExp = /[-+\d . ,]+/;

function StatisticsCurrencyValue({
  value,
  activeCurrency,
  description,
  isBluredAll,
}: StatisticsCurrencyValueProps): JSX.Element {
  const FIXED_LENGTH = 4;
  const profitValue = `${
    isBluredAll ? value.toString().replace(pattern, '*******') : value.toFixed(FIXED_LENGTH)
  }`;
  const currencyClasses = cn(
    styles.currencyValue,
    !isBluredAll && {
      [styles.positive]: value > 0,
      [styles.negative]: value < 0,
    },
  );
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={currencyClasses}>
          <span>{profitValue}</span>
          <span className={styles.currency}>{activeCurrency}</span>
        </div>
        <div className={styles.description}>
          {description}
          <IconWithTooltip id='StatisticsCurrencyValue__tooltip' content={description} />
        </div>
      </div>
    </div>
  );
}

export default StatisticsCurrencyValue;
