/* eslint-disable @typescript-eslint/naming-convention */
import { Dispatch, SetStateAction } from 'react';
import {
  useGetExchangesLabelsMutation,
  useGetExchangesMarketsMutation,
  useGetExchangesPairsMutation,
} from '../../redux/services/exchangesService';
import { formatSnakeObjKeysToCamelCase } from '../../utils/formatSnakeToCamelCase';
import { EExchangeType, EMarketType } from '../../redux/types';
import { useNavigateToSingIn } from '../../utils/navigateToSingIn';

interface IUseRequestExchangesFilterDataProps {
  token: string | null;
  sessionId: string | null;
  marketType?: EMarketType;
  setExchangeOptions: Dispatch<SetStateAction<EExchangeType[]>>;
  setMarketOptions: Dispatch<SetStateAction<EMarketType[]>>;
  setPairsOptions: Dispatch<SetStateAction<string[]>>;
}

const useRequestExchangesFilterData = ({
  token,
  sessionId,
  marketType,
  setExchangeOptions,
  setMarketOptions,
  setPairsOptions,
}: IUseRequestExchangesFilterDataProps) => {
  const [getExchangesLabels] = useGetExchangesLabelsMutation();
  const [getExchangesMarkets] = useGetExchangesMarketsMutation();
  const [getExchangesPairs] = useGetExchangesPairsMutation();
  const navigateToSingIn = useNavigateToSingIn();
  const requestExchangesFilterData = async () => {
    const exchangesLabelsResponse = await getExchangesLabels({ token, sessionId })
      .unwrap()
      .catch((error) => {
        console.log(error);
        navigateToSingIn(error);
      });
    const { exchangeLabels } = formatSnakeObjKeysToCamelCase(exchangesLabelsResponse) as {
      exchangeLabels: EExchangeType[];
    };
    // todo: change exchange labels logic when more exchanges labels will be added
    setExchangeOptions((prevOptions) => (prevOptions.length ? prevOptions : exchangeLabels));

    const exchangesMarketsResponse = await getExchangesMarkets({
      token,
      sessionId,
      exchangeLabel: exchangeLabels,
    }).unwrap();

    const { marketLabels } = formatSnakeObjKeysToCamelCase(exchangesMarketsResponse) as {
      marketLabels: EMarketType[];
    };
    setMarketOptions(marketLabels);
    if (!marketType) {
      return;
    }

    const exchangesPairsResponse = await getExchangesPairs({
      token,
      sessionId,
      exchangeLabel: exchangeLabels,
      exchangeMarket: marketType,
    }).unwrap();
    const { instrumentLabels } = formatSnakeObjKeysToCamelCase(exchangesPairsResponse) as {
      instrumentLabels: string[];
    };
    setPairsOptions(instrumentLabels);
  };

  return [requestExchangesFilterData];
};

export default useRequestExchangesFilterData;
