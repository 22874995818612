import { Dispatch, SetStateAction } from 'react';

const onSelectItems = <T>(array: T[], setter: Dispatch<SetStateAction<T[]>>, groupName: T) => {
  if (array.includes(groupName)) {
    setter(array.filter((item) => item !== groupName));
  } else {
    setter([...array, groupName]);
  }
};

export default onSelectItems;
